
const FORM_STATE_PREFIX = 'formState_';

export const formStateManager = {
  setFormState: (formId, state) => {
    localStorage.setItem(`${FORM_STATE_PREFIX}${formId}`, JSON.stringify(state));
  },
  getFormState: (formId) => {
    const state = localStorage.getItem(`${FORM_STATE_PREFIX}${formId}`);
    return state ? JSON.parse(state) : null;
  },
  clearFormState: (formId) => {
    localStorage.removeItem(`${FORM_STATE_PREFIX}${formId}`);
  }
};