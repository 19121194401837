import {
  Box,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ViewClientTop from "../components/viewClient/ViewClientTop";
import ViewClientInfo from "../components/viewClient/ViewClientInfo";
import ViewClientBottom from "../components/viewClient/ViewClientBottom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../slice";
import { watchClientSaga } from "../saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import ViewClientLocation from "../components/viewClient/ViewClientLocation";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import AnalyticsDashboard from "views/Dashboard/Analytics Dashboard";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";

const ViewClient = ({ location }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchClientSaga });

  const [rerenderKey, setRerenderKey] = useState(0);
  const [locationArray, setLocationArray] = useState([]);
  const [tab, setTab] = useState("Client Profile");

  const dispatch = useDispatch();
  const { userProfileData } = useUserContext();
  const profiles = userProfileData?.profiles;
  const entity = getCurrentProfile(profiles);
  const userRole = entity?.role?.name;

  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get("clientId");
  const customerId = searchParams.get("customerId");

  const clientDetail = useSelector(selectors.selectClientDetail);
  const clientAddresses = useSelector(selectors.selectClientAddresses);
  const clientLinks = useSelector(selectors.selectClientLink);
  const clientAvatar = useSelector(selectors.selectAvatarUrl);
  const originLocations = useSelector(selectors.selectWarehouseData);

  const addressArray = clientAddresses && clientAddresses[clientId];
  const clientEmailPhone = clientLinks && clientLinks[clientId];
  useEffect(() => {
    dispatch(actions.fetchOneClientDetails({ customerId, clientId }));
    dispatch(actions.fetchOneClientLinks({ customerId, clientId }));
    dispatch(actions.fetchOneClientAddresses({ customerId, clientId }));
    dispatch(actions.fetchWarehouseDetailsRequest(clientId));
  }, [customerId, clientId, rerenderKey]);

  const handleTabChange = (val) => {
    setTab(val);
  };

  useEffect(() => {
    if (originLocations) {
      let tempArr = originLocations.map((location) => {
        return {
          id: location.id,
          name: location.name || "",
          externalCode: location?.externalCode,
          area: null,
          additionalProperties: {},
          city: location.city || "",
          contactPerson: location.contactPerson || "",
          originType: location.originType || "",
          addressLine1: location.address.addressLine1 || "",
          addressLine2: location.address.addressLine2 || "",
          postalCode: location.postalCode || "",
          state: location.state || "",
          links: location.links,
          isEditing: false,
          isFromDB: true,
          isChanged: false,
          status: location.status || "",
        };
      });

      setLocationArray(tempArr);
    }
  }, [originLocations]);

  useEffect(() => {
    if (clientDetail && clientDetail.avatar) {
      const { avatar } = clientDetail;
      dispatch(actions.fetchAvatarURL({ payload: avatar }));
    }
  }, [clientDetail]);
  const handleDeactivateButton = (isSwitchOn) => {
    if (isSwitchOn === true) {
      dispatch(
        actions.fetchChangeClientStatus({
          customerId,
          isActive: true,
          clientId,
        })
      );
    } else {
      dispatch(
        actions.fetchChangeClientStatus({
          customerId,
          isActive: false,
          clientId,
        })
      );
    }
    setRerenderKey((prevKey) => prevKey + 1);
    if (rerenderKey > 10000) {
      setRerenderKey(0);
    }
  };
  useEffect(() => {
    return () => {
      setLocationArray([]);
      // dispatch(actions.clearWarehouseDetails());
    };
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Tabs w="100%" position="relative" colorScheme="teal">
          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            align="flex-start"
            sx={{ marginBottom: { base: "32px", md: "36px" } }}
            flexDirection={{ base: "column", md: "row" }}
            gap={{ base: "24px", md: "inherit" }}
          >
            <Text
              sx={{
                color: "#2D3748",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "140%",
              }}
            >
              {_.startCase(tab)}
            </Text>
            <TabList w={{ base: "100%", md: "auto" }}>
              <HStack w={"100%"}>
                <Tab
                  onClick={() => handleTabChange("clientProfile")}
                  key={"clientProfile"}
                  w={{ base: "100%", md: "auto" }}
                >
                  Client Profile
                </Tab>
                <Tab
                  onClick={() => handleTabChange("analytics")}
                  key={"analytics"}
                  w={{ base: "100%", md: "auto" }}
                >
                  Analytics
                </Tab>
              </HStack>
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel p="0px">
              <Flex direction={"column"} gap={"36px"}>
                <ViewClientTop
                  clientDetail={clientDetail}
                  addressArray={addressArray}
                  clientAvatar={clientAvatar}
                  clientId={clientId}
                  customerId={customerId}
                  userRole={userRole}
                />
                <ViewClientInfo
                  clientDetail={clientDetail}
                  addressArray={addressArray}
                  clientEmailPhone={clientEmailPhone}
                />
                <ViewClientLocation locationArray={locationArray} />

                {userRole === "admin" ? (
                  <ViewClientBottom
                    clientId={clientId}
                    customerId={customerId}
                    handleDeactivateButton={handleDeactivateButton}
                    clientDetail={clientDetail}
                  />
                ) : null}
              </Flex>
            </TabPanel>
            <TabPanel p="0px">
              <AnalyticsDashboard isForClient={true} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ErrorBoundary>
  );
};

export default ViewClient;
