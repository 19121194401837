import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "../Create/slice";

const selectDomain = (state) => state.homePage || initialState;

export const selectForm = createSelector([selectDomain], (state) => state.form);

export const selectSections = createSelector(
  [selectDomain],
  (state) =>
    state.sections &&
    state.sections.map((section) => ({
      id: section.id,
      order: section.order,
      title: section.title,
    }))
);

export const selectComponents = createSelector([selectDomain], (state) => {
  const sections = state.sections;
  const blocks = state.blocks;
  const composites = state.composites;

  const res = [];
  let sectionComponentMap = {};

 if (sections && blocks && composites){ sections.forEach((section) => {
    const sectionId = section.id;
    section?.structure?.blocks?.forEach((currBlock) => {
      const blockId = currBlock.id;
      const block = blocks.find((block) => block.id === blockId);
      block?.structure?.composites?.forEach((currComposite) => {
        const compositeId = currComposite.id;
        const composite = composites.find(
          (composite) => composite.id === compositeId
        );
        const components = composite?.structure?.components || [];
        components.forEach(comp => {
            sectionComponentMap[comp.id] = sectionId;
        })
        if (components.length) {
          res.push(...components);
        }
      });
    });
  });}

  return { components: res, sectionComponentMap };
});

export const selectSectionsForPost = createSelector(
  [selectDomain],
  (state) => state.sections
);
export const selectBlocksForPost = createSelector(
  [selectDomain],
  (state) => state.blocks
);
export const selectCompositesForPost = createSelector(
  [selectDomain],
  (state) => state.composites
);
export const selectComponentsForPost = createSelector(
  [selectDomain],
  (state) => state.components
);
