import React from "react";
import { Box, Checkbox } from "@chakra-ui/react";

export default function CheckBox({ formData, handleFormDataChange, data }) {
  return (
    <Box
      style={{
        border: "1px solid #EDF2F7",
        borderRadius: "8px",
        padding: "12px",
      }}
    >
      <Checkbox
        size="md"
        name={data.element.id}
        value={formData[data.element.id]}
        onChange={handleFormDataChange}
        colorScheme="login"
      >
        {data.element.label}
      </Checkbox>
    </Box>
  );
}
