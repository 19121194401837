import React, { useEffect } from "react";
import {
  Checkbox,
  VStack,
  HStack,
  Text,
  Select,
  Box,
  Button,
  RadioGroup,
  Stack,
  Radio,
  Flex,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

const FileProperties = ({
  fileOptions,
  handleOptionChange,
  handleCountChange,
  handleFileOptionsSubmit,
  isValid,
  validationFileOptions,
  errorStyle,
}) => {
  const fileTypes = [
    { value: "doc", label: "Document" },
    { value: "image", label: "Image" },
    { value: "pdf", label: "PDF" },
    { value: "spreadSheet", label: "Spread Sheet" },
    { value: "vid", label: "Video" },
    { value: "all", label: "All" },
  ];

  useEffect(() => {
    handleFileOptionsSubmit();
  }, [fileOptions]);

  return (
    <VStack align="flex-start" spacing={4}>
      <HStack
        w="100%"
        gap="16px"
        style={
          !isValid && !validationFileOptions?.allowedFileTypes
            ? { ...errorStyle, paddingLeft: "16px" }
            : { paddingLeft: "16px" }
        }
      >
        <Text>Allow only specific file types :</Text>
        <RadioGroup
          onChange={(value) => {
            handleOptionChange(value, "allowedFileTypes");
          }}
          value={
            fileOptions?.allowedFileTypes?.length
              ? fileOptions?.allowedFileTypes[0]
              : null
          }
          gap={"20px"}
          display="flex"
        >
          {fileTypes.map((fileType) => (
            <Flex spacing={10}>
              <Radio value={fileType.value} mx="0px">
                {fileType.label}
              </Radio>
            </Flex>
          ))}
        </RadioGroup>
        {/* {fileTypes.map((fileType) => (
          <Checkbox
            key={fileType.value}
            isChecked={fileOptions?.allowedFileTypes?.includes(fileType?.value)}
            onChange={() => handleOptionChange(fileType?.value)}
          >
            {fileType.label}
          </Checkbox>
        ))} */}
      </HStack>
      <HStack
        w="60%"
        gap="16px"
        style={
          !isValid && !validationFileOptions?.maxFileCount
            ? { ...errorStyle, paddingLeft: "16px" }
            : { paddingLeft: "16px" }
        }
      >
        <Text w="65%">Maximum number of files:</Text>
        <Select
          value={fileOptions?.maxFileCount}
          onChange={(e) => {
            handleCountChange(e, "maxFileCount");
          }}
        >
          <option value="">Select Max Files</option>
          {[1, 5, 10].map((count) => (
            <option key={count} value={count}>
              {count}
            </option>
          ))}
        </Select>
      </HStack>
      <HStack
        w="60%"
        gap="16px"
        style={
          !isValid && !validationFileOptions?.maxFileSize
            ? { ...errorStyle, paddingLeft: "16px" }
            : { paddingLeft: "16px" }
        }
      >
        <Text w="65%">Maximum File Size:</Text>
        <Select
          value={fileOptions?.maxFileSize}
          onChange={(e) => {
            handleCountChange(e, "maxFileSize");
          }}
        >
          <option value="">Select Max File Size</option>
          {[1, 10, 25].map((count) => (
            <option key={count} value={count}>
              {`${count} MB`}
            </option>
          ))}
        </Select>
      </HStack>
    </VStack>
  );
};

export default FileProperties;
