import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const local = localStorage.getItem("inspectionDashboard");
  const loginData = JSON.parse(local);
  const token = loginData?.token;

  useEffect(() => {
    console.log(process.env.REACT_APP_SOCKET_BASE_ENDPOINT)
    const newSocket = io(process.env.REACT_APP_SOCKET_BASE_ENDPOINT, {
      transports: ["websocket"],
      auth: { token },
    });

    newSocket.on("connect", () => {
      setIsConnected(true);
      console.log("Connected to socket server");
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection error:", err);
    });

    newSocket.on("disconnect", () => {
      setIsConnected(false);
      console.log("Disconnected from socket server");
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket, isConnected, setIsConnected }}>
      {children}
    </SocketContext.Provider>
  );
};
