import { Table } from "@agnext/reactlib";
import { Box, CircularProgress, Flex, VStack } from "@chakra-ui/react";
import noRecordImage from "../../../../../assets/img/Empty Case.png";

const styles = {
  message: {
    display: "flex",
    width: "100%",
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "190px",
    margin: "auto",
  },
};

const EntityTable = ({ data }) => {
  const { headerList, dataList, unit, loading } = data || {
    headerList: [],
    dataList: [],
    unit: "",
  };

  if (loading) {
    return (
      <Flex justifyContent="center" w="100%" height="100px">
        <CircularProgress
          isIndeterminate
          color="#4FD1C5"
          size="45px"
          alignSelf={"center"}
        />
      </Flex>
    );
  }
  if (dataList && dataList.length) {
    return (
      <Box
        w="100%"
        // w={"calc(50% - 12px)"}
        sx={{
          border: "1px solid #E2E8F0",
          boxShadow: "0px 3.499999761581421px 5.5px rgba(0, 0, 0, 0.02)",
          background: "#fff",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "20px 24px",
            alignItems: "center",
            gap: "16px",
            alignSelf: "stretch",
            color: "var(--Grey-Gray-600, #4A5568)",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "150%" /* 27px */,
          }}
        >
          {`${dataList?.length} ${unit}`}
        </Box>
        <Table
          overflowY="auto"
          headerList={headerList}
          tableContainerHeight={{ base: "400px", sm: "600px" }}
          dataList={dataList}
          stickyColumn={["Action"]}
          fixedHeader={true}
        />
      </Box>
    );
  }
  return (
    <VStack style={styles.message}>
      <img style={styles.image} src={noRecordImage} alt="No Records Image" />
      <p>No Data Available</p>
    </VStack>
  );
};

export default EntityTable;
