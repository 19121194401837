import {
  HStack,
  Badge,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import React from "react";
import _ from "lodash"; // Ensure you have lodash for capitalize function

export default function InspectionTypes({ inspectionTypes }) {
  const typesLength = inspectionTypes.length;

  return (
    <>
      {inspectionTypes[0] ? (
        <HStack>
          <Badge
            backgroundColor="#718096"
            color="#fff"
            textTransform="uppercase"
            borderRadius="4px"
          >
            {inspectionTypes[0]}
          </Badge>

          {typesLength > 1 && (
            <Menu>
              <MenuButton
                as={Badge}
                backgroundColor="#718096"
                color="#fff"
                borderRadius="4px"
                cursor="pointer"
              >
                {`+${typesLength - 1}`}
              </MenuButton>
              <Box position={"inherit"} zIndex={"10"}>
                <MenuList
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "8px",
                    borderRadius: "8px",
                    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
                    border: "1px solid #E2E8F0",
                    background: "#FFF",
                    position: "relative",
                  }}
                >
                  {inspectionTypes.slice(1).map((type, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        display: "flex",
                        height: "32px",
                        padding: "8px 12px",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "8px",
                        background: "#FFF",
                        color: "#4A5568",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      {_.capitalize(type)}
                    </MenuItem>
                  ))}
                </MenuList>
              </Box>
            </Menu>
          )}
        </HStack>
      ) : (
        <span>-</span>
      )}
    </>
  );
}
