import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "../Create/slice";

const selectDomain = (state) => state.homePage || initialState;

export const selectWorkflow = createSelector(
  [selectDomain],
  (state) => state.workflow
);

export const loading = createSelector([selectDomain], (state) => state.loading);

export const selectForms = createSelector(
  [selectDomain],
  (state) => state.forms
);
