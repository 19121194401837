export const countryCodes = ["India +91", "UAE +971"];

export const locationTypes = [
  "warehouse",
  "container",
  "truck",
  "rake",
  "kitchen",
  "shop",
  "mandi",
  "marketplace",
];