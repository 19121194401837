import React, { useState } from "react";
import GaugeChart from "react-gauge-chart";
import { Box, Center, HStack, Text } from "@chakra-ui/react";

const styles = {
  gaugeLimitContainer: {
    padding: "0 52px 0 53px",
    width: "100%",
    justifyContent: "space-between",
  },
  gaugeLimit: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  inspScore: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
  },
  gaugeChartContainer: {
    width: "375px",
    height: "220px",
  },
};

function GaugeCharts(props) {
  const { color, arcLength, needlePosition } = props;
  return (
    <Box position={"relative"}>
      <Box sx={styles.gaugeChartContainer} position={"relative"}>
        <GaugeChart
          arcWidth={0.33}
          cornerRadius={5}
          // formatTextValue={(value) => value}
          arcPadding={0.02}
          percent={needlePosition/100}
          colors={color}
          arcsLength={arcLength}
          needleColor="#BCBCBC"
          needleBaseColor="#BCBCBC"
          hideText={true}
        />
        <HStack
          sx={styles.gaugeLimitContainer}
          position={"absolute"}
          top={"158px"}
        >
          <Text sx={styles.gaugeLimit}>0%</Text>
          <Text sx={styles.gaugeLimit}>100%</Text>
        </HStack>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        w={"100%"}
        position={"absolute"}
        top={"180px"}
      >
        <Text sx={styles.inspScore}>{needlePosition}</Text>
      </Box>
    </Box>
  );
}

export default GaugeCharts;
