import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "../Create/slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";

export function* handleFetchWorkflows() {
  try {
    //console.log("saga was hit");
    const res = yield call(API.fetchWorkflows);
    //console.log("res", res);
    const data = res.data.payload;
    yield put(actions.fetchWorkflowsSuccess(data));
  } catch (err) {
    // console.log(err);
    yield put(actions.fetchWorkflowsFailure(err?.response?.data));
  }
}

export function* handleFetchWorkflow({ payload }) {
  try {
    //console.log("saga was hit");
    const res = yield call(API.fetchWorkflow, payload.workflowId);
    const data = res.data.payload;
    yield put(
      actions.fetchWorkflowSuccess({
        workflow: data.workflow,
        forms: data.forms,
      })
    );
    if(res.status === 200 && payload?.onSuccess){
      payload.onSuccess();
   }
  } catch (err) {
    // console.log(err);
    yield put(actions.fetchWorkflowFailure(err?.response?.data));
    payload.onError();
  }
}

export function* handleInitWorkflow({ payload }) {
  try {
    const resWorkflow = yield call(API.postWorkflow, payload.workflowData);
    const resForms = yield call(API.postFormsData, payload.formData);
    const resWorkflowForms = yield call(
      API.postWorkflowFormData,
      payload.workflowFormData
    );
    yield put(actions.initWorkflowSuccess({ resWorkflow }));
    payload.onSuccess();
  } catch (err) {
    yield put(actions.initWorkflowFailure(err?.response?.data));
    payload.onError(err?.response?.data?.reason);
  }
}

export function* handlePostFormData({ payload }) {
  try {
    const res = yield call(API.postFormData, payload.data);

    yield put(actions.postFormDataSuccess());
    payload.onSuccess();
  } catch (err) {
    payload.onError();
    //console.log({ err });
    yield put(actions.postFormDataFailure(err?.response?.data));
  }
}

export function* handleDeleteForm({ payload }) {
  try {
    const res = yield call(API.deleteForm, payload);

    yield put(actions.deleteFormSuccess());
    payload.onSuccess();
  } catch (err) {
    //console.log({ err });
    yield put(actions.deleteFormFailure(err?.response?.data));
    payload.onError();
  }
}

export function* handlePostWorkflow({ payload }) {
  try {
    //console.log("saga was hit");
    const res = yield call(API.postWorkflow, payload);
    //console.log("res", res);
    const data = res.data.payload;
    yield put(actions.fetchWorkflowsSuccess(data));
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchWorkflowsFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* WorkflowManagerSaga() {
  yield takeLatest(actions.fetchWorkflows.type, handleFetchWorkflows);
  yield takeLatest(actions.fetchWorkflow.type, handleFetchWorkflow);
  yield takeLatest(actions.postWorkflow.type, handlePostWorkflow);
  yield takeLatest(actions.initWorkflow.type, handleInitWorkflow);
  yield takeLatest(actions.deleteForm.type, handleDeleteForm);
  yield takeLatest(actions.postFormData.type, handlePostFormData);
}
