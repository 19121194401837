//This validation can be reused in case the min and max length changes.
export const isValidLength = (inputString, minLength, maxLength ) => {
    const trimmedInput = inputString?.trim();
    const length = trimmedInput?.length;
    
    return length >= minLength && length <= maxLength 
}

// Regular expression pattern to be passed as argument.
export const allowedSpecialChars = (inputString, allowedRegex) => {
    const regex = allowedRegex 
    return regex.test(inputString) 
}

// Regular expressions to match at least one letter and one number
export const isAlphaNumeric = (inputStr ) => {
    const letterRegex = /[a-zA-Z]/;
    const numberRegex = /\d/;

    return letterRegex.test(inputStr) && numberRegex.test(inputStr) 
}
