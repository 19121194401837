import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
  AddIcon,
} from "@chakra-ui/icons";
import { MdArrowForwardIos } from "react-icons/md";
import Component from "../component/Component";

export default function Section({
  section,
  data,
  formData,
  handleFormDataChange,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  page,
}) {
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [isSectionHidden, setIsSectionHidden] = useState(false);
  const handleToggleSectionHidden = () => {
    setIsSectionHidden((prev) => {
      return !prev;
    });
  };
  return (
    <VStack w="100%" aria-label="section" gap="24px">
      <HStack
        style={{
          background: "#EDF2F7",
          borderRadius: "8px",
          padding: "8px 16px 8px 8px",
          width: "100%",
        }}
        justify="space-between"
        align="flex-start"
      >
        <HStack justify="flex-start" align="flex-start">
          <IconButton
            variant="ghost"
            borderRadius="8px"
            // isDisabled={index < 1}
            sx={{
              ".icon": {
                color: "#718096",
              },
            }}
            icon={
              isSectionHidden ? (
                <MdArrowForwardIos w="26px" h="26px" className="icon" />
              ) : (
                <CollapseIcon w="26px" h="26px" className="icon" />
              )
            }
            onClick={handleToggleSectionHidden}
          />
          <VStack align="flex-start">
            <Text
              color="#4A5568"
              fontSize={isMobileScreen ? "16px" : "22px"}
              fontWeight="600"
              lineHeight="1.4"
            >
              {section.title}
            </Text>
            <Text
              color="#718096"
              fontSize={isMobileScreen ? "10px" : "12px"}
              fontWeight="400"
              lineHeight="1.4"
            >
              {section.description}
            </Text>
          </VStack>
        </HStack>
        {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
      </HStack>
      {data.map((el, index) => {
        return (
          <Component
            key={index}
            el={el}
            isSectionHidden={isSectionHidden}
            formData={formData}
            handleFormDataChange={handleFormDataChange}
            displayData={displayData}
            setPage={setPage}
            navIndex={navIndex}
            setNavIndex={setNavIndex}
            page={page}
          />
        );
      })}
    </VStack>
  );
}
