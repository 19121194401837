import { DatePicker } from "@agnext/reactlib";
import Document from "assets/svg/document2.svg";
import Eye from "assets/svg/eye-icon.svg";
import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import Select from "../Select";

import React, { useCallback, useEffect, useState } from "react";
import ViewFormModal from "./ViewFormModal";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { sub } from "date-fns";

const FormDetails = ({ workflowData, assigneeList, taskData, setTaskData }) => {
   const [formDates, setFormDates] = useState({});
   const [datePickerWidth, setDatePickerWidth] = useState(0);


   useEffect(() => {
      const leftBox = document.querySelector(".left-box");
      const handleResize = () => {
         const leftBox = document.querySelector(".left-box");
         if (leftBox) {
            setDatePickerWidth(leftBox.offsetWidth);
         }
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
   }, [taskData]);

   const handleDateChange = (date, formId) => {
      setFormDates((prevDates) => ({
         ...prevDates,
         [formId]: date,
      }));

      const updatedTaskData = taskData.map((form) => {
         if (form.id == formId) {
            return { ...form, date };
         }
         return form;
      });
      setTaskData(updatedTaskData);
   };

   const handleAssigneeChange = (assigneeId, formId) => {
      const updatedTaskData = taskData.map((form) => {
         if (form.id == formId) {
            return { ...form, assigneeId };
         }
         return form;
      });
      setTaskData(updatedTaskData);
   };

   useEffect(() => {
      if (workflowData && workflowData.forms) {
         setFormDates({});
         const taskData = JSON.parse(JSON.stringify(workflowData.forms))
            .sort((a, b) => a.order - b.order)
            .map((form) => ({
               workflowId: workflowData.workflow.id,
               id: form.id,
               date: null,
               assigneeId: null,
               name: form.title,
            }));
         setTaskData(taskData);
      }
   }, [workflowData]);

   const getAssigneeId = useCallback((formId) => {
      taskData.reduce((acc, cv) => {
         if (cv.id === formId) {
            acc += cv.assigneeId;
         } else {
            acc += "";
         }
         return acc;
      }, "");
   }, []);

   const {
      isOpen: viewFormModalIsOpen,
      onOpen: openViewFormModal,
      onClose: closeViewFormModal,
   } = useDisclosure();

   const [openFormId, setOpenFormId] = useState(null);

   return (
      <>
         {workflowData &&
            workflowData.forms
               .slice()
               .sort((a, b) => a.order - b.order)
               .map((form) => {
                  return (
                     <Flex gap={"16px"} key={form.id}>
                        <Flex
                           justifyContent="space-between"
                           flex="1"
                           h="40px"
                           bg="#EDF2F7"
                           borderRadius="8px"
                        >
                           <Flex>
                              <Flex w="40px" alignItems="center" justifyContent="center">
                                 <Image src={Document} />
                              </Flex>
                              <Flex alignItems="center" justifyContent="center">
                                 <Text color="#4A5568">{form.title}</Text>
                              </Flex>
                           </Flex>
                           <Flex w="40px" alignItems="center" justifyContent="center">
                              <Box cursor={"pointer"}>
                                 <Image
                                    src={Eye}
                                    onClick={() => {
                                       setOpenFormId(form.id);
                                       openViewFormModal();
                                    }}
                                 />
                              </Box>
                              {viewFormModalIsOpen && (
                                 <ViewFormModal
                                    isOpen={viewFormModalIsOpen}
                                    onClose={closeViewFormModal}
                                    openFormId={openFormId}
                                    formTitle={capitalizeStringUpdated(workflowData.workflow.title)}
                                 />
                              )}
                           </Flex>
                        </Flex>
                        <Box flex="1" className="left-box">
                           <Select
                              placeholder="Select"
                              customKey="assignee"
                              options={assigneeList}
                              formId={form.id}
                              handleAssigneeChange={handleAssigneeChange}
                              value={getAssigneeId(form.id)}
                              isPositionTop={true}
                           />
                        </Box>
                        <Box flex="1">
                           <DatePicker
                              height={40}
                              headerTextSize={12}
                              width={datePickerWidth}
                              colorSelected="#fff"
                              backgroundSelected="#4FD1C5"
                              date={formDates[form.id] ? formDates[form.id] : null}
                              setDate={(date) => handleDateChange(date, form.id)}
                              minDate={sub(new Date(), { days: 1 })}
                           />
                        </Box>
                     </Flex>
                  );
               })}
      </>
   );
};

export default FormDetails;
