import React, { useEffect, useState } from "react";
import { Select } from "@agnext/reactlib";

export default function Dropdown({ data, formData, handleFormDataChange }) {
  const [optionValue, setOptionValue] = useState(
    formData[data.element.id]?.value
  );
  useEffect(() => {
    if (optionValue !== undefined) {
      handleFormDataChange(optionValue, "", data.element.id, "NA");
    }
  }, [optionValue, data.element.id]);

  useEffect(() => {
    setOptionValue(formData[data.element.id]?.value);
  }, [data.element.id, formData]);

  return (
    <>
      {data &&
      data.element &&
      data.element.properties &&
      data.element.properties.options ? (
        <Select
          value={optionValue}
          size="md"
          name="1"
          focusBorderColor="login.500"
          options={data.element.properties.options}
          onChange={(e) => setOptionValue(e.target.value)}
        />
      ) : (
        <></>
      )}
    </>
  );
}
