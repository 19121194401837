import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { ItemContent } from "components/Menu/ItemContent";
import React from "react";
import { styles } from "../style";

function Notification({
  modifiedResponse,
  handleMarkAllRead,
  handleNotificationRead,
}) {
  return (
    <>
      <Flex sx={styles.notificationPageMainContainer}>
        <Box sx={styles.notificationInnerContainer}>
          <HStack sx={styles.notificationTopContainer}>
            <Text sx={styles.notificationNewText}>
              {modifiedResponse?.length + " " + "New Notifications"}
            </Text>
            <Text
              sx={styles.notificationMarkReadText}
              onClick={handleMarkAllRead}
            >
              Mark all as read
            </Text>
          </HStack>
          <Box
            sx={styles.notificationMiddleContainer}
            borderTopRadius="15px"
            border="1px solid #E2E8F0"
          >
            {modifiedResponse?.map((notification) => (
              <Box
                m="0"
                p="0"
                borderBottom="1px solid #E2E8F0"
                borderTopRadius="8px"
                onClick={handleNotificationRead}
              >
                <ItemContent
                  time={notification.time}
                  info={"Audit report available for"}
                  boldInfo={notification.message.text}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default Notification;
// {
//   "id": "fef150b3-dd5b-42a2-af02-63becc2d82b1",
//   "userId": "fae0fcc1-3935-4ffa-98b6-1ad63bca8819",
//   "message": {
//       "text": "Audit report available for TIRUPATI INDUSTRIAL PLOTS GODOWN NO 5A",
//       "title": "inspection"
//   },
//   "objectInfo": {
//       "id": "4cd48e70-1fc1-4b74-91ca-52523b953b83",
//       "type": "inspection"
//   },
//   "type": "inspection",
//   "status": true,
//   "actionDt": "2023-09-05T05:48:10.743Z",
//   "creationDt": "2023-09-04T12:01:08.793Z"
// }
