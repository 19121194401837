import {
  Box,
  CircularProgress,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import View from "views/Workflow Builder/pages/View";

const ViewFormModal = ({ isOpen, onClose, openFormId, formTitle }) => {
  const [isLoading, setIsLoading] = useState(true);

  console.log("Hello world")

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      setIsLoading(true);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"3xl"}>
        <ModalOverlay />

        <ModalContent width="100%">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            <Text>{formTitle}</Text>
          </ModalHeader>
          <ModalCloseButton />
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress isIndeterminate color="green.300" />
            </Box>
          ) : (
            <Box overflowY="auto" maxHeight="70vh" borderRadius="15px">
              {openFormId && (
                <View key={openFormId} formId={openFormId} width="100%" marginTop="0px" />
              )}
            </Box>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewFormModal;
