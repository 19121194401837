import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import deleteIcon from "assets/svg/deleteRed.svg";
import React, { useState } from "react";
import { CustomModal } from "./Modals/CustomModal";
const header = {
  color: "#2D3748",
  fontSize: 16,
  fontFamily: "Inter",
  fontWeight: "600",
  lineHeight: "22.40px",
  wordWrap: "break-word",
  width: "100%",
};
const styles = {
  outerContainer: {
    direction: { base: "column", md: "row" },
    justifyContent: "space-between",
    gap: { base: 2, md: "16px" },
    width: "100%",
  },
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    background: "white",
    boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    flex: 1,
  },
  text: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#718096",
  },
  switchContainer: {
    color: "#1A202C",
    gap: "8px",
    alignItems: "center",
    lineHeight: "16px",
  },
};
const isSwitchOn = (status) => {
  return status == "not-required" ? true : false;
};

const ViewJobBottomCard = ({
  handleDeleteJobDispatch,
  handleUpdateJobDispatch,
  status,
}) => {
  const {
    isOpen: notRequiredModalOpen,
    onOpen: notRequiredModalOnOpen,
    onClose: notRequiredModalClose,
  } = useDisclosure();
  const {
    isOpen: confirmJobDeleteModalOpen,
    onOpen: confirmJobDeleteModalOnOpen,
    onClose: confirmJobDeleteModalClose,
  } = useDisclosure();
  const handleSwitchClick = () => {
    notRequiredModalOnOpen();
  };
  const isNotRequiredDiabled =
    status !== "todo" && status !== "inprogress" && status !== "not-required";
  return (
    <VStack w={"100%"} borderRadius={"15px"}>
      <Text sx={header}>Job Management</Text>
      <Flex {...styles.outerContainer}>
        <HStack {...styles.container}>
          <Text {...styles.text}>
            {status === "not-required"
              ? "If You need this job, you can Unarchive this job."
              : "If This job is no longer needed and can be archived."}
          </Text>
          <Flex {...styles.switchContainer}>
            <Text>Mark as Not Required</Text>
            <Switch
              size="sm"
              isChecked={isSwitchOn(status)}
              onChange={handleSwitchClick}
              isDisabled={isNotRequiredDiabled}
            />
          </Flex>
        </HStack>
        <HStack {...styles.container}>
          <Text {...styles.text}>Delete Job</Text>
          <Box
            background={"white"}
            height={"32px"}
            width={"38px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"12px"}
            border={"1px solid #E53E3E"}
            style={{ cursor: "pointer" }}
            // style={{
            //   cursor:
            //     status === "inprogress" || "not-required"
            //       ? "not-allowed"
            //       : "pointer",
            //   opacity: status === "inprogress" || "not-required" ? 0.5 : 1,
            // }}
            onClick={() => {
              // if (status !== "inprogress" || "not-required") {
              confirmJobDeleteModalOnOpen();
              // }
            }}
          >
            <Image src={deleteIcon} alt="" />
          </Box>
        </HStack>
      </Flex>

      {/* Mark Job as Not Required Modal*/}
      <CustomModal
        isOpen={notRequiredModalOpen}
        onClose={notRequiredModalClose}
        headerText={
          status === "not-required"
            ? "Mark Job as Required"
            : "Mark Job as Not Required"
        }
        bodyText={
          <>
            <Text color="#718096" lineHeight={"24px"} fontWeight={"400"}>
              {status === "not-required"
                ? "Are you sure you want unarchive this job?"
                : "Are you sure you want to mark this job as not required? It will be moved to the archive"}
            </Text>
          </>
        }
        buttonText="Confirm"
        onButtonClick={() => {
          handleUpdateJobDispatch(status);
        }}
        maxHeight="200px"
        colorScheme="modalsDark"
      />
      <CustomModal
        isDisabled={status !== "draft" && status !== "todo"}
        isOpen={confirmJobDeleteModalOpen}
        onClose={confirmJobDeleteModalClose}
        headerText="Confirm Job Deletion"
        bodyText={
          <>
            <Text color="#718096" lineHeight={"24px"} fontWeight={"400"}>
              Are you sure you want to delete this job? It will be permanently
              removed from the system and cannot be recovered.
            </Text>
          </>
        }
        buttonText="Delete"
        onButtonClick={handleDeleteJobDispatch}
        minHeight="224px"
        colorScheme="red"
      />
    </VStack>
  );
};

export default ViewJobBottomCard;
