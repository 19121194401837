import React from "react";
import { VStack, Text, Image, Button, Box } from "@chakra-ui/react";
import Success from "assets/svg/IoCheckmarkDoneCircle.svg";
import { Link, useParams } from "react-router-dom";
export const MobileWorkflowSubmitted = () => {
  let { id } = useParams();
  return (
    <Box style={{ background: "#F2F3F6" }} h={"100vh"} borderRadius={"15px"}>
      <VStack
        alignSelf={"center"}
        justifyContent={"center"}
        h={"333px"}
        pt={"106px"}
      >
        <Image src={Success} w={"84px"} h={"84px"} />
        <Text
          style={{
            fontSize: "32px",
            lineHeight: "140%",
            fontWeight: "600",
            fontFamily: "Inter",
            color: "#4a5568",
          }}
        >
          Thank You
        </Text>
        <Text
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "Inter",
            color: "#718096",
          }}
        >
          You submitted the form successfully.
        </Text>
        <Link to={`/mobile/auth/form-view/${id}`}>
          <Button
            variant={"outlined"}
            h={"48px"}
            style={{
              border: "1px solid #38b2ac",
              color: "#38b2ac",
              borderRadius: "8px",
            }}
          >
            Fill Form Again
          </Button>
        </Link>
      </VStack>
    </Box>
  );
};
export const WorkflowSubmitted = () => {
  return (
    <Box
      style={{ background: "#F2F3F6" }}
      mt={"75px"}
      h={"80vh"}
      borderRadius={"15px"}
    >
      <VStack
        alignSelf={"center"}
        justifyContent={"center"}
        h={"333px"}
        pt={"106px"}
      >
        <Image src={Success} w={"84px"} h={"84px"} />
        <Text
          style={{
            fontSize: "32px",
            lineHeight: "140%",
            fontWeight: "600",
            fontFamily: "Inter",
            color: "#4a5568",
          }}
        >
          Thank You
        </Text>
        <Text
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "Inter",
            color: "#718096",
          }}
        >
          You submitted the form successfully.
        </Text>
        <Link to="/admin/view-form">
          <Button
            variant={"outlined"}
            h={"48px"}
            style={{
              border: "1px solid #38b2ac",
              color: "#38b2ac",
              borderRadius: "8px",
            }}
          >
            Fill Form Again
          </Button>
        </Link>
      </VStack>
    </Box>
  );
};
