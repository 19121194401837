import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import deleteIcon from "../../../assets/svg/deleteRed.svg";

const styles = {
  outerContainer: {
    direction: { base: "column", md: "row" },
    justifyContent: "space-between",
    gap: { base: 2, md: "16px" },
  },
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    background: "white",
    boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    flex: 1,
  },
  text: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#718096",
  },
  switchContainer: {
    gap: "8px",
    alignItems: "center",
    height: "32px",
  },
};

const CustomModal = ({
  isOpen,
  onClose,
  headerText,
  bodyText,
  buttonText,
  onButtonClick,
  additionalContent,
  colorScheme,
  maxWidth,
  isDisabled,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      {/* <ModalOverlay /> */}
      <ModalContent maxW={maxWidth}>
        <ModalHeader fontWeight={700} fontSize={"18px"}>
          {headerText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {bodyText}
          {additionalContent && additionalContent}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="modalsLight"
            mr={3}
            onClick={onClose}
            color={"black"}
          >
            Close
          </Button>

          <Button
            colorScheme={colorScheme}
            onClick={onButtonClick}
            isDisabled={isDisabled}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ViewRoleBottom = ({
  handleDeleteButton,
  deleteRole,
  allRoleNames,
  roleDetails,
  userAssigned,
  handleDeactivateSwitch,
  singleUserAssigned,
  handleDeactivateButton,
  previousUsersData,
  handleActivateRole,
}) => {
  const {
    isOpen: firstModalIsOpen,
    onOpen: openFirstModal,
    onClose: closeFirstModal,
  } = useDisclosure();
  const {
    isOpen: secondModalIsOpen,
    onOpen: openSecondModal,
    onClose: closeSecondModal,
  } = useDisclosure();

  const {
    isOpen: thirdModalIsOpen,
    onOpen: openThirdModal,
    onClose: closeThirdModal,
  } = useDisclosure();

  const {
    isOpen: fourthModalIsOpen,
    onOpen: openFourthModal,
    onClose: closeFourthModal,
  } = useDisclosure();

  const {
    isOpen: fifthModalIsOpen,
    onOpen: openFifthModal,
    onClose: closeFifthModal,
  } = useDisclosure();

  const {
    isOpen: sixthModalIsOpen,
    onOpen: openSixthModal,
    onClose: closeSixthModal,
  } = useDisclosure();
  const {
    isOpen: seventhModalIsOpen,
    onOpen: openSeventhModal,
    onClose: closeSeventhModal,
  } = useDisclosure();

  const {
    isOpen: roleFirstModalIsOpen,
    onOpen: openroleFirstModal,
    onClose: closeroleFirstModal,
  } = useDisclosure();

  const {
    isOpen: roleSecondModalIsOpen,
    onOpen: openroleSecondModal,
    onClose: closeroleSecondModal,
  } = useDisclosure();

  const {
    isOpen: roleThirdModalIsOpen,
    onOpen: openroleThirdModal,
    onClose: closeroleThirdModal,
  } = useDisclosure();

  const handleAssignRoleButton = () => {
    closeFirstModal();
    openSecondModal();
  };
  const handleFinalDeactivation = () => {
    closeSecondModal();
    openThirdModal();
  };
  const handleFourthAction = () => {
    if (selectedOption) {
      const newRoleInfo = {
        newRoleId: selectedOption,
        singleRoleUsers: singleUserAssigned,
      };
      handleDeactivateButton(newRoleInfo);
    } else {
      handleDeactivateButton();
    }

    closeThirdModal();
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isRevokeAssignments, setIsRevokeAssignments] = useState(false);
  //console.log(isRevokeAssignments);

  const isSwitchOn = () => {
    return roleDetails?.status == "inactive" ? true : false;
  };

  // Function to handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Determine if the delete button should be disabled
  const isDeleteDisabled = inputValue.toUpperCase() !== "DELETE";

  const handleConformation = () => {
    openroleFirstModal();
  };

  const handleSwitchClick = () => {
    setIsButtonDisabled(true);
    if (isSwitchOn()) {
      if (previousUsersData > 0) {
        openFourthModal();
      } else {
        openSixthModal();
      }
    } else {
      handleDeactivateSwitch(openFirstModal, openThirdModal);
    }
  };

  const handleActivateRoleFinal = () => {
    handleActivateRole(isRevokeAssignments);
    closeFourthModal();
    closeSixthModal();
  };
  const handleNewRoleAssignBeforeDelete = () => {
    closeroleSecondModal();
    openroleThirdModal();
  };

  const handleRoleDeleteCloseThirdModal = () => {
    setInputValue("");
    closeroleThirdModal();
  };
  const handleRoleDeleteSecondModal = () => {
    closeroleFirstModal();
    openroleSecondModal();
  };
  return (
    <Box>
      <Flex {...styles.outerContainer}>
        <HStack {...styles.container}>
          <Text {...styles.text}>Users Roles</Text>
          <Flex {...styles.switchContainer}>
            <Text {...styles.text}>Deactivate</Text>
            <Switch
              size="sm"
              isChecked={isSwitchOn()}
              onChange={handleSwitchClick}
            />
          </Flex>
        </HStack>
        <HStack {...styles.container}>
          <Text {...styles.text}>Users Roles</Text>
          <Flex {...styles.switchContainer}>
            <Text {...styles.text}>Delete Role</Text>
            <Box
              background={"white"}
              height={"32px"}
              width={"38px"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={"12px"}
              border={"1px solid #E53E3E"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDeleteButton(handleConformation, openroleThirdModal);
              }}
            >
              <Image src={deleteIcon} alt="" />
            </Box>
          </Flex>
        </HStack>
      </Flex>

      {/* first Modal */}
      <CustomModal
        isOpen={firstModalIsOpen}
        onClose={closeFirstModal}
        headerText="Deactivate Role"
        bodyText={
          <>
            <Text color="#319795">
              {singleUserAssigned?.length} of the {userAssigned} users assigned
              to the{" "}
              <span
                style={{
                  color: "#4A5568",
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                {roleDetails?.roleName}{" "}
              </span>{" "}
              role you are deactivating have no other roles. Please assign them
              a new role before deactivating.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text fontSize={"16px"} color="#319795" mt={"24px"}>
              <span style={{ color: "#4A5568", fontWeight: 400 }}>Note: </span>
              If you activate the role again, you can choose whether or not to
              assign it to the users who had it before it was deactivated.
            </Text>
          </>
        }
        buttonText="Assign a Role"
        onButtonClick={handleAssignRoleButton}
        maxWidth="436px"
        colorScheme="modalsDark"
      />

      {/* Second Modal */}
      <CustomModal
        isOpen={secondModalIsOpen}
        onClose={closeSecondModal}
        headerText="Deactivate Role"
        bodyText={
          <>
            <Text color="#319795">
              {singleUserAssigned?.length} of the {userAssigned} users assigned
              to the{" "}
              <span
                style={{
                  color: "#4A5568",
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                {roleDetails?.roleName}{" "}
              </span>{" "}
              role you are deactivating have no other roles. Please assign them
              a new role before deactivating
            </Text>
          </>
        }
        buttonText="Apply Changes"
        onButtonClick={handleFinalDeactivation}
        maxWidth="678px"
        additionalContent={
          <>
            <Text fontSize={"12px"} fontWeight={700} mt={"24px"}>
              Assign New Role
            </Text>
            <Select
              placeholder="Select New Role"
              size="md"
              color={"#A0AEC0"}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                //console.log(e.target.value);
                setIsButtonDisabled(!e.target.value);
              }}
            >
              {allRoleNames &&
                allRoleNames.map((role) => {
                  return (
                    <option key={role.id} value={role.id}>
                      {role.roleName}
                    </option>
                  );
                })}
            </Select>
          </>
        }
        colorScheme="modalsDark"
        isDisabled={isButtonDisabled}
      />

      {/* Third Modal*/}
      <CustomModal
        isOpen={thirdModalIsOpen}
        onClose={closeThirdModal}
        headerText="Deactivate Role"
        onButtonClick={handleFourthAction}
        bodyText={
          <>
            <Text color="#319795">
              By deactivating this role, all users assigned to it will lose
              access to it.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text fontSize={"16px"} color="#319795" mt={"24px"}>
              <span style={{ color: "#4A5568", fontWeight: 400 }}>Note: </span>
              If you activate the role again, you can choose whether or not to
              assign it to the users who had it before it was deactivated.
            </Text>
          </>
        }
        buttonText="Deactivate Role"
        maxWidth="436px"
        colorScheme="red"
      />

      {/* fourth modal*/}
      <CustomModal
        isOpen={fourthModalIsOpen}
        onClose={closeFourthModal}
        onButtonClick={handleActivateRoleFinal}
        headerText="Activate Role"
        bodyText={
          <>
            <Text color="#319795" fontSize={"16px"}>
              {previousUsersData} users were assigned to this role before it was
              deactivated.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Checkbox
              size="md"
              textColor="#319795"
              fontSize={"14px"}
              mt={"10px"}
              onChange={() => {
                setIsRevokeAssignments((prevValue) => !prevValue);
              }}
            >
              <Text color="#4A5568" fontSize={"14px"}>
                Do you want to assign it to them again?
              </Text>
            </Checkbox>
          </>
        }
        buttonText="Activate Role"
        maxWidth="436px"
        colorScheme="modalsDark"
      />

      {/* fifth modal*/}
      <CustomModal
        isOpen={fifthModalIsOpen}
        onClose={closeFifthModal}
        onButtonClick={null}
        headerText="Activate Role"
        bodyText={
          <>
            <Text color="#319795" fontSize={"16px"}>
              12 users were assigned to this role before it was deactivated.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Checkbox
              size="md"
              textColor="#319795"
              fontSize={"14px"}
              mt={"10px"}
            >
              <Text color="#4A5568" fontSize={"14px"}>
                Do you want to assign it to them again?
              </Text>
            </Checkbox>
          </>
        }
        buttonText="Activate Role"
        maxWidth="436px"
        colorScheme="modalsDark"
      />

      {/*sixth modal*/}
      <CustomModal
        isOpen={sixthModalIsOpen}
        onClose={closeSixthModal}
        onButtonClick={handleActivateRoleFinal}
        headerText="Activate Role"
        bodyText={
          <>
            <Text color="#319795" fontSize={"16px"}>
              There are currently no users assigned to this role. Once you
              activate the role, you can assign users to it.
            </Text>
          </>
        }
        buttonText="Activate Role"
        maxWidth="436px"
        colorScheme="modalsDark"
      />
      {/*seventh modal*/}
      <CustomModal
        isOpen={seventhModalIsOpen}
        onClose={closeSeventhModal}
        onButtonClick={null}
        headerText="Activate Role"
        bodyText={
          <>
            <Text color="#319795" fontSize={"16px"}>
              This role has not been assigned to any users, so deactivated it
              will not impact anyone's access to your application or service.
            </Text>
          </>
        }
        buttonText="Deactivate Role"
        maxWidth="436px"
        colorScheme="red"
      />

      {/*Role Deletion modal 1*/}
      <CustomModal
        isOpen={roleFirstModalIsOpen}
        onClose={closeroleFirstModal}
        buttonText="Change Role"
        onButtonClick={handleRoleDeleteSecondModal}
        headerText="Role Deletion Conformation"
        bodyText={
          <>
            <Text color="#319795" fontSize={"16px"}>
              The role you are trying to delete is assigned to {userAssigned}{" "}
              users. You cannot delete the role until you have changed the role
              for all of the users.
            </Text>
          </>
        }
        maxWidth="436px"
        colorScheme="modalsDark"
      />

      {/* Role Deletion modal 2 */}
      <CustomModal
        isOpen={roleSecondModalIsOpen}
        onClose={closeroleSecondModal}
        headerText="Deactivate Role"
        bodyText={
          <>
            <Text color="#319795">
              {userAssigned} users are currently assigned the
              <span
                style={{
                  color: "#4A5568",
                  fontWeight: 700,
                  textTransform: "capitalize",
                }}
              >
                {" "}
                {roleDetails?.roleName}{" "}
              </span>
              role. You can replace this role with a new one. It's important to
              note that changing a role will not affect or replace any other
              roles assigned to the users.
            </Text>
          </>
        }
        buttonText="Apply Changes"
        onButtonClick={handleNewRoleAssignBeforeDelete}
        maxWidth="678px"
        isDisabled={isButtonDisabled}
        additionalContent={
          <>
            <Flex alignItems="flex-start" gap={"16px"}>
              <Box flex={"1"}>
                <VStack alignItems="flex-start">
                  <Text fontSize={"12px"} fontWeight={700} mt={"24px"}>
                    Current Role
                  </Text>
                  <Input
                    style={{
                      textTransform: "capitalize",
                      pointerEvents: "none",
                    }}
                    variant="filled"
                    placeholder={roleDetails?.roleName}
                  />
                </VStack>
              </Box>

              <Box flex={"1"}>
                <VStack alignItems="flex-start">
                  <Text fontSize={"12px"} fontWeight={700} mt={"24px"}>
                    Replace With New Role
                  </Text>
                  <Select
                    placeholder="Select New Role"
                    size="md"
                    color={"#A0AEC0"}
                    onChange={(e) => {
                      setSelectedOption(e.target.value);
                      setIsButtonDisabled(!e.target.value);
                    }}
                  >
                    {allRoleNames &&
                      allRoleNames.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.roleName}
                          </option>
                        );
                      })}
                  </Select>
                </VStack>
              </Box>
            </Flex>
            {/* <Text color="#319795" mt={"36px"}>
              Some users assigned the{" "}
              <span style={{ color: "#4A5568", fontWeight: 700 }}>
                {" "}
                Manager{" "}
              </span>{" "}
              role also have the following roles. You can also update any of
              these roles if needed. (Optional)
            </Text>
            <Flex alignItems="flex-start" gap={"16px"}>
              <Box flex={"1"}>
                <VStack alignItems="flex-start">
                  <Text fontSize={"12px"} fontWeight={700} mt={"24px"}>
                    Current Role
                  </Text>
                  <Input variant="filled" placeholder="Supervisor" />
                  <Input variant="filled" placeholder="Auditor" />
                </VStack>
              </Box>

              <Box flex={"1"}>
                <VStack alignItems="flex-start">
                  <Text fontSize={"12px"} fontWeight={700} mt={"24px"}>
                    Replace With New Role
                  </Text>
                  <Select
                    placeholder="Select New Role"
                    size="md"
                    color={"#A0AEC0"}
                  ></Select>
                  <Select
                    placeholder="Select New Role"
                    size="md"
                    color={"#A0AEC0"}
                  ></Select>
                </VStack>
              </Box>
            </Flex> */}
          </>
        }
        colorScheme="modalsDark"
      />
      {/*Role Deletion modal 3*/}
      <CustomModal
        isOpen={roleThirdModalIsOpen}
        onClose={handleRoleDeleteCloseThirdModal}
        buttonText="Change Role"
        onButtonClick={() => {
          setInputValue("");
          deleteRole(selectedOption, closeroleThirdModal);
          setSelectedOption("");
        }}
        headerText="Role Deletion Conformation"
        bodyText={
          <>
            <Text color="#319795" fontSize={"16px"}>
              This role has not been assigned to any users, so deleting it will
              not impact anyone's access to your application or service.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text color="#319795" fontSize={"16px"} mt={"16px"}>
              This role has not been assigned to any users, so deleting it will
              not impact anyone's access to your application or service.
            </Text>
            <Text color="#319795" fontSize={"16px"} mt={"16px"}>
              To confirm deletion, type “DELETE” below
            </Text>
            <Input
              variant="outline"
              mt={"16px"}
              value={inputValue}
              onChange={handleInputChange}
            />
          </>
        }
        maxWidth="436px"
        colorScheme="modalsDark"
        isDisabled={isDeleteDisabled}
      />
    </Box>
  );
};

export default ViewRoleBottom;
