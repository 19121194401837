import { CircularProgress, Flex } from "@chakra-ui/react";
import React from "react";

export const Loader = ({ size, color = "#4FD1C5", position, margin }) => {
  return (
    <Flex justifyContent={position} margin={margin} width="100%">
      <CircularProgress isIndeterminate color={color} size={size} />
    </Flex>
  );
};
