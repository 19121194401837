import {
  isAlphaNumeric,
  allowedSpecialChars,
  isValidLength,
} from "utils/ValidationHelper";

export const validateExternalCode = (
  value,
  minLength,
  maxLenght,
  regexPattern
) => {
  const lengthCheck = isValidLength(value, minLength, maxLenght);
  const alphaNumericCheck = isAlphaNumeric(value);
  const specialCharCheck = allowedSpecialChars(value, regexPattern);
  return lengthCheck && alphaNumericCheck && specialCharCheck;
};

export const validateEmail = (email) => {
  if (!email) return true;
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};
export const validatePhone = (phone, code) => {
  let tempCode = code ? code : "";
  if (!phone && !tempCode) return true;
  if (!phone) return true;
  if (!tempCode) return false;
  if (tempCode === "India +91") {
    // Indian mobile number validation
    const pattern = /^[6-9]\d{9}$/;
    return pattern.test(phone);
  } else if (tempCode === "UAE +971") {
    // UAE mobile number validation
    const pattern = /^[0-9]{9}$/;
    return pattern.test(phone);
  }
  return false;
};

//this function is a recursive fn which accepts object {sections:[], blocks:[], composites:[],components:[]} and checks for empty array(Abstractio Layer) and also checks if section is having pagenumber key not falsy.
export const checkEmptyArraysAndPageNumber = (obj) => {
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return true;
    }
    for (let item of obj) {
      if (checkEmptyArraysAndPageNumber(item)) {
        return true;
      }
    }
  } else if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (Array.isArray(obj[key])) {
          if (obj[key].length === 0) {
            return true;
          }
          if (checkEmptyArraysAndPageNumber(obj[key])) {
            return true;
          }
        }
        if (key === "sections") {
          if (!Array.isArray(obj[key])) {
            return true;
          }
          for (let section of obj[key]) {
            if (!section.pageNumber || typeof section.pageNumber !== "number") {
              return true;
            }
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          if (checkEmptyArraysAndPageNumber(obj[key])) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const workflowPropertyAndValueCheck = (workflow) => {
  // console.log(workflow)
  const requiredWorkflowProperties = ["id", "status", "title", "application"];
  for (const property of requiredWorkflowProperties) {
    if (!workflow.hasOwnProperty(property)) {
      // console.error(`Missing workflow property: ${property}`);
      return { isValid: false, message: `Workflow is missing ${property}` };
    }
    //checks value is not empty for all
    if (
      property !== "updateDt" &&
      property !== "createdBy" &&
      property !== "description" &&
      (!workflow[property] || typeof workflow[property] !== "string")
    ) {
      // console.error(`Workflow property ${property} is empty`);
      return {
        isValid: false,
        message: `Value for ${property} in Workflow is empty or null.`,
      };
    }
  }
  return { isValid: true, message: "" };
};

export const formsPropertyAndValueCheck = (forms) => {
  if (!Array.isArray(forms) || forms.length === 0) {
    //  console.error('Forms array is empty or not an array');
    return { isValid: false, message: "Workflow must have at least 1 form." };
  }

  // Check each form object
  for (const formObj of forms) {
    const requiredFormProperties = [
      "form",
      "sections",
      "blocks",
      "composites",
      "components",
    ];
    for (const property of requiredFormProperties) {
      if (!formObj.hasOwnProperty(property)) {
        //   console.error(`Missing form object property: ${property}`);
        return {
          isValid: false,
          message: `Form Object is missing property ${property}.`,
        };
      }
    }

    // Check if sections, blocks, composites, and components are arrays
    const arrayProperties = ["sections", "blocks", "composites", "components"];
    for (const property of arrayProperties) {
      if (!Array.isArray(formObj[property])) {
        //   console.error(`${property} is not an array`);
        return {
          isValid: false,
          message: `In form ${formObj.form?.title}, value of property ${property} is not Array.`,
        };
      }
    }

    // Check if form has all required properties
    const requiredFormInnerProperties = [
      "id",
      "title",
      "application",
      "categoryName",
      "categoryType",
      "structure",
    ];
    for (const property of requiredFormInnerProperties) {
      if (!formObj?.form?.hasOwnProperty(property)) {
        //   console.error(`Missing form inner property: ${property}`);
        return {
          isValid: false,
          message: `Form ${formObj.form?.title} is missing property ${property}.`,
        };
      }
      // checks value is not empty for all
      if (
        property !== "structure" &&
        property !== "categoryType" &&
        property !== "categoryName" &&
        property !== "application" &&
        (!formObj?.form[property] ||
          typeof formObj?.form[property] !== "string")
      ) {
        // console.error(`Workflow property ${property} is empty`);
        return {
          isValid: false,
          message: `In Form ${formObj.form?.title}, value of property ${property} is null or empty.`,
        };
      }
    }

    // Check if form structure has sections array
    if (!Array.isArray(formObj?.form?.structure?.sections)) {
      // console.error('form.structure.sections is not an array');
      return {
        isValid: false,
        message: `In Form ${formObj.form?.title}, structure object is missing sections key.`,
      };
    }

    // Check if form structure sections length matches sections length
    if (formObj.form.structure.sections.length !== formObj.sections.length) {
      // console.error('form.structure.sections length does not match sections length');
      return {
        isValid: false,
        message: `In Form ${formObj.form?.title}, sections array inside the structure is Empty.`,
      };
    }
  }
  return { isValid: true, message: "" };
};
