// chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { styles } from "components/Navbars/style";
import eyeIcon from "../../assets/svg/right-icon.svg";
import React from "react";

export function ItemContent(props) {
  const spacing = " ";
  return (
    <>
      <Flex flexDirection="column" sx={styles.notificationMiddleInnerContainer}>
        <Text sx={styles.notificationWhNameText}>
          {props.info}
          {spacing}
          <Text fontWeight="600" as="span">
            {props.boldInfo}
          </Text>
        </Text>
        <Flex sx={styles.notificationTimeContainer}>
          <Text sx={styles.notificationTime}>{props.time}</Text>
          <img src={eyeIcon} />
        </Flex>
      </Flex>
    </>
  );
}
