import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "services/apis";
//Generator Functions

export function* handleFetchWarehouseInsightsData({ payload }) {
  try {
    const res = yield call(API.getWarehouseInsightsData, payload.payload);
    const { data } = res;
    yield put(actions.fetchWarehouseInsightsDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWarehouseInsightsDataFailure(err?.response?.data));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* warehouseSaga() {
  yield takeLatest(
    actions.fetchWarehouseInsightsData.type,
    handleFetchWarehouseInsightsData
  );
  yield takeLatest(actions.clearState.type, handleClearState);
}
