import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  notificationData: null,
  userData: null,
  avatarURL: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    fetchNotifications(state, action) {
      state.loading = true;
    },
    fetchNotificationsSuccess(state, action) {
      let requiredData = action?.payload || [];
      state.notificationData = requiredData;
      state.loading = false;
    },
    fetchNotificationsFailure(state, action) {
      state.loading = false;
    },
    markReadAllNotifications(state, action) {
      state.loading = true;
    },
    markReadAllNotificationsSuccess(state, action) {
      let requiredData = action?.payload || [];
      state.notificationData = requiredData;
      state.loading = false;
    },
    markReadAllNotificationsFailure(state, action) {
      state.loading = false;
    },
    readNotification(state, action) {
      state.loading = true;
    },
    readNotificationSuccess(state, action) {
      let requiredData = action?.payload || [];
      // state.notificationData = [...]
      state.loading = false;
    },
    readNotificationFailure(state, action) {
      state.loading = false;
    },
    fetchAvatarURL(state, action) {
      state.loading = true;
    },
    getAvatarURLSuccess(state, action) {
      state.avatarURL = action.payload.url;
      state.loading = false;
    },
    getAvatarURLFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    fetchUserData(state, action) {
      state.loading = true;
    },
    fetchUserDataSuccess(state, action) {
      state.userData = action.payload;
      state.loading = false;
    },
    fetchUserDataFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    clearState(state, action) {
      state.loading = true;
    },
    clearStateSuccess(state, action) {
      state.avatarURL = "";
      state.notificationData = null;
      state.userData = null;
      state.loading = false;
    },
    clearStateFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = notificationSlice;
