import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.userData || initialState;
export const selectUserDataLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectUserData = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const selectResetPasswordResMessage = createSelector(
  [selectDomain],
  (state) => state.passwordResponseMessage
);
export const selectPreDefinedURL = createSelector(
  [selectDomain],
  (state) => state.preDefinedURL
);
export const selectUploadLoading = createSelector(
  [selectDomain],
  (state) => state.uploadLoading
);
export const selectNewProfileURL = createSelector(
  [selectDomain],
  (state) => state.profileImageURL
);