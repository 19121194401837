import { Box, Flex, Input, Text } from "@chakra-ui/react";
import React from "react";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import Select from "views/shared/Select";

const flexStyles = {
  gap: "18px",
  direction: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};

const CustomInput = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  isValid,
  errorMessage,
}) => {
  return (
    <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <Input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        width="100%"
        alignSelf="stretch"
        focusBorderColor="#3182ce"
        onChange={(event) => onChange(event.target.value)}
        fontSize="14px"
      />

      {!isValid && (
        <Text fontSize="12px" color="red.500">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

const RegisteredAddress = ({
  handleTempAddress,
  tempAddress,
  handleOptionChange,
  clientDetails,
  pinCodeIsValid,
  setPinCodeIsValid,
  states,
  cities,
  validatePINCode,
}) => {
  const sortedStates = [...states].sort();
  const sortedCities = [...cities].sort();
  return (
    <Box padding={"16px 24px"}>
      <Flex direction={"column"} gap={"18px"}>
        <Flex sx={flexStyles}>
          <CustomInput
            label="Address Line 1*"
            placeholder="eg. 1234 Design Avenue"
            value={tempAddress?.address}
            onChange={(value) => handleTempAddress("address", value)}
          />
          <CustomInput
            label="Address Line 2"
            placeholder="eg. Near ABC Shop"
            value={tempAddress?.addressLine2}
            onChange={(value) => handleTempAddress("addressLine2", value)}
          />
        </Flex>
        <Flex sx={flexStyles}>
          <Flex direction={"column"} gap={"8px"} flex={{ base: "1", md: "1" }}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              State*
            </Text>
            <Select
              placeholder={"Select"}
              options={sortedStates}
              customKey="state"
              value={capitalizeStringUpdated(tempAddress?.state)}
              handleOptionChange={handleOptionChange}
              check={
                clientDetails?.country ? " " : "Please select country first"
              }
            />
          </Flex>
          <Flex direction={"column"} gap={"8px"} flex={{ base: "1", md: "1" }}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              City*
            </Text>
            <Select
              customKey="city"
              placeholder={"Select"}
              options={sortedCities}
              value={capitalizeStringUpdated(tempAddress?.city)}
              handleOptionChange={handleOptionChange}
              check={"Please select state first"}
            />
          </Flex>
        </Flex>
        <Flex direction={"column"} gap={"8px"} flex={{ base: "1", md: "1" }}>
          {clientDetails?.country === "India" && (
            <CustomInput
              label="Pin Code*"
              placeholder="eg. 160XXX"
              value={tempAddress?.pinCode}
              onChange={(value) => {
                setPinCodeIsValid(validatePINCode(value));
                handleTempAddress("pinCode", value);
              }}
              type={"number"}
              isValid={pinCodeIsValid}
              errorMessage="Please enter valid Pin Code"
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default RegisteredAddress;

// const validatePINCode = (pin) => {
//   const pattern = /^\d{6}$/;
//   return pattern.test(pin);
// };
