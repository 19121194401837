import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Flex,
  IconButton,
  Image,
  Spinner,
} from "@chakra-ui/react";
import FiltersDrawer from "./FiltersDrawer";
import WarehouseFilter from "views/shared/WarehouseFilter";
import Commodity from "./FiltersWidgets/Commodity";
import Location from "./FiltersWidgets/Location";
import DateSelection from "./FiltersWidgets/DateSelection";
import Client from "./FiltersWidgets/Client";
import InspectionTypes from "./FiltersWidgets/InspectionType";
import Auditor from "./FiltersWidgets/Auditor";
import MobileDateSelection from "./FiltersWidgets/MobileDateSelection";

const styles = {
  filtersContainer: {
    padding: "16px 20px",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    gap: "16px",
    border: "1px solid #E2E8F0",
    flexDirection: "row",
  },
  button: {
    backgroundColor: "#F7FAFC",
    color: "#4F4F4F",
    fontSize: "12px",
    borderRadius: "8px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    height: "39px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  greenCircle: {
    position: "absolute",
    top: "-2.5px",
    right: "-3.5px",
    width: "12px",
    height: "12px",
    backgroundColor: "#319795",
    borderRadius: "50%",
    border: "1px solid white",
  },
};

const Filters = ({
  toggleDrawer,
  filterQuery,
  commodityNames,
  locationNames,
  inspectionTypes,
  auditorNames,
  handleFilterQuery,
  isDrawerOpen,
  warehouses,
  inputValue,
  handleQuery,
  handleSelect,
  handleWareHouseChange,
  handleInputVal,
  searchQuery,
  config,
  tab,
  isMultipleFilters,
  clientNames,
  isMobile,
}) => {
  const [loading, setLoading] = useState(true);
  const [filteredConfig, setFilteredConfig] = useState(null);
  useEffect(() => {
    let filterConfig;
    if (config && config?.centralArea) {
      if (config?.centralArea?.filtersObj.length > 1) {
        filterConfig = config?.centralArea?.filtersObj.find((obj) => {
          return _.camelCase(obj.tabName) === _.camelCase(tab);
        });
      } else {
        filterConfig = config?.centralArea?.filtersObj[0];
      }
      setFilteredConfig(filterConfig);
      setLoading(false);
    }
  }, [config]);

  const getFilterWidget = (dataSource, id) => {
    const tabId = _.camelCase(tab) || null;
    const property = isMultipleFilters ? `${dataSource}_${tabId}` : dataSource;
    const widgetMap = {
      client: (
        <Client
          key={id}
          clientNames={clientNames}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
          property={property}
        />
      ),
      commodity: (
        <Commodity
          key={id}
          commodityNames={commodityNames}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
          property={property}
        />
      ),
      location: (
        <Location
          key={id}
          locationNames={locationNames}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
          property={property}
        />
      ),
      inspectionType: (
        <InspectionTypes
          key={id}
          inspectionTypes={inspectionTypes}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
          property={property}
        />
      ),
      auditor: (
        <Auditor
          key={id}
          auditorNames={auditorNames}
          handleFilterQuery={handleFilterQuery}
          filterQuery={filterQuery}
          property={property}
        />
      ),
      dateRangePicker: (
        <DateSelection
          key={id}
          filters={filterQuery[property]}
          handleFromDateChange={(value) =>
            handleFilterQuery(value, `${property}`, "fromDate")
          }
          handleToDateChange={(value) =>
            handleFilterQuery(value, `${property}`, "toDate")
          }
        />
      ),
      mobileDateRangePicker: (
        <MobileDateSelection
          key={id}
          filters={filterQuery[property]}
          handleFromDateChange={(value) =>
            handleFilterQuery(value, `${property}`, "fromDate")
          }
          handleToDateChange={(value) =>
            handleFilterQuery(value, `${property}`, "toDate")
          }
        />
      ),
      secondaryFilterDrawer: (
        <FiltersDrawer
          key={id}
          filtersConfig={filteredConfig?.secondaryFilters}
          filterQuery={filterQuery}
          handleFilterQuery={handleFilterQuery}
          clientNames={clientNames}
          commodityNames={commodityNames}
          locationNames={locationNames}
          inspectionTypes={inspectionTypes}
          auditorNames={auditorNames}
          filters={filterQuery}
          isMultipleFilters={isMultipleFilters}
          tab={tab}
        />
      ),
      // warehouse: (
      //   <WarehouseFilter
      //     warehouseOptions={warehouses}
      //     inputValue={inputValue}
      //     handleQuery={handleQuery}
      //     handleSelect={handleSelect}
      //     handleWareHouseChange={handleWareHouseChange}
      //     handleInputVal={handleInputVal}
      //     searchQuery={searchQuery}
      //   />
      // ),
    };
    return widgetMap[dataSource];
  };

  return filteredConfig === null || loading ? (
    <Flex
      sx={{
        width: "100%",
        justifyContent: "center",
        marginBottom: { base: "16px", md: "32px" },
      }}
    >
      <CircularProgress isIndeterminate color="#4FD1C5" size="40px" />
    </Flex>
  ) : (
    <Flex
      sx={{
        ...styles.filtersContainer,
        marginBottom: { base: "16px", md: "32px" },
      }}
    >
      {filteredConfig?.filters?.map((widget) => {
        return getFilterWidget(widget.dataSource, widget.id);
      })}
      {filteredConfig?.hasOwnProperty("secondaryFilters") &&
      filteredConfig?.secondaryFilters?.length ? (
        getFilterWidget("secondaryFilterDrawer", "secondaryFilterDrawer")
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default Filters;
