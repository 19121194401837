import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { v4 as uuidv4 } from "uuid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export function LineChart({ labels, data, showLine, tooltipData, isFor }) {
  function generateTooltipHTML(isFor, tooltip, dataItem, dataIndex) {
    let tooltipHTML = "";
    if (isFor === "agency") {
      tooltipHTML = `<ul class="tooltipul1">
    <li>${tooltip.title[0]} ${dataItem?.year}<span class="custom-span">${tooltip.dataPoints[0]["dataset"].data[dataIndex]}</span></li></ul>`;
    } else {
      tooltipHTML += `<ul class="tooltipul">`;
      dataItem.forEach((item) => {
        tooltipHTML += `<li>${item.label} ${
          item?.value ? `<span class="custom-span">${item.value}</span>` : ""
        }</li>`;
      });
      tooltipHTML += `<span id="tooltipData">${tooltip.title[0]} ${dataItem[0].year}</span>`;
    }
    return tooltipHTML;
  }
  //custom tooltip block
  const getOrCreteTooltip = (chart) => {
    let tooltipEL = chart.canvas.parentNode.querySelector("div");
    if (!tooltipEL) {
      tooltipEL = document.createElement("DIV");
      tooltipEL.classList.add(
        isFor === "agency" ? "tooltipDesign1" : "tooltipDesign"
      );
      let tooltipUL = document.createElement("UL");
      tooltipUL.classList.add("tooltipul");

      tooltipEL.appendChild(tooltipUL);
      chart.canvas.parentNode.appendChild(tooltipEL);
    }
    return tooltipEL;
  };
  const customTooltips = (context) => {
    const { chart, tooltip } = context;
    const tooltipEL = getOrCreteTooltip(chart);
    if (tooltip.opacity === 0) {
      tooltipEL.style.opacity = 0;
      return;
    }
    const dataIndex = tooltip.dataPoints[0].dataIndex;
    const dataItem = tooltipData[dataIndex];
    const tooltipHTML = generateTooltipHTML(
      isFor,
      tooltip,
      dataItem,
      dataIndex
    );
    const h = isFor === "agency" ? 23 : 130;

    tooltipEL.querySelector(".tooltipul").innerHTML = tooltipHTML;
    tooltipEL.style.opacity = 1;
    tooltipEL.style.left = chart.canvas.offsetLeft + tooltip.caretX + "px";
    tooltipEL.style.top = chart.canvas.offsetTop + tooltip.y - h + "px";
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: customTooltips,
      },
    },

    scales: {
      x: {
        display: showLine,
      },
      y: {
        display: showLine,
      },
    },
  };
  const data1 = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Dataset 2",
        data: data,
        borderColor: "#39AEA3",
        borderWidth: 2,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            chartArea.left,
            chartArea.bottom,
            chartArea.left,
            chartArea.top
          );
          gradient.addColorStop(1, "rgba(79, 209, 197, 0.54)");
          gradient.addColorStop(0.54, "rgba(79, 209, 197, 0.54)");
          gradient.addColorStop(0, "rgba(79, 209, 197, 0.00)");
          return gradient;
        },
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };
  return <Line options={options} data={data1} key={uuidv4()} />;
}
