import {
  Box,
  Flex,
  HStack,
  Image,
  Input,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import deleteIcon from "assets/svg/deleteRed.svg";
import CustomModal from "views/shared/CustomModal";
import { useDispatch } from "react-redux";
import { actions } from "../../slice";
import { useHistory } from "react-router-dom";

const styles = {
  outerContainer: {
    direction: { base: "column", md: "row" },
    justifyContent: "space-between",
    gap: { base: 2, md: "16px" },
  },
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    background: "white",
    boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    flex: 1,
  },
  text: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#718096",
    linHeight: "19.6px",
  },
  switchContainer: {
    gap: "8px",
    alignItems: "center",
    height: "32px",
  },
};

const ViewUserBottom = ({
  userDetails,
  handleActivateDeactivate,
  userId,
  customerId,
  clientId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();
  const [inputValue, setInputValue] = useState("");

  const {
    isOpen: deactivateModalIsOpen,
    onOpen: openDeactivateModal,
    onClose: closeDeactivateModal,
  } = useDisclosure();
  const {
    isOpen: deleteModalIsOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();

  const isSwitchOn = () => {
    console.log({ switchStatus: !userDetails?.isActive });
    return !userDetails?.isActive;
  };

  console.log("userDetails->", userDetails);

  const handleSwitchClick = () => {
    if (isSwitchOn()) {
      handleActivateDeactivate(isSwitchOn());
    } else {
      openDeactivateModal();
    }
  };

  const handleDeleteButton = () => {
    openDeleteModal();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const isDeleteDisabled = inputValue !== "DELETE";

  const handleDeactivateProcess = () => {
    handleActivateDeactivate(isSwitchOn());
    closeDeactivateModal();
  };

  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600} lineHeight={"22.4px"} color={"#2D3748"}>
            Account Management
          </Text>
        </Box>
        <Flex {...styles.outerContainer}>
          <HStack {...styles.container}>
            <Text {...styles.text}>Deactivate Account</Text>
            <Flex {...styles.switchContainer}>
              <Text {...styles.text}>Deactivate</Text>
              <Switch
                size="sm"
                colorScheme="login"
                isDisabled={clientId ? !userDetails?.clientIsActive : false}
                isChecked={!userDetails?.isActive}
                onChange={handleSwitchClick}
              />
            </Flex>
          </HStack>
          <HStack {...styles.container}>
            <Text {...styles.text}>Delete Account </Text>
            <Flex {...styles.switchContainer}>
              <Box
                background={"white"}
                height={"32px"}
                width={"38px"}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={"12px"}
                border={"1px solid #E53E3E"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDeleteButton();
                }}
              >
                <Image src={deleteIcon} alt="" />
              </Box>
            </Flex>
          </HStack>
        </Flex>
      </Stack>
      {/* Deactivate Account Modal */}
      <CustomModal
        isOpen={deactivateModalIsOpen}
        onClose={closeDeactivateModal}
        headerText="Deactivate Account"
        bodyText={
          <>
            <Text
              color="#718096"
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
            >
              By deactivating your account, you'll temporarily suspend access to
              this user.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text
              color="#718096"
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
              mt={"16px"}
            >
              However, you can reactivate your account later to regain access.
              If you're sure about this decision, click "Deactivate."
            </Text>
          </>
        }
        colorScheme="red"
        maxWidth="436px"
        buttonText="Deactivate"
        onButtonClick={handleDeactivateProcess}
      />
      {/* Delete Account Modal */}
      <CustomModal
        isOpen={deleteModalIsOpen}
        onClose={closeDeleteModal}
        headerText="Confirm Account Deletion"
        onButtonClick={() => {
          dispatch(
            actions.deleteUser({
              customerId,
              clientId,
              userId,
              onSuccess: (status) => {
                if (status) {
                  toast({
                    title: "User deleted successfully",
                    status: "success",
                    duration: 1500,
                    isClosable: true,
                  });
                  history.goBack();
                } else {
                  toast({
                    title: "Something Went wrong",
                    status: "error",
                    duration: 1500,
                    isClosable: true,
                  });
                }
              },
            })
          );
        }}
        bodyText={
          <>
            <Text
              color="#718096"
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
            >
              Upon deletion, this user will no longer be accessible and
              associated data will be permanently removed. If you're certain
              about proceeding, click "Delete Account."
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text
              color="#718096"
              fontSize="16px"
              mt={"16px"}
              fontWeight={400}
              lineHeight="24px"
            >
              To confirm deletion, type “DELETE” below
            </Text>
            <Input
              variant="outline"
              mt={"16px"}
              value={inputValue}
              onChange={handleInputChange}
            />
          </>
        }
        maxWidth="436px"
        isDisabled={isDeleteDisabled}
        colorScheme="red"
        buttonText="Delete Account"
      />
    </>
  );
};

export default ViewUserBottom;
