import React, { useEffect, useState } from "react";
import { Button, Image, Text, useDisclosure } from "@chakra-ui/react";
import Banner from "components/Layout/auth/Banner/Banner.js";
import "./index.css";
import { loginAPI } from "services/apis";
import { LoginForm } from "@agnext/reactlib";
import { useHistory } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import HandleInActiveModal from "./HandleInActiveModal";
import fiCopy from "../../assets/svg/fiCopy.svg";

function SignIn() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const {
    isOpen: deactivateModalIsOpen,
    onOpen: openDeactivateModal,
    onClose: closeDeactivateModal,
  } = useDisclosure();

  const history = useHistory();
  const { userProfileData, handleLogin, handleError } = useUserContext();

  useEffect(() => {
    if (userProfileData) {
      if (userProfileData?.profiles[0]?.isActive === false) {
        openDeactivateModal();
      } else {
        setEmail("");
        setPassword("");
        history.push("/admin/dashboard");
      }
    }
  }, [userProfileData]);

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setEmailError("");
    setError("");
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setError("");
  };


  const handleForgotPassword = () => {
    history.push("/auth/forgotPassword");
  };

  const loginHandler = async (event) => {
    event.preventDefault();
    const isValidEmail = validateType(email);
    if (!isValidEmail) {
      setEmailError("Please provide a valid email");
      return;
    } else if (password === "") {
      setPasswordError("Please enter password");
      return;
    }
    try {
      const loginResponse = await loginAPI({ email, password });
      const loginResponseData = loginResponse.data.payload;
      if (loginResponseData.token) {
        handleLogin(loginResponseData.token);
        localStorage.setItem(
          "inspectionDashboard",
          JSON.stringify(loginResponseData)
        );
      } else {
        setError("Wrong email or password");
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.reason === "Email not registered"
      ) {
        setError("Email not registered");
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.reason === "Password does not match"
      ) {
        setError("Incorrect Password");
      } else {
        setError("Internal server error");
      }
    }
  };
  const validateType = (email, password) => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const CopyButtonText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const ownerEntityType = userProfileData?.profiles[0]?.ownerEntityType;

  return (
    <>
      <LoginForm
        type="signin"
        leftComponent={<Banner />}
        logo={<Image w="100%" h="130%" mt="20px" pr="5px" src={ process.env.REACT_APP_LOGO_URL } alt="inspeqt-logo" />}
        email={email}
        password={password}
        emailErrorMessage={emailError}
        passwordErrorMessage={passwordError}
        onEmailChange={emailHandler}
        onPasswordChange={passwordHandler}
        handleForgotPassword={handleForgotPassword}
        handleLogin={loginHandler}
        error={error}
      />
      <HandleInActiveModal
        isOpen={deactivateModalIsOpen}
        onClose={closeDeactivateModal}
        actionButton={
          ownerEntityType === "customer" ? (
            <Button
              colorScheme="login"
              borderRadius={"8px"}
              rightIcon={<Image src={fiCopy} />}
              onClick={() => CopyButtonText("support@perfeqt.ai")}
            >
              support@perfeqt.ai
            </Button>
          ) : (
            <Button
              colorScheme="login"
              onClick={closeDeactivateModal}
              borderRadius={"8px"}
            >
              Go Back
            </Button>
          )
        }
        supportText={
          <Text color="#2D3748" fontWeight={800} display={"inline"}>
            {ownerEntityType === "customer"
              ? "contact support "
              : "contact your administrator "}
          </Text>
        }
      />
    </>
  );
}
export default SignIn;