import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const CreateRoleBottom = ({
  handleButtonClick,
  buttons,
  isDisabled,
  isEditing,
}) => {
  const [error, setError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleError = (reason) => {
    setError(reason);

    setTimeout(() => {
      setError("");
    }, 5000);
  };
  const history = useHistory();

  const handleButtonConformation = (buttonName, handleError) => {
    if (buttonName == "Save Changes") {
      onOpen();
    } else {
      handleButtonClick(buttonName, handleError);
    }
  };
  return (
    <>
      <Box>
        <Stack
          justifyContent={{ base: "flex-start", md: "flex-end" }}
          alignItems={{ base: "flex-start", md: "center" }}
          direction={{ base: "column", md: "row" }}
          width="100%"
          gap={"16px"}
          mb={"10px"}
        >
          {error && (
            <Text color={"red"} fontStyle={"Bold"}>
              {error} !
            </Text>
          )}
          {!isEditing && (
            <Button
              mr={3}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            colorScheme="white"
            color="black"
            size="md"
            borderRadius="8px"
            border="1px solid teal"
            mb={{ base: 2, md: 0 }}
            width={{ base: "100%", md: "auto" }}
            onClick={() => handleButtonConformation(buttons[0], handleError)}
            isDisabled={isDisabled()}
          >
            {buttons[0]}
          </Button>
          <Button
            colorScheme="login"
            size="md"
            borderRadius="8px"
            width={{ base: "100%", md: "auto" }}
            onClick={() => handleButtonConformation(buttons[1], handleError)}
            isDisabled={isDisabled()}
          >
            {buttons[1]}
          </Button>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalContent>
            <ModalHeader fontWeight={700} fontSize={"18px"}>
              Edit Role Confirmation
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text color="#319795">
                Saving your changes will update the role's permissions and
                settings. This may impact the access that users have to your
                application or service.
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={onClose}
                color={"black"}
              >
                Close
              </Button>
              <Button
                colorScheme="modalsDark"
                onClick={() => {
                  handleButtonClick("Save Changes", handleError);
                }}
              >
                Save Changes
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default CreateRoleBottom;
