import { Box, Flex, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ViewCustomerTop from "../components/viewCustomers/ViewCustomerTop";
import ViewCustomerInfo from "../components/viewCustomers/ViewCustomerInfo";
import ViewCustomerBottom from "../components/viewCustomers/ViewCustomerBottom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../slice";
import { CustomerSaga } from "../saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import ViewCustomerWarning from "../components/viewCustomers/ViewCustomerWarning";
import ViewCustomerLocation from "./ViewCustomerLocation";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const ViewCustomer = ({ location }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: CustomerSaga });

  const [rerenderKey, setRerenderKey] = useState(0);
  const [allUsersStatus, setAllUsersStatus] = useState(false);
  const [locationArray, setLocationArray] = useState([]);

  const toast = useToast();

  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("agencyId");

  const customerDetails = useSelector(selectors.selectCustomerDetails);
  const customerAddresses = useSelector(selectors.selectCustomerAddress);
  const customerLinks = useSelector(selectors.selectAllCUstomerLinks);
  const customerAvatar = useSelector(selectors.selectAvatarUrl);
  const allUsersForCustomer = useSelector(selectors.selectAllUsersForCustomer);
  const originLocations = useSelector(selectors.selectWarehouseData);

  const allCustomerUserRoles = useSelector(
    selectors.selectAllCustomerUserRoles
  );

  const [adminRoleId, setAdminRoleId] = useState(null);

  useEffect(() => {
    if (allCustomerUserRoles && allCustomerUserRoles?.length) {
      allCustomerUserRoles.map((role) => {
        if (role.name === "admin") {
          setAdminRoleId(role.id);
        }
      });
    }
  });

  useEffect(() => {
    if (originLocations) {
      let tempArr = originLocations.map((location) => {
        return {
          id: location.id,
          name: location.name || "-",
          externalCode: location?.externalCode || "-",
          area: null,
          additionalProperties: {},
          city: location.city || "",
          contactPerson: location.contactPerson || "",
          originType: location.originType || "",
          addressLine1: location.address.addressLine1 || "",
          addressLine2: location.address.addressLine2 || "",
          postalCode: location.postalCode || "",
          state: location.state || "",
          links: location.links,
          isEditing: false,
          isFromDB: true,
          isChanged: false,
          status: location.status || "",
        };
      });

      setLocationArray(tempArr);
    }
  }, [originLocations]);

  const addressArray = customerAddresses && customerAddresses[customerId];
  const customerEmailPhone = customerLinks && customerLinks[customerId];

  useEffect(() => {
    dispatch(actions.getCustomerDetails({ customerId }));
    dispatch(actions.getCustomerLinks({ customerId }));
    dispatch(actions.fetchCustomerAddresses({ customerId }));
    dispatch(
      actions.fetchCustomerUser({
        customerId,
        onSuccess: () => {
          setAllUsersStatus(true);
        },
      })
    );
    dispatch(
      actions.fetchAllCustomerUserRoles({
        customerId,
      })
    );
    dispatch(actions.fetchWarehouseDetailsRequest(customerId));
  }, [customerId, rerenderKey]);

  useEffect(() => {
    if (customerDetails && customerDetails.avatar) {
      const { avatar } = customerDetails;
      dispatch(actions.fetchAvatarURL({ payload: avatar }));
    }
  }, [customerDetails]);

  const handleDeactivateButton = (isSwitchOn) => {
    if (isSwitchOn) {
      dispatch(
        actions.fetchChangeCustomerStatus({ customerId, isActive: true })
      );
    } else {
      dispatch(
        actions.fetchChangeCustomerStatus({ customerId, isActive: false })
      );
    }
    setRerenderKey((prevKey) => prevKey + 1);
    if (rerenderKey > 10000) {
      setRerenderKey(0);
    }
  };
  const [countryCode, setCountryCode] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    department: "",
    designation: "",
    email: "",
    mobile: null,
    countryCode: null,
  });

  const handleCreateUser = (closeModal) => {
    const updatedUserDetails = Object.fromEntries(
      Object.entries(userDetails).map(([key, value]) => [
        key,
        value === "" ? null : value,
      ])
    );
    let userPayload = { ...updatedUserDetails };
    if (adminRoleId) {
      userPayload = {
        ...userPayload,
        customerRoleIds: [adminRoleId],
        gender: "prefer_not_to_answer",
      };
    }
    dispatch(
      actions.fetchCreateUserRequest({
        userPayload,
        customerId,
        onSuccess: (id) => {
          toast({
            title: "User Creation Successfull",
            description: "The user was successfully created.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          closeModal();
          setRerenderKey((prevKey) => prevKey + 1);
          if (rerenderKey > 10000) {
            setRerenderKey(0);
          }
        },
        onFailure: (reason) => {
          toast({
            title: "User Creation Failed",
            description: reason,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      })
    );
  };

  useEffect(() => {
    return () => {
      setLocationArray([]);
      dispatch(actions.clearWarehouseDetails());
    };
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          {allUsersStatus &&
            allUsersForCustomer &&
            allUsersForCustomer?.length === 0 && (
              <ViewCustomerWarning
                customerId={customerId}
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                emailIsValid={emailIsValid}
                setEmailIsValid={setEmailIsValid}
                isPhoneValid={isPhoneValid}
                setIsPhoneValid={setIsPhoneValid}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                handleCreateUser={handleCreateUser}
              />
            )}
          <ViewCustomerTop
            customerDetails={customerDetails}
            addressArray={addressArray}
            customerAvatar={customerAvatar}
            customerId={customerId}
          />
          <ViewCustomerInfo
            customerDetails={customerDetails}
            addressArray={addressArray}
            customerEmailPhone={customerEmailPhone}
          />
          <ViewCustomerLocation locationArray={locationArray} />
          <ViewCustomerBottom
            customerId={customerId}
            customerDetails={customerDetails}
            handleDeactivateButton={handleDeactivateButton}
          />
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default ViewCustomer;
