import React from "react";
import MobileDateRangePicker from "../../../../../../views/shared/MobileDateRangePicker";

function MobileDateSelection({
  filters,
  handleFromDateChange,
  handleToDateChange,
  property,
}) {
  return (
    <MobileDateRangePicker
      width="100%"
      filters={filters}
      handleFromDateChange={handleFromDateChange}
      handleToDateChange={handleToDateChange}
    />
  );
}

export default MobileDateSelection;
