export const styles = {
  createRoleInputContainer: {
    width: "100%",
    height: "100%",
    padding: "20px",
    background: "white",
    boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
    borderRadius: 15,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "700",
    display: "inline-flex",
    gap: "18px",
  },
};
