import React, { useState } from "react";
import Select from "../../../../../jobs/components/Select";
function Client({ clientNames, handleFilterQuery, filterQuery, property }) {
  return (
    <Select
      key={property}
      options={clientNames}
      placeholder="Client"
      handleOptionChange={handleFilterQuery}
      customKey={property}
      bg="#F7FAFC"
      value={filterQuery[property]?.value}
    />
  );
}

export default Client;
