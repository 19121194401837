import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  Text,
  Collapse,
  Checkbox,
  useOutsideClick,
  InputGroup,
  Input,
  IconButton,
  InputRightElement,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { DropdownArrowIcon } from "components/Icons/Icons";
import "../../views/Dashboard/Inspection Table/index.css";
import { CloseIcon } from "@chakra-ui/icons";
import { capitalizeStringUpdated } from "utils/commonFunctions";
const style = {
  sideDropList: {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    justifyContent: "flex-start",
  },
  dropDownBox: {
    position: "absolute",
    borderRadius: "15px",
    background: "var(--black-amp-white-white, #FFF)",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    zIndex: 3,
    padding: "20px 8px 20px 8px",
  },
};

const DropdownWithCheckboxesLatest = ({
  dropDownList,
  property,
  handleSelectChange,
  selectedItems,
  title,
  showCheckBox,
  setSelectedRoles,
  isForRoles,
  disable,
}) => {
  const ref = React.useRef();
  const [inputValue, setInputValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(dropDownList);

  useEffect(() => {
    setFilteredOptions(dropDownList);
  }, [dropDownList]);

  const handleSelect = (value) => {
    setSelectedValue(value);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleInputVal = (value) => {
    setInputValue(value);
    // Filter options based on input value
    const filtered = dropDownList?.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });

  const isInputEmpty = inputValue?.trim() ? false : true;

  useEffect(() => {
    if (
      selectedItems &&
      Array.isArray(selectedItems) &&
      selectedItems.length === 0
    ) {
      handleSelect(null);
      setInputValue("");
    }
  }, [selectedItems]);
  return (
    <Box ref={ref} position="relative" flex={"1"}>
      <InputGroup borderRadius="15px" height="40px">
        <Input
          isDisabled={disable}
          bg={isForRoles ? "white" : "#F7FAFC"}
          fontSize={isForRoles ? "14px" : "12px"}
          _focus={{
            bg: "#F7FAFC",
          }}
          _placeholder={{
            fontSize: "15px",
          }}
          value={
            isInputEmpty
              ? Array.isArray(selectedItems)
                ? selectedItems
                    .map(
                      (itemId) =>
                        capitalizeStringUpdated(
                          dropDownList.find((item) => item.id === itemId)
                            ?.category
                        ) || ""
                    )
                    .join(" , ")
                : typeof selectedItems === "string" ||
                  typeof selectedItems === "boolean"
                ? capitalizeStringUpdated(
                    dropDownList.find((item) => item.id === selectedItems)
                      ?.category
                  ) || ""
                : inputValue
              : inputValue
          }
          placeholder={isInputEmpty && selectedItems?.length === 0 ? title : ""}
          onClick={() => {
            if (isOpen !== true) {
              toggleDropdown();
            }
          }}
          onChange={(e) => {
            handleInputVal(e.target.value);
          }}
        />
        <InputRightElement>
          {isInputEmpty &&
          (!isForRoles || selectedItems?.length === 0) &&
          !selectedValue ? (
            <IconButton
              bg="inherit"
              _hover={{ cursor: "pointer" }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={disable ? null : <DropdownArrowIcon />}
              onClick={(e) => {
                if (!disable) {
                  e.stopPropagation();
                  toggleDropdown();
                }
              }}
            ></IconButton>
          ) : (
            <IconButton
              pb="10px"
              bg="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={
                disable ? null : (
                  <CloseIcon color="gray.400" height="10px" mt="11px" />
                )
              }
              onClick={(e) => {
                if (!disable) {
                  e.stopPropagation();
                  if (!isOpen) {
                    toggleDropdown();
                  }
                  setInputValue("");
                  handleSelect(null);
                  if (isForRoles && selectedItems?.length > 0) {
                    setSelectedRoles([]);
                  } else {
                    handleSelectChange("", property);
                  }
                }
              }}
            ></IconButton>
          )}
        </InputRightElement>
      </InputGroup>
      <Collapse className={isOpen ? "drop-down" : "hide"} in={isOpen}>
        <List className="scrollable-dropdown" sx={style.dropDownBox}>
          {filteredOptions?.length === 0 ? (
            <Box className="name-location-container">
              <Text key={uuidv4()} className="warehouse-name-text">
                {`No matching ${title}`}
              </Text>
            </Box>
          ) : (
            filteredOptions?.map((item, index) => (
              <ListItem
                key={item.id}
                sx={style.sideDropList}
                _hover={{ background: "#F9FDFD" }}
                // style={{ textTransform: "capitalize" }}
                background={
                  !showCheckBox && selectedItems === item.id
                    ? "#F7FAFC"
                    : "inherit"
                }
                borderRadius={"8px"}
                cursor="pointer"
              >
                {showCheckBox ? (
                  <>
                    <Checkbox
                      cursor="pointer"
                      in
                      colorScheme="teal"
                      isChecked={selectedItems.includes(item.id)}
                      onChange={() => {
                        handleSelectChange(item.id, property);
                      }}
                    />
                    <Text
                      onClick={() => {
                        handleSelect(item.category);
                        handleSelectChange(item.id, property);
                      }}
                      fontSize="14px"
                      fontFamily="Inter"
                      cursor="pointer"
                    >
                      {capitalizeStringUpdated(item.category)}
                    </Text>
                  </>
                ) : (
                  <Box w={"100%"} cursor="pointer">
                    <Text
                      onClick={() => {
                        handleSelect(item.category);
                        handleSelectChange(item.id, property);
                        setInputValue(capitalizeStringUpdated(item.category));
                        toggleDropdown();
                      }}
                      fontSize="14px"
                      fontFamily="Inter"
                    >
                      {capitalizeStringUpdated(item.category)}
                    </Text>
                  </Box>
                )}
              </ListItem>
            ))
          )}
        </List>
      </Collapse>
    </Box>
  );
};

export default DropdownWithCheckboxesLatest;
