import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Flex,
  Heading,
  Stack,
  Box,
} from "@chakra-ui/react";
import BackIcon from "../../../../../assets/svg/backBtn.svg";
import { IconButton } from "@chakra-ui/react";
import filterIcon from "../../../../../assets/svg/filter-icon.svg";
import { styles } from "./FilterDrawer.module.js";
import FilterDrawer from "./FilterDrawerWidgets/FilterDrawer";

function FiltersDrawer({
  filtersConfig,
  filterQuery,
  handleFilterQuery,
  clientNames,
  commodityNames,
  locationNames,
  inspectionTypes,
  auditorNames,
  isMultipleFilters,
  tab,
}) {
  const [openedDropdownId, setOpenedDropdownId] = useState(null);
  const openDropdown = (dropdownId) => {
    setOpenedDropdownId(dropdownId);
  };
  const isFilterActive = () => {
    const tabKey = _.camelCase(tab);

    for (const key in filterQuery) {
      if (
        key.includes(tabKey) &&
        !key.toLowerCase().includes("date") &&
        filterQuery[key] &&
        filterQuery[key].value !== null &&
        filterQuery[key].value !== ""
      ) {
        return true;
      }
    }
    return false;
  };
  const clearSecondaryfilters = () => {
    filtersConfig.map((widget) => {
      const dataSource = widget.dataSource;
      const tabId = _.camelCase(tab);
      const property = isMultipleFilters
        ? `${dataSource}_${tabId}`
        : dataSource;
      if (filterQuery) {
        handleFilterQuery(null, property);
      }
    });
  };
  const getSecondaryFilters = (dataSource, id) => {
    const tabId = _.camelCase(tab) || null;
    const property = isMultipleFilters ? `${dataSource}_${tabId}` : dataSource;
    const widgetMap = {
      client: (
        <FilterDrawer
          filterQuery={filterQuery}
          property={property}
          options={clientNames}
          handleFilterQuery={handleFilterQuery}
          labelKey={"value"}
          valueKey={"id"}
          placeholder={"Client"}
          key={id}
        />
      ),
      commodity: (
        <FilterDrawer
          filterQuery={filterQuery}
          property={property}
          options={commodityNames}
          handleFilterQuery={handleFilterQuery}
          labelKey={"name"}
          valueKey={"name"}
          placeholder={"Commodity"}
          key={id}
        />
      ),
      location: (
        <FilterDrawer
          key={id}
          filterQuery={filterQuery}
          property={property}
          options={locationNames}
          handleFilterQuery={handleFilterQuery}
          labelKey={"city"}
          valueKey={"city"}
          placeholder={"Location"}
        />
      ),
      inspectionType: (
        <FilterDrawer
          key={id}
          filterQuery={filterQuery}
          property={property}
          options={inspectionTypes}
          handleFilterQuery={handleFilterQuery}
          labelKey={"name"}
          valueKey={"name"}
          placeholder={"Inspection Type"}
        />
      ),
      auditor: (
        <FilterDrawer
          key={id}
          filterQuery={filterQuery}
          property={property}
          options={auditorNames}
          handleFilterQuery={handleFilterQuery}
          labelKey={"value"}
          valueKey={"id"}
          placeholder={"Auditor"}
        />
      ),
    };
    return widgetMap[dataSource];
  };
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <IconButton
          background="#EDF2F7"
          icon={<Image src={filterIcon} />}
          onClick={() => {
            openDropdown("allFilters");
          }}
          borderRadius="6px"
        />
        {isFilterActive() && <Box sx={styles.greenCircle}></Box>}
      </Box>

      <Drawer
        isOpen={openedDropdownId === "allFilters"}
        onClose={() => {
          setOpenedDropdownId(null);
        }}
        placement="bottom"
        size={"full"}
      >
        <DrawerOverlay />
        <DrawerContent sx={styles.filtersContainer} style={{ height: "90vh" }}>
          <DrawerHeader p="0">
            <Flex justify="space-between" alignItems="center">
              <IconButton
                background="white"
                icon={<Image src={BackIcon} />}
                position={"absolute"}
                left="16px"
                _focus={{
                  background: "white",
                  transform: "none",
                  borderColor: "transparent",
                }}
                onClick={() => {
                  openDropdown(null);
                }}
              />
              <Heading sx={styles.filterHead}>Filters</Heading>
            </Flex>
          </DrawerHeader>
          <DrawerBody mt="36px" p="0px" w="100%">
            <Stack spacing={"24px"} w={"100%"}>
              {filtersConfig.map((widget) => {
                return getSecondaryFilters(widget.dataSource, widget.id);
              })}
            </Stack>
          </DrawerBody>
          <DrawerFooter p="0px" w="100%" gap="20px">
            <Button
              sx={styles.filterBtn}
              colorScheme="gray"
              w="100%"
              color="#1A202C"
              onClick={() => {
                clearSecondaryfilters();
              }}
            >
              Clear
            </Button>
            <Button
              sx={styles.filterBtn}
              colorScheme="login"
              w="100%"
              onClick={() => {
                openDropdown(null);
              }}
            >
              Show Results
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default FiltersDrawer;
