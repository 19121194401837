import React, { useEffect, useState } from "react";
import AddUser from "./AddUser";
import * as selectors from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../slice";
import { UserSaga } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const EditUser = () => {
  const searchParams = new URLSearchParams(location.search);
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UserSaga });

  const dispatch = useDispatch();

  const userId = searchParams.get("userId");
  const customerId = searchParams.get("customerId");
  const clientId = searchParams.get("clientId") || null;
  const userDetails = useSelector(selectors.selectUserData);
  const clientUserDetails = useSelector(selectors.selectClientUserData);
  useEffect(() => {
    if (clientId === null || clientId === undefined) {
      dispatch(actions.fetchUserDataRequest({ userId, customerId }));
    } else {
      dispatch(actions.fetchClientUserData({ clientId, userId, customerId }));
      dispatch(actions.fetchClientUserRoles({ customerId, clientId }));
    }
    return () => {
      dispatch(actions.clearStates());
    };
  }, [userId, clientId, dispatch]);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <AddUser
        oldUserDetails={clientId ? clientUserDetails : userDetails}
        userId={userId}
        isEditing={true}
        clientIdFromEdit={clientId}
      />
    </ErrorBoundary>
  );
};

export default EditUser;
