import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Avatar,
  Stack,
  Text,
  UnorderedList,
  VStack,
  Img,
} from "@chakra-ui/react";
import leftIcon from "../../../assets/svg/leftArrow.svg";
import DownloadIcon from "../../../assets/svg/download.svg";
import EditIcon from "../../../assets/svg/edit.svg";
import UploadIcon from "../../../assets/svg/upload.svg";
import Redirect from "../../../assets/svg/redirect.svg";
function Cards({ icon, iconBg, step, header, des }) {
  return (
    <Card bg={"transparent"} borderRadius={"8px"}>
      <CardBody>
        <HStack gap={"10px"}>
          <Box
            bg={iconBg}
            alignSelf={"self-start"}
            p={"8px"}
            borderRadius={"8px"}
            w={"32px"}
          >
            <img src={icon} alt="icon" w={"32px"} />
          </Box>
          <VStack align="flex-start" color={"#4A5568"} lineHeight={"20px"}>
            <Text color={"#718096"} fontSize={"12px"}>
              {step}
            </Text>
            <Text fontSize={"16px"} fontWeight={"500"}>
              {header}
            </Text>
            <Text color={"#4A5568"} fontSize={"14px"}>
              {des}
            </Text>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  );
}

function TemplateDownload({
  isOpen,
  onClose,
  openUploadFileModal,
  onOpenBulkUserModal,
  closeTemplateDownload,
  userType,
  title,
  manager,
  details,
  avatar,
  setUserType,
  setClientId,
  handleClick,
  pointers,
}) {
  const handleClose = () => {
    onClose();
    setUserType && setUserType(null);
    setClientId && setClientId(null);
  };

  const handleBack =()=>{
    onClose();
    onOpenBulkUserModal()
  }
  return (
    <Modal
      isOpen={isOpen}
      //  onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent minW={"900px"} borderRadius="15px" minH={"600px"}>
        <ModalHeader color={"#2D3748"} fontWeight={700} fontSize={"18px"}>
          {userType && (
            <Button
              onClick={handleBack}
              width="30px"
              height="32px"
              background={"white"}
              border={"1px solid #E2E8F0"}
              padding={0}
              borderRadius={"10px"}
              marginRight={"5px"}
            >
              {" "}
              <Img src={leftIcon} alt="" srcset="" />{" "}
            </Button>
          )}
          {title}
        </ModalHeader>
        <HStack
          borderBottom={"1px solid #E2E8F0"}
          paddingBottom={"16px"}
          fontSize="14px"
        >
          {userType && (
            <HStack>
              <Text pl={"25px"} fontWeight="400" color="#718096">
                User Type:
              </Text>
              <Text fontWeight="500" color="#4A5568">
                {" "}
                {userType}
              </Text>
            </HStack>
          )}
          {userType !== "My Users" ? (
            <HStack gap={"5px"}>
              <Text pl={"25px"} fontWeight="400" color="#718096">
                {manager}:
              </Text>
              <Avatar
                width={"24px"}
                height={"24px"}
                name={details?.orgName || "NA"}
                src={avatar}
              />
              <Text fontWeight="500" color="#4A5568">
                {" "}
                {details?.orgName || "NA"}{" "}
              </Text>
            </HStack>
          ) : null}
        </HStack>

        <ModalCloseButton me="5px" mt="6px" onClick={handleClose} />
        <ModalBody p={"24px"}>
          <VStack gap={"36px"} align={"flex-start"}>
            <VStack bg={"#F7FAFC"}>
              <HStack gap={"24px"}>
                <Cards
                  icon={DownloadIcon}
                  iconBg={"#E9D8FD"}
                  step={"Step1"}
                  header={"Copy Template"}
                  des={"Copy Template in Google Sheets"}
                />
                <Cards
                  icon={EditIcon}
                  iconBg={"#FEEBCB"}
                  step={"Step2"}
                  header={"Add details"}
                  des={"Add assets details in template"}
                />
                <Cards
                  icon={UploadIcon}
                  iconBg={"#B2F5EA"}
                  step={"Step3"}
                  header={"Upload"}
                  des={"Download and upload CSV file."}
                />
              </HStack>
            </VStack>
            <Stack gap={"18px"}>
              <Text color={"#4A5568"} fontSize={"16px"} fontWeight={"500"}>
                Important!
              </Text>
              <UnorderedList
                color="#4A5568"
                fontSize="14px"
                fontWeight="400"
                spacing="12px"
              >
                <ListItem>
                  Use the provided template to enter assets details.
                </ListItem>
                <ListItem>
                  Don't add new columns or edit column headers.
                </ListItem>
                <ListItem>Only CSV files will be accepted.</ListItem>
                {pointers.map((pointer) => (
                  <ListItem>{pointer}</ListItem>
                ))}
              </UnorderedList>
            </Stack>
            <Button
              s="md"
              variant={"outline"}
              onClick={handleClick}
              borderRadius={"8px"}
            >
              Copy Template in Google Sheets{" "}
              <img style={{ marginLeft: "10px" }} src={Redirect} alt="" />
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="login"
            size="md"
            borderRadius={"8px"}
            onClick={() => {
              // onClose();
              openUploadFileModal();
              closeTemplateDownload();
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TemplateDownload;
