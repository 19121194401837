import React, { createContext, useContext, useState } from "react";

const WarehouseFilterContext = createContext();

export const WarehouseFilterProvider = ({ children }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState(selectedWarehouse || "");

  const handleQuery = (val) => {
    setSearchQuery(val);
  };

  const handleSelect = (value) => {
    setInputValue(value);
    handleQuery(value);
  };

  const handleWareHouseChange = (warehouseID) => {
    setSelectedWarehouse(warehouseID);
  };

  const handleInputVal = (value) => {
    setInputValue(value);
  };

  return (
    <WarehouseFilterContext.Provider
      value={{
        handleWareHouseChange,
        selectedWarehouse,
        handleSelect,
        searchQuery,
        handleQuery,
        inputValue,
        handleInputVal,
      }}
    >
      {children}
    </WarehouseFilterContext.Provider>
  );
};

export const useWarehouseFilter = () => {
  return useContext(WarehouseFilterContext);
};
