import React, { useState } from "react";
import { HStack, Box, Text, ButtonGroup } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import Delete from "../../../Components/Delete";
import Edit from "../../../Components/Edit";
import NavigateTo from "../../../Components/NavigateTo";
import { actions } from "../slice";

export default function Option({
  element,
  label,
  value,
  componentId,
  sectionId,
}) {
  const elementId = element.id;
  const defaultElement = element.default;

  const [titleOption, setTitleOption] = useState({
    label: "",
    value: "",
  });

  const dispatch = useDispatch();

  const handleSubmit = (section) => {
    // //console.log("Hello MOFOS", {section, value});
    setTitleOption(section);
    handleEditNavigation(section);
  };

  const handleDeleteOption = () => {
    dispatch(actions.deleteOption({ elementId, value, componentId }));
  };

  const handleSetDefault = () => {
    dispatch(
      actions.editDefault({ elementId, componentId, option: { value, label } })
    );
  };

  const handleEditNavigation = (sectionId) => {
    dispatch(
      actions.editNavigation({ elementId, componentId, sectionId, value })
    );
  };

  return (
    <HStack gap="32px" style={{ padding: "8px 0px", position: "relative" }}>
      <Box w="30%" style={{ paddingLeft: "16px" }}>
        <Text color="login.darkText">{value}</Text>
      </Box>
      <Box w="30%" style={{ paddingLeft: "16px" }}>
        <Text color="login.darkText">{label}</Text>
      </Box>
      <ButtonGroup
        justifyContent="center"
        size="md"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          label={
            defaultElement &&
            defaultElement.value &&
            defaultElement.value === value
              ? "Default"
              : "Set as default"
          }
          variant="ghost"
          color="login.darkText"
          size="sm"
          onClick={handleSetDefault}
        />
        <Delete deleteHandler={handleDeleteOption} />
      </ButtonGroup>
      <NavigateTo
        title={titleOption}
        setTitle={setTitleOption}
        handleSubmit={handleSubmit}
        parentId={sectionId}
      />
    </HStack>
  );
}
