import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.customerPage || initialState;

export const isLoadingCustomerData = createSelector(
  [selectDomain],
  (state) => state.isLoadingCustomersData
);

export const selectTotalNumOfCustomers = createSelector(
  [selectDomain],
  (state) => state.totalNumOfCustomers
);

export const selectAllCustomers = createSelector(
  [selectDomain],
  (state) => state.allCustomers
);

export const selectProfileImgUrlsArr = createSelector(
  [selectDomain],
  (state) => state.profileImgUrlsArr
);

export const selectAllCUstomerLinks = createSelector(
  [selectDomain],
  (state) => state.customerLinks || {}
);

export const selectCustomerIds = createSelector(
  [selectDomain],
  (state) => state.customerIds
);

export const selectCustomerDetails = createSelector(
  [selectDomain],
  (state) => state.customerDetails
);

export const selectCustomerAddresses = createSelector(
  [selectDomain],
  (state) => state.customerAddresses
);

export const selectCustomerAddress = createSelector(
  [selectDomain],
  (state) => state.customerAddress
);

export const selectAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.avatarUrl || null
);
export const selectFiltersData = createSelector(
  [selectDomain],
  (state) => state.filtersData
);
export const selectAllUsersForCustomer = createSelector(
  [selectDomain],
  (state) => {
    return state.allUsersForCustomer;
  }
);
export const selectAllCustomerUserRoles = createSelector(
  [selectDomain],
  (state) => state.allCustomerUserRoles
);
export const selectLocation = createSelector(
  [selectDomain],
  (state) => state.locations
);
export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehouseDetails
);
