import {
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Close from "assets/svg/ModalCloseButton.svg";
import LeftIcon from "assets/svg/left-arrow.svg";
import View from "views/Workflow Builder/pages/View";

const MultistepWorkflow = ({
  isOpen,
  onClose,
  workflowData,
  selectedWorkflow,
  openFirstModal,
  closeFirstModal,
  openedWorkflow,
  setCurrentWorkflow,
}) => {
  const [selectedFormId, setSelectedFormId] = useState(null);

  useEffect(() => {
    if (workflowData && workflowData?.forms?.length > 0) {
      setSelectedFormId(workflowData.forms[0].id);
    }
  }, [workflowData]);

  const handleFormClick = useCallback(
    (formId) => {
      setSelectedFormId(formId);
    },
    [selectedFormId]
  );

  const isFormSelected = useCallback(
    (formId) => {
      return formId === selectedFormId;
    },
    [selectedFormId]
  );

  const selectedFormStyleMemo = useMemo(
    () => ({
      borderRadius: "8px",
      border: "1.5px solid var(--Grey-Gray-300, #CBD5E0)",
      background: "var(--White, #FFF)",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    }),
    []
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (isOpen) {
      // Show loading indicator for 2 seconds
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 2500);
    } else {
      setIsLoading(true); // Reset loading state when modal is closed
    }

    return () => clearTimeout(timer); // Clear timeout when component unmounts or modal is closed
  }, [isOpen]);

  return (
    <>
      {workflowData && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={"4xl"}>
          <ModalOverlay />
          <ModalContent width="100%">
            <ModalBody sx={{ padding: "0px" }}>
              <Flex
                direction="column"
                align="stretch"
                sx={{ borderBottom: "1px #CBD5E0 solid" }}
              >
                <Box>
                  <Flex
                    padding="16px 24px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex gap="16px">
                      <Button
                        size="md"
                        sx={{
                          borderRadius: "var(--border-radius-md, 15px)",
                          border: "1px solid var(--gray-200, #E2E8F0)",
                          bg: "white",
                        }}
                        onClick={() => {
                          onClose();
                          openFirstModal();
                        }}
                      >
                        <Image
                          src={LeftIcon}
                          alt="Dan Abramov"
                          boxSize="16px"
                        />
                      </Button>
                      <Flex alignItems="center">
                        <Text fontSize="18px" fontWeight="700">
                          Multistep Audit Workflow
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex gap="24px">
                      <Button
                        size="md"
                        colorScheme="teal"
                        borderRadius="8px"
                        onClick={() => {
                          setCurrentWorkflow(openedWorkflow, onClose, "select");
                          closeFirstModal();
                        }}
                      >
                        Select WorkFlow
                      </Button>
                      <Flex alignItems="center">
                        <Image
                          src={Close}
                          onClick={onClose}
                          cursor={"pointer"}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
                <Box padding="24px 24px" height="500px" bg="#F2F3F6">
                  <Flex gap="24px" height="452px" boxSizing="border-box">
                    <Flex
                      w="260px"
                      direction="column"
                      bg="#F9F9FB"
                      borderRadius="15px"
                    >
                      {workflowData &&
                        workflowData?.forms.map((form) => {
                          return (
                            <Box
                              padding="16px 12px"
                              onClick={() => handleFormClick(form.id)}
                              style={
                                isFormSelected(form.id)
                                  ? selectedFormStyleMemo
                                  : null
                              }
                            >
                              <Text>{form.title}</Text>
                            </Box>
                          );
                        })}
                    </Flex>
                    {isLoading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        // alignItems="center"
                        minHeight="200px"
                      >
                        <CircularProgress isIndeterminate color="green.300" />
                      </Box>
                    ) : (
                      <Box overflow="auto" borderRadius="15px">
                        {selectedFormId && (
                          <View
                            key={selectedFormId}
                            formId={selectedFormId}
                            width="100%"
                            marginTop = "0px"
                          />
                        )}
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default MultistepWorkflow;
