import React, { useState ,useEffect} from "react";

import { Image } from "@chakra-ui/react";

import { useHistory, useLocation } from "react-router-dom";

import Banner from "components/Layout/auth/Banner/Banner.js";
import { LoginForm } from "@agnext/reactlib";
import "./index.css";
import { setNewPassword } from "services/apis";

function SetNewPassword() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oid = searchParams.get("oid");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isValidPassLength, setIsValidPassLength] = useState(false);
  const [isSpecialChar, setIsSpecialChar] = useState(false);
  const [isAtleastOneDigit, setIsAtleastOneDigit] = useState(false);
  const [isAtleastOneLetter, setIsAtleastOneLetter] = useState(false);

  const passwordHandler = (e) => {
     const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\/\\-]/;
     const atLeastOneLetterRegex = /[a-zA-Z]/;
     const atLeastOneDigitRegex = /\d/;

     const newPassword = e.target.value;
     setPassword(newPassword);
     const passLengthCheck = newPassword.length >= 8;
     const specialCharCheck = specialCharRegex.test(newPassword);
     const atleastOneLetterCheck = atLeastOneLetterRegex.test(newPassword);
     const atleastOneDigitCheck = atLeastOneDigitRegex.test(newPassword);

     setIsValidPassLength(passLengthCheck);
     setIsSpecialChar(specialCharCheck);
     setIsAtleastOneLetter(atleastOneLetterCheck);
     setIsAtleastOneDigit(atleastOneDigitCheck);
   
  };

  const confirmPasswordHandler = (e) => {
     const confirmPassword = e.target.value;

     setConfirmPassword(confirmPassword);
     setIsButtonActive(
        isValidPassLength &&
           isSpecialChar &&
           isAtleastOneDigit &&
           isAtleastOneLetter &&
           password === confirmPassword
     );
  };

  const resetHandler = async () => {
     if (!isValidPassLength || !isSpecialChar) return;
     try {
        if (password === confirmPassword) {
           await setNewPassword(oid, password);
        } else {
           setError("Passwords do not match");
           return;
        }
        setConfirmPassword("");
        setPassword("");
        history.push("/auth/passwordResetSuccessful");
     } catch (error) {
        if (error.response && error.response.data) {
           setError(error.response.data.reason);
        } else {
           setError("Internal server error");
        }
     }
  };

  const backToSignInHandler = () => {
     history.push("/auth/signin");
  };

  return (
     <LoginForm
        type="passwordReset"
        leftComponent={<Banner />}
        logo={<Image w="100%" h="130%" mt="20px" src={ process.env.REACT_APP_LOGO_URL } alt="inspeqt-logo" />}
        password={password}
        confirmPassword={confirmPassword}
        onChangePassword={passwordHandler}
        onChangeConfirmPassword={confirmPasswordHandler}
        passwordErrorMessage=""
        confirmPasswordErrorMessage=""
        handleSubmit={resetHandler}
        handleGoBack={backToSignInHandler}
        hasMinLength={isValidPassLength}
        hasSpecialChar={isSpecialChar}
        hasMinOneLetter={isAtleastOneLetter}
        hasMinOneDigit={isAtleastOneDigit}
        isButtonActive={isButtonActive}
        error={error}
     />
  );
}

export default SetNewPassword;