import { useToast } from "@chakra-ui/react";

const useToastNotification = () => {
  const toast = useToast();

  const dynamicToast = ({
    message,
    status,
    duration = 5000,
    isClosable = true,
    position = "bottom-left",
    ...otherProps
  }) => {
    toast({
      title: _.startCase(_.toLower(message)),
      status: status,
      duration: duration,
      isClosable: isClosable,
      position: position,
      containerStyle: {
        margin: "20px",
        marginBottom: "90px",
      },
      ...otherProps,
    });
  };

  return dynamicToast;
};

export default useToastNotification;
