import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
export const initialState = {
  loading: false,
  error: null,
  clientsOfCustomer: null,
  dataErrorResponse: null,
  clientAvatarUrl: null
};

const bulkUploadSlice = createSlice({
  name: "bulkUpload",
  initialState,
  reducers: {
    fetchClientsOfCustomer: (state, action) => {
      state.loading = true;
    },
    fetchClientsOfCustomerSuccess: (state, action) => {
      let requiredData = action?.payload || [];
      const clientList = _.cloneDeep(action.payload);
      console.log(clientList);
      state.clientsOfCustomer = requiredData;
      state.loading = false;
    },
    fetchClientsOfCustomerFailure: (state, action) => {
      state.loading = false;
    },

    // bulk user creation
    bulkUserUpload: (state, action) => {
      state.loading = true;
    },
    bulkUserUploadSuccess: (state, action) => {
      state.loading = true;
      state.dataErrorResponse = action.payload
    },
    bulkUserUploadFailure: (state, action) => {
      state.loading = true;
    },
    // fetch Avatar
    fetchAvatarURL: (state, action) => {
      state.loading = true;
    },
    getAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.clientAvatarUrl = action.payload;
    },
    getAvatarURLFailure: (state, action) => {
      state.loading = false;
    },
    uploadBulkOrigin: (state, action) => {
      state.loading = false;
    },
    uploadBulkOriginSuccess: (state, action) => {
      state.dataErrorResponse = action.payload;
    },
    clearState: (state, action) => {
      state.clientAvatarUrl= null
    }
  },
});

export const { actions, reducer, name: sliceKey } = bulkUploadSlice;
