import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Flex,
  Image,
  ListItem,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FiUser from "assets/svg/FiUser.svg";
import MdDate from "assets/svg/MdDateRange.svg";
import Location from "assets/svg/locationModified.svg";
import { ActionIcon1 } from "components/Icons/Icons";
import { ViewJobActionDropdown } from "./Buttons/ViewJobActionDropdown";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import PriorityWIthIcon from "../allJobs/Table Components/PriorityWIthIcon";
import Status, { getStatusColor, text } from "./Table Components/Status";
import "./viewJobtopCard.css";
import { useUserContext } from "context/UserContext";

const styles = {
  btn: {
    color: "white",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "700",
    textTransform: "uppercase",
    height: "16px",
    wordWrap: "break-word",
    padding: "0px 4px 0px 4px",
    borderRadius: "4px",
  },
};

const ViewJobTopCard = ({
  creationDt,
  createdBy,
  updatedBy,
  priority,
  status,
  updateDt,
  warehouseDetail,
  jobId,
  inspectionId,
  workflowId,
  task,
  dueDate,
  loadingUserDetails,
  name,
  internalCode,
  originId,
  assigneeProfileImgUrls,
  clientDetails,
  customerId,
  onClickViewReport,
}) => {
  let clientAvatarUrl = null;

  if (clientDetails && clientDetails.avatar) {
    const urlObj = assigneeProfileImgUrls[assigneeProfileImgUrls.length - 1];
    // console.log(urlObj?.url);
    clientAvatarUrl = urlObj?.url;
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
    x: 0,
    y: 0,
  });
  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent default link behavior
    e.stopPropagation();
    const iconPosition = e.currentTarget.getBoundingClientRect();

    setSelectedActionIconPosition({
      x: iconPosition.left,
      y: iconPosition.top,
    });
    onOpen();
  };

  // Creating the new date string in the desired format
  const creationDate = extractDayMonthYearFromTimestamp(creationDt);
  const updationDate = updateDt && extractDayMonthYearFromTimestamp(updateDt);
  const dueDateNew = dueDate ? extractDayMonthYearFromTimestamp(dueDate) : "";
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "24px",
        borderRadius: "15px",
        width: "100%",
        minHeight: "155.95px",
      }}
    >
      {loadingUserDetails || loadingUserDetails === null ? (
        <>
          <Flex w="100%" h="auto" justifyContent="center" alignItems="center">
            <CircularProgress isIndeterminate color="teal.400" size="30px" />
          </Flex>
        </>
      ) : (
        <>
          <Box>
            <VStack justify={"flex-start"} align={"flex-start"} gap="16px">
              <Flex gap="16px" alignItems={"center"}>
                <Text
                  style={{ fontWeight: "700", textTransform: "capitalize" }}
                >
                  {name
                    ? `${internalCode} (${capitalizeStringUpdated(name)})`
                    : internalCode}
                </Text>
                {/* <Button sx={styles.btn} bg={getStatusColor(status)}>
              {text(status)}
            </Button> */}
                <Status status={status ? status : ""} />
              </Flex>
              <Stack
                direction={{ base: "column", md: "row" }}
                align="flex-start"
                spacing={{ base: 2, md: "16px" }}
              >
                <Flex gap="12px" alignItems="center">
                  <Box>
                    <Avatar
                      size="sm"
                      src={clientAvatarUrl ? clientAvatarUrl : ""}
                      name={clientDetails?.name}
                    />
                  </Box>
                  <Text fontSize="14px" fontWeight="600" color="#4A5568">
                    {clientDetails?.name ? clientDetails?.name : ""}
                  </Text>
                </Flex>
                <Popover>
                  <PopoverTrigger>
                    <Button
                      colorScheme="gray"
                      variant="outline"
                      size="xs"
                      // borderColor="#E2E8F0"
                      borderWidth={1}
                      borderRadius={"5px"}
                      p="0px, 12px, 0px, 12px"
                      leftIcon={<Image src={Location} />}
                      bg={isOpen ? "#EDF2F7" : "#fff"}
                    >
                      {warehouseDetail?.name}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent padding="16px" borderRadius={"15px"}>
                    <PopoverBody p="0px">
                      <UnorderedList
                        sx={{
                          listStyleType: "none",
                          color: "#4A5568",
                          lineHeight: "21px",
                          fontSize: "14px",
                          fontWeight: 600,
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          margin: "0px",
                        }}
                      >
                        <ListItem>
                          Name:
                          <span style={{ fontWeight: "400" }}>
                            {capitalizeStringUpdated(
                              warehouseDetail?.name ? warehouseDetail?.name : ""
                            )}
                          </span>
                        </ListItem>
                        <ListItem>
                          Location Type:
                          <span style={{ fontWeight: "400" }}>
                            {capitalizeStringUpdated(
                              warehouseDetail?.originType
                                ? warehouseDetail?.originType
                                : ""
                            )}
                          </span>
                        </ListItem>
                        {/* <ListItem>
                      Contact Person:{" "}
                      <span style={{ fontWeight: "400" }}>
                        capitalizeStringUpdated()
                      </span>
                    </ListItem>
                    <ListItem>
                      Phone Number:<span style={{ fontWeight: "400" }}></span>
                    </ListItem>
                    <ListItem>
                      Email: <span style={{ fontWeight: "400" }}></span>
                    </ListItem> */}
                        <ListItem>
                          {warehouseDetail?.address.addressLine1
                            ? warehouseDetail?.address.addressLine1
                            : ""}
                        </ListItem>
                      </UnorderedList>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <Stack direction="row" align="center" height="100%">
                  <Box>
                    <Image src={MdDate} alt="Dan Abramov" />
                  </Box>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    Due Date:
                  </Text>

                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "600",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    {dueDateNew ? dueDateNew : "-"}
                  </Text>
                  <PriorityWIthIcon priority={priority} />
                </Stack>
              </Stack>
              <Stack
                direction={{ base: "column", md: "row" }}
                align="flex-start"
                spacing={{ base: 2, md: "16px" }}
              >
                <Stack direction="row" align="center" height="100%">
                  <Box>
                    <Image src={FiUser} alt="Dan Abramov" />
                  </Box>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    Created By:
                  </Text>

                  {createdBy ? (
                    <Button
                      borderRadius={3}
                      colorScheme="gray"
                      variant="outline"
                      size="xs"
                      p="11px"
                    >
                      {createdBy}
                    </Button>
                  ) : (
                    <Text>-</Text>
                  )}
                </Stack>

                <Stack direction="row" align="center" height="100%">
                  <Box>
                    <Image src={MdDate} alt="Dan Abramov" />
                  </Box>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    Created Date:
                  </Text>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "600",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    {creationDate ? creationDate : "-"}
                  </Text>
                </Stack>

                {updateDt && updatedBy && (
                  <>
                    <Stack direction="row" align="center" height="100%">
                      <Box>
                        <Image src={FiUser} alt="Dan Abramov" />
                      </Box>
                      <Text
                        style={{
                          color: "#718096",
                          fontSize: "16px",
                          fontWeight: "400",
                          wordWrap: "break-word",
                          padding: "1px",
                        }}
                      >
                        Modified By:
                      </Text>

                      {updatedBy ? (
                        <Button
                          borderRadius={3}
                          colorScheme="gray"
                          variant="outline"
                          size="xs"
                          p="11px"
                        >
                          {updatedBy}
                        </Button>
                      ) : (
                        <Text>-</Text>
                      )}
                    </Stack>{" "}
                    <Stack direction="row" align="center" height="100%">
                      <Box>
                        <Image src={MdDate} alt="Dan Abramov" />
                      </Box>
                      <Text
                        style={{
                          color: "#718096",
                          fontSize: "16px",
                          fontWeight: "400",
                          wordWrap: "break-word",
                          padding: "1px",
                        }}
                      >
                        Last Modified:
                      </Text>
                      <Text
                        style={{
                          color: "#718096",
                          fontSize: "16px",
                          fontWeight: "600",
                          wordWrap: "break-word",
                          padding: "1px",
                        }}
                      >
                        {updationDate ? updationDate : "-"}
                      </Text>
                    </Stack>
                  </>
                )}
              </Stack>
            </VStack>
          </Box>
          {status === "not-required" ? (
            <></>
          ) : (
            <Box
              background={"teal.400"}
              height={"40px"}
              width={"48px"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={"12px"}
              padding={"15px"}
            >
              <ActionIcon1
                w="30px"
                h="30px"
                ms={"9px"}
                mt={"9px"}
                onClick={(e) => handleOpenModal(e)}
                _hover={{
                  cursor: "pointer",
                }}
              />
            </Box>
          )}
          <ViewJobActionDropdown
            selectedActionIconPosition={selectedActionIconPosition}
            isOpen={isOpen}
            onClose={onClose}
            jobId={jobId}
            inspectionId={inspectionId}
            status={status}
            task={task}
            originId={originId}
            customerId={customerId}
            onClickViewReport={onClickViewReport}
          />
        </>
      )}
    </Box>
  );
};

export default ViewJobTopCard;
