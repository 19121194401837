import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions, sliceKey, reducer } from "../../pages/Create/slice";
import { useInjectReducer } from "redux-injectors";
import {
  Heading,
  HStack,
  Text,
  Button,
  Box,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import EditableText from "../Editable";
import FormExport from "../FormsExport";
import { DeleteIcon, ViewIcon, EditIcon } from "@chakra-ui/icons";
import { CustomModal } from "../Modal/CustomModal";

export default function Form({
  formData,
  workflowData,
  handleDeleteForm,
  Status,
  workflowStatus,
  numOfForm
}) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  const dispatch = useDispatch();

  const { isOpen : isOpenDeleteModal, onOpen : onOpenDeleteModal, onClose : onCloseDeleteModal } = useDisclosure();
  const [form, setForm] = useState(formData.title);
  const [description, setDescription] = useState(formData.description);
  const formId = formData.id;
  const workflowId = workflowData.id;

  const handleFormChange = (value) => {
    setForm(value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleFormSubmit = (form) => {
    dispatch(actions.editFormName({ form, formId }));
  };

  const handleDescriptionSubmit = (description) => {
    dispatch(actions.editFormDescription({ description, formId }));
  };

  const deleteFormHandler = () => {
    handleDeleteForm({ formId, workflowId });
  };

  return (
    <HStack
      style={{
        width: "90%",
        padding: "16px",
        background: "#FFF",
        boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
        borderRadius: "15px",
      }}
      w="100%"
      justify="space-between"
    >
      <Box>
        <Heading>
          {/* <Text
            color="#2D3748"
            fontSize="18px"
            fontWeight="600"
            lineHeight="1.5"
          >
            {formData.title}
          </Text> */}
          <EditableText
            text={form}
            handleTextChange={handleFormChange}
            handleSubmit={handleFormSubmit}
            wfStatus={workflowStatus}
          />
        </Heading>
        {/* <Text color="#4A5568" fontSize="14px" fontWeight="500" lineHeight="1.5">
          {formData.description}
        </Text> */}
        <EditableText
          text={description}
          handleTextChange={handleDescriptionChange}
          handleSubmit={handleDescriptionSubmit}
          wfStatus={workflowStatus}
        />
        <Text color="#4A5568" fontSize="14px" fontWeight="500" lineHeight="1.5">
          Order: {formData.order}
        </Text>
      </Box>
      <HStack w="240px" position="relative">
        <Flex
          w="240px"
          sx={{
            position: "absolute",
            top: "-35px",
            left: "0px",
            borderRadius: "15px",
            padding: "8px 12px",
          }}
        >
          <Flex
            sx={{
              position: "absolute",
              top: workflowStatus === "published" ? "16px" : "6px",
              right: "0px",
            }}
          >
            {Status}
          </Flex>
        </Flex>
        {workflowStatus === "published" ? (
          <></>
        ) : (
          <Link to={`/admin/workflows/${workflowId}/${formId}/create`}> 
            <Button
              variant="ghost"
              borderRadius="8px"
              sx={{
                _hover: {
                  bg: "teal.400",
                  ".icon": {
                    color: "white",
                  },
                },
              }}
            >
              <EditIcon color="#718096" size={18} className="icon" />
            </Button>
          </Link>
        )}
        <Link to={`/admin/workflows/${workflowId}/${formId}/view`}>
          <Button
            variant="ghost"
            borderRadius="8px"
            sx={{
              _hover: {
                bg: "teal.400",
                ".icon": {
                  color: "white",
                },
              },
            }}
          >
            <ViewIcon color="#718096" size={18} className="icon" />
          </Button>
        </Link>
        <FormExport formId={formId} />
        { workflowStatus === "published" || numOfForm === 1 ? (
            <></>
          ) : (<Button
              onClick={onOpenDeleteModal}
              variant="ghost"
              borderRadius="8px"
              sx={{
                  _hover: {
                    bg: "teal.400",
                    ".icon": {
                      color: "white",
                    },
                  },
                }}>
          <DeleteIcon color="#718096" size={18} className="icon" />
        </Button>) }
      </HStack>
      <CustomModal
          isOpen={isOpenDeleteModal}
          onClose={onCloseDeleteModal}
          headerText="Delete Form"
          onButtonClick={deleteFormHandler}
          buttonText="Yes, I'm sure!"
          maxWidth="436px"
          colorScheme="red"
          bodyText={
            <span color="#718096" mb="10px">
              Are you sure you want to delete form
              <span style={{fontWeight:"500"}}>{` "${formData?.title}"`}</span>?
            </span>
           }
        />
    </HStack>
  );
}