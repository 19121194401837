import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.userRole || initialState;

export const selectUserData = createSelector(
  [selectDomain],
  (state) => state.userRoleData
);

export const selectEditData = createSelector(
  [selectDomain],
  (state) => state.editRoleData
);

export const selectPermissionData = createSelector(
  [selectDomain],
  (state) => state.permissionsData
);

export const selectPostUserRoleData = createSelector(
  [selectDomain],
  (state) => state.postUserRole
);

export const selectRolePermissionData = createSelector(
  [selectDomain],
  (state) => state.rolePermissionData
);
export const selectUserDetails = createSelector(
  [selectDomain],
  (state) => state.userDetails
);

export const selectUserAssignedData = createSelector(
  [selectDomain],
  (state) => state.userAssignedData
);

export const selectUserAssignedDataError = createSelector(
  [selectDomain],
  (state) => state.userAssignedDataError
);

export const selectSingeUsers = createSelector(
  [selectDomain],
  (state) => state.singleUsers
);
