import React, { useCallback } from "react";
import ShortAnswer from "views/Workflow/components/shortAnswer";
import LongAnswer from "views/Workflow/components/longAnswer";
import { Box } from "@chakra-ui/react";
import SingleSelect from "views/Workflow/components/singleSelect";
import MultiSelect from "views/Workflow/components/multiSelect";
import CheckBox from "views/Workflow/components/checkbox";
import Dropdown from "views/Workflow/components/dropdown";
import FileUpload from "views/Workflow/components/fileUpload";
import FileUploads from "views/Workflow/components/fileUploads";
import MediaUpload from "views/Workflow/components/mediaUpload";
import Address from "views/Workflow/components/address";
import MultiTextFields from "views/Workflow/components/multiTextFields";
import Slider from "views/Workflow/components/slider";
import Email from "views/Workflow/components/email";
import Number from "views/Workflow/components/number";
import DateTimePicker from "views/Workflow/components/datetimepicker";
import CheckboxWithSearch from "views/Workflow/components/checkboxWithSearch";
import DropdownWithSearch from "views/Workflow/components/dropdownWithSearch";
import Mobile from "views/Workflow/components/mobile";

export default function Element({
  elementType,
  formData,
  handleFormDataChange,
  data,
  handleFilesChange,
  // setPage,
  // displayData,
  // navIndex,
  // setNavIndex,
}) {
  const getElement = useCallback(() => {
    const elementMap = {
      textInput: (
        <ShortAnswer
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      textarea: (
        <LongAnswer
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      radio: (
        <SingleSelect
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          // setPage={setPage}
          // displayData={displayData}
          // navIndex={navIndex}
          // setNavIndex={setNavIndex}
        />
      ),
      checkbox: (
        <MultiSelect
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      checkboxSearch: (
        <CheckboxWithSearch
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      switch: (
        <CheckBox
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      dropdown: (
        <Dropdown
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      dropdownWithSearch: (
        <DropdownWithSearch
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      fileUpload: (
        <FileUploads
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      singleFileUpload: (
        <FileUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleFilesChange={handleFilesChange}
        />
      ),
      multiFileUpload: (
        <FileUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleFilesChange={handleFilesChange}
        />
      ),
      mediaUpload: (
        <MediaUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      address: (
        <Address
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      multiTextFields: (
        <MultiTextFields
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      slider: <Slider />,
      email: (
        <Email
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      numberInput: (
        <Number
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      datepicker: (
        <DateTimePicker
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      mobile: (
        <Mobile
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
    };
    return elementMap[elementType];
  }, [
    elementType,
    formData,
    handleFormDataChange,
    data,
    handleFilesChange,
    // setPage,
    // displayData,
    // navIndex,
    // setNavIndex,
  ]);
  return (
    <Box w="100%" style={{ margin: "16px auto" }}>
      {getElement()}
    </Box>
  );
}
