import { Button, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useErrorBoundary } from "react-error-boundary";
import { FaExclamationTriangle } from "react-icons/fa";
import { useHistory } from "react-router-dom";

function TopLevelFallback({ error, resetErrorBoundary }) {
  const { resetBoundary } = useErrorBoundary();
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/admin/dashboard");
    window.location.reload();
  };
  return (
    <Stack justifyContent="center" alignContent="center">
      <VStack
        justifyContent="center"
        alignContent="center"
        borderRadius="15px"
        backgroundColor="white"
        border={"1px solid #efefef"}
        minH="95vh"
        lineHeight="0px"
      >
        <Stack>
          <FaExclamationTriangle size="60px" color="#DD6B20" />
        </Stack>
        <Heading size="xl">Something Went Wrong</Heading>
        <Text color="#DD6B20">Please Contact Service Provider (contact@agnext.com) !!</Text>
        <Button
          bg="teal"
          color="white"
          border="none"
          p="8px 12px"
          borderRadius="8px"
          fontSize="18px"
          cursor="pointer"
          onClick={handleGoBack}
        >
          Go To Dashboard
        </Button>
      </VStack>
    </Stack>
  );
}

export default TopLevelFallback;
