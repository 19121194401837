import React from "react";
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

import lockLogo from "../../assets/svg/lockLogo.svg";

function HandleInActiveModal({ isOpen, onClose, actionButton, supportText }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={"844px"} borderRadius="18px" paddingBottom="36px">
        <ModalHeader color={"#2D3748"} fontWeight={700} fontSize={"18px"}>
          Account Deactivated
        </ModalHeader>
        <ModalCloseButton me="5px" mt="6px" />
        <ModalBody p={"24px"}>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"36px"}
            alignSelf={"stretch"}
          >
            <Flex
              padding="25.255px"
              justifyContent="center"
              alignItems="flexStart"
              gap="7.015px"
              borderRadius="11.224px"
              background="#FFF5F5"
            >
              <Image src={lockLogo} alt="logo" />
            </Flex>
            <VStack gap={"16px"}>
              <Text
                color="#2D3748"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="28px"
              >
                Account Deactivated!
              </Text>

              <VStack
                color="#4A5568"
                textAlign="center"
                maxW={"440px"}
                transform="rotate(0.014deg)"
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"24px"}
                gap={"16px"}
              >
                <p>
                  Your account has been deactivated. This means you no longer
                  have access to the platform's features and services.
                </p>
                <p>
                  Please {supportText}
                  for further assistance.
                </p>
              </VStack>
            </VStack>
            {actionButton}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default HandleInActiveModal;
