import { HStack, Box } from "@chakra-ui/layout";
import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import Editable from "../../../Components/Editable";
import * as selectors from "../selectors";
import { actions } from "../../Create/slice";
import { ButtonGroup } from "@chakra-ui/button";
import { Button } from "@agnext/reactlib";
import { AddIcon } from "@chakra-ui/icons";
import Component from "../Component";
import { generateId } from "../../../../../utils/index";
import LinkTo from "../../../Components/LinkTo";

export default function Composite({
  compositeId,
  blockId,
  compositeOrder,
  compositeOrderLast,
  sectionId,
  isValid,
  validationState,
  errorStyle,
}) {
  const compositeValidationState = validationState.composites?.find(
    (composite) => composite.id === compositeId
  );

  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [title, setTitle] = useState({
    label: "",
    value: "",
  });

  const [compositeDescription, setCompositeDescription] = useState("");

  const [components, setComponents] = useState([]);

  const composites = useSelector(selectors.selectCompositesForComponents);

  const dispatch = useDispatch();

  useEffect(() => {
    if (composites.length) {
      composites.forEach((composite) => {
        if (composite && composite.id === compositeId) {
          const newComponents =
            composite.structure && composite.structure.components;
          setComponents(newComponents);
          setcompositeName(composite.title);
          setCompositeDescription(
            composite.description ? composite.description : "Description"
          );
        }
      });
    }
  }, [compositeId, composites]);

  // useEffect(() => {
  //   dispatch(actions.fetchCompositeData({ id: compositeId }));
  // }, [dispatch, compositeId]);

  const [compositeName, setcompositeName] = useState("");

  const getLinks = useCallback(() => {
    let links;
    if (composites.length) {
      composites.forEach((composite) => {
        if (composite.id === compositeId) {
          links = composite.structure &&
            composite.structure.links && [...composite.structure.links];
        }
      });
    }
    return links || [];
  }, [compositeId, composites]);

  const handleCompositeNameChange = (value) => {
    setcompositeName(value);
  };

  const handleCompositeDescriptionChange = (value) => {
    setCompositeDescription(value);
  };

  const handleSubmitLinkTo = () => {
    dispatch(
      actions.addLinkToComposite({
        compositeId,
        linkTo: {
          type: selectedOption.value,
          id: title.value,
          title: title.label,
        },
      })
    );
    const newOption = { label: "", value: "" };
    setSelectedOption(newOption);
    setTitle(newOption);
  };

  const handleCompositeDelete = () => {
    const payload = {
      blockId,
      compositeId,
    };
    dispatch(actions.deletionComposite(payload));
  };

  const handleCompositeDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      blockId,
      compositeId,
    };
    dispatch(actions.duplicateComposite(payload));
  };

  const handleCompositeNameSubmit = (compositeName) => {
    const payload = {
      compositeId,
      blockId,
      compositeName,
    };
    dispatch(actions.editCompositeName(payload));
  };

  const handleCompositeDescriptionSubmit = (compositeDescription) => {
    const payload = {
      compositeId,
      compositeDescription,
    };
    dispatch(actions.editCompositeDescription(payload));
  };

  const handleAddComponent = async () => {
    const componentId = await generateId();
    dispatch(actions.initNewComponent({ compositeId, componentId }));
  };

  const moveUpHandlerComposite = () => {
    const payload = {
      blockId,
      compositeId,
    };
    dispatch(actions.moveUpComposite(payload));
  };

  const moveDownHandlerComposite = () => {
    const payload = {
      blockId,
      compositeId,
    };
    dispatch(actions.moveDownComposite(payload));
  };

  return (
    <>
      <Box
        w={"100%"}
        style={
          !isValid && !compositeValidationState?.structure
            ? {
                ...errorStyle,
                padding: "16px",
                margin: "16px 0 16px 0",
                borderLeft: "2px solid rgba(255, 191, 0, 0.5)",
                borderRadius: "0 8px 8px 0",
              }
            : {
                padding: "16px",
                border: "1px solid #EFEFEF",
                margin: "16px 0 16px 0",
                borderLeft: "2px solid rgba(255, 191, 0, 0.5)",
                borderRadius: "0 8px 8px 0",
              }
        }
      >
        <HStack
          justify="space-between"
          style={{
            background: "rgba(84,3, 117, 0.03)",
            padding: "7px 12px",
            borderRadius: "0 8px 8px 0",
          }}
        >
          <Box
            w="40%"
            sx={!isValid && !compositeValidationState?.title ? errorStyle : {}}
          >
            <Editable
              text={compositeName}
              handleTextChange={handleCompositeNameChange}
              handleSubmit={handleCompositeNameSubmit}
            />
          </Box>
          <ButtonGroup
            justifyContent="center"
            size="md"
            style={{ position: "relative" }}
          >
            <Moveup
              moveUpHandler={moveUpHandlerComposite}
              isDisabled={compositeOrder === 1 ? true : false}
            />
            <MoveDown
              moveDownHandler={moveDownHandlerComposite}
              isDisabled={compositeOrder === compositeOrderLast ? true : false}
            />
            <Delete deleteHandler={handleCompositeDelete} />
            <Duplicate duplicateHandler={handleCompositeDuplicate} />
            {/* <LinkTo
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              title={title}
              setTitle={setTitle}
              handleSubmit={handleSubmitLinkTo}
              links={getLinks()}
              parentType="composites"
              parentId={compositeId}
            /> */}
          </ButtonGroup>

          <Box
            w={"40%"}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={handleAddComponent}
              size="sm"
              borderRadius="8px"
              colorScheme="login"
              leftIcon={<AddIcon />}
              label="Component"
            />
          </Box>
        </HStack>
        <Box w="40%" style={{ paddingLeft: "12px" }}>
          <Editable
            text={compositeDescription}
            handleTextChange={handleCompositeDescriptionChange}
            handleSubmit={handleCompositeDescriptionSubmit}
          />
        </Box>
        {components &&
          components.map((component) => (
            <Component
              key={component.id}
              componentId={component.id}
              compositeId={compositeId}
              componentOrder={component.order}
              isValid={isValid}
              validationState={validationState}
              errorStyle={errorStyle}
              componentOrderLast={
                composites.length >= 1
                  ? components[components.length - 1].order
                  : 0
              }
              sectionId={sectionId}
            />
          ))}
      </Box>
    </>
  );
}
