import { Box, Flex, Image } from "@chakra-ui/react";
import DropdownWithCheckboxesLatest from "components/DropdownWithCheckboxes/DropdownWithCheckBoxesLatest";
import React, { useState } from "react";
import DateRangePicker from "views/Dashboard/Inspection Table/components/DateRangePicker";
import { SearchBar } from "views/RoleAndPermission/components/SearchBar/SearchBar";
import AddFilter from "./allUsers/AddFilter";
import Seperator from "assets/svg/seperator.svg";

const style = {
  backgroundColor: "white",
  borderRadius: "15px",
  gap: "16px",
  padding: "16px",
  justifyContent: "space-between",
  boxShadow: "0px 3.49px 5.5px 0px rgba(0, 0, 0, 0.02)",
};

const UserFilters = ({
  departmentDropDownForCustomer,
  designationDropDownForCustomer,
  roleDropDownForCustomer,
  stateDropDownForCustomer,
  cityDropDownForCustomer,
  handleFromDateChange,
  handleToDateChange,
  filters,
  handleUserListFilters,
  isClientUser,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // console.log("prosp",
  //   departmentDropDownForCustomer,
  //   designationDropDownForCustomer,
  //   roleDropDownForCustomer
  // );
  return (
    <Flex sx={style}>
      {/* <Box flex={"1"}>
        <SearchBar />
      </Box> */}
      {isClientUser ? (
        <Box flex={"1"}>
          <DropdownWithCheckboxesLatest
            // dropDownList={}
            // property="clientName"
            // handleSelectChange={}
            // selectedItems={filters["clientName"]}
            title="Client Name"
            flex="1"
            // showCheckBox
          />
        </Box>
      ) : (
        <>
          <Box flex={"1"}>
            <DropdownWithCheckboxesLatest
              dropDownList={departmentDropDownForCustomer}
              property="department"
              handleSelectChange={handleUserListFilters}
              selectedItems={filters["department"]}
              title="Department"
              flex="1"
              showCheckBox
            />
          </Box>
          <Box flex={"1"}>
            <DropdownWithCheckboxesLatest
              dropDownList={designationDropDownForCustomer}
              property="designation"
              handleSelectChange={handleUserListFilters}
              selectedItems={filters["designation"]}
              title="Designation"
              flex="1"
              showCheckBox
            />
          </Box>
        </>
      )}
      <Box flex={"1"}>
        <DropdownWithCheckboxesLatest
          dropDownList={roleDropDownForCustomer}
          property="role"
          handleSelectChange={handleUserListFilters}
          selectedItems={filters["role"]}
          title="Role"
          flex="1"
          showCheckBox
        />
      </Box>
      <Box flex={"1"}>
        <DateRangePicker
          filters={filters}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
        />
      </Box>
      <Flex flexDirection="row" gap="16px">
        <Box>
          <Image src={Seperator} />
        </Box>
        <AddFilter
          isMobile={isMobile}
          stateDropDownForCustomer={stateDropDownForCustomer}
          cityDropDownForCustomer={cityDropDownForCustomer}
        />
      </Flex>
    </Flex>
  );
};

export default UserFilters;
