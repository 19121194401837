import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AddJobInfo from "../components/addJob/AddJobInfo";
import { watchJobsAndManagement } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import { actions, reducer, sliceKey } from "../slice";
import { userStorage } from "utils/helper";
import { generateUUId } from "utils/utils";
import { useHistory, useParams } from "react-router-dom";
import { id } from "date-fns/locale";

import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const priorities = [
  { id: "critical", value: "Critical" },
  { id: "high", value: "High" },
  { id: "medium", value: "Medium" },
  { id: "low", value: "Low" },
];

const AddJob = ({ tasksData, jobData, isEditing, inspectionData }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });

  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const customerId = searchParams.get("customerId");

  const toast = useToast();
  const showToast = (title, description, status) => {
    toast({
      title,
      description,
      status,
      duration: 2000,
      isClosable: true,
    });
  };

  const [taskData, setTaskData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [openedWorkflow, setOpenedWorkflow] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [priority, setPriority] = useState(null);
  const [jobDetails, setJobDetails] = useState({
    jobName: "",
    instructions: "",
  });
  const [isWorkflowChanged, setIsWorkflowChanged] = useState(false);
  const [oldTasksData, setOldTasksData] = useState(null);
  const [dataToCompare, setDataToCompare] = useState(null);
  const [isAddingJob, setIsAddingJob] = useState(null);
  const [isDraftingJob, setIsDraftingJob] = useState(null);
  const [warehouseDetails, setWarehouseDetails] = useState(null);

  const originLocations = useSelector(selectors.selectWarehouseDataByClientId);
  const workflowList = useSelector(selectors.selectWorkflowList);
  const workflowData = useSelector(selectors.selectWorkflowData);
  const customerUser = useSelector(selectors.selectCustomerUsers);
  const clientsOfCustomer = useSelector(selectors.selectClientsOfCustomer);
  const creatingJobStatus = useSelector(selectors.selectJobCreationStatus);
  const updatingJobStatus = useSelector(selectors.selectJobUpdationStatus);

  const { userProfileData } = useUserContext();

  const workflowUsersIds =
    workflowList && workflowList.map((workflow) => workflow.createdBy);

  const entity = getCurrentProfile(userProfileData?.profiles);
  const ownerEntityId = entity?.ownerEntityId;

  const clients = useCallback(() => {
    return clientsOfCustomer
      .filter((client) => client.isActive)
      .map((obj) => {
        return {
          ["value"]: obj?.clientName ? obj?.clientName : "XYZ",
          ["id"]: obj?.clientId,
        };
      });
  }, [clientsOfCustomer]);
  //Filter will filter the assignee who are inActive.
  const assigneeList = useCallback(() => {
    return customerUser
      .filter((obj) => obj.isActive)
      .map((obj) => {
        return {
          ["value"]: obj?.firstName + " " + obj?.lastName,
          ["id"]: obj?.userId,
        };
      });
  }, [customerUser]);

  useEffect(() => {
    if (clientId) {
      dispatch(actions.fetchWarehouseDetailsRequestByClientId(clientId));
    } else {
      setWarehouseDetails([]);
    }
    setSelectedLocation(null);
  }, [clientId]);

  useEffect(() => {
    if (originLocations) setWarehouseDetails(originLocations);
  }, [originLocations]);

  useEffect(() => {
    if (
      jobData &&
      selectedWorkflow &&
      jobData.workflowId !== selectedWorkflow.id
    ) {
      setIsWorkflowChanged(true);
    }
  }, [selectedWorkflow]);

  useEffect(() => {
    if (tasksData && jobData && isEditing && inspectionData) {
      if (jobData) {
        setJobDetails({
          jobName: jobData.name,
          instructions: jobData.instructions,
        });
        setClientId(inspectionData.clientId);
        setPriority(jobData.priority);
      }
    }
  }, [jobData]);

  useEffect(() => {
    if (workflowList && jobData) {
      const oldWorkflow = workflowList.find(
        (workflow) => workflow.id == jobData.workflowId
      );
      if (oldWorkflow) {
        setSelectedWorkflow(oldWorkflow);
        const workflowId = oldWorkflow.id;
        dispatch(actions.fetchWorkflowDataRequest({ workflowId }));
      }
    }
  }, [workflowList, jobData]);

  useEffect(() => {
    if (isEditing && warehouseDetails && locations && jobData) {
      const oldLocaiton = locations.find(
        (location) => location.id === jobData.originId
      );
      setSelectedLocation(oldLocaiton);
    }
  }, [warehouseDetails, jobData]);

  useEffect(() => {
    if (tasksData && tasksData.length && workflowData?.workflow.id) {
      const map = {};
      workflowData.forms.forEach((form) => {
        map[form.id] = form.order;
      });
      const oldTaskData = tasksData.map((task) => {
        const date = new Date(task.dueDate);

        return {
          assigneeId: task.assignedTo?.id,
          date,
          formId: task.formId,
          workflowId: task.workflowId,
          id: task.id,
          order: map[task.formId],
        };
      });

      setOldTasksData(oldTaskData);
      setDataToCompare(oldTaskData);
    }
  }, [tasksData, workflowData]);

  useEffect(() => {
    if (oldTasksData && isWorkflowChanged === false && workflowData) {
      let tempTaskData = [...oldTasksData];
      workflowData.forms.forEach((form) => {
        const formIndex = oldTasksData.findIndex(
          (oldTask) => oldTask.formId === form.id
        );
        if (formIndex === -1) {
          tempTaskData.push({
            assigneeId: null,
            date: null,
            formId: form.id,
            workflowId: workflowData.workflow.id,
            id: generateUUId(),
          });
        }
      });

      setOldTasksData(tempTaskData);
      setDataToCompare(tempTaskData);
    }
  }, [workflowData]);

  useEffect(() => {
    dispatch(actions.fetchWorkfkowListRequest());
    dispatch(
      actions.fetchCustomerUsers({
        customerId,
        roleName: "auditor",
      })
    );
    dispatch(
      actions.fetchClientsOfCustomer({
        customerId,
      })
    );

    return () => {
      setSelectedLocation(null);
      setSelectedWorkflow(null);
      setOpenedWorkflow(null);
      setClientId(null);
      setPriority(null);
      setJobDetails({ jobName: "", instructions: "" });
      setIsWorkflowChanged(false);
      setOldTasksData(null);
      setDataToCompare(null);
      setTaskData([]);
      dispatch(actions.clearStates());
      setIsAddingJob(null);
      setIsDraftingJob(null);
    };
  }, [history]);


  const locations =
    warehouseDetails &&
    warehouseDetails
      ?.filter((loc) => loc.status !== "inactive")
      .map((warehouse) => {
        let address =
          (warehouse?.address?.addressLine1
            ? warehouse?.address?.addressLine1
            : "") +
          (warehouse?.address?.addressLine2
            ? "," + warehouse?.address?.addressLine2
            : "") +
          (warehouse?.city ? ", " + warehouse?.city : "") +
          (warehouse?.state ? ", " + warehouse?.state : "") +
          (warehouse?.postalCode ? ", " + warehouse?.postalCode : "");
        let phoneNumber = "-";
        let email = "-";
        let phoneLink = warehouse?.links?.find((link) => link.type === "phone");
        if (phoneLink?.value && phoneLink?.countryCode) {
          phoneNumber = `+${phoneLink?.countryCode} ${phoneLink?.value}`;
        }
        let emailLink = warehouse?.links?.find((link) => link.type === "email");
        let externalCode = warehouse?.externalCode;
        if (emailLink?.value) email = emailLink?.value;
        return {
          id: warehouse.id,
          name: warehouse.name,
          type: warehouse.originType,
          contactPerson: warehouse.contactPerson || "-",
          address,
          phoneNumber,
          email,
          externalCode
        };
      });

  const setCurrentWorkflow = (workflow, onClose, action) => {
    if (action === "select") {
      setSelectedWorkflow(workflow);
      onClose();
    }
    if (action == "view") {
      setOpenedWorkflow(workflow);
    }
    const workflowId = workflow.id;
    dispatch(actions.fetchWorkflowDataRequest({ workflowId }));
  };

  const handleSelectedLocation = (location, onClose) => {
    setSelectedLocation(location);
    onClose();
  };

  const handleJobDetails = (field, value) => {
    setJobDetails((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleOptionChange = (value, customKey) => {
    if (customKey === "client") {
      setClientId(value);
    }
    if (customKey === "priority") {
      setPriority(value);
    }
  };

  const handleAfterCreateInspection = (inspectionId, status) => {
    const jobPayload = {
      name: jobDetails.jobName,
      workflowId: selectedWorkflow.id,
      originId: selectedLocation.id,
      priority,
      status,
      instructions: jobDetails.instructions,
    };
    dispatch(
      actions.fetchCreateJobRequest({
        inspectionId,
        jobPayload,
        onSuccess: (jobId, inspectionId) => {
          handleAfterJobCreation(jobId, inspectionId);
        },
        onFailure: () => {
          toast({
            title: "Job Creation Failed",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      })
    );
  };

  const handleAfterJobCreation = (jobId, inspectionId) => {
    const taskPayload = taskData.map((task) => {
      return {
        workflowId: task.workflowId,
        assignedTo: task.assigneeId,
        dueDate: task.date,
        formId: task.id,
        name: task.name,
      };
    });

    dispatch(
      actions.fetchPostTasksRequest({
        inspectionId,
        jobId,
        taskPayload,
        onSuccess: (status) => {
          if (status) {
            showToast("Job Created Successfully", "", "success");
            history.goBack();
          }
        },
        onFailure: () => {
          showToast(
            "Task Creation Failed",
            "The task could not be created, but the job was created successfully.",
            "error"
          );
          history.goBack();
        },
      })
    );
  };

  const handleAddJob = (status) => {
    const inspectionPayload = {
      originType: selectedLocation.type,
      originId: selectedLocation.id,
      description: null,
      clientId,
      customerId,
    };
    dispatch(
      actions.fetchCreateInspectionRequest({
        inspectionPayload,
        onSuccess: (id) => {
          handleAfterCreateInspection(id, status);
        },
        onFailure: () => {
          toast({
            title: "Job Creation Failed",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      })
    );
  };

  const updateNewTasks = () => {
    const inspectionId = inspectionData?.id;
    const jobId = jobData.id;
    const taskPayload = taskData.map((task) => {
      return {
        workflowId: task.workflowId,
        assignedTo: task.assigneeId,
        dueDate: task.date,
        formId: task.id,
        name: task.name,
      };
    });
    dispatch(
      actions.fetchPostTasksRequest({
        inspectionId,
        jobId,
        taskPayload,
        onSuccess: (status) => {
          updateInspectionAndJob();
        },
      })
    );

    setOldTasksData(null);
    setTaskData(null);
  };

  const handleUpdateJob = () => {
    const jobId = jobData.id;
    if (isWorkflowChanged) {
      const inspectionId = jobData.inspectionId;
      dispatch(
        actions.fetchDeleteTasksRequest({
          inspectionId,
          jobId,
          onSuccess: () => {
            console.log("success");
            updateNewTasks();
          },
        })
      );
    } else {
      updateInspectionAndJob();
    }
  };

  const updateInspectionAndJob = () => {
    const inspectionId = inspectionData?.id;

    const inspectionPayload = {
      originType: selectedLocation.type,
      originId: selectedLocation.id,
      description: null,
      clientId,
      customerId,
    };

    //mange put API here for to update inspection
    dispatch(
      actions.fetchUpdateInspectionRequest({
        inspectionPayload,
        inspectionId,
        onFailure: () => {
          showToast("Saving Changes Failed", "", "error");
        },
      })
    );

    const jobPayload = {
      name: jobDetails.jobName,
      workflowId: selectedWorkflow.id,
      originId: selectedLocation.id,
      priority,
      status: jobData?.status === "not-required" ? "not-required" : "todo",
      instructions: jobDetails.instructions,
    };

    dispatch(
      actions.fetchUpdateJobRequest({
        inspectionId,
        jobPayload,
        jobId,
        onSuccess: (status) => {
          if (status) {
            showToast("Saved Changes Successfully", "", "success");
            history.goBack();
          }
        },
        onFailure: () => {
          showToast("Saving Changes Failed", "", "error");
        },
      })
    );
  };

  const isEnabled = () => {
    if (taskData) {
      if (taskData.some((task) => !task.assigneeId || !task.date)) {
        return false;
      }
    }
    if (oldTasksData) {
      if (
        oldTasksData.some((oldTask) => !oldTask.assigneeId || !oldTask.date)
      ) {
        return false;
      }
    }
    return (
      clientId !== null &&
      priority != null &&
      // jobDetails.jobName.trim() !== "" &&
      // jobDetails.instructions.trim() !== "" &&
      selectedWorkflow !== null &&
      selectedLocation !== null
    );
  };

  const updateButtonState = () => {
    let res = true;
    if (clientId && clientId !== inspectionData.clientId) res = false;

    if (priority && priority !== jobData.priority) res = false;

    if (selectedLocation && selectedLocation?.id !== jobData.originId)
      res = false;

    if (jobDetails.jobName && jobDetails.jobName !== jobData.name) res = false;

    if (jobDetails.instructions !== jobData.instructions) res = false;

    if (isWorkflowChanged && clientId && priority && jobDetails.jobName) {
      if (!taskData?.some((task) => !task.assigneeId || !task.date))
        res = false;
    }

    return res;
  };
  const handleUpdateTask = (oldTask) => {
    const updatedDataToCompare = dataToCompare.map((task) => {
      if (task.formId == oldTask.formId) {
        const date = oldTask.date;
        const assigneeId = oldTask.assigneeId;

        return { ...task, date, assigneeId };
      }
      return task;
    });
    setDataToCompare(updatedDataToCompare);

    const taskPayload = {
      assignedTo: oldTask.assigneeId,
      dueDate: oldTask.date,
    };

    const inspectionId = jobData.inspectionId;
    const jobId = jobData.id;
    const taskId = oldTask.id;

    dispatch(
      actions.fetchUpdateTaskRequest({
        inspectionId,
        jobId,
        taskId,
        taskPayload,
      })
    );
  };

  const { inspectionId, jobId } = useParams();
  const status = jobData?.status;

  const fetchWorkflowDetails = () => {};
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          <AddJobInfo
            allWorkflows={workflowList}
            selectedWorkflow={selectedWorkflow}
            setCurrentWorkflow={setCurrentWorkflow}
            locations={locations}
            selectedLocation={selectedLocation}
            handleSelectedLocation={handleSelectedLocation}
            handleJobDetails={handleJobDetails}
            jobDetails={jobDetails}
            clients={clients()}
            clientId={clientId}
            handleOptionChange={handleOptionChange}
            priorities={priorities}
            fetchWorkflowDetails={fetchWorkflowDetails}
            workflowData={workflowData}
            assigneeList={assigneeList().sort((a, b) =>
              a.value.localeCompare(b.value)
            )}
            setTaskData={setTaskData}
            taskData={taskData}
            isWorkflowChanged={isWorkflowChanged}
            isEditing={isEditing}
            oldTasksData={oldTasksData}
            setOldTasksData={setOldTasksData}
            dataToCompare={dataToCompare}
            handleUpdateTask={handleUpdateTask}
            openedWorkflow={openedWorkflow}
            priority={priority}
            jobData={jobData}
          />

          {/* {isEditing && (
          <EditJobBottomCard
            handleDeleteJobDispatch={handleDeleteJobDispatch}
            handleUpdateJobDispatch={handleUpdateJobDispatch}
            status={status}
          />
        )} */}

          <Flex justifyContent={"flex-end"}>
            <Flex gap="16px">
              {isEditing ? (
                <Button
                  borderRadius={"8px"}
                  onClick={() => history.push("/admin/jobs")}
                >
                  Go Back
                </Button>
              ) : (
                <Button
                  borderRadius={"8px"}
                  onClick={() => {
                    setIsDraftingJob(true);
                    handleAddJob("draft");
                  }}
                  isDisabled={!isEnabled()}
                  isLoading={isDraftingJob && creatingJobStatus}
                  loadingText="Drafting Job..."
                >
                  Save As Draft
                </Button>
              )}
              {isEditing ? (
                <Button
                  colorScheme="login"
                  size="md"
                  borderRadius={"8px"}
                  onClick={handleUpdateJob}
                  isDisabled={
                    jobData.status && jobData.status === "draft"
                      ? !isEnabled()
                      : updateButtonState()
                  }
                  isLoading={updatingJobStatus}
                  loadingText={
                    jobData.status && jobData.status === "draft"
                      ? "Adding Job..."
                      : "Saving Changes"
                  }
                >
                  {jobData.status && jobData.status === "draft"
                    ? "+ Add Job"
                    : "Save Changes"}
                </Button>
              ) : (
                <Button
                  colorScheme="login"
                  size="md"
                  borderRadius={"8px"}
                  onClick={() => {
                    setIsAddingJob(true);
                    handleAddJob("todo");
                  }}
                  isDisabled={!isEnabled()}
                  isLoading={isAddingJob && creatingJobStatus}
                  loadingText="Adding Job..."
                >
                  + Add Job
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default AddJob;
