import { Box, Text } from "@chakra-ui/react";
import DropdownWithCheckboxes from "components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import React from "react";
const styles = {
   heading: {
      color: "#4A5568",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
   },
   itemBadge: {
      backgroundColor: "#718096",
      color: "white",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      padding: "0px 6px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "142.857%",
      marginRight: "8px",
      width: "fit-content",
   },
   hide: {
      display: "none",
   },
};

function FilterCategory({
   title,
   property,
   selectedItems,
   handleRemoveItem,
   handleSelectChange,
   handleItemClick,
   dropDownList,
   handleSetSelection,
   isMobile,
   extraProperty,
}) {
     
   return (
      <Box width="100%">
         <Box display="flex" flexDirection="column" rowGap="8px">
            <Text sx={styles.heading}>{title}</Text>
            <DropdownWithCheckboxes
               title={title}
               dropDownList={dropDownList}
               property={property}
               handleSelectChange={handleSelectChange}
               selectedItems={selectedItems}
               handleItemClick={handleItemClick}
               handleSetSelection={handleSetSelection}
               isMobile={isMobile}
               extraProperty={extraProperty}
               handleRemoveItem={handleRemoveItem}
            />
         </Box>
      </Box>
   );
}

export default FilterCategory;
