import React from "react";
import {
  Slider as SliderChakra,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
} from "@chakra-ui/react";

export default function Slider() {
  const percentageCompletion = 50;
  return (
    <Box style={{padding: "12px 0"}}>
      <Text style={{ position: "absolute", top: "20px", right: "20px" }}>
        {percentageCompletion} / 100
      </Text>
      <SliderChakra
        aria-label="slider"
        defaultValue={percentageCompletion}
        colorScheme="login"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </SliderChakra>
    </Box>
  );
}
