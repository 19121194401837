import { Box, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";
import React from "react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const header = {
  color: "#2D3748",
  fontSize: 16,
  fontFamily: "Inter",
  fontWeight: "600",
  lineHeight: "22.40px",
  wordWrap: "break-word",
  width: "100%",
};

const styles = {
  text1: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    fontWeight: "600",
    fontSize: "14px",
  },
};

const JobInstructionCard = ({ instructions }) => {
  return (
    <VStack gap={"16px"} w={"100%"} borderRadius={"15px"}>
      <Text sx={header}>Instructions</Text>
      <VStack
        bg={"#fff"}
        borderRadius={"15px"}
        padding={"24px"}
        width={"100%"}
        gap={"12px"}
        justifyContent={"flex-start"}
      >
        <Text sx={styles.text1}>
          {" "}
          {instructions ? capitalizeStringUpdated(instructions) : "-"}
        </Text>
      </VStack>
    </VStack>
  );
};

export default JobInstructionCard;
