import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchUserProfileData } from "services/apis";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userProfileData, setUserData] = useState(null);
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("inspectionDashboard"))?.token
  );

  const getProfileData = async () => {
    try {
      const response = await fetchUserProfileData();
      const { data } = response;
      setUserData(data.payload);
    } catch (error) {
      localStorage.removeItem("inspectionDashboard");
      window.location.href = "/auth/signin";
    }
  };

  useEffect(() => {
    if (token) {
      getProfileData();
    } else {
      setUserData(null);
    }
  }, [token]);


  const handleLogin = (newToken) => {
    setToken(newToken);
  };

  const handleLogout1 = () => {
    localStorage.removeItem("inspectionDashboard");
    setToken(null);
  };

  return (
    <UserContext.Provider
      value={{
        userProfileData,
        handleLogin,
        handleLogout1,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
