import React from "react";
import { Flex } from "@chakra-ui/react";

export function VerticalSeparator(props) {
  const { variant, children, ...rest } = props;
  return (
    <Flex w="1px" h="100%" bg="#E0E1E2" {...rest}>
      {children}
    </Flex>
  );
}
