import React, { useRef, useContext, useEffect } from "react";
import { Button, Box } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { FilterIcon } from "components/Icons/Icons";
import FilterCategory from "components/FilterCategory/FilterCategory";
import { ReviewAddFilterContext } from "../../context/ReviewAddFilterContext";
import "../../../../../Dashboard/Inspection Table/index.css";
import { styles } from "./AddFilterNew.module";
import { actions } from "../../slice";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../selectors";

function AddFilterNew({
  handleApplyFilter,
  isMobile,
}) {
  const {
    selectedItems,
    toggleCollapse,
    handleRemoveItem,
    handleSelectChange,
    handleItemClick,
    handleClear,
    isOpen,
    setIsOpen,
    handleQuery,
    query,
  } = useContext(ReviewAddFilterContext);

  const ref = useRef();
  const dispatch = useDispatch();
  const filterData = useSelector(selectors.selectFiltersData);

  // filters data
  const auditorsData = filterData?.auditors?.map((data)=>({id : data?.assigned_to, label : `${data?.first_name} ${data?.last_name}` }))
  const location =  filterData?.city?.map((data)=>({id : data, label : data}))  

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsOpen(false);
    },
  });

  useEffect(() => {
    dispatch(actions.fetchPendingApprovalsFilterData());
  }, []);

  return (
    <Box ref={ref} style={{ position: "relative" }}>
      <Button
        sx={styles.button}
        rightIcon={<FilterIcon width="30px" height="18px" color="#A0AEC0" />}
        variant="outline"
        onClick={toggleCollapse}
      >
        Add Filter
        {(query.auditorId.length > 0 ||
          query.originLocation.length > 0) && (
          <Box sx={styles.greenCircle}></Box>
        )}
      </Button>
      <Box
        className={
          isOpen ? (isMobile ? "drop-Down-mobile" : "drop-Down") : "hide"
        }
      >
        <FilterCategory
          title={"Auditor Name"}
          property={"auditorId"}
          selectedItems={selectedItems}
          handleRemoveItem={handleRemoveItem}
          handleSelectChange={handleSelectChange}
          handleItemClick={handleItemClick}
          dropDownList={auditorsData}
          isMobile={isMobile}
          extraProperty={"auditors"}
        />
        <FilterCategory
          title={"Location"}
          property={"originLocation"}
          selectedItems={selectedItems}
          handleRemoveItem={handleRemoveItem}
          handleSelectChange={handleSelectChange}
          handleItemClick={handleItemClick}
          dropDownList={location}
          isMobile={isMobile}
        />

        <Box sx={styles.clearApplyBtnContainer}>
          <Button sx={styles.clearApplyBtn} onClick={handleClear}>
            Clear
          </Button>
          <Button
            sx={styles.clearApplyBtn}
            onClick={() => {
              handleQuery();
              handleApplyFilter();
            }}
            colorScheme="login"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AddFilterNew;
