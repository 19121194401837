import { Avatar, Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Edit2 from "assets/svg/edit2.svg";
import dateRange from "assets/svg/dateRange.svg";
import buisnessLogo from "assets/svg/Busisness Type.svg";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../slice";
import * as selectors from "../../selectors";
import { Link } from "react-router-dom";
import defaultAvatar from "../../../../assets/img/avatars/defaultAvatar.png";
import { capitalizeStringUpdated } from "utils/commonFunctions";
const ProfileWithName = ({ avatarUrl }) => {
  return (
    <Box
      background={"#EDF2F7"}
      height={"57px"}
      width={"57px"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ cursor: "pointer" }}
      borderRadius={"15px"}
    >
      <Image src={avatarUrl?.url || defaultAvatar} alt="avatarUrl" />
    </Box>
  );
};

export const LogoWithName = ({ clientAvatarUrl, clientName }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ cursor: "pointer" }}
      borderRadius="15px"
      gap="8px"
    >
      {clientName === "-" ? (
        <></>
      ) : (
        <Avatar size="xs" name={clientName} src={clientAvatarUrl} />
      )}
      <Text
        style={{
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "20px",
          color: " #4A5568",
        }}
      >
        {clientName}
      </Text>
    </Box>
  );
};

const Dot = ({ status }) => {
  const bg = status === true ? "#4FD1C5" : "red";
  return (
    <Box
      style={{
        width: "7px",
        height: "7px",
        background: bg,
        borderRadius: "50%",
      }}
    />
  );
};

const statusText = {
  color: "#718096",
  fontSize: "14px",
  fontWeight: "400",
  wordWrap: "break-word",
  textTransform: "capitalize",
  alignItems: "center",
};

const outerBox = {
  display: "flex",
  justifyContent: "space-between",
  // backgroundColor: "white",
  background: "linear-gradient(91deg, #FFF 39.14%, #B6FFF8 263.21%), #FFF",
  padding: "24px 20px",
  borderRadius: "15px",
};

const editButtonStyle = {
  background: "teal.400",
  height: "40px",
  width: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
};

const SvgAndText = ({ src, text }) => {
  return (
    <Flex align="center" gap="4px">
      <Box>
        <Image src={src} alt="Dan Abramov" />
      </Box>
      <Text
        style={{
          color: "#718096",
          fontSize: "16px",
          fontWeight: "400",
          wordWrap: "break-word",
          padding: "4px",
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

const ViewUserTop = ({ userDetails, customerId, clientId, userRole }) => {
  const dispatch = useDispatch();
  const avatarUrl = useSelector(selectors.selectAvatarUrl);
  useEffect(() => {
    if (userDetails?.avatar) {
      const avatar = userDetails.avatar;
      dispatch(actions.fetchAvatarURL({ payload: avatar }));
    }
  }, [userDetails, customerId]);
  return (
    <Box style={outerBox}>
      <Flex width="100%" gap="16px">
        <ProfileWithName avatarUrl={avatarUrl} />
        <Flex flex="1" direction="column" gap="8px">
          <Flex lineHeight="25.2px" gap="8px" alignItems="center">
            {userDetails && userDetails?.firstName ? (
              <Text
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  color: "#2D3748",
                }}
              >
                {capitalizeStringUpdated(
                  (userDetails?.firstName ?? "") +
                    " " +
                    (userDetails?.lastName ?? "")
                ).trim()}
              </Text>
            ) : (
              "-"
            )}
            <Flex alignItems="center">
              <Flex alignItems="center" h="inherit" px="8.5px">
                <Dot status={userDetails?.isActive} />
              </Flex>

              <Text sx={statusText}>
                {userDetails?.isActive ? "Active" : "Inactive"}
              </Text>
            </Flex>
          </Flex>
          <Stack
            direction="row"
            align="center"
            height="100%"
            gap="24px"
            h="24px"
          >
            {clientId ? (
              <LogoWithName
                clientAvatarUrl={""}
                clientName={
                  capitalizeStringUpdated(userDetails?.clientName) || "-"
                }
              />
            ) : (
              <SvgAndText
                src={buisnessLogo}
                text={
                  userDetails && userDetails?.designation
                    ? capitalizeStringUpdated(userDetails?.designation)
                    : "-"
                }
              />
            )}

            <SvgAndText
              src={dateRange}
              text={
                userDetails && userDetails?.creationDt
                  ? extractDayMonthYearFromTimestamp(userDetails?.creationDt)
                  : "-"
              }
            />
          </Stack>
        </Flex>
        {userRole === "admin" ? (
          <Box sx={editButtonStyle}>
            <Link
              to={
                clientId
                  ? `/admin/users/updateUser?userId=${userDetails?.userId}&customerId=${customerId}&clientId=${clientId}`
                  : `/admin/users/updateUser?userId=${userDetails?.userId}&customerId=${customerId}`
              }
            >
              <Image src={Edit2} alt="" />
            </Link>
          </Box>
        ) : null}
      </Flex>
    </Box>
  );
};

export default ViewUserTop;
