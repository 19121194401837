import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";

export function* handlePostFormData({ payload }) {
  try {
    const res = yield call(API.postFormData, payload.data);
    payload.onSuccess();
    let data = JSON.parse(res.data);
    yield put(actions.postFormDataSuccess(data));
  } catch (err) {
    payload.onError();
    yield put(actions.postFormDataFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* postFormDataSaga() {
  yield takeLatest(actions.postFormData.type, handlePostFormData);
}
