import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function Duplicate({ duplicateHandler }) {
  return (
    <>
      <Button
        variant="ghost"
        borderRadius="8px"
        onClick={duplicateHandler}
        sx={{
          _hover: {
            bg: "teal.400",
            ".icon": {
              color: "white",
            },
          },
        }}
      >
        <CopyIcon color="main.icon" className="icon" />
      </Button>
    </>
  );
}

export default Duplicate;
