import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import AlertIcon from "assets/svg/AlertIcon.svg";
import { Link } from "react-router-dom";
import AddUser from "./AddUser";

const outerBox = {
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "white",
  padding: "12px var(--container-padding-md, 20px)",
  borderLeft: "4px solid var(--orange-400, #ED8936)",
};
const ViewCustomerWarning = ({
  userDetails,
  setUserDetails,
  emailIsValid,
  setEmailIsValid,
  isPhoneValid,
  setIsPhoneValid,
  countryCode,
  setCountryCode,
  handleCreateUser,
}) => {
  const {
    isOpen: userModalIsOpen,
    onOpen: onOpenUserModal,
    onClose: onCloseUserModal,
  } = useDisclosure();

  const handleInputChange = (field, value) => {
    setUserDetails((prevValues) => {
      return { ...prevValues, [field]: value };
    });
  };

  const updateButtonStatus = () => {
    let res = false;
    if (
      Object.values(userDetails).some((value) => value === "" || value === null)
    ) {
      res = true;
    }
    if (!emailIsValid || !isPhoneValid) res = true;
    return res;
  };

  return (
    <>
      <Box style={outerBox}>
        <HStack justifyContent="space-between" w="100%">
          <Flex gap="16px">
            <Box h="24px" w="24px">
              <Image src={AlertIcon}></Image>
            </Box>
            <VStack gap="2px" alignItems="left">
              <Text color="#171923" fontSize="16px" fontWeight="600">
                No Admin Yet, Onboard One Now!
              </Text>
              <Text color="#4A5568" fontSize="14px" fontWeight="400">
                Admin has full access and control to manage the agency and its
                users
              </Text>
            </VStack>
          </Flex>
          {/* <Link to={`/admin/users/addUser?customerId=${customerId}`}> */}
          <Button
            background="var(--Teal-Teal-400, #38B2AC)"
            borderRadius="8px"
            size="md"
            color="white"
            onClick={onOpenUserModal}
          >
            Add Admin
          </Button>
          {/* </Link> */}
        </HStack>
      </Box>
      <Modal
        isOpen={userModalIsOpen}
        onClose={onCloseUserModal}
        size={"4xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={"100%"}>
          <ModalHeader>Add Admin</ModalHeader>
          <ModalCloseButton />
          <AddUser
            userDetails={userDetails}
            handleInputChange={handleInputChange}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
            isPhoneValid={isPhoneValid}
            setIsPhoneValid={setIsPhoneValid}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setUserDetails={setUserDetails}
          />
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={() => {
                onCloseUserModal();
                setEmailIsValid(true);
                setIsPhoneValid(true);
                setUserDetails({
                  firstName: "",
                  lastName: "",
                  userName: "",
                  department: "",
                  designation: "",
                  email: "",
                  mobile: null,
                  countryCode: null,
                  gender: "",
                });
              }}
              color={"black"}
              borderRadius="8px"
            >
              Cancel
            </Button>

            <Button
              colorScheme="teal"
              borderRadius="8px"
              onClick={() => handleCreateUser(onCloseUserModal)}
              isDisabled={updateButtonStatus()}
              isLoading={null}
              loadingText="Adding"
            >
              Add Admin
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewCustomerWarning;
