import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ViewUserTop from "../components/viewUser/ViewUserTop";
import ViewUserInfo from "../components/viewUser/ViewUserInfo";
import ViewUserBottom from "../components/viewUser/ViewUserBottom";
import * as selectors from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../slice";
import { UserSaga } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { getCurrentProfile } from "utils/commonFunctions";
import { useUserContext } from "context/UserContext";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
const ViewUser = () => {
  const searchParams = new URLSearchParams(location.search);
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UserSaga });
  const dispatch = useDispatch();

  const { userProfileData } = useUserContext();
  const currentLoginUserId = userProfileData?.id;
  const profiles = userProfileData?.profiles;
  const entity = getCurrentProfile(profiles);
  const userRole = entity?.role?.name;

  const userId = searchParams.get("userId");
  const customerId = searchParams.get("customerId");
  const clientId = searchParams.get("clientId") || null;
  let userDetails;
  if (clientId) {
    userDetails = useSelector(selectors.selectClientUserData);
  } else {
    userDetails = useSelector(selectors.selectUserData);
  }

  const getUserData = () => {
    if (clientId === null || clientId === undefined) {
      dispatch(actions.fetchUserDataRequest({ userId, customerId }));
    } else {
      dispatch(actions.fetchClientUserData({ clientId, userId, customerId }));
    }
  };

  useEffect(() => {
    getUserData();
    return () => {
      dispatch(actions.clearStates());
    };
  }, [userId, customerId]);

  const handleActivateDeactivate = (isSwitchOn) => {
    if (isSwitchOn === true) {
      dispatch(
        actions.ChangeUserStatus({
          customerId,
          userId,
          clientId,
          isActive: true,
          onSuccess: () => getUserData(),
        })
      );
    } else {
      dispatch(
        actions.ChangeUserStatus({
          customerId,
          userId,
          clientId,
          isActive: false,
          onSuccess: () => getUserData(),
        })
      );
    }
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          <ViewUserTop
            userDetails={userDetails}
            customerId={customerId}
            clientId={clientId}
            userRole={userRole}
          />
          <ViewUserInfo userDetails={userDetails} clientId={clientId} />
          {userRole === "admin" && currentLoginUserId !== userId ? (
            <ViewUserBottom
              userDetails={userDetails}
              handleActivateDeactivate={handleActivateDeactivate}
              userId={userId}
              customerId={customerId}
              clientId={clientId}
            />
          ) : (
            <></>
          )}
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default ViewUser;
