import { watchJobsAndManagement } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import { actions, reducer, sliceKey } from "../slice";
import React, { useEffect, useMemo, useState } from "react";
import AddJob from "./AddJob";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const EditJob = () => {
  const searchParams = new URLSearchParams(location.search);
  const { inspectionId, jobId } = useParams();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });
  const dispatch = useDispatch();
  const jobData = useSelector(selectors.selectJob);
  const tasksData = useSelector(selectors.selectTasksData);
  const inspectionData = useSelector(selectors.selectInspection);

  useEffect(() => {
    dispatch(actions.fetchGetTasksRequest({ inspectionId, jobId }));
    dispatch(actions.fetchJob({ inspectionId, jobId }));
    dispatch(actions.fetchGetInspectionRequest({ inspectionId }));
  }, []);

  useEffect(() => {
    dispatch(actions.fetchGetTasksRequest({ inspectionId, jobId }));
    dispatch(actions.fetchJob({ inspectionId, jobId }));
    dispatch(actions.fetchGetInspectionRequest({ inspectionId }));
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <>
        {tasksData && jobData && inspectionData && (
          <AddJob
            isEditing={true}
            tasksData={tasksData}
            jobData={jobData}
            inspectionData={inspectionData}
          />
        )}
      </>
    </ErrorBoundary>
  );
};

export default EditJob;
