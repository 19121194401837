import React, {useState} from "react";
import { HStack, Box, Input, Text } from "@chakra-ui/react";
import { Select } from "@agnext/reactlib";

const options = [
  { value: "1", label: "Option 1" },
  { value: "2", label: "Option 2" },
  { value: "3", label: "Option 3" },
];

export default function Address() {
  const [data, setData] = useState({

  });

  const handleChange = (e, id)=> {
    setData(prevState => {
      return {...prevState, [id]: e.target.value}
    });
  }

  return (
    <>
      <HStack w="100%" gap="10px">
        <Box>
          <Text
            color="#2D3748"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
            style={{ marginBottom: "8px" }}
          >
            Address Line 1*
          </Text>
          <HStack
            h="40px"
            style={{
              border: "1px solid #E2E8F0",
              borderRadius: "8px",
              paddingLeft: "16px",
            }}
          >
            <Input
              variant="unstyled"
              placeholder="Placeholder"
              color="#2D3748"
              _placeholder={{ color: "#A0AEC0" }}
              value={data}
              onChange={e => handleChange(e, 'abc')}
            />
          </HStack>
        </Box>
        <Box>
          <Text
            color="#2D3748"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
            style={{ marginBottom: "8px" }}
          >
            Address Line 2
          </Text>
          <HStack
            h="40px"
            style={{
              border: "1px solid #E2E8F0",
              borderRadius: "8px",
              paddingLeft: "16px",
            }}
          >
            <Input
              variant="unstyled"   
              placeholder="Placeholder"
              color="#2D3748"
              _placeholder={{ color: "#A0AEC0" }}
            />
          </HStack>
        </Box>
        <Box>
          <Text
            color="#2D3748"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
            style={{ marginBottom: "8px" }}
          >
            Pin Code*
          </Text>
          <HStack
            h="40px"
            style={{
              border: "1px solid #E2E8F0",
              borderRadius: "8px",
              paddingLeft: "16px",
            }}
          >
            <Input
              variant="unstyled"
              placeholder="Placeholder"
              color="#2D3748"
              _placeholder={{ color: "#A0AEC0" }}
            />
          </HStack>
        </Box>
      </HStack>
      <HStack w="100%" gap="10px" style={{ marginTop: "16px" }}>
        <Box w="100%">
          <Text
            color="#2D3748"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
            style={{ marginBottom: "8px" }}
          >
            Address Line 1*
          </Text>

          <Select
            size="md"
            name="1"
            focusBorderColor="login.500"
            options={options}
          />
        </Box>
        <Box w="100%">
          <Text
            color="#2D3748"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
            style={{ marginBottom: "8px" }}
          >
            Pin Code*
          </Text>

          <Select
            size="md"
            name="1"
            focusBorderColor="login.500"
            options={options}
          />
        </Box>
      </HStack>
    </>
  );
}
