import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./Tooltips.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ labels, barData, tooltipData, isFor }) {
  const [barThickness, setBarThickness] = useState(33); // Initial bar thickness

  // Function to update bar thickness based on screen width

  const updateBarThickness = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      if (barData.length < 15) {
        setBarThickness(11);
      } else if (barData.length >= 15 && barData.length <= 20) {
        setBarThickness(9);
      } else {
        setBarThickness(5);
      }
    } else if (screenWidth <= 600) {
      if (barData.length > 40) {
        setBarThickness(6);
      }
    } else {
      if (barData.length > 20 && barData.length < 30) {
        setBarThickness(13); // Adjust this value for fewer data points
      } else if (barData.length > 30) {
        setBarThickness(9);
      } else {
        setBarThickness(30); // Default value for larger data sets
      }
    }
  };
  useEffect(() => {
    // Update bar thickness when the component mounts and on window resize
    updateBarThickness();
    window.addEventListener("resize", updateBarThickness);
    return () => {
      window.removeEventListener("resize", updateBarThickness);
    };
  }, [barData.length]);
      function generateTooltipHTML(isFor, tooltip, dataItem, dataIndex) {
        let tooltipHTML = "";
        if (isFor === "agency") {
          tooltipHTML = `<ul class="tooltipul1">
        <li>${tooltip.title[0]} ${dataItem?.year}<span class="custom-span">${tooltip.dataPoints[0]["dataset"].data[dataIndex]}</span></li></ul>`;
        } else {
          tooltipHTML += `<ul class="tooltipul">`;
          dataItem.forEach((item) => {
            tooltipHTML += `<li>${
              item.label
            } ${`<span class="custom-span">${item.value}</span>`}</li>`;
          });
          tooltipHTML += `<span id="tooltipData">${tooltip.title[0]} ${dataItem[0].year}</span>`;
        }
        return tooltipHTML;
      }
      //custom tooltip block
      const getOrCreteTooltip = (chart) => {
        let tooltipEL = chart.canvas.parentNode.querySelector("div");
        if (!tooltipEL) {
          tooltipEL = document.createElement("DIV");
          tooltipEL.classList.add(
            isFor === "agency" ? "tooltipDesign1" : "tooltipDesign"
          );
          let tooltipUL = document.createElement("UL");
          tooltipUL.classList.add("tooltipul");

          tooltipEL.appendChild(tooltipUL);
          chart.canvas.parentNode.appendChild(tooltipEL);
        }
        return tooltipEL;
      };
      const customTooltips = (context) => {
        const { chart, tooltip } = context;
        const tooltipEL = getOrCreteTooltip(chart);
        if (tooltip.opacity === 0) {
          tooltipEL.style.opacity = 0;
          return;
        }
        const dataIndex = tooltip.dataPoints[0].dataIndex;
        const dataItem = tooltipData[dataIndex];
        const tooltipHTML = generateTooltipHTML(
          isFor,
          tooltip,
          dataItem,
          dataIndex
        );
        const h = isFor === "agency" ? 23 : 130;

        tooltipEL.querySelector(".tooltipul").innerHTML = tooltipHTML;
        tooltipEL.style.opacity = 1;
        tooltipEL.style.left = chart.canvas.offsetLeft + tooltip.caretX + "px";
        tooltipEL.style.top = chart.canvas.offsetTop + tooltip.y - h + "px";
      };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    height: 270,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: (context) => {
            if (context.tick.value === 0) {
              return "rgba(0, 0, 0, 0.1)";
            }
            return "rgba(0, 0, 0, 0)";
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: customTooltips,
      },
      legend: {
        display: false,
        // position: "bottom",
        // align: "start",
        // labels: {
        //   font: {
        //     size: 14,
        //   },
        //   color: "black",
        //   boxWidth: 20,
        //   padding: 20,
        // },
      },
      tooltips: {
        enabled: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Total Inspection Events",
        data: barData,
        backgroundColor: "#4FD1C5",
        barThickness: barThickness,
        borderColor: "#D1FFFB",
        barSpacing: 54,
        borderRadius: {
          topLeft: 16,
          topRight: 16,
        },
      },
    ],
  };
  const dashedGridLines = {
    id: "dashedGridLines",

    beforeDatasetsDraw(chart, args, options) {
      const lines = options.lines;
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();

      ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
      ctx.setLineDash([11, 11]);
      y.ticks.forEach((tick) => {
        if (tick.value !== 0) {
          ctx.strokeRect(left + 5, y.getPixelForValue(tick.value), width, 0);
        }
      });
      ctx.restore();
    },
  };
  const verticalLine = {
    id: "vertcalLine",
    beforeDatasetsDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = "grey";
      ctx.lineWidth = 1;
      ctx.setLineDash([0, 10]);
      ctx.moveTo(left, bottom);
      ctx.moveTo(left, top);
      ctx.stroke();
    },
  };

  return (
    <Bar
      options={options}
      data={data}
      plugins={[dashedGridLines, verticalLine]}
    />
  );
}

export default BarChart;
