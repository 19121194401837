import React, { useCallback } from "react";
import ShortAnswer from "../../../components/shortAnswer";
import LongAnswer from "../../../components/longAnswer";
import { Box, Image } from "@chakra-ui/react";
import SingleSelect from "../../../components/singleSelect";
import MultiSelect from "../../../components/multiSelect";
import CheckBox from "../../../components/checkbox";
import FileUpload from "../../../components/fileUpload";
import MediaUpload from "../../../components/mediaUpload";
import Address from "../../../components/address";
import MultiTextFields from "../../../components/multiTextFields";
import Slider from "../../../components/slider";
import Email from "../../../components/email";
import Number from "../../../components/number";
import DateTimePicker from "views/Workflow/components/datetimepicker";
import Dropdown2 from "views/Workflow/components/dropdown/index2";
import FileUpload2 from "views/Workflow/components/fileUpload/index2";
import SingleSelect2 from "views/Workflow/components/singleSelect/indexReview";
import DropdownWithSearch from "views/Workflow/components/dropdownWithSearch";
import Mobile from "views/Workflow/components/mobile";
import CheckboxWithSearch from "views/Workflow/components/checkboxWithSearch";
import FileUploadsReview from "views/Workflow/components/fileUploads/indexReview";
export default function Element({
  elementType,
  formData,
  reviewData,
  handleFormDataChange,
  data,
  handleFilesChange,
  setPage,
  displayData,
  navIndex,
  setNavIndex,
  discardButton,
  handleImageDownload,
  repeatable,
  index,
}) {
  const getElement = useCallback(() => {
    const elementMap = {
      textInput: (
        <ShortAnswer
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
        />
      ),
      textarea: (
        <>
          <LongAnswer
            formData={formData}
            handleFormDataChange={handleFormDataChange}
            data={data}
            repeatable={repeatable}
            index={index}
          />
        </>
      ),
      radio: (
        <SingleSelect2
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          setPage={setPage}
          displayData={displayData}
          navIndex={navIndex}
          setNavIndex={setNavIndex}
        />
      ),
      checkbox: (
        <MultiSelect
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      checkboxSearch: (
        <CheckboxWithSearch
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      switch: (
        <CheckBox
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      dropdown: (
        <Dropdown2
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          reviewData={reviewData}
          discardButton={discardButton}
        />
      ),
      dropdownWithSearch: (
        <DropdownWithSearch
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      fileUpload: (
        <FileUploadsReview
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleImageDownload={handleImageDownload}
        />
      ),
      singleFileUpload: (
        <FileUpload2
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleFilesChange={handleFilesChange}
          handleImageDownload={handleImageDownload}
        />
      ),
      multiFileUpload: (
        <FileUpload2
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          handleFilesChange={handleFilesChange}
          handleImageDownload={handleImageDownload}
        />
      ),
      mediaUpload: (
        <MediaUpload
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      address: (
        <Address
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      multiTextFields: (
        <MultiTextFields
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
        />
      ),
      slider: <Slider />,
      email: (
        <Email
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
        />
      ),
      numberInput: (
        <Number
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
        />
      ),
      datepicker: (
        <DateTimePicker
          formData={formData}
          reviewData={reviewData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
        />
      ),
      mobile: (
        <Mobile
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          data={data}
          repeatable={repeatable}
          index={index}
        />
      ),
    };
    return elementMap[elementType];
  }, [
    elementType,
    formData,
    handleFormDataChange,
    data,
    handleFilesChange,
    // setPage,
    // displayData,
    // navIndex,
    // setNavIndex,
  ]);
  return (
    <>
      {Object.keys(formData).length ? (
        <Box
          w="100%"
          style={{
            margin: "16px auto",
          }}
        >
          {getElement()}
        </Box>
      ) : (
        <Box
          w="100%"
          style={{
            margin: "16px auto",
          }}
        ></Box>
      )}
    </>
  );
}
