import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import locationBlack from "assets/svg/locationBlack.svg";
import { capitalizeStringUpdated } from "utils/commonFunctions";
const flexStyles = {
  gap: "18px",
  justifyContent: {
    base: "space-between",
    sm: "space-between",
    xxl: "space-between",
    xxxl: "space-between",
    xxxxl: "flex-start",
  },
  width: "100%",
  flexWrap: "wrap",
};

const BasicInfo = ({ label, displayText, location, forAddress }) => {
  return (
    <Flex
      gap={"11px"}
      w={forAddress ? "100%" : { base: "100%", md: "23%" }}
      minW={"300px"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};

const LocationInfo = ({ label, displayText, location }) => {
  return (
    <Flex
      gap={"11px"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};
const ViewCustomerInfo = ({
  customerDetails,
  addressArray,
  customerEmailPhone,
}) => {
  const registeredAddress = addressArray?.filter(
    (address) => address.isRegistered
  );
  const additionalAddress = addressArray?.filter(
    (address) => !address.isRegistered
  );

  const linkTypeCheck = (customerEmailPhone, check) => {
    return customerEmailPhone?.filter((linkObj) => linkObj.linkType === check);
  };
  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600} color="#2D3748">
            Basic Info
          </Text>
        </Box>
        <Box
          style={{
            background: "white",
            boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
            borderRadius: 15,
          }}
        >
          <Box padding={"16px 24px"}>
            <Flex gap={"28px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Agency Details
              </Text>
              <Box>
                <Flex gap={"12px"} direction={"column"}>
                  <Flex
                    {...flexStyles}
                    direction={{ base: "column", md: "row" }}
                  >
                    <BasicInfo
                      label={"Country:"}
                      displayText={
                        addressArray && addressArray[0]?.country
                          ? addressArray[0]?.country
                          : "-"
                      }
                    />
                    <BasicInfo
                      label={"Organization Name:"}
                      displayText={
                        customerDetails?.name
                          ? capitalizeStringUpdated(customerDetails?.name)
                          : "-"
                      }
                    />
                    {/* <BasicInfo
                      label={"Buisness Type:"}
                      displayText={customerDetails?.businessType || ""}
                    /> */}
                    <BasicInfo
                      label={"Email:"}
                      displayText={
                        customerEmailPhone && customerEmailPhone.length
                          ? linkTypeCheck(customerEmailPhone, "email")[0].value
                          : "-"
                      }
                    />
                    <BasicInfo
                      label={"Phone Number:"}
                      displayText={
                        customerEmailPhone && customerEmailPhone.length
                          ? "+" +
                            linkTypeCheck(customerEmailPhone, "phone")[0]
                              .countryCode +
                            " " +
                            linkTypeCheck(customerEmailPhone, "phone")[0].value
                          : "-"
                      }
                    />
                    {addressArray && addressArray[0]?.country === "India" && (
                      <BasicInfo
                        label={"GSTIN:"}
                        displayText={
                          customerDetails &&
                          customerDetails?.registrationInfo["TIN"]
                            ? customerDetails?.registrationInfo["TIN"]
                            : "-"
                        }
                      />
                    )}
                    {addressArray && addressArray[0]?.country === "India" && (
                      <BasicInfo
                        label={"BCIN:"}
                        displayText={
                          customerDetails?.registrationInfo["CIN"]
                            ? customerDetails?.registrationInfo["CIN"]
                            : "-"
                        }
                      />
                    )}
                    {addressArray && addressArray[0]?.country === "India" && (
                      <BasicInfo
                        label={"Buisness Pan:"}
                        displayText={
                          customerDetails?.registrationInfo["PAN"]
                            ? customerDetails?.registrationInfo["PAN"]
                            : "-"
                        }
                      />
                    )}

                    {addressArray && addressArray[0]?.country === "UAE" && (
                      <BasicInfo
                        label={"Commercial Licence Number:"}
                        displayText={
                          customerDetails?.registrationInfo["CLN"]
                            ? customerDetails?.registrationInfo["CLN"]
                            : "-"
                        }
                      />
                    )}
                    {/* {addressArray && addressArray[0]?.country === "India" && (
                      <BasicInfo
                        label={"TAN:"}
                        displayText={
                          customerDetails?.registrationInfo["TAN"] : "-"
                        }
                      />
                    )} */}
                    {addressArray && addressArray[0]?.country === "UAE" && (
                      <BasicInfo
                        label={"TRN:"}
                        displayText={
                          customerDetails?.registrationInfo["TAN"]
                            ? customerDetails?.registrationInfo["TAN"]
                            : "-"
                        }
                      />
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
          {registeredAddress?.length ? (
            <Box
              style={{
                background: "white",
                boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
                borderRadius: 15,
                flex: "1",
              }}
            >
              <Box padding={"16px 24px"}>
                <Flex gap={"28px"} direction={"column"}>
                  <Text
                    sx={{
                      color: "#718096",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Registered Address
                  </Text>
                  <Flex direction={"column"} gap={"12px"} w="100%">
                    <BasicInfo
                      forAddress={true}
                      label={"Address Type:"}
                      displayText={"Registered"}
                    />
                    <BasicInfo
                      forAddress={true}
                      displayText={
                        (registeredAddress[0]?.addressInfo?.addressLine1
                          ? capitalizeStringUpdated(
                              registeredAddress[0].addressInfo.addressLine1
                            )
                          : "") +
                        (registeredAddress[0]?.addressInfo?.addressLine2?.trim()
                          ? ", " +
                            capitalizeStringUpdated(
                              registeredAddress[0].addressInfo.addressLine2
                            )
                          : "") +
                        (registeredAddress[0]?.city
                          ? ", " +
                            capitalizeStringUpdated(registeredAddress[0].city)
                          : "") +
                        (registeredAddress[0]?.state
                          ? ", " +
                            capitalizeStringUpdated(registeredAddress[0].state)
                          : "") +
                        (registeredAddress[0]?.country
                          ? ", " + registeredAddress[0].country
                          : "") +
                        (registeredAddress[0]?.postalCode
                          ? " " + registeredAddress[0].postalCode
                          : "")
                      }
                    />
                  </Flex>
                </Flex>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {additionalAddress?.length ? (
            <Box
              style={{
                background: "white",
                boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
                borderRadius: 15,
                flex: "1",
              }}
            >
              <Box padding={"16px 24px"}>
                <Flex gap={"28px"} direction={"column"}>
                  <Text
                    sx={{
                      color: "#718096",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Additional Address
                  </Text>
                  <Flex direction={"column"} gap={"12px"}>
                    <BasicInfo
                      forAddress={true}
                      label={"Address Type:"}
                      displayText={additionalAddress[0].addressInfo.type}
                    />
                    <BasicInfo
                      forAddress={true}
                      displayText={
                        (additionalAddress[0]?.addressInfo?.addressLine1
                          ? capitalizeStringUpdated(
                              additionalAddress[0].addressInfo.addressLine1
                            )
                          : "") +
                        (additionalAddress[0]?.addressInfo?.addressLine2
                          ? ", " +
                            capitalizeStringUpdated(
                              additionalAddress[0].addressInfo.addressLine2
                            )
                          : "") +
                        (additionalAddress[0]?.city
                          ? ", " +
                            capitalizeStringUpdated(additionalAddress[0].city)
                          : "") +
                        (additionalAddress[0]?.state
                          ? ", " +
                            capitalizeStringUpdated(additionalAddress[0].state)
                          : "") +
                        (additionalAddress[0]?.country
                          ? ", " + additionalAddress[0].country
                          : "") +
                        (additionalAddress[0]?.postalCode
                          ? " " + additionalAddress[0].postalCode
                          : "")
                      }
                    />
                  </Flex>
                </Flex>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Flex>
        {/* <Box>
          <Text fontWeight={600} color="#2D3748">Basic Info</Text>
        </Box> */}
        {/* <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
          <Box
            style={{
              background: "white",
              boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
              borderRadius: 15,
              flex: "1",
            }}
          >
            <Box padding={"16px 24px"}>
              <Flex gap={"18px"}>
                <Box minWidth={"18px"}>
                  <Image src={locationBlack} alt="Dan Abramov" />
                </Box>
                <Flex direction={"column"}>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    flexWrap={"wrap"}
                    flex={"1"}
                    sx={{
                      "> div": {
                        marginBottom: { base: "12px", md: "12px" }, // Adjust as needed
                        marginRight: { base: "0px", md: "36px" },
                      },
                    }}
                  >
                    <LocationInfo
                      label={"Name:"}
                      displayText={"Rahul"}
                      location={true}
                    />

                    <LocationInfo
                      label={"Contact Person:"}
                      displayText={"Neeraj"}
                      location={true}
                    />
                    <LocationInfo
                      label={"Email:"}
                      displayText={"Neerajbawana@agnext.in"}
                      location={true}
                    />
                    <LocationInfo
                      label={"Location Type:"}
                      displayText={"Warehouse"}
                      location={true}
                    />
                  </Flex>
                  <BasicInfo
                    displayText={
                      "C-184, Industrial Area, Sector 75, Sahibzada Ajit Singh Nagar, Punjab 140308"
                    }
                  />
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Box
            style={{
              background: "white",
              boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
              borderRadius: 15,
              flex: "1",
            }}
          >
            <Box padding={"16px 24px"}></Box>
          </Box>
        </Flex> */}
      </Stack>
    </>
  );
};

export default ViewCustomerInfo;
