import { IconButton } from "@chakra-ui/button";
import { EditIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { Box, HStack } from "@chakra-ui/layout";
import { useEffect, useRef, useState } from "react";

function EditableText({ text, handleTextChange, handleSubmit, wfStatus }) {
  const [isEditing, setIsEditing] = useState(false);

  const refCurr = useRef();

  const [textBoxValue, setTextBoxValue] = useState(text);

  const handleTextboxChange = (e) => {
    setTextBoxValue(e.target.value);
  };

  const handleBlur = () => {
    handleSubmit(textBoxValue);
    handleTextChange(textBoxValue);
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      refCurr.current.focus();
    }
  }, [isEditing]);

  return !isEditing ? (
    <Box w={"100%"}>
      {text}
      {wfStatus && wfStatus === "published" ? (
        <></>
      ) : (
        <IconButton
          size="sm"
          icon={<EditIcon color={"#4FD1C5"} />}
          style={{
            background: "none",
          }}
          _focus={{ boxShadow: "none" }}
          onClick={() => {
            setTextBoxValue(text);
            setIsEditing(true);
          }}
        />
      )}
    </Box>
  ) : (
    <>
      <HStack>
        <Input
          ref={refCurr}
          value={textBoxValue}
          onChange={handleTextboxChange}
          onBlur={handleBlur}
          w={"100%"}
          variant="flushed"
          colorScheme="login"
          focusBorderColor="rgba(0, 0, 0, 0)"
          style={{ borderBottom: "1px solid #EFEFEF" }}
        />
      </HStack>
    </>
  );
}

export default EditableText;
