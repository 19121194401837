import React, { useState } from "react";
import Dropdown from "../../../../../shared/Dropdown";

function InspectionTypes({
  inspectionTypes,
  handleFilterQuery,
  filterQuery,
  property,
}) {
  return (
    <Dropdown
      key={property}
      options={inspectionTypes}
      placeholder={"Inspection Type"}
      handleFilterQuery={handleFilterQuery}
      property={property}
      listKey={"name"}
      filterQuery={filterQuery}
    />
  );
}

export default InspectionTypes;
