import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react";
import CustomInput from "components/Input/InputWithErrMesage";
import React from "react";
import { locationTypes, countryCodes} from "variables/AgencyClientUser";
import Select from "views/shared/Select";

const AddLocation = ({
  handleNewLocationInputChange,
  newLocation,
  handleLinksChange,
  handleNewLocationSelectChange,
  states,
  cities,
  isValidLocationPINCode,
  isValidMobileNumber,
  isValidLocationEmail,
  country,
  isValidExternalCode
}) => {
  const sortedStates = [...states].sort();
  const sortedCities = [...cities].sort();
  return (
    <Box padding={"16px 24px"}>
      <Flex  flexWrap="wrap" gap={"8px"}>
          <CustomInput
            label="Name*"
            placeholder="eg. ABC Location"
            value={newLocation.name}
            onChange={handleNewLocationInputChange}
            name="name"
          />
          <CustomInput
            label="Contact Person"
            placeholder="eg. Amit Singh"
            value={newLocation.contactPerson}
            name="contactPerson"
            onChange={handleNewLocationInputChange}
          />
          <Flex direction={"column"} gap={"8px"} minW={"300px"} flex={2}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              Location Type*
            </Text>
            <Select
              placeholder={"Select"}
              options={locationTypes}
              customKey="originType"
              handleOptionChange={handleNewLocationSelectChange}
              value={newLocation.originType}
            />
          </Flex>
          {
            newLocation.originType === "warehouse" ? <CustomInput
            label="Warehouse Code"
            placeholder="eg. BIHI0001035, 7ot0001064, etc"
            value={newLocation.externalCode}
            name="externalCode"
            onChange={handleNewLocationInputChange}
            isValid={isValidExternalCode}
            errorMessage="Invalid warehouse code format"
          />
          : <></>
          }
          
          <CustomInput
            label="Email"
            placeholder="eg. abc@gmail.com"
            type={"email"}
            value={
              Array.isArray(newLocation.links)
                ? newLocation.links.find((link) => link.type === "email")
                    ?.value ?? ""
                : ""
            }
            onChange={(e) => {
              handleLinksChange(e, "email");
            }}
            isValid={isValidLocationEmail}
            errorMessage="Invalid email format"
          />
          <Flex direction="column" gap="8px" minW={"300px"} flex="2">
            <Text fontSize={"12px"} fontWeight={"700"}>
              Phone Number
            </Text>
            <InputGroup>
              <InputLeftAddon p={"0"} br={"2px"} border={"none"}>
                <Select
                  placeholder="(+XX)"
                  customKey="countryCode"
                  value={
                    newLocation?.links?.find((link) => link.type === "phone")
                      ?.countryCode
                      ? newLocation?.links?.find(
                          (link) => link.type === "phone"
                        )?.countryCode === "91"
                        ? "India +91"
                        : "UAE +971"
                      : ""
                  }
                  handleOptionChange={handleNewLocationSelectChange}
                  options={countryCodes}
                  minWidth="120px"
                  maxWidth="120px"
                />
              </InputLeftAddon>
              <Input
                type={"number"}
                placeholder={"eg. 967 000 ####"}
                value={
                  Array.isArray(newLocation.links)
                    ? newLocation.links.find((link) => link.type === "phone")
                        ?.value ?? ""
                    : ""
                }
                alignSelf="stretch"
                focusBorderColor="#3182ce"
                onChange={(e) => {
                  handleLinksChange(e, "phone");
                }}
                fontSize="14px"
              />
            </InputGroup>
            {!isValidMobileNumber && (
              <Text fontSize="12px" color="red.500">
                {!newLocation?.links?.find((link) => link.type === "phone")
                  ?.countryCode
                  ? "Please select Country code"
                  : "Please enter valid mobile number"}
              </Text>
            )}
          </Flex>

          <CustomInput
            label="Address Line 1*"
            placeholder="eg. 1234 Design Avenue"
            value={newLocation.addressLine1}
            onChange={handleNewLocationInputChange}
            name="addressLine1"
          />
          <CustomInput
            label="Address Line 2"
            placeholder="eg. Near ABC Shop"
            value={newLocation.addressLine2}
            onChange={handleNewLocationInputChange}
            name="addressLine2"
          />

          <Flex direction={"column"} gap={"8px"} minW={"300px"} flex={2}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              State*
            </Text>
            <Select
              placeholder={"Select"}
              options={sortedStates}
              customKey="state"
              handleOptionChange={handleNewLocationSelectChange}
              value={newLocation.state}
              check={country ? " " : "Please select country first"}
            />
          </Flex>
          <Flex direction={"column"} gap={"8px"} minW={"300px"} flex={2}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              City*
            </Text>
            <Select
              placeholder={"Select"}
              options={sortedCities}
              customKey="city"
              handleOptionChange={handleNewLocationSelectChange}
              value={newLocation.city}
              check={"Please select state first"}
            />
          </Flex>
          {country === "India" ? (
            <CustomInput
              label="Pin Code*"
              placeholder="eg. 160XXX"
              type={"number"}
              value={newLocation.postalCode}
              onChange={handleNewLocationInputChange}
              name="postalCode"
              isValid={isValidLocationPINCode}
              errorMessage="Please enter valid Pin Code"
            />
          ) : (
            <></>
          )}
        </Flex>
    </Box>
  );
};

export default AddLocation;
