import React from "react";
import Select from "../../../../../jobs/components/Select";

function Auditor({ auditorNames, handleFilterQuery, filterQuery, property }) {
  return (
    <Select
      key={property}
      options={auditorNames}
      placeholder={"Auditor"}
      handleOptionChange={handleFilterQuery}
      customKey={property}
      bg="#F7FAFC"
      value={filterQuery[property]?.value}
    />
  );
}

export default Auditor;
