import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  uploadLoading: false,
  userData: null,
  error: null,
  preDefinedURL: "",
  profileImageURL: "",
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    fetchUserData(state, action) {
      state.loading = true;
    },
    fetchUserDataSuccess(state, action) {
      state.userData = action.payload;
      state.loading = false;
    },
    fetchUserDataFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    updateProfile(state, action) {
      state.loading = true;
    },
    updateProfileSuccess(state, action) {
      state.userData = action.payload;
      state.loading = false;
    },
    updateProfileFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    passwordReset(state, action) {
      state.loading = true;
    },
    passwordResetSuccess(state, action) {
      state.loading = false;
    },
    passwordResetFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    getPreDefinedURL(state, action) {
      state.loading = true;
    },
    getPreDefinedURLSuccess(state, action) {
      state.preDefinedURL = action.payload;
      state.loading = false;
    },
    getPreDefinedURLFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    uploadFileBlob(state, action) {
      state.uploadLoading = true;
    },
    uploadFileBlobSuccess(state, action) {
      state.profileImageURL = action.payload;
      state.uploadLoading = false;
    },
    uploadFileBlobFailure(state, action) {
      state.uploadLoading = false;
      state.error = true;
    },
    clearState(state, action) {
      state.loading = true;
    },
    clearStateSuccess(state, action) {
      state.preDefinedURL = "";
      state.profileImageURL = "";
      state.loading = false;
    },
    clearStateFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userDataSlice;
