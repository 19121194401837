import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.notification || initialState;

export const selectNotificationData = createSelector(
  [selectDomain],
  (state) => state.notificationData
);

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectUserData = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const selectAvatarURL = createSelector(
  [selectDomain],
  (state) => state.avatarURL
);