import React, { useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Box,
  Image,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
  AddIcon,
} from "@chakra-ui/icons";
import Element from "../element";
import Reset from "assets/svg/BiReset.svg";
import { MdArrowForwardIos, MdOutlineDeleteOutline } from "react-icons/md";
import { Button } from "@agnext/reactlib";

function Component({
  isSectionHidden,
  compEl,
  section,
  formData,
  handleFormDataChange,
  handleFilesChange,
  reviewData,
  handleResetClick,
  discardButton,
  handleImageDownload,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  isObjectMatching,
}) {
   
  const isRepeatable = compEl.component.repeatable?.isRepeatable;
  const minEntries = compEl.component.repeatable?.minEntries;
  const maxEntries = compEl.component.repeatable?.maxEntries;
  const unlimitedEntries = compEl.component.repeatable?.unlimitedEntries;
  const elements = compEl.component.structure?.elements;

  const [repeats, setRepeats] = useState([{ isHidden: false }]);

  let initialRepeats = [];

  if (minEntries) {
    for (let i = 0; i < minEntries; i++) {
      initialRepeats.push({
        isHidden: false
      });
    }
  } else {
    initialRepeats.push({
      isHidden: false
    });
  }

  useEffect(() => {
      initialRepeats = elements.reduce(
      (acc, element) => {
        const attachmentsLength = formData[element.id]?.attachments?.length;
        if (attachmentsLength > acc.length) {
          acc = formData[element.id]?.attachments.map((_) => ({
            isHidden: false,
          }));
        }
        else 
        {
          acc = initialRepeats;
        }
        return acc;
      },
      [[{ isHidden: false }]]
    );
    if (initialRepeats.length > repeats.length) {
      setRepeats(initialRepeats);
    }
  }, [formData]);

  const handleAddRepeats = () => {
    if (repeats.length >= maxEntries && !unlimitedEntries) return;
    setRepeats((prev) => [...prev, { isHidden: false }]);
  };

  const handleToggleHide = (index) => {
    setRepeats((prev) =>
      prev.map((repeat, i) => {
        if (i === index) {
          return { isHidden: !repeat.isHidden };
        }
        return repeat;
      })
    );
  };

  const handleDelete = (elements, index) => {
    if (repeats.length < 2 || (minEntries && repeats.length <= minEntries))
      return;
    setRepeats((prev) => {
      prev.splice(index, 1);
      return prev;
    });
    elements.forEach((element) => {
      const elementId = element.id;
      handleFormDataChange(elementId, "repeatable-delete", index);
    });
  };
  if (isSectionHidden) {
    return <></>;
  }
  return (
    <>
      <VStack w="100%" gap="24px" aria-label="component">
        {isRepeatable ? (
          repeats.map((repeat, index) => (
            <VStack
              w="100%"
              key={index}
              gap={isRepeatable ? "8px" : "24px"}
              sx={
                isRepeatable
                  ? {
                      padding: "20px",
                      width: "100%",
                      border: "1.5px solid #CBD5E0",
                      borderRadius: "15px",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      position: "relative",
                    }
                  : {}
              }
              aria-label="component"
            >
              {isRepeatable && index > 0 ? (
                <HStack w="100%" justify="space-between" alignItems="center">
                  <HStack>
                    <IconButton
                      variant="ghost"
                      borderRadius="8px"
                      // isDisabled={index < 1}
                      sx={{
                        ".icon": {
                          color: "#718096",
                        },
                      }}
                      icon={
                        repeat.isHidden ? (
                          <MdArrowForwardIos
                            w="26px"
                            h="26px"
                            className="icon"
                          />
                        ) : (
                          <CollapseIcon w="26px" h="26px" className="icon" />
                        )
                      }
                      onClick={() => {
                        handleToggleHide(index);
                      }}
                    />
                    <Text>#{index}</Text>
                  </HStack>
                  <IconButton
                    variant="ghost"
                    borderRadius="8px"
                    isDisabled={
                      repeats.length < 2 ||
                      (minEntries && repeats.length <= minEntries)
                    }
                    sx={{
                      ".icon": {
                        color: "red.500",
                      },
                    }}
                    icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                    onClick={() => {
                      handleDelete(elements, index);
                    }}
                  />
                </HStack>
              ) : (
                <></>
              )}
              {elements.map((element) => {
                const isMatching = isObjectMatching(element.id);
                const borderColor = isMatching ? "#CBD5E0" : "#F6AD55";
                return (
                  <Box
                    aria-label="element"
                    key={element.id}
                    sx={repeat.isHidden ? { display: "none" } : {}}
                    style={
                      isRepeatable
                        ? {
                            width: "100%",
                            borderRadius: "15px",
                            background: "#FFF",
                          }
                        : {
                            padding: "20px",
                            width: "100%",
                            border: `1.5px solid ${borderColor}`,
                            borderRadius: "15px",
                            background: "#FFF",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                          }
                    }
                  >
                    <Flex justifyContent={"space-between"}>
                      <Text
                        aria-label="element label"
                        color="#2D3748"
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="1.4"
                      >
                        {element.label}
                        {element.mandatory ? (
                          <span
                            style={{
                              color: "#D93025",
                              paddingLeft: "6px",
                              fontSize: "20px",
                            }}
                          >
                            *
                          </span>
                        ) : (
                          <></>
                        )}
                      </Text>
                      {element.htmlType === "textarea" && (
                        <Box style={{ cursor: "pointer" }}>
                          <Image
                            src={Reset}
                            alt="xyz"
                            onClick={() => handleResetClick(element.id)}
                          />
                        </Box>
                      )}
                    </Flex>
                    <Element
                      elementType={element.htmlType}
                      formData={formData}
                      reviewData={reviewData}
                      handleFormDataChange={handleFormDataChange}
                      data={{ element }}
                      handleFilesChange={handleFilesChange}
                      discardButton={discardButton}
                      handleImageDownload={handleImageDownload}
                      setPage={setPage}
                      displayData={displayData}
                      navIndex={navIndex}
                      setNavIndex={setNavIndex}
                      sectionId={section.id}
                      repeatable={compEl.component.repeatable}
                      index={index}
                    />
                    {element?.supportingText ? (
                      <Text
                        aria-label="element label"
                        color="#718096"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="1.4"
                      >
                        {element.supportingText}
                      </Text>
                    ) : null}
                    {/* range text  */}
                    {element?.range ? (
                      <Text
                        aria-label="element label"
                        color="#718096"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="1.4"
                      >
                        Please Enter between {element?.range?.from} and{" "}
                        {element?.range?.to}
                      </Text>
                    ) : null}
                  </Box>
                );
              })}
            </VStack>
          ))
        ) : (
          <VStack w="100%" gap="24px" aria-label="component">
            {elements.map((element) => {
              const isMatching = isObjectMatching(element.id);
              const borderColor = isMatching ? "#CBD5E0" : "#F6AD55";
              return (
                <Box
                  aria-label="element"
                  key={element.id}
                  style={{
                    padding: "20px",
                    width: "100%",
                    border: `1.5px solid ${borderColor}`,
                    borderRadius: "15px",
                    background: "#FFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Flex justifyContent={"space-between"}>
                    <Text
                      aria-label="element label"
                      color="#2D3748"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="1.4"
                    >
                      {element.label}
                      {element.mandatory ? (
                        <span
                          style={{
                            color: "#D93025",
                            paddingLeft: "6px",
                            fontSize: "20px",
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <></>
                      )}
                    </Text>
                    {element.htmlType === "textarea" && (
                      <Box style={{ cursor: "pointer" }}>
                        <Image
                          src={Reset}
                          alt="xyz"
                          onClick={() => handleResetClick(element.id)}
                        />
                      </Box>
                    )}
                  </Flex>
                  <Element
                    elementType={element.htmlType}
                    formData={formData}
                    reviewData={reviewData}
                    handleFormDataChange={handleFormDataChange}
                    data={{ element }}
                    handleFilesChange={handleFilesChange}
                    discardButton={discardButton}
                    handleImageDownload={handleImageDownload}
                    setPage={setPage}
                    displayData={displayData}
                    navIndex={navIndex}
                    setNavIndex={setNavIndex}
                    sectionId={section.id}
                  />
                  {element?.supportingText ? (
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      {element.supportingText}
                    </Text>
                  ) : null}
                  {/* range text  */}
                  {element?.range ? (
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      Please Enter between {element?.range?.from} and{" "}
                      {element?.range?.to}
                    </Text>
                  ) : null}
                </Box>
              );
            })}
          </VStack>
        )}
        {isRepeatable ? (
          <HStack w="100%" justify="flex-start">
            <Button
              size="md"
              borderRadius="8px"
              onClick={() => {
                handleAddRepeats(compEl.component.id);
              }}
              leftIcon={<AddIcon />}
              label="Add More"
              isDisabled={repeats.length >= maxEntries && !unlimitedEntries}
            />
          </HStack>
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
}

export default Component;
