export const styles = {
  mainContainer: {
    width: "100%",
    position: "relative",
    borderRadius: "15px",
    backgroundColor: "#fff",
    boxShadow: "0px 3.499999761581421px 5 5px rgba(0, 0, 0, 0.02)",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "16px 20px",
    boxSizing: "border-box",
    gap: "16px",
  },
};
