import { Box, Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import React from "react";

const styles = {
   cancelBtn: {
      lineHeight: "24px",
      fontWeight: "600",
      borderRadius: "15px",
      backgroundColor: "#edf2f7",
      height: "40px",
      alignItems: "center",
      padding: "0px 16px",
      fontSize: "16px",
      color: "#1a202c",
      fontFamily: "Inter",
   },
};

    
    export const DiscardModal = ({
      isOpen,
      onClose,
      msg,
      btnText,
      setFormData,
      setDiscardButton,
      discardButton,
      reviewData,
      setTempError,
    }) => {
      return (
        <>
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent p={"0px"} borderRadius={"15px"}>
              <ModalHeader p={"16px 24px"}>Please confirm</ModalHeader>
              <ModalCloseButton />
              <Separator />
              <Box p={"8px 24px"}>
                <Text>{msg}</Text>
              </Box>
              <ModalFooter p={"16px 24px"}>
                <Button
                  colorScheme="login"
                  mr={3}
                  onClick={() => {
                    setTempError(false);
                    onClose();
                    setFormData(reviewData);
                    setDiscardButton(discardButton + 1);
                    if (discardButton > 1000) {
                      setDiscardButton(0);
                    }
                  }}
                >
                  {btnText}
                </Button>
                <Button sx={styles.cancelBtn} mr={3} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      );
    };
