import { Box, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import React from "react";
import { styles } from "../../style.module";
import "./CreateRoleInput.css";

const CreateRoleInput = ({
  isEditing,
  roleName,
  setRoleName,
  roleDescription,
  setRoleDescription,
}) => {
  return (
    <>
      <Box>
        <Box sx={styles.createRoleInputContainer}>
          <FormControl>
            <FormLabel fontSize="xs" fontWeight={700}>
              Role Name*
            </FormLabel>
            <Input
              type="text"
              placeholder="eg. Manager"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize="xs" fontWeight={700}>
              Role Description*
            </FormLabel>
            <textarea
              placeholder="Provide a concise summary of the role, including its primary responsibilities and key objectives"
              className="responsive-textarea"
              value={roleDescription}
              onChange={(e) => setRoleDescription(e.target.value)}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default CreateRoleInput;
