import React, { useState } from "react";
import { Box, HStack, VStack, Text, IconButton, Stack } from "@chakra-ui/react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { ChevronDownIcon as CollapseIcon } from "@chakra-ui/icons";
import { MdArrowForwardIos as ExpandIcon } from "react-icons/md";

export default function Component({
  component,
  setSelected,
  selected,
  hoveredComp,
  setHoveredComp,
}) {
  const show = selected === component.id;

  const handleToggleHide = () => {
    setSelected(prev => {
        if (prev !== component.id) return component.id;
        return null;
    });
  };

  return (
    <Droppable droppableId={component.id}>
      {(provided, snapshot) => (
        <Box
          w="100%"
          //   onMouseEnter={() => {
          //     if (selected) {
          //         setHoveredComp(component.id);
          //     }
          //   }}
          //   onMouseLeave={() => {
          //     if (selected) {
          //         setHoveredComp(null);
          //     }
          //   }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <HStack>
            <IconButton
              variant="ghost"
              borderRadius="8px"
              // isDisabled={index < 1}
              sx={{
                ".icon": {
                  color: "#718096",
                },
              }}
              icon={
                !show ? (
                  <ExpandIcon w="26px" h="26px" className="icon" />
                ) : (
                  <CollapseIcon w="26px" h="26px" className="icon" />
                )
              }
              onClick={() => {
                handleToggleHide();
              }}
            />
            <Text>{component?.title}</Text>
          </HStack>
          {!show && hoveredComp !== component.id ? (
            <></>
          ) : (
            <VStack gap="16px" p="16px">
              {component?.structure?.elements?.length ? (
                component.structure.elements.map((element, index) => (
                  <Draggable
                    key={element.id}
                    draggableId={`${element.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Stack
                        w="100%"
                        h="48px"
                        justify="center"
                        alignItems="flex-start"
                        pl="16px"
                        bg={`rgba(226, 232, 240, .8)`}
                        borderRadius="16px"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Text color="#2D3748" fontSize="14px" fontWeight="500">
                          {element.label}
                        </Text>
                      </Stack>
                    )}
                  </Draggable>
                ))
              ) : (
                <></>
              )}
              {provided.placeholder}
            </VStack>
          )}
        </Box>
      )}
    </Droppable>
  );
}
