import * as api from "../../services/apis";
import { downloadImage } from "utils/commonFunctions";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { isArray } from "lodash";

export function* handlefetchImageBlob({ payload }) {
  try {
    const { source, imageName } = payload;
    const res = yield call(api.fetchSASUrl, {
      source,
      blobName: imageName,
    });
    downloadImage(res.data.url, imageName);
    yield put(actions.fetchImageBlobSuccess());
  } catch (err) {
    yield put(actions.fetchImageBlobFailure());
  }
}

export function* handleFetchFiltersData(action) {
  try {
    const response = yield call(api.customersFiltersData, action.payload);
    const { data } = response;
    yield put(actions.fetchFiltersDataSuccess(data?.payload));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchFiltersDataFailure(e));
  }
}

export function* handleFetchAllCustomers(action) {
  try {
    const response = yield call(api.getAllCustomers, action.payload);
    const { data } = response;
    yield put(actions.fetchAllCustomersSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchAllCustomersFailure(e));
  }
}
function* handleFetchProfileImgURLArr(action) {
  try {
    const response = yield call(api.getUserProfileImg, action.payload);
    yield put(actions.fetchProfileImgURLArrSuccess(response));
  } catch (e) {
    yield put(actions.fetchProfileImgURLArrFailure(e));
  }
}

//post
function* handlePostCustomersData(action) {
  try {
    const response = yield call(api.postCustomerData, action.payload);
    yield put(actions.postCustomerDataSuccess(response.data.payload));
    // action.payload.onSuccess(response.success);
  } catch (error) {
    yield put(actions.postCustomerDataFailure(error));
  }
}

// getLink
function* handleGetCustomerLinks(action) {
  try {
    const response = yield call(api.getCustomerLinks, action.payload);
    const { data } = response;
    yield put(
      actions.getCustomerLinksSuccess({
        customerId: action.payload.customerId,
        response: data.payload,
      })
    );
  } catch (error) {
    yield put(actions.getCustomerLinksFailure(error));
    console.log(error);
  }
}

function* handleAddCustomersAddress(action) {
  try {
    const response = yield call(api.addCustomersAddress, action.payload);
    yield put(actions.addCustomerAddressesSuccess(response.data.payload));
    action.payload.onSuccess(response.data.success);
  } catch (error) {
    yield put(actions.addCustomerAddressesFailure(error));
    action.payload.onSuccess(error.data.success);
  }
}

function* handleFetchCustomerDetails(action) {
  const response = yield call(api.getCustomerDetails, action.payload);
  const { data } = response;
  yield put(actions.getCustomerDetailsSuccess(data.payload));
  try {
  } catch (error) {
    yield put(actions.getCustomerDetailsFailure(error));
  }
}

function* handleFetchCustomerAddress(action) {
  const response = yield call(api.getCustomerAddresses, action.payload);
  const { data } = response;
  yield put(actions.getCustomerAddressesSuccess(data.payload));
  try {
  } catch (error) {
    yield put(actions.getCustomerAddressesFailure(error));
  }
}
function* handleFetchCustomersAddresses(action) {
  try {
    const response = yield call(api.getCustomersAddress, action.payload);
    const { data } = response;
    yield put(
      actions.fetchCustomerAddressesSuccess({
        customerId: action.payload.customerId,
        response: data.payload,
      })
    );
  } catch (error) {
    yield put(actions.fetchCustomerAddressesFailure(error));
  }
}

function* handleFetchClearStates({ payload }) {
  yield put(actions.clearStateSuccess());
}

export function* handleFetchAvatarURL({ payload }) {
  try {
    console.log("payload:", payload)
    const res = yield call(api.getPreDefinedURL, payload.payload);
    const { data } = res;
    console.log(data)
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}

//update
function* handleUpdateCustomersData(action) {
  try {
    const response = yield call(api.updateCustomerData, action.payload);
    yield put(actions.updateCustomerDataSuccess(response.data.payload));
  } catch (error) {
    yield put(actions.updateCustomerDataFailure(error));
  }
}

function* handleDeleteCustomer(action) {
  try {
    const response = yield call(api.deleteCustomer, action.payload);
    yield put(actions.deleteCustomerSuccess(response.data.payload));
    action.payload.onSuccess(response.data.success);
  } catch (error) {
    yield put(actions.deleteCustomerFailure(error));
    action.payload.onSuccess(error.data.success);
  }
}
function* handleUpdateCustomersAddress(action) {
  try {
    const response = yield call(api.updateCustomerAddress, action.payload);
    yield put(actions.updateCustomerDataSuccess(response.data.payload));
    action.payload.onSuccess(response.data.success);
  } catch (error) {
    yield put(actions.updateCustomerDataFailure(error));
    action.payload.onSuccess(e.data.success);
  }
}

function* handleChangeCustomerStatus(action) {
  try {
    const response = yield call(api.setCustomerStatus, action.payload);
    yield put(actions.fetchChangeCustomerStatusSuccess());
  } catch (error) {
    yield put(actions.fetchChangeCustomerStatusFailure());
  }
}

function* handleFetchCustomersUsers(action) {
  try {
    const response = yield call(api.getAllUsers, action.payload);
    const { data } = response;
    yield put(actions.fetchCustomerUserSuccess(data.payload));
    action.payload.onSuccess();
  } catch (error) {
    yield put(actions.fetchCustomerUserFailure(error));
  }
}

function* handleFetchUserRoles(action) {
  try {
    const response = yield call(api.getCustomerUserRoles, action.payload);
    const { data } = response;
    yield put(actions.fetchAllCustomerUserRolesSuccess(data?.payload));
  } catch (error) {
    yield put(actions.fetchAllCustomerUserRolesFailure(error));
  }
}

function* handleCreateUser(action) {
  try {
    const response = yield call(api.createUser, action.payload);
    action.payload.onSuccess(response.data.payload.userId);

    yield put(actions.fetchCreateUserSuccess());
  } catch (error) {
    console.log(error);
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.fetchCreateUserFailure(error));
  }
}
function* handleFetchLocationData(action) {
  try {
    const response = yield call(api.getLocationData, action.payload);
    if (isArray(response.data.payload)) {
      // console.log("sagaaa");
      action.payload.onSuccess();
      // return
    } else {
      yield put(
        actions.fetchLocationDataSuccess({
          response: response.data.payload,
          id: action.payload.id,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.fetchLocationDataFailure(error));
  }
}

function* handleClearLocationState({ payload }) {
  yield put(actions.clearLocationStateSuccess());
}

function* fetchWarehouseDetails(action) {
  try {
    const response = yield call(api.getWarehouseDetails, action.payload);
    const { data } = response;
    yield put(actions.fetchWarehouseDetailsSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchWarehouseDetailsFailure(e));
  }
}

function* handleCreateClientLocation(action) {
  try {
    const response = yield call(api.createLocation, action.payload);
    action.payload.onSuccess();
    yield put(actions.createClientLocationSuccess());
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.createClientLocationFailure(error));
  }
}

function* handleUpdateLocation(action) {
  try {
    const response = yield call(api.updateLocation, action.payload);
    const { data } = response;
    action.payload.onSuccess();
    yield put(actions.updateClientLocationSuccess());
  } catch (e) {
    action.payload.onFailure();
    yield put(actions.upadateClientLocationFailure());
  }
}

function* handleUpdateLocationStatus(action) {
  try {
    const response = yield call(api.updateLocationStatus, action.payload);
    action.payload.onSuccess();
    yield put(actions.createClientLocationSuccess());
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.createClientLocationFailure(error));
  }
}

function* handleDeleteLocation(action) {
  try {
    const response = yield call(api.deleteLocation, action.payload);
    const { data } = response;
    action.payload.onSuccess();

    yield put(actions.deleteLocationSuccess());
  } catch (e) {
    action.payload.onFailure();

    yield put(actions.deleteLocationFailure());
  }
}

function* handleDeleteAdditionalAddress({ payload }) {
 try {
    const res = yield call(api.deleteAdditionalAddress, payload);
    yield put(actions.deleteAdditionalAddressSuccess());
    payload.onSuccess(res.data.success);
  } catch (err) {
    payload.onFailure();
    yield put(actions.deleteAdditionalAddressFailure(err));
  }
}


export function* CustomerSaga() {
  yield takeLatest(actions.fetchImageBlob.type, handlefetchImageBlob);
  //to get filters data
  yield takeLatest(actions.fetchFiltersData.type, handleFetchFiltersData);

  yield takeLatest(actions.fetchAllCustomers.type, handleFetchAllCustomers);
  // yield takeLatest(actions.updateOneCustomer.type, handleFetchAllCustomers);
  yield takeLatest(
    actions.fetchProfileImgURLArr.type,
    handleFetchProfileImgURLArr
  );

  // get links
  yield takeEvery(actions.getCustomerLinks.type, handleGetCustomerLinks);
  //postcustomersData
  yield takeLatest(actions.postCustomerData.type, handlePostCustomersData);
  //postcustomerAddresses
  yield takeLatest(actions.addCustomersAddress.type, handleAddCustomersAddress);
  yield takeEvery(
    actions.fetchCustomerAddresses.type,
    handleFetchCustomersAddresses
  );
  yield takeLatest(actions.getCustomerDetails, handleFetchCustomerDetails);
  yield takeLatest(actions.getCustomerAddresses, handleFetchCustomerAddress);
  yield takeLatest(actions.clearStates.type, handleFetchClearStates);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(actions.updateCustomerData.type, handleUpdateCustomersData);
  yield takeLatest(actions.deleteCustomer.type, handleDeleteCustomer);
  yield takeLatest(
    actions.updateCustomerAddress.type,
    handleUpdateCustomersAddress
  );
  yield takeLatest(
    actions.fetchChangeCustomerStatus.type,
    handleChangeCustomerStatus
  );
  yield takeLatest(actions.fetchCustomerUser.type, handleFetchCustomersUsers);
  yield takeLatest(
    actions.fetchAllCustomerUserRoles.type,
    handleFetchUserRoles
  );
  yield takeLatest(actions.fetchCreateUserRequest.type, handleCreateUser);
  yield takeEvery(actions.fetchLocationData.type, handleFetchLocationData);
  yield takeLatest(actions.clearLocationState.type, handleClearLocationState);
  yield takeEvery(
    actions.fetchWarehouseDetailsRequest.type,
    fetchWarehouseDetails
  );
  yield takeLatest(
    actions.createClientLocation.type,
    handleCreateClientLocation
  );
  yield takeLatest(actions.updateClientLocation.type, handleUpdateLocation);

  yield takeLatest(
    actions.updateLocationStatus.type,
    handleUpdateLocationStatus
  );

  yield takeLatest(actions.deleteLocation.type, handleDeleteLocation);

  yield takeLatest(
    actions.deleteAdditionalAddress.type,
    handleDeleteAdditionalAddress
  );
}
