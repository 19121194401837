import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../../pages/Create/slice";
import * as selectors from "../../pages/WorkflowManager/selectors";
import { WorkflowManagerSaga } from "../../pages/WorkflowManager/saga";
import { userStorage } from "utils/helper";
import JSONImport from "../JSONImport";
import useToastNotification from "utils/useToastNotification";

export default function FormImport({ fetchWorkflow }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: WorkflowManagerSaga });
  const [formConfig, setFormConfig] = useState(null);
  const createdBy = userStorage.get()?.id;

  const workflow = useSelector(selectors.selectWorkflow);
  const forms = useSelector(selectors.selectForms);
  const dispatch = useDispatch();
  const showToast = useToastNotification();

  useEffect(() => {
    if (formConfig) {
      const form = formConfig.form;
      const order = forms[forms.length - 1].order + 1;
      form.order = order;
      form.status = "draft"
      const newForms = [...forms, form];
      dispatch(
        actions.initWorkflow({
          workflowData: { workflow },
          formData: { forms: newForms },
          workflowFormData: {
            workflowForms: newForms.map((form) => ({
              formId: form.id,
              workflowId: workflow.id,
              order: form.order,
              status: form.status || "draft",
            })),
          },
          onError: () => {
            showToast({
              message: `Something went wrong`,
              status: "error",
            });
          },
          onSuccess: () => {
            dispatch(
              actions.postFormData({
                data: {
                  forms: [
                    {
                      ...formConfig.form,
                      application: "",
                      status: "draft",
                      createdBy,
                    },
                  ],
                  sections: formConfig.sections,
                  blocks: formConfig.blocks,
                  composites: formConfig.composites,
                  components: formConfig.components,
                },
                onSuccess: () => {
                fetchWorkflow();
                  showToast({
                    message: `Form Imported Successfully`,
                    status: "success",
                  });
                },
                onError: () => {
                  showToast({
                    message: `Something went wrong with form import`,
                    status: "error",
                  });
                },
              })
            );
          },
        })
      );
    }
  }, [formConfig]);
  return (
    <JSONImport type="form" jsonData={formConfig} setJsonData={setFormConfig} />
  );
}
