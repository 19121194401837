import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  warehousesLoading: false,
  commoditiesLoading: false,
  warehousesData: null,
  commoditiesData: null,
  error: null,
  reviewedInspectionData: null,
  reviewedInspectionDataLoading: false,
  submittedInspectionData: null,
  submittedInspectionDataLoading: false,
  reportLoader: null,
  reviewedDataWithoutPages: null,
  reviewedDataWithoutPagesLoading: false,
  filtersData : null,
  totalPendingApprovalsCount: 0,
  PendingApprovalsCountForBadge: 0

};

const myApprovalsSlice = createSlice({
  name: "myApprovals",
  initialState,
  reducers: {
    fetchCommoditiesData(state, action) {
      state.commoditiesLoading = true;
    },
    fetchCommoditiesDataSuccess(state, action) {
      state.commoditiesData = action.payload;
      state.commoditiesLoading = false;
    },
    fetchCommoditiesDataFailure(state, action) {
      state.commoditiesLoading = false;
      state.error = true;
    },
    fetchWarehouses(state, action) {
      state.loading = true;
    },
    fetchWarehousesSuccess(state, action) {
      state.warehousesData = action.payload;
      state.loading = false;
    },
    fetchWarehousesFailure(state, action) {
      state.loading = false;
    },
    fetchReviewedInspectionData(state, action) {
      state.loading = true;
    },
    fetchReviewedInspectionDataSuccess(state, action) {
      state.loading = false;
      state.reviewedInspectionData = action.payload;
    },
    fetchReviewedInspectionDataFailure(state, action) {
      state.reviewedInspectionDataLoading = false;
      state.error = true;
    },

    fetchSubmittedInspectionData(state, action) {
      state.loading = true;
    },
    fetchSubmittedInspectionDataSuccess(state, action) {
      state.loading = false;
      state.submittedInspectionData = action.payload?.payload;
      state.totalPendingApprovalsCount = action.payload?.otherParams?.noOfItems
    },
    fetchSubmittedInspectionDataFailure(state, action) {
      state.submittedInspectionDataLoading = false;
      state.error = true;
    },

    fetchRewiewedDataWithoutPages(state, action) {
      state.loading = true;
    },
    fetchRewiewedDataWithoutPagesSuccess(state, action) {
      state.loading = false;
      state.reviewedDataWithoutPages = action.payload;
    },
    fetchReviewedDataWithoutPagesFailure(state, action) {
      state.reviewedDataWithoutPagesLoading = false;
      state.error = true;
    },
    // find pending approvals count for side navBar badge 
    fetchPendingApprovalCountForBadge(state, action) {
      state.loading = true;
    },
    fetchPendingApprovalCountForBadgeSuccess(state, action) {
      state.PendingApprovalsCountForBadge = action.payload?.otherParams?.noOfItems;
      state.loading = false;
    },
    fetchPendingApprovalCountForBadgeFailure(state, action) {
      console.log(action.payload)
      state.loading = false;
    },
    viewReport(state, action) {
      state.reportLoader = true;
    },
    viewReportSuccess(state, action) {
      state.reportLoader = false;
    },
    viewReportFailure(state, action) {
      state.reportLoader = false;
    },
    // for pending approvals filters data
    fetchPendingApprovalsFilterData: (state, action) => {
      state.loading = true;
    },
    fetchPendingApprovalsFilterDataSuccess: (state, action) => {
      state.filtersData = action.payload;
      state.loading = false;
    },
    fetchPendingApprovalsFilterDataFailure: (state, action) => {
      state.loading = false;
    },

    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.warehousesData = null;
      state.commoditiesData = null;
      state.reviewedInspectionData = null;
      state.submittedInspectionData = null;
      state.reviewedDataWithoutPages = null;
    },
    clearStateFailure(state, action) {},
  },
});

export const { actions, reducer, name: sliceKey } = myApprovalsSlice;
