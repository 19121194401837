import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";

const colorCode = (score) => {
  if (score >= 0 && score <= 60) {
    return "#C43333";
  } else if (score > 60 && score <= 80) {
    return "#E8CF3A";
  }
  return "#39AEA3";
};

const WarehouseScore = ({ WHScore }) => {
  if (
    WHScore === null ||
    WHScore === undefined ||
    WHScore === "" ||
    WHScore === "N.A."
  ) {
    return "N.A.";
  }
  return (
    <HStack gap={"16px"}>
      <Text width={"44.2px"}>{WHScore + "%"}</Text>
      <Box
        bg={colorCode(WHScore)}
        w={"12px"}
        h={"12px"}
        borderRadius={"50%"}
      ></Box>
    </HStack>
  );
};

export default WarehouseScore;
