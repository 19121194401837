import React, { useEffect, useState } from "react";
import {
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Image,
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  Menu,
  MenuButton,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { DropdownArrowIcon } from "components/Icons/Icons";
import { CloseIcon } from "@chakra-ui/icons";
import { SearchBar } from "../SearchBar";
import Share from "assets/svg/Share.svg";
import FiUser from "assets/svg/FiUserBlack.svg";
import Eye from "assets/svg/eye-icon.svg";
import Close from "assets/svg/ModalCloseButton.svg";
import MultistepWorkflow from "./MultistepWorkflow";
import { CustomModal } from "../viewJob/Modals/CustomModal";

const WorkflowModal = ({
  isOpen,
  onClose,
  handleSecondModal,
  selectedWorkflow,
  setCurrentWorkflow,
  allWorkflows,
  setOpenedWorkflow,
  fetchWorkflowDetails,
  workflowData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const isChecked = (workflow) => {
    return workflow.id == selectedWorkflow?.id;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"2xl"}>
      <ModalOverlay />
      <ModalContent width="100%">
        <ModalBody sx={{ padding: "0px" }}>
          <Box height="70vh">
            <VStack align="stretch" sx={{ borderBottom: "1px #CBD5E0 solid" }}>
              <Box>
                <Flex
                  padding="16px 24px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text fontSize="18px" fontWeight="700">
                    Select WorkFlow
                  </Text>
                  <Box>
                    <SearchBar
                      searchQuery={searchQuery}
                      handleSearchQuery={handleSearchQuery}
                      placeholder="Search Workflow"
                    />
                  </Box>
                  <Box>
                    <Image src={Close} onClick={onClose} cursor={"pointer"} />
                  </Box>
                </Flex>
              </Box>
            </VStack>
            <Box
              paddingBottom={"16px"}
              paddingLeft={"16px"}
              paddingRight={"16px"}
              background="#F9F9FB"
              border-radius="10px"
              overflowY="auto"
              maxHeight="70vh"
            >
              <Flex padding={"22px 0px"} justifyContent="space-between" >
                <Box 
                // width="50%"
                 pl="45px"
                 >
                  <Text fontSize={"14px"} fontWeight="500">
                    Workflow
                  </Text>
                </Box>
                {/* <Box width="25%" alignContent="left">
                  <Text fontSize={"14px"} fontWeight="500">
                    Created By
                  </Text>
                </Box> */}
                {/* <Box width="25%" alignContent="left">
                  <Text fontSize={"14px"} fontWeight="500">
                    Last Modified
                  </Text>
                </Box> */}
                <Box 
                // width="50%" 
                // alignContent="right"
                pr={"25px"}
                >
                  <Text fontSize={"14px"} fontWeight="500">
                    View
                  </Text>
                </Box>
              </Flex>

              <Flex direction="column" gap="12px">
                {allWorkflows &&
                  allWorkflows
                    .filter((workflow) =>
                      workflow.title
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) && workflow.status === 'published'
                    )
                    .map((workflow) => {
                      return (
                        <Box
                          key={workflow.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchWorkflowDetails(workflow);
                            setCurrentWorkflow(workflow, null, "view");
                            handleSecondModal();
                            // setCurrentWorkflow(workflow, onClose, "select");
                          }}
                        >
                          <Flex
                            padding={"12px 16px"}
                            border={
                              isChecked(workflow)
                                ? "1.5px #38B2AC solid"
                                : "0.5px solid  #EEEEEE"
                            }
                            borderRadius="15px"
                            background="white"
                            justifyContent={"space-between"}
                          >
                            <Box  alignContent="center">
                              <Stack
                                direction="row"
                                align="center"
                                height="100%"
                              >
                                <Box>
                                  <Image src={Share} alt="Dan Abramov" />
                                </Box>
                                <Text
                                  style={{
                                    fontWeight: "600",
                                    padding: "1px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {workflow?.title}
                                </Text>
                              </Stack>
                            </Box>
                            <HStack align="center">
                              {/* <Button
                                colorScheme="white"
                                color="black"
                                size="xs"
                                borderWidth={1}
                                borderRadius={3}
                                fontWeight={"600"}
                              > */}
                                <HStack spacing={1} align="center">
                                  {/* <Image
                                    src={FiUser}
                                    alt="Dan Abramov"
                                    boxSize="14px"
                                  /> */}
                                  {/* <Text fontWeight={"600"} fontSize={"14px"}>
                                    {workflow.createdBy}
                                    tarun
                                  </Text> */}
                                </HStack>
                              {/* </Button> */}
                            </HStack>
                            {/* <HStack align="center" width="25%">
                              <Text>{workflow.lastModified}</Text>
                            </HStack> */}

                            <Flex>
                              <Box
                                background={"#EDF2F7"}
                                height={"40px"}
                                width={"48px"}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius={"12px"}
                                style={{ cursor: "pointer" }}
                                ml={"10px"}
                                onClick={() => {
                                  setCurrentWorkflow(workflow, null, "view");
                                  handleSecondModal();
                                }}
                              >
                                <Image src={Eye} alt="" />
                              </Box>
                            </Flex>
                          </Flex>
                        </Box>
                      );
                    })}
              </Flex>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SelectWorkflow = ({
  selectedWorkflow,
  setCurrentWorkflow,
  allWorkflows,
  fetchWorkflowDetails,
  workflowData,
  isWorkflowChanged,
  isEditing,
  openedWorkflow,
}) => {
  const {
    isOpen: firstModalIsOpen,
    onOpen: openFirstModal,
    onClose: closeFirstModal,
  } = useDisclosure();
  const {
    isOpen: secondModalIsOpen,
    onOpen: openSecondModal,
    onClose: closeSecondModal,
  } = useDisclosure();

  const {
    isOpen: thirdModalIsOpen,
    onOpen: openThirdModal,
    onClose: closeThirdModal,
  } = useDisclosure();

  const handleSecondModal = () => {
    closeFirstModal();
    openSecondModal();
  };

  return (
    <Flex>
      <Box width={"100%"}>
        <Menu>
          <MenuButton
            paddingRight="11px"
            width={"100%"}
            borderRadius="8px"
            as={Button}
            onClick={() => {
              if (isEditing && !isWorkflowChanged) {
                openThirdModal();
              } else openFirstModal();
            }}
            bgColor="white"
            border="1px solid #E2E8F0"
            _focus={{ boxShadow: "none" }}
            _active={{ bg: "white" }}
            _hover={{ bg: "white" }}
          >
            <Flex justifyContent={"space-between"}>
              <Text
                fontSize="14px"
                color={selectedWorkflow ? "#4A5568" : "#718096"}
              >
                {selectedWorkflow ? selectedWorkflow.title : "Select"}
              </Text>
              <DropdownArrowIcon />
            </Flex>
          </MenuButton>
        </Menu>

        <WorkflowModal
          handleSecondModal={handleSecondModal}
          isOpen={firstModalIsOpen}
          onClose={closeFirstModal}
          onOpen={openFirstModal}
          selectedWorkflow={selectedWorkflow}
          setCurrentWorkflow={setCurrentWorkflow}
          allWorkflows={allWorkflows}
          fetchWorkflowDetails={fetchWorkflowDetails}
          workflowData={workflowData}
        />
        <MultistepWorkflow
          isOpen={secondModalIsOpen}
          onClose={closeSecondModal}
          workflowData={workflowData}
          selectedWorkflow={selectedWorkflow}
          openFirstModal={openFirstModal}
          closeFirstModal={closeFirstModal}
          openedWorkflow={openedWorkflow}
          setCurrentWorkflow={setCurrentWorkflow}
        />
        <CustomModal
          isOpen={thirdModalIsOpen}
          onClose={closeThirdModal}
          headerText="Changing Workflow"
          onButtonClick={() => {
            openFirstModal();
            closeThirdModal();
          }}
          bodyText={
            <Text color="var(--Grey-Gray-500, #718096)">
              You are trying to change the{" "}
              <span style={{ fontWeight: 800 }}>Workflow</span>
              . You all tasks Data will be lost. <br />
              <br />
              Are you sure want to continue ?
            </Text>
          }
          buttonText="Continue"
          maxWidth="436px"
          colorScheme="teal"
        />
      </Box>
    </Flex>
  );
};

export default SelectWorkflow;
