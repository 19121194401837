import React, { useEffect, useState } from "react";

const Email = ({ links }) => {
  const [customerLink, setCustomerLink] = useState(null);

  useEffect(() => {
    if (links && links.length) {
      links?.forEach((link) => {
        if (link.type === "email") {
          setCustomerLink(link.value);
        }
      });
    }
  }, [links]);

  return <div>{customerLink || "-"}</div>;
};

export default Email;
