import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddUserTop from "../components/addUsers/AddUserTop";
import AddUserLocation from "../components/addUsers/AddUserLocation";
import { fetchSasurl, uploadFile } from "services/index";
import * as selectors from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "../slice";
import { UserSaga } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { getClientDetails } from "services/apis";
import { useHistory } from "react-router-dom";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { generateIdSync } from "utils";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import { useUserContext } from "context/UserContext";

const AddUser = ({ isEditing, oldUserDetails, clientIdFromEdit }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UserSaga });

  const history = useHistory();
  const toast = useToast();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");

  const [isChanged, setIsChanged] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [isClientUser, setIsClientUser] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [clientId, setClientId] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);

  const avatarUrl = useSelector(selectors.selectAvatarUrl);

  const allCustomerUserRoles = useSelector(
    selectors.selectAllCustomerUserRoles
  );
  // console.log(selectedRoles);
  const clientsOfCustomer = useSelector(selectors.selectClientsOfCustomers);
  const adminRoleCount = useSelector(selectors.selectAdminCount);

  const clientsOfCustomerDropdown = clientsOfCustomer
    ?.filter((obj) => obj.isActive)
    .map((client) => {
      return { id: client.clientId, label: client.clientName };
    });

  const handleChooseClient = (id, value) => {
    setSelectedRoles([]);
    setSelectedClient(value);
    setClientId(id);
    const clientId = id;
    if (clientId) {
      dispatch(actions.fetchClientUserRoles({ customerId, clientId }));
    }
  };

  const clientUserRoles = useSelector(selectors.selectClientUserRoles);

  const allRolesForDropdown = isClientUser
    ? clientUserRoles?.map((role) => {
        return { category: role.name, id: role.id };
      })
    : allCustomerUserRoles?.map((role) => {
        return { category: role.name, id: role.id };
      });

  const {
    isOpen: saveIsOpen,
    onOpen: openSaveModal,
    onClose: closeSaveModal,
  } = useDisclosure();

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    department: "",
    designation: "",
    email: "",
    mobile: null,
    countryCode: null,
    avatar: "",
    gender: "",
  });

  const [registeredAddress, setRegisteredAddress] = useState({
    id: generateIdSync(),
    addressLine1: "",
    addressLine2: "",
    pinCode: null,
    city: "",
    state: "",
    country: "",
  });
  const [tempAddress, setTempAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    pinCode: null,
    city: "",
    state: "",
    country: "",
  });
  useEffect(() => {
    if (isEditing && oldUserDetails && Object.keys(oldUserDetails).length) {
      setClientId(clientIdFromEdit);
      let avatar = oldUserDetails?.avatar;
      avatar && dispatch(actions.fetchAvatarURL({ payload: avatar }));
      if (oldUserDetails.countryCode)
        setCountryCode(
          oldUserDetails.countryCode === "91" ? "India +91" : "UAE +971"
        );
      const newData = {
        firstName: capitalizeStringUpdated(oldUserDetails.firstName),
        lastName: capitalizeStringUpdated(oldUserDetails.lastName),
        userName: oldUserDetails.email,
        department: clientIdFromEdit
          ? null
          : capitalizeStringUpdated(oldUserDetails.department),
        designation: clientIdFromEdit
          ? null
          : capitalizeStringUpdated(oldUserDetails.designation),
        email: oldUserDetails.email,
        mobile: oldUserDetails.mobile ? oldUserDetails.mobile : null,
        countryCode: Number(oldUserDetails.countryCode),
        avatar: oldUserDetails.avatar,
        gender:
          oldUserDetails.gender === "prefer_not_to_answer"
            ? "Prefer Not To Answer"
            : oldUserDetails.gender,
      };
      setUserDetails(newData);
      if (oldUserDetails.roles?.length) {
        clientIdFromEdit
          ? setSelectedRoles(
              oldUserDetails.roles.map((role) => {
                return role.clientRoleId;
              })
            )
          : setSelectedRoles(
              oldUserDetails.roles.map((role) => {
                return role.customerRoleId;
              })
            );
      }
      if (oldUserDetails.addresses?.length) {
        let data = oldUserDetails.addresses?.map((address) => {
          return {
            id: generateIdSync(),
            addressLine1: address.addressInfo.addressLine1
              ? address.addressInfo.addressLine1
              : "",
            addressLine2: address.addressInfo.addressLine2
              ? address.addressInfo.addressLine2
              : "",
            pinCode: address.postalCode || null,
            city: address.city,
            state: address.state,
            country: address.country,
          };
        });
        if (data.length) {
          setTempAddress(data[0]);
          setRegisteredAddress(data[0]);
        }
      }
    }
    if (clientIdFromEdit) {
      setIsClientUser(true);
    }
  }, [oldUserDetails]);

  useEffect(() => {
    if (isEditing && clientsOfCustomer && oldUserDetails) {
      const oldClientId = oldUserDetails.clientId;
      const filteredClient = clientsOfCustomer.filter(
        (client) => client.clientId === oldClientId
      );
      setSelectedClient(filteredClient[0]?.clientName);
    }
  }, [clientsOfCustomer, oldUserDetails]);
  useDispatch(() => {
    if (selectedClient) {
      setSelectedRoles(
        oldUserDetails.roles.map((role) => {
          return role.clientRoleId;
        })
      );
    }
  }, [selectedClient]);

  const handleSwitchClick = () => {
    setIsClientUser(!isClientUser);
    setSelectedRoles([]);
  };

  const handleFileChange = async (event) => {
    if (!isChanged) setIsChanged(true);
    try {
      const file = event.target.files[0];

      if (file) {
        setProfileImagePreview(URL.createObjectURL(file));
        const fileName = file.name;
        let sasURLData = await fetchSasurl({
          fileName,
        });

        const sasURL = sasURLData && sasURLData.data.payload.url;

        const uploadResponse = await uploadFile({ file, sasURL });

        // handleInputChange("avatar", fileName);
        setUserDetails((prevValues) => {
          return { ...prevValues, avatar: fileName };
        });
      }
    } catch (error) {
      console.error("Error handling file change:", error);
    }
  };

  useEffect(() => {
    dispatch(actions.fetchAllCustomerUserRoles({ customerId }));
    dispatch(actions.fetchClientsOfCustomer({ customerId }));
    dispatch(actions.getUserByRole({ roleName: "admin", customerId }));
  }, [customerId]);
  const failureToast = (reason, msg, entity) => {
    setButtonLoader(false);
    toast({
      title: `${entity} User ${msg} Failed`,
      description: capitalizeStringUpdated(reason),
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };
  const successToast = (msg, entity) => {
    setButtonLoader(false);
    dispatch(actions.clearStates());
    history.goBack();
    setTimeout(() => {
      toast({
        title: `${entity} User ${msg} Successfully`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }, [100]);
  };

  const handleUpdateUser = () => {
    setButtonLoader(true);
    const updatedUserDetails = Object.fromEntries(
      Object.entries(userDetails).map(([key, value]) => {
        if (
          key === "gender" &&
          (value === "Prefer Not To Answer" || value === null || value === "")
        ) {
          return [key, "prefer_not_to_answer"];
        } else {
          return [key, value === "" ? null : value];
        }
      })
    );
    let userId = oldUserDetails?.userId;
    let userPayload;

    if (clientIdFromEdit) {
      userPayload = { ...updatedUserDetails, clientRoleIds: selectedRoles };
    } else {
      userPayload = { ...updatedUserDetails, customerRoleIds: selectedRoles };
    }
    clientIdFromEdit
      ? dispatch(
          actions.updateClientUserData({
            userId,
            clientId: clientIdFromEdit,
            customerId,
            userPayload,
            onSuccess: () => handleAfterUserUpdation(),
            onFailure: (reason) => {
              failureToast(reason, "Updation", "Client");
            },
          })
        )
      : dispatch(
          actions.fetchUpdateUserRequest({
            userId,
            customerId,
            userPayload,
            onSuccess: () => handleAfterUserUpdation(),
            onFailure: (reason) => {
              failureToast(reason, "Updation", "Agency");
            },
          })
        );
  };

  const handleAfterUserUpdation = () => {
    if (
      oldUserDetails.addresses?.length &&
      Object.entries(registeredAddress).some(([key, value]) => {
        return key !== "addressLine2" && key !== "id" && value !== "" && value !== null;
      })
    ) {
      let addressPayload = { ...registeredAddress };
      clientIdFromEdit
        ? dispatch(
            actions.updateAddressForClientUser({
              userId: oldUserDetails?.userId,
              clientId: clientIdFromEdit,
              customerId,
              addressPayload,
              addressId: oldUserDetails.addresses[0]?.userAddressId,
              onSuccess: () => {
                successToast("Updated", "Client");
              },
              onFailure: (reason) => {
                failureToast(reason, "Updation", "Client");
              },
            })
          )
        : dispatch(
            actions.fetchUpdateAddressRequest({
              id: oldUserDetails?.userId,
              customerId,
              addressPayload,
              addressId: oldUserDetails.addresses[0]?.userAddressId,
              onSuccess: () => {
                successToast("Updated", "Agency");
              },
              onFailure: (reason) => {
                failureToast(reason, "Updation", "Agency");
              },
            })
          );
    } else if (
      Object.entries(registeredAddress).some(([key, value]) => {
        return key !== "addressLine2" && key !== "id" && value !== "" && value !== null;
      })
    ) {
      let addressPayload = [{ ...registeredAddress }];
      clientIdFromEdit
        ? dispatch(
            actions.createAddressForClientUser({
              id: oldUserDetails?.userId,
              clientId: clientIdFromEdit,
              customerId,
              addressPayload,
              onSuccess: () => {
                successToast("Updated", "Client");
              },
              onFailure: (reason) => {
                failureToast(reason, "Updation", "Client");
              },
            })
          )
        : dispatch(
            actions.fetchCreateAddressRequest({
              id: oldUserDetails?.userId,
              customerId,
              addressPayload,
              onSuccess: () => {
                successToast("Updated", "Agency");
              },
              onFailure: (reason) => {
                failureToast(reason, "Updation", "Agency");
              },
            })
          );
    } else {
      successToast("Updated", "Agency");
    }
  };
  const handleCreateUserForCustomer = () => {
    setButtonLoader(true);
    const updatedUserDetails = Object.fromEntries(
      Object.entries(userDetails).map(([key, value]) => {
        if (
          key === "gender" &&
          (value === "Prefer Not To Answer" || value === null || value === "")
        ) {
          return [key, "prefer_not_to_answer"];
        } else {
          return [key, value === "" ? null : value];
        }
      })
    );

    let userPayload = { ...updatedUserDetails, customerRoleIds: selectedRoles };
    dispatch(
      actions.fetchCreateUserRequest({
        userPayload,
        customerId,
        onSuccess: (id) => {
          handleAfterUserCreation(id, customerId);
        },
        onFailure: (reason) => {
          failureToast(reason, "Creation", "Agency");
        },
      })
    );
  };

  const handleAfterUserCreation = (id, customerId) => {
    if (
      Object.entries(registeredAddress).some(([key, value]) => {
        return key !== "id" && value !== "" && value !== null;
      })
    ) {
      let addressPayload = [{ ...registeredAddress }];
      dispatch(
        actions.fetchCreateAddressRequest({
          id,
          customerId,
          addressPayload,
          onSuccess: () => {
            successToast("Added", "Agency");
          },
          onFailure: (reason) => {
            failureToast(reason, "Creation", "Agency");
          },
        })
      );
    } else {
      successToast("Added", "Agency");
    }
  };

  // for clientuser creation
  const handleCreateUserForClient = () => {
    setButtonLoader(true);
    const updatedUserDetails = Object.fromEntries(
      Object.entries(userDetails).map(([key, value]) => {
        if (
          key === "gender" &&
          (value === "Prefer Not To Answer" || value === null || value === "")
        ) {
          return [key, "prefer_not_to_answer"];
        } else {
          return [key, value === "" ? null : value];
        }
      })
    );

    const userPayload = {
      ...updatedUserDetails,
      clientRoleIds: selectedRoles,
    };
    dispatch(
      actions.createUserForClient({
        userPayload,
        customerId,
        clientId,
        onSuccess: (id) => {
          handleAfterUserCreationForClient(id, clientId, customerId);
        },
        onFailure: (reason) => {
          failureToast(reason, "Creation", "Client");
        },
      })
    );
  };

  const handleAfterUserCreationForClient = (id, clientId, customerId) => {
    // console.log("clientId-->", clientId)
    if (
      Object.entries(registeredAddress).some(([key, value]) => {
        return key !== "id" && value !== "" && value !== null;
      })
    ) {
      const addressPayload = [{ ...registeredAddress }];
      dispatch(
        actions.createAddressForClientUser({
          id,
          clientId,
          customerId,
          addressPayload,
          onSuccess: () => {
            successToast("Added", "Client");
          },
          onFailure: (reason) => {
            failureToast(reason, "Creation", "Client");
          },
        })
      );
    } else {
      successToast("Added", "Client");
    }
  };

  const addButtonStatus = () => {
    let res = false;
    if (
      !isClientUser &&
      Object.entries(userDetails).some(([key, value]) => {
        return (
          key !== "avatar" &&
          key !== "gender" &&
          (value === "" || value === null || value === undefined)
        );
      })
    ) {
      res = true;
    } else if (
      Object.entries(userDetails).some(([key, value]) => {
        return (
          key !== "avatar" &&
          key !== "gender" &&
          key !== "department" &&
          key !== "designation" &&
          (value === "" || value === null || value === undefined)
        );
      })
    ) {
      res = true;
    }

    if (
      Object.entries(registeredAddress).some(([key, value]) => {
        if (registeredAddress.country === "India") {
          return key !== "addressLine2" && key !== "id" && value !== "";
        } else {
          return (
            key !== "addressLine2" &&
            key !== "id" &&
            key !== "pinCode" &&
            value !== ""
          );
        }
      }) &&
      Object.entries(registeredAddress).some(([key, value]) => {
        if (registeredAddress.country === "India")
          return key !== "addressLine2" && key !== "id" && value === "";
        else
          return (
            key !== "addressLine2" &&
            key !== "id" &&
            key !== "pinCode" &&
            value === ""
          );
      })
    ) {
      res = true;
    }

    if (selectedRoles.length === 0) res = true;
    if (!isPhoneValid || !emailIsValid) res = true;
    return res;
  };

  const updateButtonStatus = () => {
    let res = true;
    if (isChanged && !addButtonStatus()) res = false;
    return res;
  };

  useEffect(() => {
    if (avatarUrl != null && isEditing === true) {
      setProfileImagePreview(avatarUrl.url);
    }
  }, [avatarUrl]);
  useEffect(() => {}, []);
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          <AddUserTop
            selectedClient={selectedClient}
            clientId={clientId}
            handleChooseClient={handleChooseClient}
            isClientUser={isClientUser}
            handleSwitchClick={handleSwitchClick}
            clientsOfCustomerDropdown={clientsOfCustomerDropdown}
            profileImagePreview={profileImagePreview}
            setUserDetails={setUserDetails}
            userDetails={userDetails}
            handleFileChange={handleFileChange}
            allRolesForDropdown={allRolesForDropdown}
            setRegisteredAddress={setRegisteredAddress}
            selectedRoles={selectedRoles}
            setSelectedRoles={setSelectedRoles}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
            isPhoneValid={isPhoneValid}
            setIsPhoneValid={setIsPhoneValid}
            setIsChanged={setIsChanged}
            isChanged={isChanged}
            isEditing={isEditing}
            clientIdFromEdit={clientIdFromEdit}
            adminRoleCount={adminRoleCount}
            oldUserDetails={oldUserDetails}
          />
          <AddUserLocation
            registeredAddress={registeredAddress}
            setRegisteredAddress={setRegisteredAddress}
            userDetails={userDetails}
            tempAddress={tempAddress}
            setTempAddress={setTempAddress}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            isEditing={isEditing}
            oldUserDetails={oldUserDetails}
            clientIdFromEdit={clientIdFromEdit}
            customerId={customerId}
          />
          <Flex justifyContent={"flex-end"}>
            <Button
              colorScheme="login"
              size="md"
              borderRadius={"8px"}
              onClick={
                isEditing
                  ? openSaveModal
                  : isClientUser
                  ? handleCreateUserForClient
                  : handleCreateUserForCustomer
              }
              isLoading={buttonLoader}
              isDisabled={isEditing ? updateButtonStatus() : addButtonStatus()}
            >
              {isEditing ? "Save Changes" : "Add User"}
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Modal isOpen={saveIsOpen} onClose={closeSaveModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Confirm Save
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="#718096">
              Are you certain you want to proceed with saving?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={closeSaveModal}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="modalsDark"
              onClick={() => {
                handleUpdateUser();
                closeSaveModal();
              }}
            >
              Yes, Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default AddUser;
