import {
  Box,
  Flex,
  HStack,
  Image,
  Input,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import deleteIcon from "assets/svg/deleteRed.svg";
import CustomModal from "views/shared/CustomModal";
import { useDispatch } from "react-redux";
import { actions } from "../../slice";
import { useHistory } from "react-router-dom";

const styles = {
  outerContainer: {
    direction: { base: "column", md: "row" },
    justifyContent: "space-between",
    gap: { base: 2, md: "16px" },
  },
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    background: "white",
    boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    flex: 1,
  },
  text: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#1A202C",
  },
  textLeft: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#718096",
  },
  switchContainer: {
    gap: "8px",
    alignItems: "center",
    height: "32px",
  },
};

const ViewCustomerBottom = ({
  customerId,
  customerDetails,
  handleDeactivateButton,
}) => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();

  const isSwitchOn = () => {
    return !customerDetails?.isActive;
  };

  const {
    isOpen: deactivateModalIsOpen,
    onOpen: openDeactivateModal,
    onClose: closeDeactivateModal,
  } = useDisclosure();
  const {
    isOpen: deleteModalIsOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();

  const handleSwitchClick = () => {
    if (isSwitchOn()) {
      handleDeactivateButton(isSwitchOn());
    } else {
      openDeactivateModal();
    }
  };

  const handleDeleteButton = () => {
    openDeleteModal();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDeactivateProcess = () => {
    handleDeactivateButton(isSwitchOn());
    closeDeactivateModal();
  };

  const isDeleteDisabled = inputValue !== "DELETE";
  const handleDeleteCustomer = () => {
    dispatch(
      actions.deleteCustomer({
        customerId,
        onSuccess: (status) => {
          console.log(status);
          if (status) {
            toast({
              title: "Customer deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            history.goBack();
          } else {
            toast({
              title: "Error deleting address",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        },
      })
    );
  };
  return (
    <>
      <Stack spacing="24px">
        <Box>
          <Text fontWeight={600} color="#2D3748">
            Account Management
          </Text>
        </Box>

        <Flex {...styles.outerContainer}>
          <HStack {...styles.container}>
            <Text {...styles.textLeft}>Deactivate Account</Text>
            <Flex {...styles.switchContainer}>
              <Text {...styles.text}>Deactivate</Text>
              <Switch
                size="sm"
                isChecked={isSwitchOn()}
                onChange={handleSwitchClick}
              />
            </Flex>
          </HStack>
          <HStack {...styles.container}>
            <Text {...styles.textLeft}>Delete Account </Text>
            <Flex {...styles.switchContainer}>
              <Box
                background={"white"}
                height={"32px"}
                width={"38px"}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={"12px"}
                border={"1px solid #E53E3E"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDeleteButton();
                }}
              >
                <Image src={deleteIcon} alt="" />
              </Box>
            </Flex>
          </HStack>
        </Flex>
      </Stack>
      {/* Deactivate Account Modal */}
      <CustomModal
        isOpen={deactivateModalIsOpen}
        onClose={closeDeactivateModal}
        headerText="Deactivate Account"
        bodyText={
          <>
            <Text color="#718096" fontSize="16px">
              By deactivating your account, you'll temporarily suspend access to
              this account and users associated with it.
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text color="#718096" fontSize={"16px"} mt={"16px"}>
              However, you can reactivate your account later to regain access.
              If you're sure about this decision, click "Deactivate."
            </Text>
          </>
        }
        colorScheme="red"
        maxWidth="436px"
        buttonText="Deactivate"
        onButtonClick={handleDeactivateProcess}
      />
      {/* Delete Account Modal */}
      <CustomModal
        isOpen={deleteModalIsOpen}
        onClose={closeDeleteModal}
        headerText="Confirm Account Deletion"
        onButtonClick={handleDeleteCustomer}
        bodyText={
          <>
            <Text color="#718096" fontSize="16px">
              Upon deletion, this account will no longer be accessible and it’s
              users and associated data will be permanently removed. If you're
              certain about proceeding, click "Delete Account."
            </Text>
          </>
        }
        additionalContent={
          <>
            <Text color="#718096" fontSize={"16px"} mt={"16px"}>
              To confirm deletion, type “DELETE” below
            </Text>
            <Input
              variant="outline"
              mt={"16px"}
              value={inputValue}
              onChange={handleInputChange}
            />
          </>
        }
        maxWidth="436px"
        isDisabled={isDeleteDisabled}
        colorScheme="red"
        buttonText="Delete Account"
      />
    </>
  );
};

export default ViewCustomerBottom;
