import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  userRoleData: null,
  userRoleError: null,
  editRoleData: null,
  editRoleError: null,
  permissionsData: null,
  permissionsDataError: null,
  postUserRole: null,
  postUserRoleError: null,
  rolePermissionData: null,
  rolePermissionDataError: null,
  userDetails: null,
  userDetailsError: null,
  roleUpdateData: null,
  roleUpdateDataError: null,
  userAssignedData: null,
  userAssignedDataError: null,
  deleteRole: null,
  deleteRoleError: null,
  filterRolesData: null,
  filterRolesDataError: null,
  singleUsers: null,
  singleUsersError: null,
  deactivateRole: null,
  deactivateRoleError: null,
  activateRole: null,
  activateRoleError: null,
};

const userRoleSlice = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    fetchUserRoleRequest(state) {
      state.loading = true;
      state.userRoleError = null;
    },
    fetchUserRoleSuccess(state, action) {
      state.userRoleData = action.payload;
      state.loading = false;
    },
    fetchUserRoleFaliure(state, action) {
      state.loading = false;
      state.userRoleError = action.payload;
    },
    fetchEditRoleRequest(state) {
      state.loading = true;
      state.editRoleError = null;
    },
    fetchEditRoleSuccess(state, action) {
      state.loading = false;
      state.editRoleData = action.payload;
    },
    fetchEditRoleFaliure(state, action) {
      state.loading = false;
      state.editRoleError = action.payload;
    },
    fetchPermissionsDataRequest(state) {
      state.loading = true;
      state.permissionsDataError = null;
    },
    fetchPermissionsDataSuccess(state, action) {
      state.loading = false;
      state.permissionsData = action.payload;
    },
    fetchPermissionsDataFaliure(state, action) {
      state.loading = false;
      state.permissionsDataError = action.payload;
    },
    fetchPostUserRoleRequest(state, action) {
      state.loading = true;
      state.postUserRoleError = null;
    },
    fetchPostUserRoleSuccess(state, action) {
      state.loading = false;
      state.postUserRole = action.payload;
    },
    fetchPostUserRoleFailure(state, action) {
      state.loading = false;
      state.postUserRoleError = action.payload;
    },
    fetchRolePermissionsDataRequest(state) {
      state.loading = true;
      state.rolePermissionDataError = null;
    },
    fetchRolePermissionsDataSuccess(state, action) {
      state.loading = false;
      state.rolePermissionData = action.payload;
    },
    fetchRolePermissionsDataFaliure(state, action) {
      state.loading = false;
      state.rolePermissionDataError = action.payload;
    },
    fetchUserDetailsRequest(state) {
      state.loading = true;
      state.userDetailsError = null;
    },
    fetchUserDetailsSuccess(state, action) {
      state.loading = false;
      state.userDetails = action.payload;
    },
    fetchUserDetailsFaliure(state, action) {
      state.loading = false;
      state.userDetailsError = action.payload;
    },
    fetchRoleUpdateDataRequest(state) {
      state.loading = true;
      state.roleUpdateDataError = null;
    },
    fetchRoleUpdateDataSuccess(state, action) {
      state.loading = false;
      state.roleUpdateData = action.payload;
    },
    fetchRoleUpdateDataFaliure(state, action) {
      state.loading = false;
      state.roleUpdateDataError = action.payload;
    },
    fetchUserAssignedDataRequest(state) {
      state.loading = true;
      state.userAssignedDataError = null;
    },
    fetchUserAssignedDataSuccess(state, action) {
      state.loading = false;
      state.userAssignedData = action.payload;
    },
    fetchUserAssignedDataFaliure(state, action) {
      state.loading = false;
      state.userAssignedDataError = true;
    },
    fetchDeleteRoleRequest(state) {
      state.loading = true;
      state.deleteRoleError = null;
    },
    fetchDeleteRoleSuccess(state, action) {
      state.loading = false;
      state.deleteRole = action.payload;
    },
    fetchDeleteRoleFaliure(state, action) {
      state.loading = false;
      state.deleteRoleError = true;
    },
    fetchSingleUsersRequest(state) {
      state.loading = true;
      state.singleUsersError = null;
    },
    fetchSingleUsersSuccess(state, action) {
      state.loading = false;
      state.singleUsers = action.payload;
    },
    fetchSingleUsersFaliure(state, action) {
      state.loading = false;
      state.singleUsersError = true;
    },
    fetchDeactivateRoleRequest(state) {
      state.loading = true;
      state.deactivateRoleError = null;
    },
    fetchDeactivateRoleSuccess(state, action) {
      state.loading = false;
      state.deactivateRole = action.payload;
    },
    fetchDeactivateRoleFaliure(state, action) {
      state.loading = false;
      state.deactivateRoleError = true;
    },
    fetchActivateRoleRequest(state) {
      state.loading = true;
      state.activateRoleError = null;
    },
    fetchActivateRoleSuccess(state, action) {
      state.loading = false;
      state.activateRole = action.payload;
    },
    fetchActivateRoleFaliure(state, action) {
      state.loading = false;
      state.activateRoleError = true;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.userRoleData = null;
      state.editRoleData = null;
      state.permissionsData = null;
      state.postUserRole = null;
      state.rolePermissionData = null;
      state.userDetails = null;
      state.roleUpdateData = null;
      state.userAssignedData = null;
      state.deleteRole = null;
      state.filterRolesData = null;
      state.singleUsers = null;
      state.deactivateRole = null;
      state.activateRole = null;
    },
    clearStateFailure(state, action) {},
  },
});

export const { actions, reducer, name: sliceKey } = userRoleSlice;
