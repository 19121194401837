import React, { useEffect } from "react";
import { useSocket } from "../../context/SocketContext";
import useToastNotification from "../../utils/useToastNotification";
import { Box, Text, CloseButton, HStack, Link } from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

const ReportNotifications = () => {
  const { socket } = useSocket();
  const showToast = useToastNotification();

  useEffect(() => {
    if (!socket) return;

    const reportGenerated = (value) => {
      showToast({
        status: "success",
        duration: 8000,
        render: ({ onClose }) => (
          <Box color="white" p={3} bg="green.500" borderRadius="md">
            <HStack spacing={3} align="center" justify="center">
              <Link
                href={value.inspectionsDetails.inspectionReportURL}
                target="_blank"
                rel="noopener noreferrer"
                color="white"
                fontWeight="bold"
                display="flex"
                alignItems="center"
              >
                <Text>{`Report ready for ${value?.inspectionsDetails?.originName}. Click to view`}</Text>
              </Link>
              <CloseButton onClick={onClose} alignSelf="center" justifySelf="center" />
            </HStack>
          </Box>
        ),
      });
    };

    const reportGenerationFailed = (value) => {
      showToast({
        status: "error",
        duration: 8000,
        message: `Report Generation Failed for ${value?.inspectionsDetails?.originName}`
      });
    };
    

    socket.on("reportGenerated", reportGenerated);
    socket.on("reportGenerationFailed", reportGenerationFailed);
    return () => {
      socket.off("reportGenerated", reportGenerated);
      socket.off("reportGenerationFailed", reportGenerationFailed);
    };
  }, [socket]);

  return <></>;
};

export default ReportNotifications;
