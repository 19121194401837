import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state.reviewInspection || initialState;

export const selecReviewData = createSelector(
  [selectDomain],
  (state) => state.reviewData
);
export const selectFormId = createSelector(
  [selectDomain],
  (state) => state.form && state.form.id
);

export const selectSections = createSelector(
  [selectDomain],
  (state) => state.sections
);

export const selectForm = createSelector([selectDomain], (state) => state.form);

export const selectSectionsForBlocks = createSelector(
  [selectDomain],
  (state) => state.sections || []
);

export const selectBlocksForComposites = createSelector(
  [selectDomain],
  (state) => state.blocks || []
);

export const selectCompositesForComponents = createSelector(
  [selectDomain],
  (state) => state.composites || []
);

export const selectComponentsForElements = createSelector(
  [selectDomain],
  (state) => state.components || []
);

export const selectSectionList = createSelector(
  [selectDomain],
  (state) =>
    state.sections &&
    [...state.sections].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectBlockList = createSelector(
  [selectDomain],
  (state) =>
    state.blocks &&
    [...state.blocks].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectCompositeList = createSelector(
  [selectDomain],
  (state) =>
    state.composites &&
    [...state.composites].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectComponentList = createSelector(
  [selectDomain],
  (state) =>
    state.components &&
    [...state.components].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectReportLoad = createSelector(
  [selectDomain],
  (state) => state.reportLoading
);
export const selectFilesUploadLoader = createSelector(
  [selectDomain],
  (state) => state.filesUploadLoader || {}
);

export const selectFilesUploadError = createSelector(
  [selectDomain],
  (state) => state.filesUploadError || {}
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.fileUploadLoading || {}
);
export const selectReviewDataLoading = createSelector(
  [selectDomain],
  (state) => state.reviewDataLoading || {}
);

export const selectTrackLocation = createSelector(
  [selectDomain],
  (state) => state.trackLocation
);
export const selectLocationLoading = createSelector(
  [selectDomain],
  (state) => state.locationLoading
);

export const selectOriginLocation = createSelector(
  [selectDomain],
  (state) => state.originLocation
);

export const selectAuditorAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.auditorAvatarUrl || null
);

export const selectClientAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.clientAvatarUrl || null
);

export const selectImageUrls = createSelector(
  [selectDomain],
  (state) => state.imageUrls
);
