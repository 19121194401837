import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack, IconButton } from "@chakra-ui/react";
import { ArrowUpIcon, AddIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function Moveup({ moveUpHandler, isDisabled }) {
  // //console.log(order);
  return (
    <>
      <IconButton
        variant="ghost"
        borderRadius="8px"
        sx={{
          _hover: {
            bg: "teal.400",
            color: "white",
            ".icon": {
              color: "white",
            },
          },
        }}
        icon={
          <ArrowUpIcon
            color={isDisabled ? "main.iconDisabled" : "main.icon"}
            className="icon"
          />
        }
        onClick={moveUpHandler}
      />
    </>
  );
}

export default Moveup;
