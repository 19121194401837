import { Button } from "@chakra-ui/react";
import React from "react";

export const getStatusColor = (status) => {
  switch (status) {
    case "todo":
      return "#718096";
    case "not-required":
      return "#2D3748";
    case "draft":
      return "#D69E2E";
    case "done" || "approved":
      return "#38A169";
    case "inprogress" || "review-inprogress":
      return "#3182CE";
    case "deleted":
      return "#E53E3E";
    default:
      return "#718096";
  }
};
export const text = (status) => {
  switch (status) {
    case "todo":
      return "TO DO";
    case "done":
      return "DONE";
    case "inprogress":
      return "IN PROGRESS";
    case "review-inprogress":
      return "IN REVIEW";
    case "deleted":
      return "DELETED";
    case "not-required":
      return "NOT REQUIRED";
    case "approved":
      return "APPROVED";
    case "draft":
      return "DRAFT";
  }
};

const StatusBadge = ({ status }) => {
  return (
    <Button
      colorScheme="white"
      color="white"
      size="xs"
      bg={getStatusColor(status)}
      borderWidth={1}
      borderRadius={5}
      mx={"1px"}
      py={"0px"}
      alignItems="center"
      pt="3px"
    >
      {text(status)}
    </Button>
  );
};

export default StatusBadge;
