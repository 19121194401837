import React, { useEffect, useState } from "react";
import { Button, Input } from "@agnext/reactlib";
import { HStack, Text, Box } from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { IoCloseCircleOutline, IoCloudUploadOutline } from "react-icons/io5";
import { workflowPropertyAndValueCheck } from "utils/validationFunctions";
import { formsPropertyAndValueCheck } from "utils/validationFunctions";
import useToastNotification from "utils/useToastNotification";
import * as APIs from "../../../../services";
import { generateIdSync, updateFormStructureIds } from "utils";

export default function JSONImport({ type, setJsonData }) {
  const [file, setFile] = useState("");
  const toast = useToastNotification();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file.type !== "application/json") {
      return;
    }
    setFile(file.name);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = JSON.parse(e.target.result);
        let message;
        if (
          type === "workflow" &&
          data?.workflow &&
          data?.forms &&
          workflowPropertyAndValueCheck(data.workflow).isValid &&
          formsPropertyAndValueCheck(data.forms)
        ) {
          let workflow = data.workflow;
          let forms = data.forms;
          workflow.title = workflow.title + " import";
          workflow.id = generateIdSync();
          forms = forms.map((form) => {
            return updateFormStructureIds(form, generateIdSync());
          });
          setJsonData({ workflow, forms });
        } else if (
          type === "form" &&
          formsPropertyAndValueCheck([data]).isValid
        ) {
          const clonedData = updateFormStructureIds(data, generateIdSync());
          setJsonData(clonedData);
        } else {
          if (type === "workflow") {
            message =
              workflowPropertyAndValueCheck(data.workflow).message ||
              formsPropertyAndValueCheck([data]).message;
          } else {
            message = formsPropertyAndValueCheck([data]).message;
          }
          throw new Error(message);
        }
      } catch (error) {
        toast({
          message: error.message,
          status: "error",
        });
        console.error("Error parsing JSON file:", error);
      }
    };
    reader.readAsText(file);
  };

  const handleInputClick = () => {
    document.getElementById(type).click();
  };

  const handleFileDelete = () => {
    document.getElementById(type).value = "";
    setJsonData(null);
    setFile(null);
  };

  return (
    <Box w="220px">
      <Input
        id={type}
        accept=".json"
        type="file"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        key={type}
      />
      <Button
        size="sm"
        borderRadius="8px"
        onClick={handleInputClick}
        colorScheme="login"
        leftIcon={<IoCloudUploadOutline size={18} fontWeight="bold" />}
        label={`Import ${type}`}
      />
      {file ? (
        <HStack
          w="100%"
          justify="space-between"
          align="center"
          sx={{
            padding: "4px 12px",
            background: "#F7FAFC",
            borderRadius: "8px",
            margin: "6px auto 8px auto",
          }}
        >
          <HStack>
            <FiFile size="22px" color="#718096" />
            <Text
              color="#4A5568"
              fontSize="14px"
              fontWeight="400"
              lineHeight="1.4"
            >
              {file?.length > 18
                ? `${file.slice(0, 18)}...${file.slice(file.length - 6)}`
                : file}
            </Text>
          </HStack>
          <span
            style={{ padding: "6px", cursor: "pointer" }}
            onClick={handleFileDelete}
          >
            <IoCloseCircleOutline size="24px" color="#718096" />
          </span>
        </HStack>
      ) : (
        <></>
      )}
    </Box>
  );
}
