import React from "react";
import { HStack, Input, Box, Text } from "@chakra-ui/react";
import { HiMenuAlt4 } from "react-icons/hi";

export default function MultiTextFields() {
  return (
    <>
      <Box style={{ margin: "16px auto" }}>
        <Text
          color="#2D3748"
          fontSize="14px"
          fontWeight="400"
          lineHeight="1.4"
          style={{ marginBottom: "8px" }}
        >
          Text Field
        </Text>
        <HStack
          w="100%"
          h="48px"
          style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
        >
          <HStack w="48px" h="48px" justify="center" align="center">
            <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
          </HStack>
          <Input
            variant="unstyled"
            placeholder="Placeholder"
            color="#2D3748"
            _placeholder={{ color: "#A0AEC0" }}
          />
        </HStack>
      </Box>
      <Box style={{ margin: "16px auto" }}>
        <Text
          color="#2D3748"
          fontSize="14px"
          fontWeight="400"
          lineHeight="1.4"
          style={{ marginBottom: "8px" }}
        >
          Text Field
        </Text>
        <HStack
          w="100%"
          h="48px"
          style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
        >
          <HStack w="48px" h="48px" justify="center" align="center">
            <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
          </HStack>
          <Input
            variant="unstyled"
            placeholder="Placeholder"
            color="#2D3748"
            _placeholder={{ color: "#A0AEC0" }}
          />
        </HStack>
      </Box>
      <Box style={{ margin: "16px auto" }}>
        <Text
          color="#2D3748"
          fontSize="14px"
          fontWeight="400"
          lineHeight="1.4"
          style={{ marginBottom: "8px" }}
        >
          Text Field
        </Text>
        <HStack
          w="100%"
          h="48px"
          style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
        >
          <HStack w="48px" h="48px" justify="center" align="center">
            <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
          </HStack>
          <Input
            variant="unstyled"
            placeholder="Placeholder"
            color="#2D3748"
            _placeholder={{ color: "#A0AEC0" }}
          />
        </HStack>
      </Box>
    </>
  );
}
