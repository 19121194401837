import React from "react";
import {
  Box,
  HStack,
  Text,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  VStack,
  IconButton,
  FormLabel,
  Switch,
  useDisclosure,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
  Tooltip,
  Stack,
} from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import { RiPlayListAddFill } from "react-icons/ri";

export default function Repeatable({
  isFor,
  repeatable,
  handleChange,
  handleSubmit,
  repeatableModalValidation,
  disabled,
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  let tooltipLabel;
  const {
    repeatableDisabled,
    notApplicableforThisElement,
    isNotSelected,
  } = disabled;
  if (repeatableDisabled) {
    tooltipLabel =
      "Feature can't be enabled for both component and its elements simultaneously.";
  } else if (isNotSelected) {
    tooltipLabel = "Please Select Element Before using Repeatable feature";
  } else if (notApplicableforThisElement) {
    tooltipLabel = "Repeatable feature not Applicable for this Element";
  } else {
    tooltipLabel = "Multiple Entries";
  }

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom"
      isLazy={true}
    >
      <PopoverTrigger>
        <Box>
          <Tooltip isDisabled={isOpen} label={tooltipLabel}>
            <IconButton
              isDisabled={
                isNotSelected ||
                notApplicableforThisElement ||
                repeatableDisabled
              }
              variant="ghost"
              borderRadius="8px"
              sx={{
                _hover: {
                  bg: "teal.400",
                  color: "white",
                },
                ".icon": {
                  color: repeatable?.isRepeatable ? "login.500" : "#718096",
                },
                "&:hover .icon": {
                  color: "white",
                },
              }}
              icon={<RiPlayListAddFill className="icon" />}
            />
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        style={{
          boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
          width: "345px",
        }}
      >
        <PopoverArrow />
        <PopoverHeader
          sx={{
            padding: "10px 16px",
            color: "#4A5568",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Allow Multi-{isFor} Entries
        </PopoverHeader>
        <PopoverCloseButton
          sx={{ color: "#4A5568", top: "10px", right: "16px" }}
        />
        <PopoverBody
          sx={{
            padding: "10px 16px",
          }}
        >
          <VStack
            alignItems="flex-start"
            gap="20px"
            style={{ margin: "12px auto" }}
          >
            <Text
              sx={{
                color: "#4A5568",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Allow auditor to repeat this{" "}
              {isFor === "Component" ? "entire component" : "element"} for
              multiple entries by clicking 'Add More'.
            </Text>
            <HStack w="100%" justify="space-between">
              <FormLabel
                color="login.label"
                htmlFor="repeatable"
                mb="0"
                sx={{
                  color: "#2D3748",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Enable Multi-Entries
              </FormLabel>
              <Switch
                isChecked={repeatable?.isRepeatable}
                id="repeatable"
                colorScheme="login"
                onChange={() => {
                  handleChange("isRepeatable", !repeatable?.isRepeatable);
                }}
                size="sm"
              />
            </HStack>
            <VStack w="100%" gap="6px" alignItems="flex-start">
              <NumberInput
                min={1}
                value={repeatable?.minEntries}
                onChange={(e) => {
                  handleChange("minEntries", parseInt(e ? e : 1));
                }}
                size="sm"
                type="number"
                w="100%"
                focusBorderColor="login.500"
                borderRadius="2px"
                height="24px"
                color="#4A5568"
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper color="#4A5568" />
                  <NumberDecrementStepper color="#4A5568" />
                </NumberInputStepper>
              </NumberInput>
              <Text
                sx={{
                  color: "#718096",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Minimum entries allowed.
              </Text>
            </VStack>
            <VStack w="100%" alignItems="flex-start" gap="8px">
              <HStack w="100%" justify="space-between" alignItems="center">
                <Text
                  sx={{
                    color: "#2D3748",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Max entries
                </Text>
                <HStack align="center">
                  <Text
                    sx={{
                      color: "#718096",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Unlimited
                  </Text>
                  <Checkbox
                    id="unlimited"
                    colorScheme="login"
                    onChange={() => {
                      handleChange(
                        "unlimitedEntries",
                        !repeatable?.unlimitedEntries
                      );
                    }}
                    isChecked={repeatable?.unlimitedEntries}
                    type="checkbox"
                    w="16px"
                    h="16px"
                    borderRadius="2px"
                  />
                </HStack>
              </HStack>
              {!repeatable?.unlimitedEntries ? (
                <VStack w="100%" gap="6px" alignItems="flex-start">
                  <NumberInput
                    min={1}
                    value={repeatable?.maxEntries}
                    onChange={(e) => {
                      handleChange("maxEntries", parseInt(e ? e : 1));
                    }}
                    size="sm"
                    type="number"
                    w="100%"
                    focusBorderColor="login.500"
                    borderRadius="2px"
                    height="24px"
                    color="#4A5568"
                    sx={{
                      display: "flex",
                      fontSize: "12px",
                      alignItems: "center",
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper color="#4A5568" />
                      <NumberDecrementStepper color="#4A5568" />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text
                    sx={{
                      color: "#718096",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Maximum entries allowed.
                  </Text>
                </VStack>
              ) : (
                <></>
              )}
            </VStack>
          </VStack>
          {repeatableModalValidation && (
            <Stack textAlign="center" fontSize="12px" color="red">
              {" "}
              <Text>{repeatableModalValidation}</Text>
            </Stack>
          )}
          <VStack gap={4}>
            <HStack w="100%" justify="flex-end">
              <Button
                size="sm"
                borderRadius="8px"
                colorScheme="login"
                label="Done"
                onClick={() => {
                  handleSubmit(onClose);
                }}
              />
            </HStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
