import React, { useRef, useContext, useState } from "react";
import { Button, Box, Flex, Text } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { FilterIcon } from "components/Icons/Icons";
import FilterCategory from "components/FilterCategory/FilterCategory";
import Select from "./Select";
import { DatePicker } from "@agnext/reactlib";
import DropdownWithCheckboxesLatest from "components/DropdownWithCheckboxes/DropdownWithCheckBoxesLatest";

export const styles = {
  button: {
    backgroundColor: "#F7FAFC",
    width: "106px",
    fontSize: "12px",
    borderRadius: "8px",
    padding: "0 14px",
    display: "flex",
    justifyContent: "space-between",
    height: "39px",
    color: "#4F4F4F",
    fontWeight: "600",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    width: "100%",
  },

  clearApplyBtnContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "20px",
    alignSelf: "stretch",
  },
  clearApplyBtn: {
    display: "flex",
    width: "131px",
    height: "40px",
    padding: "0px 8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
  },
  greenCircle: {
    position: "absolute",
    top: "-2.5px",
    right: "-3.5px",
    width: "12px",
    height: "12px",
    backgroundColor: "#319795",
    borderRadius: "50%",
    border: "1px solid white",
  },
};

const priorities = [
  { id: "critical", value: "Critical" },
  { id: "high", value: "High" },
  { id: "medium", value: "Medium" },
  { id: "low", value: "Low" },
];

const AddFilter = ({
  isMobile,
  handleOptionChange,
  filters,
  handleClearFilters,
  locations,
  selectedLocations,
  setSelectedLocations,
  tempFilters,
  handleApplyButtonClick,
  tempLocations,
  setTempLocations,
  handleApplyButton
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsOpen(false);
    },
  });

  const [query, setQuery] = useState({
    cmName: [],
    auditorName: [],
    originLocation: [],
  });
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const handleSelectChange = (itemId, property) => {
    if (property === "Locaitons") {
      setTempLocations((prevSelectedItems) =>
        handleSelection(prevSelectedItems, itemId)
      );
    }
  };
  const handleSelection = (prevSelectedItems, itemId) => {
    if (prevSelectedItems.includes(itemId)) {
      return prevSelectedItems.filter((id) => id !== itemId);
    } else {
      return [...prevSelectedItems, itemId];
    }
  };

  return (
    <Box ref={ref} style={{ position: "relative" }}>
      <Button
        sx={styles.button}
        rightIcon={<FilterIcon width="20px" height="18px" color="#A0AEC0" />}
        variant="outline"
        onClick={toggleCollapse}
      >
        Add Filter
        {(filters?.priority ||
          filters.date ||
          selectedLocations.length > 0) && <Box sx={styles.greenCircle}></Box>}
      </Button>
      <Flex
        className={
          isOpen ? (isMobile ? "drop-Down-mobile" : "drop-Down") : "hide"
        }
        gap="16px"
        w="285px"
      >
        <Flex direction="column" w="100%" gap="8px">
          <Text>Priority</Text>
          <Select
            options={priorities}
            handleOptionChange={handleOptionChange}
            customKey="priority"
            bg="#F7FAFC"
            value={tempFilters.priority}
            placeholder="Priority"
          />
        </Flex>
        <Flex direction="column" w="100%" gap="8px">
          <Text>Due Date</Text>
          <DatePicker
            height={40}
            headerTextSize={12}
            date={tempFilters?.date ? tempFilters?.date : null}
            setDate={(date) => handleOptionChange(date, "date")}
          />
        </Flex>
        <Flex direction="column" w="100%" gap="8px">
          <Text>Location</Text>
          <DropdownWithCheckboxesLatest
            dropDownList={locations}
            property="Locaitons"
            title="Location"
            flex="1"
            showCheckBox
            selectedItems={tempLocations}
            setSelectedRoles={setTempLocations}
            isForRoles={true}
            handleSelectChange={handleSelectChange}
          />
        </Flex>

        <Box sx={styles.clearApplyBtnContainer}>
          <Button sx={styles.clearApplyBtn} onClick={handleClearFilters}>
            Clear
          </Button>
          <Button
            sx={styles.clearApplyBtn}
            colorScheme="login"
            onClick={() => {
              handleApplyButtonClick();
              toggleCollapse();
              handleApplyButton()
            }}
          >
            Apply
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default AddFilter;
