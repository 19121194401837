import React from "react";
import { Box, CircularProgress, Flex, HStack } from "@chakra-ui/react";
import { v4 as id } from "uuid";
import noRecordImage from "../../../../../assets/img/Empty Case.png";
import InsightsAndGraphChild from "../InsightsAndGraph";

const styles = {
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
  insightsContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
};

function DataCard({ data }) {
  const { insight, loading, tooltipData = null, isInfoBtnActive } = data || {
    insight: null,
    loading: true,
    tooltipData: null,
    isInfoBtnActive: false,
  };
  if (loading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100px"
        width="10%"
      >
        <CircularProgress
          isIndeterminate
          color="#4FD1C5"
          size="40px"
          alignSelf={"center"}
        />
      </Flex>
    );
  }

  if (insight) {
    return (
      <>
        <InsightsAndGraphChild
          title={insight.title}
          amount={insight.amount}
          icon={insight.icon}
          key={id()}
          labels={insight?.labels || null}
          data={insight?.chartData || null}
          loading={loading}
          percentage={insight.percentage}
          tooltipData={tooltipData}
          iconBg={insight.iconBg}
          isInfoBtnActive={isInfoBtnActive}
        />
      </>
    );
  }

  return (
    <Box style={styles.message}>
      <img style={styles.image} src={noRecordImage} alt="No Records Image" />
      <p>No Insights For Selected Filters</p>
    </Box>
  );
}

export default DataCard;
