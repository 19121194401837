import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const CustomModal = ({
  isOpen,
  onClose,
  headerText,
  bodyText,
  buttonText,
  onButtonClick,
  additionalContent,
  colorScheme,
  minHeight,
  isDisabled,
  initRef,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered initialFocusRef={initRef && initRef}>
      <ModalOverlay />
      <ModalContent minH={minHeight} w={"436px"} borderRadius={"15px"}>
        <ModalHeader fontWeight={700} fontSize={"18px"}>
          {headerText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {bodyText}
          {additionalContent && additionalContent}
        </ModalBody>
        <ModalFooter gap={"10px"}>
         
          <Button
            borderRadius={"8px"}
            colorScheme="modalsLight"
            onClick={onClose}
            color={"black"}
            fontWeight={"600"}
          >
            Cancel
          </Button> 
          <Button
            colorScheme={colorScheme}
            onClick={() => {
              onButtonClick();
              onClose();
            }}
            isDisabled={isDisabled}
            borderRadius={"8px"}
            fontWeight={"500"}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
