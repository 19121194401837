import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { styles } from "./DrawerDropdown.modules.js";
import React, { useEffect } from "react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import _ from "lodash";
function DrawerDropdown({
  placeholder,
  options,
  handleFilterQuery,
  property,
  labelKey,
  valueKey,
  filterQuery,
  setOpenedDropdownId,
}) {
  const [inputValue, setInputValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const handleSelect = (value) => {
    setSelectedValue(value);
  };
  const handleInputVal = (value) => {
    setInputValue(value);
  };
  let filteredOptions =
    options && options.length && inputValue
      ? options?.filter((option) =>
          option[labelKey]?.toLowerCase().includes(inputValue?.toLowerCase())
        )
      : options;
  filteredOptions = filteredOptions?.sort((a, b) =>
    a[labelKey].localeCompare(b[labelKey])
  );
  const isInputEmpty = inputValue?.trim() ? false : true;
  useEffect(() => {
    if (
      typeof filterQuery[property] === "object" &&
      !_.isNull(filterQuery[property])
    ) {
      setSelectedValue(filterQuery[property][valueKey]);
    } else {
      setSelectedValue(filterQuery[property]);
    }
  }, [filterQuery[property]]);
  return (
    <Box sx={styles.dropDownOuterContainer} overflow="none">
      <InputGroup sx={styles.inputContainer}>
        <Input
          type={"text"}
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            handleInputVal(e.target.value);
          }}
        ></Input>
        <InputLeftElement>
          <SearchIcon color={"gray"} w="15px" h="15px" />
        </InputLeftElement>
        <InputRightElement>
          {isInputEmpty ? (
            <></>
          ) : (
            <IconButton
              bg="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<CloseIcon color="gray.400" height="10px" />}
              onClick={() => {
                handleSelect(null);
              }}
            ></IconButton>
          )}
        </InputRightElement>
      </InputGroup>
      <VStack height={"90%"} justifyContent={"space-between"}>
        {filteredOptions.length === 0 ? (
          <Box>No Matching {property}</Box>
        ) : (
          <Box sx={styles.dropdownContainer}>
            {filteredOptions.map((option) => (
              <>
                <Box
                  bg={
                    selectedValue && selectedValue === option[valueKey]
                      ? "#F7FAFC"
                      : ""
                  }
                  key={option[valueKey]}
                  sx={styles.dropdownOption}
                  _hover={{ cursor: "pointer", bg: "#F7FAFC" }}
                  onClick={() => {
                    handleSelect(option[valueKey]);
                    handleFilterQuery(option[valueKey], property);
                    setOpenedDropdownId(null);
                  }}
                >
                  {_.capitalize(option[labelKey])}
                </Box>
                <Box height={"1px"} bg={"#E2E8F0"} />
              </>
            ))}
          </Box>
        )}
        <HStack w="100%">
          <Button
            sx={styles.filterBtn}
            colorScheme="gray"
            w="100%"
            color="#1A202C"
            onClick={() => {
              handleFilterQuery(null, property);
            }}
          >
            Clear
          </Button>
          {/* <Button
            sx={styles.filterBtn}
            colorScheme="login"
            w="100%"
            onClick={() => {
              handleFilterQuery(selectedValue, property);
            }}
          >
            Apply
          </Button> */}
        </HStack>
      </VStack>
    </Box>
  );
}

export default DrawerDropdown;
