import React, { useEffect, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
} from "@chakra-ui/icons";
import * as selectors from "../selectors";
import { useSelector } from "react-redux";
import { MdArrowForwardIos, MdOutlineDeleteOutline } from "react-icons/md";
import Component from "../component/Component";

export default function Section({
  section,
  getComponents,
  formData,
  handleFormDataChange,
  handleFilesChange,
  reviewData,
  handleResetClick,
  discardButton,
  handleImageDownload,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  disabledSectionCheck,
  isObjectMatching,
}) {
  //section.id "j83n8aa8e2b2iqdz" is added in the below code to exclude disclaimer from the review page.
  if (
    disabledSectionCheck?.includes(section.id) ||
    section.title.toLowerCase() === "disclaimer"
  ) {
    return;
  }
  const sections = useSelector(selectors.selectSectionsForBlocks);
  const blocks = sections?.find((currSection) => currSection.id === section.id)
    ?.structure?.blocks;
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [isSectionHidden, setIsSectionHidden] = useState(false);

  const handleToggleSectionHidden = () => {
    setIsSectionHidden((prev) => {
      return !prev;
    });
  };
  console.log("section index")
  return (
    <VStack w="100%" aria-label="section" gap="24px">
      {blocks?.map((block) => {
        return (
          <VStack w="100%" aria-label="section" gap="24px" key={block.id}>
            <HStack
              style={{
                background: "#EDF2F7",
                borderRadius: "8px",
                padding: "8px 16px 8px 8px",
                width: "100%",
              }}
              justify="space-between"
              align="flex-start"
            >
              <HStack justify="center" align="center">
                <IconButton
                  variant="ghost"
                  borderRadius="8px"
                  // isDisabled={index < 1}
                  sx={{
                    ".icon": {
                      color: "#718096",
                    },
                  }}
                  icon={
                    isSectionHidden ? (
                      <MdArrowForwardIos w="26px" h="26px" className="icon" />
                    ) : (
                      <CollapseIcon w="26px" h="26px" className="icon" />
                    )
                  }
                  onClick={handleToggleSectionHidden}
                />
                <HStack align="center">
                  <Text
                    color="#4A5568"
                    fontSize={isMobileScreen ? "16px" : "22px"}
                    fontWeight="600"
                    lineHeight="1.4"
                  >
                    {block.title}
                  </Text>
                  <Text
                    color="#718096"
                    fontSize={isMobileScreen ? "10px" : "12px"}
                    fontWeight="400"
                    lineHeight="1.4"
                  >
                    {block.description}
                  </Text>
                </HStack>
              </HStack>
              {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
            </HStack>
            <>
              {getComponents(block.id).map((compEl, index) => {
                return (
                  <Component
                    key={index}
                    isSectionHidden={isSectionHidden}
                    compEl={compEl}
                    section={section}
                    formData={formData}
                    handleFormDataChange={handleFormDataChange}
                    handleFilesChange={handleFilesChange}
                    reviewData={reviewData}
                    handleResetClick={handleResetClick}
                    discardButton={discardButton}
                    handleImageDownload={handleImageDownload}
                    displayData={displayData}
                    setPage={setPage}
                    navIndex={navIndex}
                    setNavIndex={setNavIndex}
                    isObjectMatching={isObjectMatching}
                  />
                );
              })}
            </>
          </VStack>
        );
      })}
    </VStack>
  );
}
