export const styles = {
  pendingApprovals: {
    position: "relative",
    fontSize: "18px",
    lineHeight: "140%",
    fontFamily: "Inter",
    color: "#2d3748",
    textAlign: "left",
    fontWeight: "600",
  },
  activeBtn: {
    color: "#38b2ac",
  },
  inActiveBtn: {
    color: "#2d3748",
  },
  btn: {
    position: "relative",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    fontFamily: "Inter",
    textAlign: "center",
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: "15px",
    width: "100%",
  },
  viewReportDropdown: {
    borderRadius: "15px !important",
    position: "absolute !important",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  },
};
// text color for btn
// background color btns
// font-weight
