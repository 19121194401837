import React, { useEffect, useState } from "react";
import { Box, Text, VStack, Stack, HStack } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { UpdateFormDataSaga } from "../Update/saga";
import { actions, sliceKey, reducer } from "../Create/slice";
import * as selectors from "./selectors";
import useToastNotification from "utils/useToastNotification";
import SectionsList from "./Sections";
import Components from "./Components";

export default function Move() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UpdateFormDataSaga });

  const sections = useSelector(selectors.selectSections);

  //   data for post

  const sectionsForPost = useSelector(selectors.selectSectionsForPost);
  const form = useSelector(selectors.selectForm);
  const blocksForPost = useSelector(selectors.selectBlocksForPost);
  const compositesForPost = useSelector(selectors.selectCompositesForPost);
  const componentsForPost = useSelector(selectors.selectComponentsForPost);

  //   sectionComponentMap has componentId as key and it's parent section as value;
  const { components, sectionComponentMap } = useSelector(
    selectors.selectComponents
  );

  const [selectedComponent, setSelectedComponent] = useState(null);

  const [hoveredComponent, setHoveredComponent] = useState(null);

  const { formId, workflowId } = useParams();

  const showToast = useToastNotification();

  const dispatch = useDispatch();
  const handleDragEndSections = (result) => {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const dropIndex = result.destination.index;
    dispatch(actions.dragSection({ startIndex, dropIndex }));
  };

  const handleDragEndElements = (result) => {
    if (!result.destination) {
      return;
    }
    const startComp = result.source.droppableId;
    const startComponent = componentsForPost.find(
      (comp) => comp.id === startComp
    );
    if (!startComponent || startComponent.structure?.elements?.length < 2) {
      showToast({
        message: `Component must have at least one element`,
        status: "warning",
      });
      return;
    }
    const dropComp = result.destination.droppableId;
    const startIndex = result.source.index;
    const dropIndex = result.destination.index;
    dispatch(
      actions.dragElement({ startIndex, dropIndex, startComp, dropComp })
    );
    setSelectedComponent(dropComp);
  };

  const handleUpdateData = (status) => {
    const onSuccess = () => {
      showToast({
        message: `Form ${status} successfully`,
        status: "success",
      });
    };
    const onError = () => {
      showToast({
        message: `Something Went Wrong`,
        status: "error",
      });
    };

    dispatch(
      actions.postFormData({
        data: {
          forms: [{ ...form, status }],
          sections: sectionsForPost,
          blocks: blocksForPost,
          composites: compositesForPost,
          components: componentsForPost,
        },
        onSuccess,
        onError,
      })
    );
  };

  useEffect(() => {
    dispatch(actions.fetchFormData({ id: formId }));
  }, [dispatch, formId]);

  return (
    <Box p={"15px"} borderRadius={"8px"} mt={"75px"} bg={"#fff"}>
      <Text color="#2D3748" fontSize="18px" fontWeight="500" mb="24px">
        Move / Reorder
      </Text>
      <HStack align="flex-start" justify="flex-start" gap="24px">
        <DragDropContext onDragEnd={handleDragEndSections}>
          <SectionsList
            sections={sections}
            selected={sectionComponentMap[selectedComponent]}
          />
        </DragDropContext>
        <DragDropContext onDragEnd={handleDragEndElements}>
          <Components
            selected={selectedComponent}
            setSelected={setSelectedComponent}
            components={componentsForPost}
            componentsForMap={components}
            hoveredComponent={hoveredComponent}
            setHoveredComponent={setHoveredComponent}
          />
        </DragDropContext>
      </HStack>
      <HStack
        style={{
          position: "fixed",
          right: "24px",
          bottom: "28px",
          boxShadow: "0px 5.5px 7.5px 0px rgba(0, 0, 0, 0.08)",
        }}
        w="170px"
        height="65px"
        p="17px"
        bg="white"
        borderRadius="15px"
        border="var(--border-radius-0, 1px) solid var(--gray-200, #E2E8F0)"
      >
        <Link to={`/admin/workflows/${workflowId}/${formId}/create`}>
          <Button
            size="sm"
            borderRadius="8px"
            onClick={() => {}}
            colorScheme="login"
            label="Cancel"
          />
        </Link>
        <Button
          size="sm"
          borderRadius="8px"
          onClick={() => {
            handleUpdateData("draft");
          }}
          colorScheme="login"
          label="Save"
        />
      </HStack>
    </Box>
  );
}
