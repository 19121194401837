import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import View from "views/Workflow Builder/pages/View";
import { watchJobsAndManagement } from "../../../jobs/saga";
import * as selectors from "../../../jobs/selectors";
import { selectReviewDataLoading } from "../../../Workflow/pages/reviewWorkflow/selectors";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "../../../jobs/slice";
import ReviewWorkflow from "views/Workflow/pages/reviewWorkflow";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const ReviewInspection = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });

  const [selectedFormId, setSelectedFormId] = useState(null);
  const { inspectionid } = useParams();
  const dispatch = useDispatch();

  const workflowData = useSelector(selectors.selectWorkflowData);
  const inspectionDetails = useSelector(selectors.selectInspectionDetails);
  const formTaskObj = useSelector(selectors.selectFormTaskDetails);
  const tasksData = useSelector(selectors.selectTasksData)
  useEffect(() => {
    dispatch(actions.fetchInspectionDetails({ inspectionId: inspectionid }));
    return () => {
      dispatch(actions.clearReviewDetails());
      setSelectedFormId(null);
    };
  }, [inspectionid]);

  //We are selecting inspectionDetails[0] as there will be only one job for inspection for now.
  useEffect(() => {
    if (inspectionDetails && Object.keys(inspectionDetails).length) {
      dispatch(
        actions.fetchWorkflowDataRequest({
          workflowId: inspectionDetails[0]["workflowId"],
        })
      );
      dispatch(
        actions.fetchGetTasksRequest({
          inspectionId: inspectionid,
          jobId: inspectionDetails[0]["id"],
        })
      );
    }
  }, [inspectionDetails]);
  useEffect(() => {
    // if (formid && selectedFormId === null) {
    //   setSelectedFormId(formid);
    // }
    if (
      workflowData &&
      workflowData?.forms?.length > 0 &&
      selectedFormId === null
    ) {
      setSelectedFormId(workflowData.forms[0].id);
    }
  }, [workflowData]);

  const handleFormClick = useCallback(
    (formId) => {
      setSelectedFormId(formId);
    },
    [selectedFormId]
  );

  const isFormSelected = useCallback(
    (formId) => {
      return formId === selectedFormId;
    },
    [selectedFormId]
  );
  const selectedFormStyleMemo = useMemo(
    () => ({
      borderRadius: "8px",
      border: "1.5px solid var(--Grey-Gray-300, #CBD5E0)",
      background: "var(--White, #FFF)",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    }),
    []
  );
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"} w={"100%"}>
        {selectedFormId &&
          formTaskObj &&
          formTaskObj[selectedFormId] &&
          inspectionDetails &&
          inspectionDetails.length &&
          selectReviewDataLoading && (
            <ReviewWorkflow
              inspectionId={inspectionid}
              formId={selectedFormId}
              jobId={inspectionDetails[0]["id"]}
              taskId={formTaskObj[selectedFormId]}
              workflowData={workflowData}
              handleFormClick={handleFormClick}
              isFormSelected={isFormSelected}
              selectedFormStyleMemo={selectedFormStyleMemo}
              inspectionDetails={inspectionDetails}
              tasksData={tasksData}
            />
          )}
      </Box>
    </ErrorBoundary>
  );
};

export default ReviewInspection;