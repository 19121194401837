import { Box, Flex, Image, Text } from "@chakra-ui/react";
import deleteIcon from "assets/svg/deleteRed.svg";
import Edit from "assets/svg/edit-icon.svg";
import locationBlack from "assets/svg/locationBlack.svg";

export const BasicInfo = ({
  displayText,
  onOpenAddress,
  isEditing,
  handleDeleteButton,
  oldAddress,
}) => {
  return (
    <Flex justifyContent="space-between">
      <Flex
        gap={"11px"}
        flex={"1"}
        justifyContent={{ base: "space-between", md: "flex-start" }}
        alignItems="center"
      >
        <Flex gap={"10px"}>
          <Image src={locationBlack} alt="Dan Abramov" />{" "}
          {displayText && (
            <Text
              sx={{
                color: "#2D3748",
                fontWeight: location ? 400 : 500,
                fontSize: "14px",
              }}
            >
              {displayText}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex gap="12px">
        {isEditing &&
        oldAddress &&
        oldAddress?.length ? (
          <Box
            background={"white"}
            height={"32px"}
            width={"38px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"12px"}
            border={"1px solid #E53E3E"}
            style={{ cursor: "pointer" }}
            onClick={handleDeleteButton}
          >
            <Image src={deleteIcon} alt="" />
          </Box>
        ) : (
          <></>
        )}
        <Box
          background={"#EDF2F7"}
          height={"32px"}
          width={"38px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
          onClick={onOpenAddress}
        >
          <Image src={Edit} alt="" />
        </Box>
      </Flex>
    </Flex>
  );
};
