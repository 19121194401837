import { Table } from "@agnext/reactlib";
import { Box, Image, Text, VStack } from "@chakra-ui/react";
import { ViewReportIcon } from "components/Icons/Icons";
import Eye from "assets/svg/eye-icon.svg";

import React from "react";
import JobTaskTableEyeBtn from "./Buttons/JobTaskTableEyeBtn";
import { taskHeader } from "./variables/variables";
import AvatarWithName from "./Table Components/AvatarWithName";
import Status from "./Table Components/Status";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const header = {
  color: "#2D3748",
  fontSize: 16,
  fontFamily: "Inter",
  fontWeight: "600",
  lineHeight: "22.40px",
  wordWrap: "break-word",
  width: "100%",
};

export const JobTaskTable = ({
  taskList,
  assigneeUserDetails,
  assigneeProfileImgUrls,
  originId,
  clientId,
}) => {
  const taskData = taskList?.map((task, index) => {
    return {
      jobName: `${capitalizeStringUpdated(task.name)}`,
      asignee: (
        <AvatarWithName
          name={`${
            assigneeUserDetails[index]?.firstName
              ? assigneeUserDetails[index]?.firstName
              : "-"
          } ${
            assigneeUserDetails[index]?.lastName
              ? assigneeUserDetails[index]?.lastName
              : ""
          }`}
          avatarUrl={assigneeProfileImgUrls[index]?.url}
        />
      ),
      status: <Status status={task?.status} />,
      dueDate: extractDayMonthYearFromTimestamp(task?.dueDate),
      actions: (
        <JobTaskTableEyeBtn
          workflowId={task?.workflowId}
          formId={task?.formId}
          taskId={task?.id}
          jobId={task?.jobId}
          inspectionId={task?.inspectionId}
          originId={originId}
          clientId={clientId}
          status={task?.status}
        />
      ),
    };
  });

  // console.log(taskList);
  //
  return (
    <VStack w={"100%"} borderRadius={"15px"}>
      <Text sx={header}>Tasks</Text>
      <Box bg={"#fff"} w={"100%"} borderRadius={"15px"}>
        <Table headerList={taskHeader} dataList={taskData}></Table>
      </Box>
    </VStack>
  );
};
