import React from "react";
import { Text } from "@chakra-ui/react";

export default function SectionHeader({ data }) {
  return (
    <Text
      sx={{
        color: "#4A5568",
        fontFamily: "Inter",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "140%",
      }}
    >
      {data}
    </Text>
  );
}
