import React from "react";
import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import locationBlack from "assets/svg/locationBlack.svg";
import { LogoWithName } from "./ViewUserTop";

const flexStyles = {
  gap: "18px",
  justifyContent: "space-between",
  width: "100%",
};

export const BasicInfo = ({ label, displayText, location }) => {
  return (
    <Flex
      gap={"11px"}
      flex={"1"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};

const ViewUserInfo = ({ userDetails, clientId }) => {
  return (
    <Stack spacing={"36px"}>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600} lineHeight={"22.4px"} color={"#2D3748"}>
            Basic Info
          </Text>
        </Box>
        <Box
          style={{
            background: "white",
            boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
            borderRadius: 15,
          }}
        >
          <Box padding={"16px 24px"}>
            <Flex gap={"28px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Roles
              </Text>
              <Box>
                <Flex {...flexStyles} direction={{ base: "column", md: "row" }}>
                  {clientId ? (
                    <>
                      <BasicInfo
                        label={"Client:"}
                        displayText={
                          <LogoWithName
                            clientAvatarUrl={""}
                            clientName={
                              capitalizeStringUpdated(
                                userDetails?.clientName
                              ) || "-"
                            }
                          />
                        }
                      />
                    </>
                  ) : (
                    <>
                      <BasicInfo
                        label={"Department:"}
                        displayText={
                          capitalizeStringUpdated(userDetails?.department) ||
                          "-"
                        }
                      />
                      <BasicInfo
                        label={"Designation:"}
                        displayText={
                          capitalizeStringUpdated(userDetails?.designation) ||
                          "-"
                        }
                      />
                    </>
                  )}
                  <BasicInfo
                    label={"Roles:"}
                    displayText={
                      capitalizeStringUpdated(
                        userDetails?.roles
                          ?.map((role) => role?.roleName)
                          .join(", ")
                          .split("-")
                          .join(" ")
                      ) || "-"
                    }
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          style={{
            background: "white",
            boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
            borderRadius: 15,
          }}
        >
          <Box padding={"16px 24px"}>
            <Flex gap={"28px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                User Details
              </Text>
              <Box>
                <Flex gap={"12px"} direction={"column"}>
                  <Flex
                    {...flexStyles}
                    direction={{ base: "column", md: "row" }}
                  >
                    <BasicInfo
                      label={"First Name:"}
                      displayText={
                        capitalizeStringUpdated(userDetails?.firstName) || "-"
                      }
                    />
                    <BasicInfo
                      label={"Last Name:"}
                      displayText={
                        capitalizeStringUpdated(userDetails?.lastName) || "-"
                      }
                    />
                    <BasicInfo
                      label={"Gender:"}
                      displayText={
                        capitalizeStringUpdated(
                          userDetails?.gender?.split("_").join(" ")
                        ) || "-"
                      }
                    />
                    <BasicInfo
                      label={"Email:"}
                      displayText={userDetails?.email || "-"}
                    />
                  </Flex>
                  <Box>
                    <BasicInfo
                      label={"Phone Number:"}
                      displayText={
                        userDetails?.mobile && userDetails?.countryCode
                          ? "+" +
                            userDetails?.countryCode +
                            " " +
                            userDetails?.mobile
                          : userDetails?.mobile
                          ? userDetails?.mobile
                          : "-"
                      }
                    />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Stack>
      {userDetails?.addresses?.length ? (
        <Stack spacing={"24px"}>
          <Box>
            <Text fontWeight={600} lineHeight={"22.4px"} color={"#2D3748"}>
              Address
            </Text>
          </Box>
          <Box
            style={{
              background: "white",
              boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
              borderRadius: 15,
              flex: "1",
            }}
          >
            <Flex gap="18px" padding={"16px 24px"}>
              <Image
                src={locationBlack}
                alt="locationIcon"
                h={"18px"}
                w={"18px"}
              />{" "}
              <BasicInfo
                displayText={
                  userDetails?.addresses
                    ?.map((address) =>
                      capitalizeStringUpdated(
                        [
                          address?.addressInfo?.addressLine1,
                          address?.addressInfo?.addressLine2?.trim(),
                          address?.city,
                          address?.state,
                          address?.country && address?.postalCode
                            ? `${address.country} ${address.postalCode}`
                            : address?.country,
                        ]
                          .filter(Boolean)
                          .join(", ")
                      )
                    )
                    .join(", ") || "-"
                }
              />
            </Flex>
          </Box>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ViewUserInfo;
