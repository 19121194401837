import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Polyline, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import { Stack, Text } from "@chakra-ui/react";

// Fix the default marker icon issue in React Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

const FullscreenControl = () => {          
  const map = useMap();
  useEffect(() => {
    map.addControl(new L.Control.Fullscreen());

    // Ensure the map is correctly sized on load and fullscreen toggle
    const handleResize = () => {
      setTimeout(() => {
        map.invalidateSize();
      }, 100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [map]);

  return null;
};

function LocationInMap({ coordinates }) {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, [mapRef.current, coordinates]);

  // If coordinates are empty, return null or a fallback UI
  if (!coordinates || coordinates.length === 0) {
    return (
      <Stack alignItems="center" w="100%" border="1px solid black">
        <Text>No coordinates to display movement on map.</Text>
      </Stack>
    );
  }

  return (
    <MapContainer center={coordinates[0]} zoom={13} style={{ height: "298px", width: "100%", borderRadius: "8px" }} ref={mapRef}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {coordinates.map((coord, index) => (
        <Marker key={index} position={coord}></Marker>
      ))}
      <Polyline positions={coordinates} color="blue" />
      <FullscreenControl />
    </MapContainer>
  );
}

export default LocationInMap;
