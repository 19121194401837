import React from "react";
import { Table } from "@agnext/reactlib";
import { Box, HStack, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { ViewReportIcon } from "components/Icons/Icons";
import { capitalizeString } from "utils/commonFunctions";
import { CmDetailsHeader } from "../Pages/Collateral Manager Table/variables/variable";
import WarehouseScore from "views/Dashboard/Analytics Dashboard/components/WarehouseScore";

export const PopupTable = ({ name, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Group data by originCode
  const groupByOriginCode = data?.reduce((acc, item) => {
    const originCode = item.originCode;
    if (acc[originCode]) acc[originCode].push(item);
    else acc[originCode] = [item];
    return acc;
  }, {});

  // Extract unique origin codes
  const originCodes = Object.keys(groupByOriginCode);

  // Merge and compute popup table statistics for each origin code
  const mergedObjects = originCodes?.map((originCode) => {
    const originName = capitalizeString(groupByOriginCode[originCode][0]?.originName?.toLocaleLowerCase() || "N.A");
    const inspectionCount = groupByOriginCode[originCode].length || 0;
    const bagCount = groupByOriginCode[originCode]?.reduce((sum, obj) => sum + parseInt(Number(obj.fundedBagsCount)), 0).toFixed(1);
    const quantity = groupByOriginCode[originCode]?.reduce((sum, obj) => sum + Number(obj.fundedNetWeight), 0).toFixed(1);
    const warehouseScore = groupByOriginCode[originCode]?.reduce((sum, item) => sum + parseFloat(item.warehouseScore || 0), 0) / (inspectionCount || 1);

    return {
      originName,
      originCode,
      inspectionCount,
      bagCount,
      quantity,
      warehouseScore: warehouseScore || "N.A",
    };
  });

  // Modify mergedObjects to include the WarehouseScore component for warehouseScore field
  let modifiedMergedObjects = mergedObjects?.map((obj) => {
    return {
      ...obj,
      warehouseScore: <WarehouseScore WHScore={obj.warehouseScore} />,
    };
  });

  return (
    <>
      <HStack>
        <ViewReportIcon onClick={onOpen} style={{ cursor: "pointer" }} />
        <Text>{capitalizeString(name.toLowerCase())}</Text>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent bg={"gray.50"}>
          <ModalHeader p={"15px"}>Warehouses</ModalHeader>
          <ModalCloseButton />
          <Box p={"15px"} bg={"#fff"} m={"12px"} borderRadius={"15px"}>
            <Table
              overflowY="auto"
              headerList={CmDetailsHeader}
              dataList={modifiedMergedObjects}
              fixedHeader={true}
              containerStyle={{
                width: "100%",
                height: data.length > 6 ? "400px" : "fitContent",
              }}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
