import { createSlice } from "@reduxjs/toolkit";
import { generateUUId } from "utils/utils";

export const initialState = {
  loading: false,
  workflowLoading: false,
  tableLoading: false,
  emailLoader: null,
  reportLoader: null,
  data: null,
  tableData: null,
  warehousesData: null,
  inspectionIdArr: [],
  analyticsData: null,
  inspectionCount: 0,
  downloading: false,
  downloadingSheet: false,
  error: null,
  emailLoader: null,
  reportLoader: null,
  fetchGeneratedReportMonthsLoading: false,
  generatedReportMonths: [],
  workflow: {},
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
  blockchainData: {},
  blockchainVerificationFailed: null,
  userData: null,
  avatarUrl: null,
  jobDetails: null,
  taskDetails: null,
  clientsOfCustomer: [],
  isHederaEnabled :false,
  filtersData : null
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchData(state, action) {
      state.loading = true;
    },
    fetchDataSuccess(state, action) {
      let requiredData = action?.payload?.payload || [];
      state.data = requiredData;
      state.inspectionIdArr = requiredData.map((obj) => {
        return obj.id;
      });
      state.inspectionCount = action.payload?.otherParams?.noOfItems;
      state.loading = false;
    },
    fetchDataFailure(state, action) {
      state.loading = false;
    },
    fetchAllData(state, action) {
      state.tableLoading = true;
    },
    fetchAllDataSuccess(state, action) {
      let requiredData = action?.payload || [];
      state.tableData = requiredData;
      state.tableLoading = false;
    },
    fetchAllDataFailure(state, action) {
      state.tableLoading = false;
    },
    fetchWarehouses(state, action) {
      state.loading = true;
    },
    fetchWarehousesSuccess(state, action) {
      state.warehousesData = action.payload;
      state.loading = false;
    },
    fetchWarehousesFailure(state, action) {
      state.loading = false;
    },
    viewReport(state, action) {
      state.reportLoader = true;
    },
    viewReportSuccess(state, action) {
      state.reportLoader = false;
    },
    viewReportFailure(state, action) {
      state.reportLoader = false;
    },
    downloadReport(state, action) {
      state.downloading = true;
    },
    downloadReportSuccess(state, action) {
      state.downloading = false;
    },
    downloadReportFailure(state, action) {
      state.downloading = false;
    },
    sendEmail(state, action) {
      state.emailLoader = true;
    },
    sendEmailSuccess(state, action) {
      state.emailLoader = false;
    },
    sendEmailFailure(state, action) {
      state.emailLoader = false;
    },
    downloadSummarySheet(state, action) {
      state.downloadingSheet = true;
    },
    downloadSummarySheetSuccess(state, action) {
      state.downloadingSheet = false;
    },
    downloadSummarySheetFailure(state, action) {
      state.downloadingSheet = false;
    },
    fetchGeneratedReportMonths(state, action) {
      state.fetchGeneratedReportMonthsLoading = true;
    },
    fetchGeneratedReportMonthsSuccess(state, action) {
      state.fetchGeneratedReportMonthsLoading = false;
      state.generatedReportMonths = action.payload;
    },
    fetchGeneratedReportMonthsFailure(state, action) {
      state.fetchGeneratedReportMonthsLoading = false;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.data = "";
      state.tableData = "";
      state.warehousesData = "";
      state.inspectionIdArr = "";
      state.addFilterDropdown = "";
      state.analyticsData = "";
      state.inspectionCount = 0;
      state.generatedReportMonths = "";
    },
    clearStateFailure(state, action) {},
    fetchWorkflowData: (state, action) => {
      state.loading = true;
      state.workflowLoading = true;
    },
    fetchWorkflowDataSuccess: (state, action) => {
      // let newWorkflow = action.payload.form;
      // const newWorkflow = Object.assign({}, action.payload.form);
      let newWorkflow = JSON.parse(JSON.stringify(action.payload.form));
      newWorkflow["inspectionId"] = generateUUId();
      state.workflow = [newWorkflow];
      state.sections = action.payload.sections;
      state.blocks = action.payload.blocks;
      state.composites = action.payload.composites;
      state.components = action.payload.components;
      state.loading = false;
      state.workflowLoading = false;
    },
    fetchWorkflowDataFailure: (state, action) => {
      state.loading = false;
      state.workflowLoading = false;    },
    fetchBlockchainData: (state, action) => {
      state.loading = true;
    },
    fetchBlockchainDataSuccess: (state, action) => {
      state.blockchainData = action.payload;
      state.blockchainVerificationFailed = false;
      state.loading = false;
    },
    fetchBlockChainDataFailure: (state, action) => {
      state.loading = false;
      state.blockchainVerificationFailed = true;
    },

    fetchClearStates: (state, action) => {},

    fetchClearStatesSuccess: (state, action) => {
      console.log("coming to clear");
      state.blockchainVerificationFailed = null;
      state.blockchainData = null;
      state.avatarUrl = null;
      state.userData = null;
      state.jobDetails = null;
      state.taskDetails = null;
      state.workflow = {};
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
    },
    fetchUserData: (state, action) => {
      state.loading = true;
    },
    fetchUserDataSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    },
    fetchUserDataFailure: (state, action) => {
      state.loading = false;
    },

    fetchAvatarURL: (state, action) => {
      state.loading = true;
    },
    getAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.avatarUrl = action.payload;
    },
    getAvatarURLFailure: (state, action) => {
      state.loading = false;
    },
    fetchJobDetailsRequest: (state, action) => {
      state.loading = true;
    },
    fetchJobDetailsSuccess: (state, action) => {
      state.loading = false;
      state.jobDetails = action.payload;
    },
    fetchJobDetailsFailure: (state, action) => {
      state.loading = false;
    },
    fetchTaskDetailsRequest: (state, action) => {
      state.loading = true;
    },
    fetchTaskDetailsSuccess: (state, action) => {
      state.loading = false;
      state.taskDetails = action.payload;
    },
    fetchTaskDetailsFailure: (state, action) => {
      state.loading = false;
    },
    fetchClientsOfCustomer: (state, action) => {
      state.loading = true;
    },
    fetchClientsOfCustomerSuccess: (state, action) => {
      state.loading = false;
      state.clientsOfCustomer = action.payload;
    },
    fetchClientsOfCustomerFailure: (state, action) => {
      state.loading = false;
    },

    checkIsHederaEnable:(state, action)=>{
    },
    checkIsHederaEnableSuccess:(state, action)=>{
      state.isHederaEnabled = action.payload?.hederaVerifiable
    },
    checkIsHederaEnableFailure:(state, action)=>{
      console.log(action.payload)
    },
    // for inspection filter data
    fetchInspectionFilterData: (state, action) => {
      state.loading = true;
    },
    fetchInspectionFilterDataSuccess: (state, action) => {
      state.filtersData = action.payload;
      state.loading = false;
    },
    fetchInspectionFilterDataFailure: (state, action) => {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardSlice;
