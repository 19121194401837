import React, { useEffect, useState } from "react";
import { actions, reducer, sliceKey } from "../slice";
import UploadFileModal from "../components/UploadFileModal";
import TemplateDownload from "../components/TemplateDownload";
import { useDisclosure } from "@chakra-ui/react";
import BulkUserUpload from "../components/BulkUserUpload";
import { bulkUploadSaga } from "../saga";
import * as selectors from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";

const allowedFieldsForCustomerUser = [
  "department",
  "designation",
  "first_name",
  "last_name",
  "gender",
  "email",
  "country_code",
  "mobile",
  "role",
  "address_line1",
  "address_line2",
  "city",
  "state",
  "country",
  "pin_code",
];

const requiredFieldsForCustomerUser = [
  "department",
  "designation",
  "first_name",
  "last_name",
  "gender",
  "email",
  "country_code",
  "mobile",
  "role",
  "address_line1",
  "city",
  "state",
  "country",
  "pin_code",
];

const allowedFieldsForClientUser = [
  "first_name",
  "last_name",
  "gender",
  "email",
  "country_code",
  "mobile",
  "role",
  "address_line1",
  "address_line2",
  "city",
  "state",
  "country",
  "pin_code",
];

const requiredFieldsForClientUser = [
  "first_name",
  "last_name",
  "gender",
  "email",
  "country_code",
  "mobile",
  "role",
  "address_line1",
  "city",
  "state",
  "country",
  "pin_code",
];

let customerUserPointers = ["Department,Designation,First Name,Last Name,Gender,Email,Country Code,Mobile,Role,Address Line 1,City,State,Country and Pin Code are required fields", "Email must be unique"];
let clientUserPointers = ["First Name,Last Name,Gender,Email,Country Code,Mobile,Role,Address Line 1,City,State,Country and Pin Code are required fields","Email must be unique"];

const UserBulkCreation = ({onOpen, isOpen, onClose, ownerEntityId }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: bulkUploadSaga });

  const dispatch = useDispatch();
  const clientsOfCustomer = useSelector(selectors.selectClientsOfCustomer);
  const clientAvatarUrl = useSelector(selectors.selectClientAvatar);

  const [selectedClient, setSelectedClient] = useState(null)
  const [userType, setUserType] = useState(null);
  const [clientId, setClientId] = useState(null);

  const handleSetUserType = (value) => {
    setUserType(value);
    setClientId(null);
  };
  const {
    isOpen: templateModalIsOpen,
    onOpen: openTemplateModal,
    onClose: closeTemplateModal,
  } = useDisclosure();
  const {
    isOpen: uploadFileModalIsOpen,
    onOpen: openUploadFileModal,
    onClose: closeUploadFileModal,
  } = useDisclosure();

  useEffect(() => {
    if (ownerEntityId) {
      dispatch(
        actions.fetchClientsOfCustomer({
          customerId: ownerEntityId,
        })
      );
    }
  }, [ownerEntityId]);

  useEffect(() => {
   if(clientId){
      const client = clientsOfCustomer?.find((data) => data.clientId === clientId)

      if (client?.avatar) {
        const avatar = client.avatar;
        dispatch(actions.fetchAvatarURL({ payload: avatar }));
      }
      setSelectedClient(client);
   }
   return ()=>{
    dispatch(actions.clearState());
   }
  }, [clientId]);

  // useEffect(()=>{
  //   return ()=>{
  //     setSelectedClient(null)
  //     setUserType(null)
  //     setClientId(null)
  //   }
  // },[])

const handleUserTemplate= ()=> {
 if(userType == "My Users")
  window.open(`https://docs.google.com/spreadsheets/d/1OwnpCfHrtT2cyjvOEj1_gAJgUAXyZuMN11CSp2AmrT0/copy?gid=0#gid=0`)
 else
  window.open(`https://docs.google.com/spreadsheets/d/1ornGqUTFWg3N6yfdGBhh769tMGVa4_vukz3LJZFYa_w/copy?gid=0#gid=0`)
}

  return (
    <>
      <BulkUserUpload
        isOpenBulkUserModal={isOpen}
        onCloseBulkUserModal={onClose}
        openDownloadTemplateModal={openTemplateModal}
        userType={userType}
        handleSetUserType={handleSetUserType}
        clientsOfCustomer={clientsOfCustomer}
        clientId={clientId}
        setClientId={setClientId}
      />
      <TemplateDownload
        title={"Bulk User Upload"}
        isOpen={templateModalIsOpen}
        onClose={closeTemplateModal}
        onOpenBulkUserModal={onOpen}
        openUploadFileModal={openUploadFileModal}
        closeTemplateDownload={closeTemplateModal}
        userType={userType}
        avatar={clientAvatarUrl?.url}
        setUserType={setUserType}
        setClientId={setClientId}
        handleClick={handleUserTemplate}
        pointers={(userType=="My Users")? customerUserPointers : clientUserPointers}
        manager={(userType=="My Users")? null : "Client"}
        details={(userType=="My Users")? null : {orgName :selectedClient?.clientName }}
      />
      <UploadFileModal
        title={"Bulk User Upload"}
        isOpen={uploadFileModalIsOpen}
        openTemplateDownload={openTemplateModal}
        onClose={closeUploadFileModal}
        onOpen= {openUploadFileModal}
        customerId={ownerEntityId}
        clientId={clientId}
        userType={userType}
        setUserType={setUserType}
        setClientId={setClientId}
        avatar={clientAvatarUrl?.url}
        allowedFields={
          clientId ? allowedFieldsForClientUser : allowedFieldsForCustomerUser
        }
        requiredFields={
          clientId ? requiredFieldsForClientUser : requiredFieldsForCustomerUser
        }
        manager={(userType=="My Users")? null : "Client"}
        details={(userType=="My Users")? null : {orgName :selectedClient?.clientName }}
      />
    </>
  );
};

export default UserBulkCreation;
