import JSONExport from "../JSONExport";
import * as APIs from "../../../../services";

export default function FormExport({ formId }) {
  const handleFetchFormData = () => {
    return APIs.fetchFormData({ id: formId });
  };

  return <JSONExport fetchData={handleFetchFormData} name="form" />;
}
