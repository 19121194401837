import React, { useState, useEffect } from "react";
import { Stack, Box, Radio, RadioGroup } from "@chakra-ui/react";

export default function SingleSelect({
  data, //getElement(sectionId)
  handleFormDataChange,
  formData,
  displayData, //Section
  navIndex,
  setNavIndex,
  setPage,
  setSkippedPages,
  prevPages,
  page,
  repeatable,
  index,
}) {
  if (repeatable?.isRepeatable) {
    const minEntries = data.element.repeatable?.minEntries;

    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: "",
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: "",
      });
    }

    const [values, setValues] = useState(
      formData[data.element.id]?.attachments.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => ({
              order: index + 1,
              value,
            })),
          ]
        : initialValues
    );

    useEffect(() => {
      if (formData[data.element.id]?.attachments.length) {
        setValues(
          formData[data.element.id]?.attachments.map((value, index) => ({
            order: index + 1,
            value,
          }))
        );
      } else {
        values.forEach((value) => {
          // handleFormDataChange(
          //   { target: { name: data.element.id, value: value.value } },
          //   "repeatable",
          //   value.order
          // );
        });
      }
    }, [formData]);

    const value = values[index];
    return (
      <Stack>
        <RadioGroup
          defaultValue={
            formData[data.element.id]?.value ||
            (data.element.default && data.element.default.value)
          }
          name={data.element.id}
          value={value?.value}
          onChange={(val) => {
            handleFormDataChange(
              val,
              "repeatableDD",
              data.element.id,
              index + 1
            );
          }}
        >
          {data &&
          data.element &&
          data.element.properties &&
          data.element.properties.options ? (
            data.element.properties.options.map((option) => {
              return (
                <Box
                  style={{
                    border: "1px solid #EDF2F7",
                    borderRadius: "8px",
                    padding: "12px",
                    margin: "8px",
                  }}
                  key={option.value}
                >
                  <Radio
                    size="md"
                    colorScheme="login"
                    isChecked
                    value={option.value}
                  >
                    {option.value}
                  </Radio>
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </RadioGroup>
      </Stack>
    );
  }
  useEffect(() => {
    let sectionId = "";
    let newPageNumber = navIndex;
    const value = formData[data.element.id]?.value;
    if (data.element?.properties?.navigations?.length) {
      data.element.properties.navigations.forEach((navOption) => {
        if (value === navOption.value) {
          sectionId = navOption.sectionId;
        }
      });
    }

    displayData?.forEach((pageData) => {
      const pageId = pageData.pageId;
      if (pageId === sectionId) {
        newPageNumber = pageData.pageNumber - 1;
      }
      if (navIndex !== newPageNumber) {
        setNavIndex(newPageNumber);
        setSkippedPages((skippedPages) => {
          return {
            from: page + 1,
            to: newPageNumber + 1,
          };
        });
      }
    });
  }, [formData, data, displayData, setNavIndex, navIndex]);

  useEffect(() => {
    if (
      !data?.element?.properties?.navigations &&
      data.element?.default &&
      data.element.default?.value &&
      !formData[data.element.id]?.value
    ) {
      handleFormDataChange(data.element.default.value, null, data.element.id);
    }
  }, [formData]);

  return (
    <Stack>
      <RadioGroup
        defaultValue={
          data?.element?.properties?.navigations
            ? null
            : formData[data.element.id]?.value ||
              (data.element.default && data.element.default.value)
        }
        name={data.element.id}
        value={formData[data.element.id]?.value}
        onChange={(val) => {
          handleFormDataChange(val, null, data.element.id);
        }}
      >
        {data &&
        data.element &&
        data.element.properties &&
        data.element.properties.options ? (
          data.element.properties.options.map((option) => {
            return (
              <Box
                style={{
                  border: "1px solid #EDF2F7",
                  borderRadius: "8px",
                  padding: "12px",
                  margin: "8px",
                }}
                key={option.value}
              >
                <Radio
                  size="md"
                  colorScheme="login"
                  isChecked
                  value={option.value}
                >
                  {option.value}
                </Radio>
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </RadioGroup>
    </Stack>
  );
}
