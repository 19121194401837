import React from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function BarChartWidget({ data }) {
  const { chartData, title, dataKeys } = data;
  return (
    <Box
      w="100%"
      h="450px"
      sx={{
        border: "1px solid #E2E8F0",
        boxShadow: "0px 3.499999761581421px 5.5px rgba(0, 0, 0, 0.02)",
        background: "#fff",
        borderRadius: "15px",
        padding: "20px",
      }}
    >
      <Text
        sx={{
          color: "#7E8DA2",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "150%",
        }}
      >
        {title}
      </Text>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="top"
            // wrapperStyle={{ lineHeight: "40px", top: 0, right: 25 }}
          />
          <ReferenceLine y={0} stroke="#000" />
          {/* <ReferenceLine x={0} stroke="#000" /> */}

          <Bar dataKey={dataKeys.key1} fill="#ffb783" />
          <Bar dataKey={dataKeys.key2} fill="#39aea3" />
          <Bar dataKey={dataKeys.key3} fill="#ff6d6d" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
