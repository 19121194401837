import { Box } from "@chakra-ui/react";
import React from "react";
const styles = {
  padding: "20px",
  color: "#4A5568",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
};
function InfoBox({ text }) {
  return <Box sx={styles}>{text}</Box>;
}

export default InfoBox;
