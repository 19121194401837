import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import geoJSONData from "../../assets/geojson/india.json";
import { Box, Button, Text, Icon, HStack } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { scaleLinear } from "d3-scale";
import "./MapChart.css";

const MapChart = ({ regionData, selectedGeoOption, tooltipData, isMobile }) => {
  const [zoom, setZoom] = useState({
    coordinates: [80.9629, 20.5937],
    zoom: isMobile ? 10 : 4,
  });
  const [stateContent, setStateContent] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({});

  // Step 2: Find the minimum and maximum quantity values
  const minQuantity = Math.min(
    ...regionData.map((item) => item[selectedGeoOption])
  );
  const maxQuantity = Math.max(
    ...regionData.map((item) => item[selectedGeoOption])
  );

  // Step 3: Create a color scale based on the minimum and maximum values
  const minColor = "#4EC3B9";
  const maxColor = "#1B776F";

  const handleZoomIn = () => {
    if (zoom.zoom >= 20) return;
    setZoom((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (zoom.zoom <= 4) return;
    setZoom((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  };

  const showTooltip = (geo, event) => {
    event.preventDefault();
    const { st_nm } = geo.properties;
    const { clientX, clientY } = event;
    const regionDataItem = regionData.find((item) => item.state === st_nm);
    if (regionDataItem) {
      const updatedRegionDataItem = { ...regionDataItem, state: st_nm };
      setTooltipContent(updatedRegionDataItem);
      setTooltipPosition({
        left: clientX - 280,
        top: clientY - 100,
        position: "absolute",
      });
    }
  };

  const hideTooltip = () => {
    setTooltipContent("");
  };

  const getColorForRegion = (region) => {
    const regionName = region.properties.st_nm;

    const regionDataItem = regionData.find((item) => item.state === regionName);
    if (regionDataItem) {
      const quantity = regionDataItem[selectedGeoOption];
      const colorScale = scaleLinear()
        .domain([minQuantity, maxQuantity])
        .range([minColor, maxColor]);
      return colorScale(quantity);
    }
    return "#F8F8F8";
  };

  const countDecimalAndNumberLength = (maxInput) => {
    // Use a regular expression to match decimal and number characters
    const inputString = String(maxInput);
    const regex = /[0-9.]/g;
    const matches = inputString.match(regex);

    // Calculate the total length of decimal and number characters
    const totalLength = matches ? matches.length : 0;
    return totalLength == 0 ? "-55px" : -12 * totalLength + "px";
  };
  const handleMoveEnd = (position) => {
    setZoom(position);
  };
  const getUnit = () => {
    const unitObj = tooltipData.filter((obj) => {
      if (obj.keyText === selectedGeoOption) {
        return obj;
      }
    });
    return unitObj.length && unitObj[0].hasOwnProperty("unit")
      ? unitObj[0].unit
      : "";
  };
  return (
    <Box w="100%" data-tip="" position="relative">
      <ComposableMap
        width={800}
        height={isMobile ? 1000 : 400}
        projection="geoMercator"
      >
        <ZoomableGroup
          center={zoom.coordinates}
          zoom={zoom.zoom}
          onMoveEnd={handleMoveEnd}
          minZoom={4}
          maxZoom={40}
        >
          <Geographies geography={geoJSONData}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(event) => showTooltip(geo, event)}
                  onMouseLeave={hideTooltip}
                  onMouseDown={(e) => e.preventDefault()}
                  style={{
                    default: {
                      fill: getColorForRegion(geo),
                      stroke: "#D0D5DD",
                      outline: "none",
                      strokeWidth: 0.2,
                    },
                    hover: {
                      fill: getColorForRegion(geo),
                      stroke: "#D0D5DD",
                      outline: "none",
                      strokeWidth: 0.2,
                    },
                    pressed: {
                      fill: getColorForRegion(geo),
                      stroke: "#D0D5DD",
                      outline: "none",
                      strokeWidth: 0.2,
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {tooltipContent && tooltipData && (
        <Box
          className="tooltip"
          left={tooltipPosition.left}
          top={tooltipPosition.top}
        >
          <Text w={"100%"}>
            <Icon as={FaMapMarkerAlt} boxSize={3} color="#D0D5DD" />
            <span className="tooltipHeading">{tooltipContent.state}</span>
          </Text>
          {/* <Text w={"100%"} className="rowMap">
            <span className="textLeftContent">Portfolio Size</span>{" "}
            <span className="textRightContent">
              Rs{tooltipContent.portfolioSize}
            </span>
          </Text> */}
          {tooltipData.map((data) => {
            return (
              <Text w={"100%"} className="rowMap">
                <span className="textLeftContent">{data.label}</span>
                <span className="textRightContent">
                  {`${tooltipContent[data.keyText]}` +
                    (data.keyText1 ? `/${tooltipContent[data.keyText1]}` : "") +
                    (data?.unit ? data?.unit : "")}
                </span>
              </Text>
            );
          })}
        </Box>
      )}
      <Box className="color-gradient">
        <HStack>
          w="fit-content"
          <Text className="bar-text">{minQuantity + getUnit()}</Text>
          <Box className="gradient-bar-container">
            <div className="color-gradient-bar"></div>
          </Box>
          <Text className="bar-text">{maxQuantity + getUnit()}</Text>
        </HStack>
      </Box>
      <Box>
        <Button
          className="buttonStyle"
          size="sm"
          onClick={handleZoomIn}
          bottom="70px"
          position="absolute"
        >
          +
        </Button>
        <Button
          className="buttonStyle"
          size="sm"
          onClick={handleZoomOut}
          position="absolute"
        >
          -
        </Button>
      </Box>
    </Box>
  );
};

export default MapChart;
