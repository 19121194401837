import { Box, Flex, Image } from "@chakra-ui/react";
import DropdownWithCheckboxesLatest from "components/DropdownWithCheckboxes/DropdownWithCheckBoxesLatest";
import { SearchBar } from "views/RoleAndPermission/components/SearchBar/SearchBar";

import Seperator from "assets/svg/seperator.svg";
import AddFilter from "../AddFilter";
import { useCallback, useState } from "react";
import Select from "../Select";

const style = {
  backgroundColor: "white",
  borderRadius: "15px",
  gap: "16px",
  padding: "16px",
  justifyContent: "space-between",
  boxShadow: "0px 3.49px 5.5px 0px rgba(0, 0, 0, 0.02)",
};

// const clients = [
//   { value: "Kotak", id: "e09caa08-c6e1-11ee-9bf1-325096b39f47" },
//   { value: "HDFC", id: "e09cadc8-c6e1-11ee-adcf-325096b39f47" },
// ];

// const assignees = [
//   {
//     value: "amit singh",
//     id: "e09caa08-c6e1-11ee-9bf1-325096b39f47",
//     email: "amitsingh@agnext.in",
//   },
//   {
//     value: "rahul punia",
//     id: "e09cadc8-c6e1-11ee-adcf-325096b39f47",
//     email: "rahulpunia@agnext.in",
//   },
// ];

const statusList = [
  { value: "To Do", id: "todo" },
  { value: "In Progress", id: "inprogress" },
  { value: "In Review", id: "review-inprogress" },
  { value: "Done", id: "done" },
  { value: "Draft", id: "draft" },
  { value: "Not Required", id: "not-required" },
  { value: "Approved", id: "approved" },
  // { value: "Deleted", id: "deleted" },
];

const AllJobsFilterBar = ({
  filters,
  setFilters,
  handleOptionChange,
  assignees,
  clients,
  handleClearFilters,
  locations,
  selectedLocations,
  setSelectedLocations,
  tempFilters,
  handleApplyButtonClick,
  tempLocations,
  setTempLocations,
  handleApplyButton,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const getClientName = useCallback(
    (id) => {
      const client =
        clients.length && clients?.find((client) => client.id === id);
      return client ? client.value : "";
    },
    [clients]
  );
  const getAssigneeName = useCallback(
    (id) => {
      const assignee =
        assignees.length && assignees?.find((assignee) => assignee.id === id);
      return assignee ? assignee.value : "";
    },
    [assignees]
  );

  const getStatus = useCallback(
    (id) => {
      const status = statusList.find((status) => status.id === id);
      return status ? status.value : "";
    },
    [statusList]
  );

  return (
    <Flex sx={style}>
      {/* <Box flex={"1"}>
        <SearchBar />
      </Box> */}
      <Box flex={"1"}>
        <Select
          bg="#F7FAFC"
          placeholder="Client Name"
          customKey="client"
          options={clients}
          handleOptionChange={handleOptionChange}
          value={getClientName(filters.client)}
        />
      </Box>
      <Box flex={"1"}>
        <Select
          bg="#F7FAFC"
          placeholder="Assignee"
          customKey="assignee"
          options={assignees}
          handleOptionChange={handleOptionChange}
          value={getAssigneeName(filters.assignee)}
        />
      </Box>
      <Box flex={"1"}>
        <Select
          options={statusList}
          placeholder="Status"
          handleOptionChange={handleOptionChange}
          customKey="status"
          bg="#F7FAFC"
          value={getStatus(filters.status)}
        />
      </Box>

      <Flex flexDirection="row" gap="16px">
        <Box>
          <Image src={Seperator} />
        </Box>
        <AddFilter
          filters={filters}
          isMobile={isMobile}
          statusList={statusList}
          handleOptionChange={handleOptionChange}
          handleClearFilters={handleClearFilters}
          locations={locations}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          tempFilters={tempFilters}
          handleApplyButtonClick={handleApplyButtonClick}
          tempLocations={tempLocations}
          setTempLocations={setTempLocations}
          handleApplyButton={handleApplyButton}
        />
      </Flex>
    </Flex>
  );
};

export default AllJobsFilterBar;
