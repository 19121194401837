import { Table } from "@agnext/reactlib";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Box, HStack, VStack, Text, Tooltip } from "@chakra-ui/react";
import { format, sub } from "date-fns";
import * as selectors from "../../selectors";
import { actions, reducer, sliceKey } from "../../slice";
import { collateralManagerSaga } from "../../saga";
import WarehouseScore from "views/Dashboard/Analytics Dashboard/components/WarehouseScore";
import { CmHeader } from "./variables/variable";
import { capitalizeString } from "utils/commonFunctions";
import { PopupTable } from "../../components/PopupTable";

const CmTable = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: collateralManagerSaga });

  const [selectedDateType, setSelectedDateType] = useState("all");
  const [date, setDate] = useState({ fromDate: null, toDate: null });

  const { fromDate, toDate } = date;
  const dispatch = useDispatch();
  const handleDateChange = (fromDate, toDate) => {
    setDate({ fromDate, toDate });
  };

  const inspectionData = useSelector(selectors.selectInspectionData) || [];

  // Helper function to convert and handle numbers safely
  const numHandler = (num) => (isNaN(Number(num)) ? 0 : Number(num));

  // Group inspection data by collateral manager name
  const groupByCmName =
    inspectionData?.reduce((acc, item) => {
      const cmName = capitalizeString(item.cmName?.toLowerCase());
      if (acc[cmName]) acc[cmName].push(item);
      else acc[cmName] = [item];
      return acc;
    }, {}) || {};

  // Convert grouped data into an array of results
  const data = Object.values(groupByCmName);

  // Compute aggregated results for each collateral manager

  const resultArray = data.map((arrayOfObjects) => {
    const firstObject = arrayOfObjects[0] || [];
    const uniqueWarehouses = Array.from(new Set(arrayOfObjects.map((obj) => obj.originCode)));


    // Create a map of origin codes to their respective warehouse scores
    const originScoreMap = arrayOfObjects.reduce((acc, item) => {
      if (acc[item.originCode]) acc[item.originCode].push(item.warehouseScore);
      else acc[item.originCode] = [item.warehouseScore];
      return acc;
    }, {});

    // Calculate average score for each origin code
    const originAvgScore = Object.values(originScoreMap)?.map((item) => item?.reduce((sum, it) => sum + parseFloat(numHandler(it)) / item.length, 0));

    // Calculate overall average score
    const avgScore = originAvgScore?.reduce((sum, it) => sum + it, 0) / originAvgScore.length;

    // Create result object for each collateral manager
    const resultObject = {
      cmName: capitalizeString(firstObject.cmName?.toLowerCase()),
      warehouseCount: uniqueWarehouses.length,
      avgScore: isNaN(Number(avgScore)) ? 0 : avgScore?.toFixed(1),
      inspectionCount: arrayOfObjects.length,
      quantity: arrayOfObjects.reduce((sum, obj) => sum + obj.fundedNetWeight, 0).toFixed(2),
      bagCount: arrayOfObjects.reduce((sum, obj) => sum + parseInt(Number(obj.fundedBagsCount)), 0),
    };
    return resultObject;
  });

  // Filter out results with null or undefined collateral manager names
  let filteredArray = resultArray?.filter((item) => item.cmName !== null && item.cmName !== undefined && item.cmName !== "");
  
  // Sort the filtered results by average score in descending order
  const sortedData = filteredArray?.sort((b, a) => a.avgScore - b.avgScore);

  // Prepare data for the table, including custom components for certain fields
  const dataForTable = sortedData?.map((item) => {
    return {
      ...item,
      cmName: <PopupTable name={item.cmName} data={groupByCmName[item.cmName]} />,
      avgScore: <WarehouseScore WHScore={item?.avgScore} />,
    };
  });

  // Function to handle date type selection and update date range accordingly
  const handleDateType = (type) => {
    setSelectedDateType(type);
  };

  const styles = {
    select: {
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      background: "#F7FAFC",
      display: "flex",
      height: "32px",
      padding: " 6px 8px 6px 12px",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      gap: "12px",
      color: "#4A5568",
      fontSize: "12.5px",
    },
    selectOpt: {
      color: "#2D3748",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
    heading: {
      color: "#7E8DA2",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
    },
    headerBtn: {
      padding: "0px 12px",
      justifyContent: "center",
      height: "32px",
      alignItems: "center",
      border: "1px solid #EDF2F7",
      color: "#4A5568",
      fontFamilpy: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      cursor: "pointer",
    },
  };

  // Function to set the date range based on the selected duration
  function setDates(duration) {
    const today = new Date();
    let fromDate, toDate;
    switch (duration) {
      case "day":
        fromDate = toDate = sub(today, { days: 1 });
        break;
      case "week":
        fromDate = sub(today, { days: 7 });
        toDate = today;
        break;
      case "month":
        fromDate = sub(today, { days: 30 });
        toDate = today;
        break;
      case "quarter":
        fromDate = sub(today, { months: 3 });
        toDate = today;
        break;
      case "year":
        fromDate = sub(today, { months: 12 });
        toDate = today;
        break;
      case "all":
        fromDate = null;
        toDate = null;
        break;
      default:
        break;
    }

    handleDateChange(fromDate, toDate);
  }


  useEffect(() => {
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      status: ["approved"],
    };

    dispatch(actions.fetchInspectionData({ payload: forQueryParams }));

  }, [date]);

  return (
    <Box mt={"75px"} w={"100%"} bg={"#fff"} borderRadius={"15px"}>
      <VStack w={"100%"}>
        <HStack justifyContent={"space-between"} w={"100%"} p={"15px"}>
          <Text sx={styles.heading}>Collateral Manager</Text>
          <HStack gap={"0px"}>
            <Box
              sx={styles.headerBtn}
              borderBottomLeftRadius="8px"
              borderTopLeftRadius="8px"
              as="button"
              backgroundColor={selectedDateType === "day" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("day");
                setDates("day");
              }}
            >
              <Tooltip label="Yesterday" hasArrow>
                D
              </Tooltip>
            </Box>
            <Box
              sx={{ ...styles.headerBtn }}
              as="button"
              backgroundColor={selectedDateType === "month" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("month");
                setDates("month");
              }}
            >
              <Tooltip label="Last Month" hasArrow>
                M
              </Tooltip>
            </Box>
            <Box
              sx={{ ...styles.headerBtn }}
              as="button"
              backgroundColor={selectedDateType === "quarter" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("quarter");
                setDates("quarter");
              }}
            >
              <Tooltip label="Last Quater" hasArrow>
                Q
              </Tooltip>
            </Box>
            <Box
              sx={{ ...styles.headerBtn }}
              as="button"
              backgroundColor={selectedDateType === "year" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("year");
                setDates("year");
              }}
            >
              <Tooltip label="Last Year" hasArrow>
                Y
              </Tooltip>
            </Box>
            <Box
              sx={{ ...styles.headerBtn }}
              as="button"
              borderBottomRightRadius="8px"
              borderTopRightRadius="8px"
              backgroundColor={selectedDateType === "all" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("all");
                setDates("all");
              }}
            >
              All Time
            </Box>
          </HStack>
        </HStack>

        <Table overflowY="auto" headerList={CmHeader} dataList={dataForTable} fixedHeader={true} containerStyle={{ width: "100%" }} />

      </VStack>
    </Box>
  );
};

export default CmTable;
