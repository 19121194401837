import React, { useState, useEffect } from "react";
import { Stack, Box, Radio, RadioGroup } from "@chakra-ui/react";

export default function SingleSelect2({
  data, //getElement(sectionId)
  handleFormDataChange,
  formData,
  displayData,
}) {
  useEffect(() => {
    let currPage;
    let navPage;
    let currentSectionId = data.sectionId;
    let navSectionId;
    const value = formData[data.element.id]?.value;
    if (data.element.properties.navigations && value !== "" && value === "No") {
      data.element.properties.navigations.forEach((navOption) => {
        if (value === navOption.value) {
          navSectionId = navOption.sectionId;
        }
      });
    }

    displayData?.forEach((pageData) => {
      pageData.sections.forEach((section) => {
        if (navSectionId !== undefined && value !== "") {
          if (section.id === currentSectionId) {
            currPage = pageData.pageNumber - 1;
          }
          if (section.id === navSectionId) {
            navPage = pageData.pageNumber - 1;
          }
        }
      });
    });
  }, [displayData]);
  useEffect(() => {
    if (
      !data.element.properties.navigations &&
      data.element.default &&
      data.element.default.value
    ) {
      handleFormDataChange(data.element.default.value, null, data.element.id);
    }
  }, []);

  return (
    <Stack>
      <RadioGroup
        defaultValue={
          data.element.properties.navigations
            ? null
            : data.element.default && data.element.default.value
        }
        name={data.element.id}
        value={formData[data.element.id]?.value}
        onChange={(val) => {
          handleFormDataChange(val, null, data.element.id);
        }}
      >
        {data ? (
          data.element.properties.options.map((option) => {
            return (
              <Box
                style={{
                  border: "1px solid #EDF2F7",
                  borderRadius: "8px",
                  padding: "12px",
                  margin: "8px",
                }}
                key={option.value}
              >
                <Radio size="md" colorScheme="login" value={option.value}>
                  {option.value}
                </Radio>
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </RadioGroup>
    </Stack>
  );
}
