import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddClientTop from "../components/addClient/AddClientTop";
import AddClientLocation from "../components/addClient/AddClientLocation";
import React, { useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../slice";
import { watchClientSaga } from "../saga";
import { useHistory } from "react-router-dom";
import { fetchSasurl, uploadFile } from "services/index";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import { generateIdSync } from "utils";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
// import { useHistory } from "react-router-dom";

const AddClient = ({
  isEditing,
  oldClientDetails,
  oldClientAddress,
  clientId,
  // customerId,
  oldLinks,
  clientAddress,
  oldLocations,
  refreshLocations,
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchClientSaga });
  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const customerId = entity?.ownerEntityId;

  const dispatch = useDispatch();
  const [isChanged, setIsChanged] = useState(false);

  const avatarUrl = useSelector(selectors.selectAvatarUrl);
  const locations = useSelector(selectors.selectLocation);

  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [isAddressDeleted, setIsAddressDeleted] = useState(false); // This is not getting use

  const [clientDetails, setClientDetails] = useState({
    country: "",
    orgName: "",
    avatar: "",
    businessType: "",
  });
  const [locationArray, setLocationArray] = useState([]);
  const [locationCompareArray, setLocationCompareArray] = useState([]);

  const [links, setLinks] = useState([
    { value: null, type: "email", countryCode: null },
    {
      value: null,
      type: "phone",
      countryCode: "",
    },
  ]);

  const [registeredAddress, setRegisteredAddress] = useState({
    id: generateIdSync(),
    address: "",
    addressLine2: null,
    pinCode: null,
    city: "",
    state: "",
    country: "",
    isRegistered: true,
    isActive: true,
  });

  const [tempAddress, setTempAddress] = useState({
    address: "",
    addressLine2: null,
    pinCode: null,
    city: "",
    state: "",
  });

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [pinCodeIsValid, setPinCodeIsValid] = useState(true);

  useEffect(() => {
    if (isEditing && oldLocations) {
      let tempArr = oldLocations.map((location) => {
        return {
          id: location?.id,
          name: location?.name || "",
          externalCode: location?.externalCode,
          area: null,
          additionalProperties: {},
          city: location?.city || "",
          contactPerson: location?.contactPerson || "",
          originType: location?.originType || "",
          addressLine1: location?.address.addressLine1 || "",
          addressLine2: location?.address.addressLine2 || "",
          postalCode: location?.postalCode || "",
          state: location?.state || "",
          links: location?.links?.length
          ? (
              location?.links?.length === 1 
                ? (
                    location?.links[0].type === "phone"
                      ? [...location.links, { value: null, type: "email", countryCode: null }]
                      : [{ value: null, type: "phone", countryCode: "" }, ...location.links]
                  )
                : location?.links
            )
          : [
              { value: null, type: "phone", countryCode: "" },
              { value: null, type: "email", countryCode: null },
            ],
          isEditing: false,
          isFromDB: true,
          isChanged: false,
          status: location?.status || "",
          country: location?.country || "",
        };
      });

      setLocationArray(tempArr);
      setLocationCompareArray(tempArr);
    }
  }, [oldLocations]);

  useEffect(() => {
    if (isEditing) {
      if (oldClientDetails) {
        let avatar = oldClientDetails?.avatar;
        avatar && dispatch(actions.fetchAvatarURL({ payload: avatar }));

        setClientDetails((prevValues) => {
          let newValues = {
            orgName: oldClientDetails?.name,
            avatar: oldClientDetails?.avatar,
            businessType: oldClientDetails?.businessType,
            country: oldClientDetails?.country,
          };
          return { ...prevValues, ...newValues };
        });
      }
      if (clientAddress && clientAddress?.length === 0) {
        setIsAddressDeleted(true);
      }
      if (clientAddress && clientAddress?.length) {
        // if (clientAddress?.country) {
        //   console.log(clientAddress);
        //   setClientDetails((prevValues) => {
        //     return { ...prevValues, country: clientAddress.country };
        //   });
        // }

        let newData = {
          id: generateIdSync(),
          address: clientAddress[0].addressInfo?.addressLine1,
          addressLine2: clientAddress[0].addressInfo?.addressLine2,
          pinCode: clientAddress[0].postalCode,
          city: clientAddress[0]?.city,
          state: clientAddress[0]?.state,
          isRegistered: true,
          isActive: true,
          country: clientAddress[0]?.country,
        };
        setRegisteredAddress(newData);
        setTempAddress(newData);
        // console.log("edit", isEditing);
        dispatch(
          actions.fetchLocationData({
            id: newData?.id,
            country: newData?.country,
            state: newData?.state,
            city: newData?.city,
          })
        );
      }
    }
  }, [oldClientDetails, clientAddress]);

  useEffect(() => {
    if (oldLinks?.length > 0) {
      let newLinks = oldLinks.map((oldLink) => {
        let type = oldLink.linkType;
        return {
          value: oldLink.value,
          type,
          countryCode: type === "email" ? "" : oldLink.countryCode,
        };
      });
      if (!newLinks.some((link) => link.type === "email")) {
        newLinks.push({ value: null, type: "email", countryCode: "" });
      }
      if (!newLinks.some((link) => link.type === "phone")) {
        newLinks.push({ value: null, type: "phone", countryCode: "" });
      }
      setLinks(newLinks);
    }
  }, [oldLinks]);

  useEffect(() => {
    if (avatarUrl != null && isEditing === true) {
      setProfileImagePreview(avatarUrl?.url);
    }
  }, [avatarUrl]);

  const isAddAdressDetailsMissing = Object.entries(registeredAddress).some(
    ([key, value]) => {
      if (
        key === "country" ||
        key === "addressLine2" ||
        (clientDetails?.country === "UAE" && key === "pinCode")
      ) {
        return false;
      }
      return value === "";
    }
  );

  const handleInputChange = (field, value) => {
    if (!isChanged) setIsChanged(true);
    setClientDetails((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleRegisteredAddress = (field, value) => {
    if (!isChanged) setIsChanged(true);
    setRegisteredAddress({
      ...registeredAddress,
      [field]: value,
    });
  };
  const handleLinksChange = (check, value, countryCode) => {
    if (!isChanged) setIsChanged(true);
    const newLinks = links.map((link) => {
      if (link.type === check) {
        return { ...link, value: value?.trim() === "" ? null : value };
      }
      return link;
    });
    setLinks(newLinks);
  };

  // console.log("links-->", links);

  const handleFileChange = async (event) => {
    if (!isChanged) setIsChanged(true);
    try {
      const file = event.target.files[0];

      if (file) {
        setProfileImagePreview(URL.createObjectURL(file));

        const fileName = file.name;
        let sasURLData = await fetchSasurl({
          fileName,
        });

        const sasURL = sasURLData && sasURLData.data.payload.url;

        const uploadResponse = await uploadFile({ file, sasURL });

        handleInputChange("avatar", fileName);
      }
    } catch (error) {
      console.error("Error handling file change:", error);
    }
  };

  const handleAddressCreation = (id, customerId) => {
    const address = [registeredAddress];
    dispatch(
      actions.postClientAddressRequest({
        id,
        customerId,
        address,
        onSuccess: (status) => {
          if (status) {
            toast({
              title: "Client Added Successfully",
              status: "success",
              duration: 1000,
              isClosable: true,
            });
            history.goBack();
          } else {
            toast({
              title: "Something went wrong",
              status: "error",
              duration: 1000,
              isClosable: true,
            });
            setTimeout(() => {
              history.goBack();
            }, [500]);
          }
        },
      })
    );
  };

  const handleLocationCreation = (id, customerId) => {
    if (locationArray && locationArray.length) {
      let payload = [];
      locationArray.forEach((location) => {
        if (!location.isFromDB) {
          let manager = { id, type: "client" };
          let tempLocation = { ...location };
          delete tempLocation.id;
          delete tempLocation.isFromDB;
          delete tempLocation.isEditing;
          payload.push({
            ...tempLocation,
            country: clientDetails.country,
            managers: [manager],
          });
        }
      });
      if (payload) dispatch(actions.fetchPostMultipleClientLocation(payload));
    }
  };
  const handleAddClient = () => {
    const clientPayload = { ...clientDetails, links };
    dispatch(
      actions.postClientDataRequest({
        customerId,
        clientPayload,
        onSuccess: (id) => {
          if (id) {
            if (registeredAddress.address)
              handleAddressCreation(id, customerId);
            // if (locationArray.length) handleLocationCreation(id, customerId);
            toast({
              title: "Client Added Successfully",
              status: "success",
              duration: 1000,
              isClosable: true,
            });
            history.goBack();
          } else {
            toast({
              title: "Something went wrong",
              status: "error",
              duration: 1000,
              isClosable: true,
            });
          }
        },
      })
    );
  };

  const handleUpdateClient = () => {
    let clientPayload = { ...clientDetails };

    if (oldLinks?.length > 0) {
      const oldLinksData = oldLinks;
      const newLinks = links.map((link) => {
        let type = link.type;

        let oldLink = oldLinksData.find((temp) => temp.linkType === type);

        let clientLinkId = oldLink ? oldLink.clientLinkId : null;
        let linkId = oldLink ? oldLink.linkId : null;

        return clientLinkId && linkId && type === "phone"
          ? {
              linkType: link.type,
              value: link.value,
              countryCode: link.countryCode,
              clientLinkId,
              linkId,
              country: clientDetails.country,
            }
          : { ...link, clientLinkId, linkId };
      });
      clientPayload = { ...clientPayload, links: newLinks };
    } else {
      clientPayload = { ...clientPayload, links };
    }
    let oldAddressId = oldClientAddress?.addressId;

    const addressFields = ["address", "city", "state", "country"];

    const includePinCode = clientPayload.country === "India";

    if (includePinCode) {
      addressFields.splice(1, 0, "pinCode"); // Insert "pinCode" at index 1
    } else {
      const pinCodeIndex = addressFields.indexOf("pinCode");
      if (pinCodeIndex !== -1) {
        addressFields.splice(pinCodeIndex, 1); // Remove "pinCode" if present
      }
    }

    const isAddressValid = addressFields.every((key) => {
      const valueCheck = registeredAddress[key];
      return valueCheck && valueCheck !== null && valueCheck !== "";
    });

    const toastSuccess = () => {
      toast({
        title: "Client Updated Successfully",
        status: "success",
        duration: 1500,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(actions.clearStates());
      }, 1000);
      setProfileImagePreview(null);
      setIsChanged(false);
      setLocationArray([]);
      setLocationCompareArray([]);
      history.push("/admin/clients");
    };
    const toastError = () => {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    };

    dispatch(
      actions.updateClientData({
        clientPayload,
        clientId,
        customerId,
        onSuccess: (status) => {
          if (isAddressValid === false && status) {
            toastSuccess();
            return;
          }
        },
        onFailure: () => {
          toastError();
          return;
        },
      })
    );

    if (isAddressValid) {
      const address = [registeredAddress];
      oldAddressId
        ? dispatch(
            actions.updateClientAddress({
              clientId,
              customerId,
              oldAddressId,
              registeredAddress,
              onSuccess: (status) => {
                if (status) {
                  toastSuccess();
                }
              },
              onFailure: () => {
                toastError();
              },
            })
          )
        : dispatch(
            actions.postClientAddressRequest({
              id: clientId,
              customerId,
              address,
              onSuccess: (status) => {
                if (status) {
                  toastSuccess();
                }
              },
              onFailure: () => {
                toastError();
              },
            })
          );
    }
  };

  const isAnyFieldEmpty = () => {
    const { country, orgName } = clientDetails;
    if (
      [country, orgName].some((value) => value === "" || value === undefined)
    ) {
      return true;
    }
    if (
      links.some((link) => {
        if (link.type === "email") {
          return link.value !== "" && !emailIsValid;
        } else {
          return link.type !== "" && !isPhoneValid;
        }
      })
    )
      return true;
    return false;
  };

  const updateButtonState = () => {
    let res = true;
    if (!isAnyFieldEmpty() && isChanged) res = false;
    return res;
  };

  const toast = useToast();
  const history = useHistory();

  const handleDeleteButton = () => {
    let oldClientAddressId = oldClientAddress?.clientAddressId;
    dispatch(
      actions.deleteClientAddress({
        customerId,
        clientId,
        oldClientAddressId,
        onSuccess: (status) => {
          if (status === true) {
            toast({
              title: "Address deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setIsAddressDeleted(true);
            if (!isChanged) setIsChanged(true);
            setRegisteredAddress({
              id: generateIdSync(),
              address: "",
              addressLine2: "",
              pinCode: null,
              city: "",
              state: "",
              isRegistered: true,
              isActive: true,
            });
            setTempAddress({
              address: "",
              addressLine2: null,
              pinCode: null,
              city: "",
              state: "",
            });
            dispatch(actions.clearClientAddress());
          } else {
            toast({
              title: "Error deleting address",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        },
      })
    );
  };

  useEffect(() => {
    if (clientDetails && clientDetails?.country) {
      dispatch(
        actions.fetchLocationData({
          country:
            clientDetails?.country === "UAE"
              ? "United Arab Emirates"
              : clientDetails?.country,
          id: registeredAddress?.id,
          state: tempAddress.state,
          city: tempAddress.city,
        })
      );
    }
  }, [clientDetails.country]);

  useEffect(() => {
    if (tempAddress?.pinCode?.length === 6) {
      dispatch(
        actions.fetchLocationData({
          id: registeredAddress?.id,
          postalCode: tempAddress?.pinCode,
          onSuccess: () => {
            if (!tempAddress.state && !tempAddress.city)
              dispatch(
                actions.fetchLocationData({
                  country:
                    clientDetails?.country === "UAE"
                      ? "United Arab Emirates"
                      : clientDetails?.country,
                  id: registeredAddress?.id,
                })
              );
          },
        })
      );
    }
  }, [tempAddress.pinCode]);

  useEffect(() => {
    if (
      (locations[registeredAddress?.id]?.states?.length === 1 &&
        locations[registeredAddress?.id]?.cities?.length) === 1
    ) {
      setTempAddress((prev) => {
        return {
          ...prev,
          city: locations[registeredAddress?.id]?.cities[0],
          state: locations[registeredAddress?.id]?.states[0],
        };
      });
    }

    if (tempAddress?.pinCode) {
      setPinCodeIsValid(validatePINCode(tempAddress?.pinCode));
    }
  }, [locations]);

  const validatePINCode = (pin) => {
    const pattern = /^\d{6}$/;
    return pattern.test(pin);
  };

  const {
    isOpen: saveIsOpen,
    onOpen: openSaveModal,
    onClose: closeSaveModal,
  } = useDisclosure();

  useEffect(() => {
    return () => {
      setLocationArray([]);
      setLocationCompareArray([]);
      dispatch(actions.clearStates());
    };
  }, []);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          <AddClientTop
            isEditing={isEditing}
            handleInputChange={handleInputChange}
            handleLinksChange={handleLinksChange}
            handleFileChange={handleFileChange}
            profileImagePreview={profileImagePreview}
            clientDetails={clientDetails}
            links={links}
            oldClientAddress={oldClientAddress}
            handleRegisteredAddress={handleRegisteredAddress}
            emailIsValid={emailIsValid}
            setEmailIsValid={setEmailIsValid}
            isPhoneValid={isPhoneValid}
            setIsPhoneValid={setIsPhoneValid}
            setLinks={setLinks}
            setTempAddress={setTempAddress}
            tempAddress={tempAddress}
            pinCodeIsValid={pinCodeIsValid}
            setPinCodeIsValid={setPinCodeIsValid}
            locationArray={locationArray}
          />
          <AddClientLocation
            // isDisabled={isAddAdressDetailsMissing}
            handleRegisteredAddress={setRegisteredAddress}
            registeredAddress={registeredAddress}
            isEditing={isEditing}
            isAddressDeleted={isAddressDeleted}
            setIsAddressDeleted={setIsAddressDeleted}
            handleDeleteButton={handleDeleteButton}
            clientDetails={clientDetails}
            tempAddress={tempAddress}
            setTempAddress={setTempAddress}
            setIsChanged={setIsChanged}
            isChanged={isChanged}
            clientAddress={clientAddress}
            pinCodeIsValid={pinCodeIsValid}
            setPinCodeIsValid={setPinCodeIsValid}
            locations={locations}
            validatePINCode={validatePINCode}
            locationArray={locationArray}
            setLocationArray={setLocationArray}
            locationCompareArray={locationCompareArray}
            setLocationCompareArray={setLocationCompareArray}
            clientId={clientId}
            refreshLocations={refreshLocations}
          />
          <Flex justifyContent={"flex-end"}>
            <Button
              colorScheme="login"
              size="md"
              borderRadius={"8px"}
              isDisabled={isEditing ? updateButtonState() : isAnyFieldEmpty()}
              onClick={isEditing ? openSaveModal : handleAddClient}
            >
              {isEditing ? "Save Changes" : "+ Add Client"}
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Modal isOpen={saveIsOpen} onClose={closeSaveModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Confirm Save
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="#718096">
              Are you certain you want to proceed with saving?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={closeSaveModal}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="modalsDark"
              onClick={() => {
                handleUpdateClient();
                closeSaveModal();
              }}
            >
              Yes, Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default AddClient;
