import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Input,
  VStack,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { FiUploadCloud, FiFile } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { actions, sliceKey, reducer } from "../../pages/generateTemplate/slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import SparkMD5 from "spark-md5";
import { useDispatch, useSelector } from "react-redux";
import { formDataSaga } from "../../pages/generateTemplate/saga";
import * as selectors from "../../pages/generateTemplate/selectors";
import { Loader } from "components/Loader/Loader";
import { generateIdSync } from "utils";
import { validMimeTypesForFileUploads } from "utils/helper";

export default function FileUploads({
  data,
  formData,
  handleFormDataChange,
  repeatable,
  index,
}) {
  if (repeatable?.isRepeatable) {
    const repeatIndex = index;
    const minEntries = data.element.repeatable?.minEntries;

    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: formDataSaga });

    const dispatch = useDispatch();

    const selectFilesUploadLoader = useSelector(
      selectors.selectFilesUploadLoader
    );

    const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
    const [filesToUpload, setFilesToUpload] = useState([]);

    const [filesForDisplay, setFilesForDisplay] = useState(() => {
      if (formData[data.element.id]?.attachments[repeatIndex]) {
        return JSON.parse(formData[data.element.id]?.attachments[repeatIndex]);
      }
      return [];
    });

    console.log({ files: formData[data.element.id] });

    const [allHashedFiles, setAllHashedFiles] = useState(() => {
      if (
        formData[data.element.id]?.itemInfo?.hashArray &&
        formData[data.element.id]?.itemInfo?.hashArray[repeatIndex]
      )
        return formData[data.element.id]?.itemInfo?.hashArray[repeatIndex];
      return [];
    });
    const toast = useToast();

    const handleInputClick = (id) => {
      document.getElementById(id).click();
    };

    const showWarningToast = () => {
      toast({
        title: "File Already Uploaded",
        status: "info",
        duration: 1300,
        isClosable: true,
        position: "bottom-left",
      });
    };

    //takes array of files and returns [{name : xyz_perfeqt_elemetID, hash: asuhw87e298},{...]
    async function hashFiles(files) {
      const hashedFiles = [];

      const promises = files.map(async (file) => {
        const fileReader = new FileReader();
        const fileName = file.name;
        fileReader.readAsArrayBuffer(file);

        return new Promise((resolve, reject) => {
          fileReader.onloadend = async () => {
            try {
              const hash = SparkMD5.ArrayBuffer.hash(fileReader.result);
              hashedFiles.push({ name: fileName, hash });
              resolve();
            } catch (error) {
              reject(error);
            }
          };

          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      });

      await Promise.all(promises);
      return hashedFiles;
    }

    const handleFileChange = async (e) => {
      // Check for number of files start
      const totalFilesCount = filesForDisplay.length + e.target.files.length;
      const fileCount = data?.element?.fileOptions?.maxFileCount
        ? data?.element?.fileOptions?.maxFileCount
        : 1;
      if (totalFilesCount > fileCount) {
        toast({
          title: `You cannot add more than ${fileCount} files.`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        return;
      }
      // Check for number of files end

      // Check for number of file size start
      const maxFileSizeMB = data?.element?.fileOptions?.maxFileSize
        ? data?.element?.fileOptions?.maxFileSize
        : 10;
      const filesExceedingSize = Array.from(e.target.files).filter((file) => {
        return file.size > maxFileSizeMB * 1024 * 1024;
      });

      if (filesExceedingSize.length > 0) {
        toast({
          title: `Files larger than ${maxFileSizeMB} mb cannot be uploaded.`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        return;
      }
      // Check for number of file size end

      // ----------------------------------------------
      // Upload process for new files starts here.
      let files =
        filesToUpload && filesToUpload.length ? [...filesToUpload] : [];
      let currentFiles = [...Array.from(e.target.files)];
      currentFiles = currentFiles.map((file) => {
        const fileNameArr = file.name.split(".");
        const type = fileNameArr.pop();
        const id = generateIdSync();
        const fileName = `${fileNameArr.join("")}_perfeqt_${id}.${type}`;
        const updatedFile = new File([file], fileName, {
          type: file.type,
        });
        return updatedFile;
      });

      //Create hash to check for duplicates
      let newFilesHash = await hashFiles(Array.from(currentFiles));
      //IN case someone tries to upload copied files following fiter will be applied.

      let modifiedFiles = [];
      let filteredHash = [];
      const filterFileAndHash = newFilesHash.map((fileHash, index) => {
        const hashValue = fileHash.hash;
        const fileName = fileHash.name;

        if (allHashedFiles.some((file) => file.hash === hashValue)) {
          showWarningToast();
          return;
        }
        const fileToKeep = currentFiles.find((file) => file.name === fileName);
        modifiedFiles.push(fileToKeep);
        filteredHash.push(fileHash);
      });
      //clear value of input to allow reupload of same files in a row
      if (e.target.files.length) {
        e.target.value = null;
      }

      let uniqueFiles = [...files, ...modifiedFiles];
      setAllHashedFiles((prev) => {
        return [...prev, ...filteredHash];
      });
      let toSaveHashedFiles = [...allHashedFiles, ...filteredHash];

      setFilesToUpload(uniqueFiles);

      files = Object.values(uniqueFiles);

      const fileNames = [...filesForDisplay];

      let mimeTypes =
        formData[data.element.id]?.mimeType &&
        formData[data.element.id]?.mimeType !== "NA"
          ? JSON.parse(formData[data.element.id]?.mimeType)[repeatIndex]
          : "";

      const token = localStorage.getItem("token");

      files.forEach((file) => {
        const fileName = file.name;

        mimeTypes = mimeTypes ? mimeTypes + "," + file.type : file.type;

        if (token) {
          dispatch(
            actions.postFileData({
              fileName,
              file,
              token: JSON.parse(token),
              onFailure: () => {
                afterFailure();
              },
              onSuccess: () => {
                afterSuccess(fileName);
              },
            })
          );
        } else {
          dispatch(
            actions.postFileData({
              fileName,
              file,
              onFailure: () => {
                afterFailure();
              },
              onSuccess: () => {
                afterSuccess(fileName);
              },
            })
          );
        }
      });

      const afterSuccess = (fileName) => {
        fileNames.push(fileName);
        setFilesForDisplay(fileNames);
        setFilesToUpload([]);
        handleFormDataChange(
          toSaveHashedFiles,
          fileNames,
          data.element.id,
          mimeTypes,
          repeatIndex + 1
        );
      };

      const afterFailure = () => {
        setFilesToUpload([]);
        toast({
          title: `File upload failed. Please try again.`,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-left",
        });
      };
    };

    const handleFileDelete = (index, fileName) => {
      document.getElementById(`${data.element.id}${repeatIndex}`).value = "";
      let deleteFileName =
        fileName.split("_perfeqt_")[0] + "." + fileName.split(".")[1];

      const fileNames = filesForDisplay.filter((file, i) => i !== index);

      setAllHashedFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((prevFile) => {
          const key = prevFile.name;
          return key !== fileName;
        });
        return updatedFiles;
      });
      const toSaveHashedFiles = allHashedFiles.filter(
        (file) =>
          file.name.split("_perfeqt_")[0] + "." + fileName.split(".")[1] !==
          deleteFileName
      );

      let mimeTypes =
        formData[data.element.id]?.mimeType &&
        formData[data.element.id]?.mimeType !== "NA"
          ? JSON.parse(formData[data.element.id]?.mimeType)[repeatIndex]
          : "";
      mimeTypes = mimeTypes
        .split(",")
        .filter((file, i) => i !== index)
        .join(",");

      handleFormDataChange(
        toSaveHashedFiles,
        fileNames,
        data.element.id,
        (mimeTypes = mimeTypes === "" ? "NA" : mimeTypes),
        repeatIndex + 1
      );
    };

    const acceptTypes = data?.element?.fileOptions?.allowedFileTypes
      ? data.element.fileOptions.allowedFileTypes
          .map((value) => {
            switch (value) {
              case "doc":
                return ".doc,.docx";
              case "image":
                return "image/*";
              case "pdf":
                return ".pdf";
              case "spreadSheet":
                return ".xls,.xlsx,.csv";
              case "vid":
                return "video/*";
              case "all":
                return "*.*";
              default:
                return "";
            }
          })
          .join(",")
      : null;

    useEffect(() => {
      let initialFiles = [];
      if (formData[data.element.id]?.attachments[repeatIndex]) {
        initialFiles = JSON.parse(formData[data.element.id]?.attachments[repeatIndex]);
      }
      setFilesForDisplay(initialFiles);
    }, [formData, data.element.id]);

    console.log({filesForDisplay})

    return (
      <Box>
        <Input
          id={`${data.element.id}${repeatIndex}`}
          onChange={(e) => {
            handleFileChange(e);
          }}
          style={{ display: "none" }}
          type="file"
          accept={acceptTypes ? acceptTypes : "image/*"}
          multiple
        />
        <HStack
          style={{
            width: "100%",
            border: "2px dashed #E2E8F0",
            borderRadius: "8px",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            padding: "20px 40px 40px 40px",
          }}
          onClick={() => {
            handleInputClick(`${data.element.id}${repeatIndex}`);
          }}
        >
          <VStack align="center">
            <Box
              style={{
                padding: "8px",
                border: "1px solid #EDF2F7",
                borderRadius: "8px",
              }}
            >
              <FiUploadCloud size="24px" color="#718096" />
            </Box>
            <Text
              fontSize="10px"
              fontWeight="400"
              color="#718096"
              lineHeight="1.4"
            >
              {`Size limit: ${
                data?.element?.fileOptions?.maxFileSize || 10
              } mb`}
            </Text>
            <Text
              fontSize="10px"
              fontWeight="400"
              color="#718096"
              lineHeight="1.4"
              w={isMobileScreen ? "100%" : "442px"}
              textAlign="center"
            >
              {acceptTypes && acceptTypes === "*.*"
                ? ".doc, .docx, .txt, .rtf, .odt, .ppt, .pptx, .odp, .ods, .csv, .xls,.xlsx, .nymbers, .key, .png, .jpg, .gif, .json, .xml, .zip, .rar,.mp3, .wav, .aiff, .pbix, .pdf"
                : acceptTypes || "image/*"}
            </Text>
          </VStack>
        </HStack>
        {filesForDisplay && filesForDisplay.length > 0 ? (
          filesForDisplay.map((file, index) => {
            return (
              <HStack
                w="100%"
                justify="space-between"
                align="center"
                key={index}
                style={{
                  padding: "6px 12px",
                  background: "#F7FAFC",
                  borderRadius: "8px",
                  margin: "14px auto",
                }}
              >
                <HStack>
                  <FiFile size="22px" color="#718096" />
                  <Text
                    color="#4A5568"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1.4"
                  >
                    {file?.length > 18
                      ? `${file.slice(0, 18)}...${file.slice(file.length - 6)}`
                      : file}
                  </Text>
                </HStack>
                <span
                  style={{ padding: "6px", cursor: "pointer" }}
                  onClick={() => handleFileDelete(index, file)}
                >
                  {selectFilesUploadLoader[file] ? (
                    <Loader size={"24px"} />
                  ) : (
                    <IoCloseCircleOutline size="24px" color="#718096" />
                  )}
                </span>
              </HStack>
            );
          })
        ) : (
          <></>
        )}
      </Box>
    );
  }
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: formDataSaga });

  const dispatch = useDispatch();

  const selectFilesUploadLoader = useSelector(
    selectors.selectFilesUploadLoader
  );

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [filesForDisplay, setFilesForDisplay] = useState(
    formData[data.element.id]?.attachments || []
  );

  const [allHashedFiles, setAllHashedFiles] = useState(
    formData[data.element.id]?.itemInfo?.hashArray !== undefined
      ? formData[data.element.id]?.itemInfo?.hashArray
      : []
  );
  const toast = useToast();

  useEffect(() => {
    const initialFiles = formData[data.element.id]?.attachments || [];
    setFilesForDisplay(initialFiles);
  }, [formData, data.element.id]);

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  const showWarningToast = () => {
    toast({
      title: "File Already Uploaded",
      status: "info",
      duration: 1300,
      isClosable: true,
      position: "bottom-left",
    });
  };

  //takes array of files and returns [{name : xyz_perfeqt_elemetID, hash: asuhw87e298},{...]
  async function hashFiles(files) {
    const hashedFiles = [];

    const promises = files.map(async (file) => {
      const fileReader = new FileReader();
      const fileName = file.name;
      fileReader.readAsArrayBuffer(file);

      return new Promise((resolve, reject) => {
        fileReader.onloadend = async () => {
          try {
            const hash = SparkMD5.ArrayBuffer.hash(fileReader.result);
            hashedFiles.push({ name: fileName, hash });
            resolve();
          } catch (error) {
            reject(error);
          }
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    });

    await Promise.all(promises);
    return hashedFiles;
  }

  const handleFileChange = async (e) => {
    //validation for file type
    const allowedFileTypes = data?.element?.fileOptions?.allowedFileTypes;
    const selectedFiles = e.target.files;

    if (!selectedFiles.length) {
      return;
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileType = file.type; // Get the MIME type of each file
      let isValidFile = false;

      // Check if the file's MIME type matches the allowed types
      allowedFileTypes?.forEach((value) => {
        const validTypes = validMimeTypesForFileUploads[value];
        if (validTypes) {
          validTypes.forEach((validType) => {
            // Use regex to check for wildcard types like image/* or */*
            if (new RegExp(validType.replace("*", ".*")).test(fileType)) {
              isValidFile = true;
            }
          });
        }
      });

      if (!isValidFile) {
        e.target.value = null;
        toast({
          title: `Invalid file type: ${file.name}. Please upload a valid file.`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-left",
        });
        return;
      }
    }

    // Check for number of files start
    const totalFilesCount = filesForDisplay.length + e.target.files.length;
    const fileCount = data?.element?.fileOptions?.maxFileCount
      ? data?.element?.fileOptions?.maxFileCount
      : 1;
    if (totalFilesCount > fileCount) {
      toast({
        title: `You cannot add more than ${fileCount} files.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }
    // Check for number of files end

    // Check for number of file size start
    const maxFileSizeMB = data?.element?.fileOptions?.maxFileSize
      ? data?.element?.fileOptions?.maxFileSize
      : 10;
    const filesExceedingSize = Array.from(e.target.files).filter((file) => {
      return file.size > maxFileSizeMB * 1024 * 1024;
    });

    if (filesExceedingSize.length > 0) {
      toast({
        title: `Files larger than ${maxFileSizeMB} mb cannot be uploaded.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }
    // Check for number of file size end

    // ----------------------------------------------
    // Upload process for new files starts here.
    let files = filesToUpload && filesToUpload.length ? [...filesToUpload] : [];
    let currentFiles = [...Array.from(e.target.files)];
    currentFiles = currentFiles.map((file) => {
      const fileNameArr = file.name.split(".");
      const type = fileNameArr.pop();
      const id = generateIdSync();
      const fileName = `${fileNameArr.join("")}_perfeqt_${id}.${type}`;
      const updatedFile = new File([file], fileName, {
        type: file.type,
      });
      return updatedFile;
    });
    //Create hash to check for duplicates
    let newFilesHash = await hashFiles(Array.from(currentFiles));
    //IN case someone tries to upload copied files following fiter will be applied.

    // const uniqueHashes = new Set();
    // const filesToRemove = [];
    // newFilesHash = newFilesHash.filter((item, index) => {
    //   if (!uniqueHashes.has(item.hash)) {
    //     uniqueHashes.add(item.hash);
    //     return true;
    //   } else {
    //     showWarningToast();

    //     filesToRemove.push(item.name);
    //     return false;
    //   }
    // });
    // console.log("filtered hash", newFilesHash);
    // Remove duplicates from filesToUpload based on filename
    // currentFiles = currentFiles.filter(
    //   (file) => !filesToRemove.includes(file.name)
    // );
    // console.log("afer removing files", currentFiles);

    let modifiedFiles = [];
    let filteredHash = [];
    const filterFileAndHash = newFilesHash.map((fileHash, index) => {
      const hashValue = fileHash.hash;
      const fileName = fileHash.name;

      if (allHashedFiles.some((file) => file.hash === hashValue)) {
        showWarningToast();
        return;
      }
      const fileToKeep = currentFiles.find((file) => file.name === fileName);
      modifiedFiles.push(fileToKeep);
      filteredHash.push(fileHash);
    });

    //clear value of input to allow reupload of same files in a row
    if (e.target.files.length) {
      e.target.value = null;
    }

    let uniqueFiles = [...files, ...modifiedFiles];
    setAllHashedFiles((prev) => {
      return [...prev, ...filteredHash];
    });
    let toSaveHashedFiles = [...allHashedFiles, ...filteredHash];

    setFilesToUpload(uniqueFiles);

    files = Object.values(uniqueFiles);

    const fileNames = [...filesForDisplay];

    let mimeTypes =
      formData[data.element.id]?.mimeType &&
      formData[data.element.id]?.mimeType !== "NA"
        ? formData[data.element.id]?.mimeType
        : "";

    const token = localStorage.getItem("token");

    files.forEach((file) => {
      const fileName = file.name;

      mimeTypes = mimeTypes ? mimeTypes + "," + file.type : file.type;

      if (token) {
        dispatch(
          actions.postFileData({
            fileName,
            file,
            token: JSON.parse(token),
            onFailure: () => {
              afterFailure();
            },
            onSuccess: () => {
              afterSuccess(fileName);
            },
          })
        );
      } else {
        dispatch(
          actions.postFileData({
            fileName,
            file,
            onFailure: () => {
              afterFailure();
            },
            onSuccess: () => {
              afterSuccess(fileName);
            },
          })
        );
      }
    });

    const afterSuccess = (fileName) => {
      fileNames.push(fileName);
      setFilesForDisplay(fileNames);
      setFilesToUpload([]);
      handleFormDataChange(
        toSaveHashedFiles,
        fileNames,
        data.element.id,
        mimeTypes
      );
    };

    const afterFailure = () => {
      setFilesToUpload([]);
      toast({
        title: `File upload failed. Please try again.`,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "bottom-left",
      });
    };
  };

  const handleFileDelete = (index, fileName) => {
    document.getElementById(data.element.id).value = "";
    let deleteFileName =
      fileName.split("_perfeqt_")[0] + "." + fileName.split(".")[1];

    const fileNames = filesForDisplay.filter((file, i) => i !== index);

    setAllHashedFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((prevFile) => {
        const key = prevFile.name;
        return key !== fileName;
      });
      return updatedFiles;
    });
    const toSaveHashedFiles = allHashedFiles.filter(
      (file) =>
        file.name.split("_perfeqt_")[0] + "." + fileName.split(".")[1] !==
        deleteFileName
    );

    let mimeTypes =
      formData[data.element.id]?.mimeType &&
      formData[data.element.id]?.mimeType !== "NA"
        ? formData[data.element.id]?.mimeType
        : "";
    mimeTypes = mimeTypes
      .split(",")
      .filter((file, i) => i !== index)
      .join(",");

    handleFormDataChange(
      toSaveHashedFiles,
      fileNames,
      data.element.id,
      (mimeTypes = mimeTypes === "" ? "NA" : mimeTypes)
    );
  };

  const acceptTypes = data?.element?.fileOptions?.allowedFileTypes
    ? data.element.fileOptions.allowedFileTypes
        .map((value) => {
          switch (value) {
            case "doc":
              return ".doc,.docx";
            case "image":
              return "image/*";
            case "pdf":
              return ".pdf";
            case "spreadSheet":
              return ".xls,.xlsx,.csv";
            case "vid":
              return "video/*";
            case "all":
              return "*.*";
            default:
              return "";
          }
        })
        .join(",")
    : null;

  return (
    <Box>
      <Input
        id={data.element.id}
        onChange={(e) => {
          handleFileChange(e);
        }}
        style={{ display: "none" }}
        type="file"
        accept={acceptTypes ? acceptTypes : "image/*"}
        multiple
      />
      <HStack
        style={{
          width: "100%",
          border: "2px dashed #E2E8F0",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "20px 40px 40px 40px",
        }}
        onClick={() => {
          handleInputClick(data.element.id);
        }}
      >
        <VStack align="center">
          <Box
            style={{
              padding: "8px",
              border: "1px solid #EDF2F7",
              borderRadius: "8px",
            }}
          >
            <FiUploadCloud size="24px" color="#718096" />
          </Box>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
          >
            {`Size limit: ${data?.element?.fileOptions?.maxFileSize || 10} mb`}
          </Text>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
            w={isMobileScreen ? "100%" : "442px"}
            textAlign="center"
          >
            {acceptTypes && acceptTypes === "*.*"
              ? ".doc, .docx, .txt, .rtf, .odt, .ppt, .pptx, .odp, .ods, .csv, .xls,.xlsx, .nymbers, .key, .png, .jpg, .gif, .json, .xml, .zip, .rar,.mp3, .wav, .aiff, .pbix, .pdf"
              : acceptTypes || "image/*"}
          </Text>
        </VStack>
      </HStack>
      {filesForDisplay && filesForDisplay.length > 0 ? (
        filesForDisplay.map((file, index) => {
          return (
            <HStack
              w="100%"
              justify="space-between"
              align="center"
              key={index}
              style={{
                padding: "6px 12px",
                background: "#F7FAFC",
                borderRadius: "8px",
                margin: "14px auto",
              }}
            >
              <HStack>
                <FiFile size="22px" color="#718096" />
                <Text
                  color="#4A5568"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="1.4"
                >
                  {file?.length > 18
                    ? `${file.slice(0, 18)}...${file.slice(file.length - 6)}`
                    : file}
                </Text>
              </HStack>
              <span
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => handleFileDelete(index, file)}
              >
                {selectFilesUploadLoader[file] ? (
                  <Loader size={"24px"} />
                ) : (
                  <IoCloseCircleOutline size="24px" color="#718096" />
                )}
              </span>
            </HStack>
          );
        })
      ) : (
        <></>
      )}
    </Box>
  );
}
