import { HStack, Box } from "@chakra-ui/layout";
import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import Editable from "../../../Components/Editable";
import * as selectors from "../selectors";
import { actions } from "../slice";
import { ButtonGroup } from "@chakra-ui/button";
import { Button } from "@agnext/reactlib";
import { AddIcon } from "@chakra-ui/icons";
import Composite from "../Composite";
import { generateId } from "../../../../../utils/index";
import LinkTo from "../../../Components/LinkTo";

export default function Block({
  blockId,
  sectionId,
  blockOrder,
  blockOrderLast,
}) {
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [titleOption, setTitleOption] = useState({
    label: "",
    value: "",
  });

  const [blockDescription, setblockDesciption] = useState("Description");

  const blocks = useSelector(selectors.selectBlocksForComposites);

  const dispatch = useDispatch();

  const getComposites = useCallback(() => {
    let composites = [];
    if (blocks.length) {
      blocks.forEach((block) => {
        if (block.id === blockId) {
          composites = block.structure && block.structure.composites;
        }
      });
    }
    return composites;
  }, [blocks, blockId]);

  const getBlockName = useCallback(() => {
    let blockName = "";
    if (blocks.length) {
      blocks.forEach((block) => {
        if (block.id === blockId) {
          blockName = block.title;
        }
      });
    }
    return blockName;
  }, [blocks, blockId]);

  const [blockName, setBlockName] = useState(getBlockName());

  const handleBlockNameChange = (value) => {
    setBlockName(value);
  };

  const getLinks = useCallback(() => {
    let links;
    if (blocks.length) {
      blocks.forEach((block) => {
        if (block.id === blockId) {
          links = block.structure &&
            block.structure.links && [...block.structure.links];
        }
      });
    }
    return links || [];
  }, [blockId, blocks]);

  const handleSubmitLinkTo = () => {
    dispatch(
      actions.addLinkToBlock({
        blockId,
        linkTo: {
          type: selectedOption.value,
          id: titleOption.value,
          title: titleOption.label,
        },
      })
    );
    const newOption = { label: "", value: "" };
    setSelectedOption(newOption);
    setTitleOption(newOption);
  };

  const handleBlockDelete = () => {
    const payload = {
      sectionId,
      blockId,
    };
    dispatch(actions.deletionBlock(payload));
  };

  const handleBlockDescriptionChange = (value) => {
    setblockDesciption(value);
  };

  const handleBlockNameSubmit = (blockName) => {
    const payload = {
      blockId,
      sectionId,
      blockName,
    };
    dispatch(actions.editBlockName(payload));
  };

  const handleBlockDescriptionSubmit = (blockDescription) => {
    const payload = {
      blockId,
      blockDescription,
    };
    dispatch(actions.editBlockDescription(payload));
  };

  const moveUpHandlerBlock = () => {
    const payload = {
      blockId,
      sectionId,
    };
    dispatch(actions.moveUpBlock(payload));
  };
  const moveDownHandlerBlock = () => {
    const payload = {
      blockId,
      sectionId,
    };
    dispatch(actions.moveDownBlock(payload));
  };

  const handleBlockDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      sectionId,
      blockId,
    };
    dispatch(actions.duplicateBlock(payload));
  };

  const handleAddComposite = async () => {
    const compositeId = await generateId();
    dispatch(actions.initNewComposite({ compositeId, blockId }));
  };
  let composites = getComposites();
  return (
    <>
      <Box
        w={"100%"}
        style={{
          padding: "16px",
          border: "1px solid #EFEFEF",
          margin: "16px 0 16px 0",
          borderLeft: "2px solid rgba(255, 112, 0, 0.5)",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <HStack
          justify={"space-between"}
          style={{
            background: "rgba(84,3, 117, 0.03)",
            padding: "7px 12px",
            borderRadius: "0 8px 8px 0",
          }}
        >
          <Box w="40%">
            <Editable
              text={blockName}
              handleTextChange={handleBlockNameChange}
              handleSubmit={handleBlockNameSubmit}
            />
          </Box>
          <ButtonGroup
            justifyContent="center"
            size="md"
            style={{ position: "relative" }}
          >
            <Moveup
              moveUpHandler={moveUpHandlerBlock}
              isDisabled={blockOrder === 1 ? true : false}
            />
            <MoveDown
              moveDownHandler={moveDownHandlerBlock}
              isDisabled={blockOrder === blockOrderLast ? true : false}
            />
            <Delete deleteHandler={handleBlockDelete} />
            <Duplicate duplicateHandler={handleBlockDuplicate} />
            <LinkTo
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              title={titleOption}
              setTitle={setTitleOption}
              handleSubmit={handleSubmitLinkTo}
              links={getLinks()}
              parentType="blocks"
              parentId={blockId}
            />
          </ButtonGroup>
          <Box>
            <Button
              size="sm"
              onClick={handleAddComposite}
              colorScheme="login"
              leftIcon={<AddIcon />}
              label="Composite"
            />
          </Box>
        </HStack>
        <Box w="40%" style={{ paddingLeft: "12px" }}>
          <Editable
            text={blockDescription}
            handleTextChange={handleBlockDescriptionChange}
            handleSubmit={handleBlockDescriptionSubmit}
          />
        </Box>
        {getComposites() &&
          getComposites().map((composite) => (
            <Composite
              key={composite.id}
              compositeId={composite.id}
              sectionId={sectionId}
              blockId={blockId}
              compositeOrder={composite.order}
              compositeOrderLast={
                composites.length >= 1
                  ? composites[composites.length - 1].order
                  : 0
              }
            />
          ))}
      </Box>
    </>
  );
}
