import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Avatar,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import VerifyFileData from "./VerifyFileData";
import leftIcon from "../../../assets/svg/leftArrow.svg";
import { FiFile, FiUploadCloud } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import UploadedTemplate from "./UploadedTemplate";
import { actions } from "../slice";
import ConfirmationModal from "./ConfirmationModal";
import { actions as actions2 } from "../../User/slice";
import { actions as clientActions } from "../../Client/slice";
import { actions as customerActions } from "../../Customer/slice";
import { parseRow } from "utils/helper";

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const uaePattern = /^(\+971|971)?\d{9}$/;
const indiaPattern = /^(\+91|91)?[6-9]\d{9}$/;
function UploadFileModal({
  isOpen,
  onClose,
  openTemplateDownload,
  title,
  manager,
  details,
  avatar,
  onOpen,
  customerId,
  clientId,
  allowedFields,
  requiredFields,
  type,
  userType,
  setUserType,
  setClientId,
}) {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState([]);
  const [dataErrorArray, setDataErrorArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileDataCheck, setFileDataCheck] = useState(false);
  const [file, setFile] = useState(null);
  const [headerList, setHeaderList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [errorRows, setErrorRows] = useState(new Set());
  const [errorColumns, setErrorColumns] = useState(new Set());
  const [uploadFailed, setUploadFailed] = useState(null);
  const {
    isOpen: completedModalIsOpen,
    onOpen: openCompletedModal,
    onClose: closeCompletedModal,
  } = useDisclosure();
  const {
    isOpen: reUploadConfirmModalIsOpen,
    onOpen: openReUploadConfirmModal,
    onClose: closeReUploadConfirmModal,
  } = useDisclosure();

  const {
    isOpen: cancelUploadModalIsOpen,
    onOpen: openCancelUploadModal,
    onClose: closeCancelUploadModal,
  } = useDisclosure();

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  const handleFileUpload = (e, type) => {
    const uploadedFile = e.target.files[0];

    if (!uploadedFile) {
      setErrorMessage([
        "No file uploaded. Please select a CSV file and try again.",
      ]);
      return;
    }

    // Check for file type
    const fileExtension = uploadedFile.name.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      setFile(null);
      setErrorMessage(["Invalid file type. Please upload a CSV file."]);
      return;
    } else {
      setFile(uploadedFile);
      setErrorMessage([]);
    }

    // Check file size
    if (uploadedFile.size > 10 * 1024 * 1024) {
      setErrorMessage([
        "File size exceeds 10MB. Please upload a smaller file.",
      ]);
      return;
    }

    // Read file
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      parseCSV(fileContent);
    };
    reader.readAsText(uploadedFile);
  };

  const parseCSV = (fileContent) => {
    const rows = fileContent.split(/\r?\n/).filter((row) => row.trim() !== "");

    if (rows.length < 2) {
      setErrorMessage((prev) => [
        ...prev,
        "CSV file does not contain any data rows.",
      ]);
      return;
    }

    const headers = parseRow(rows[0]);
    const headerErrors = [];

    headers.forEach((header) => {
      if (!allowedFields.includes(header)) {
        headerErrors.push(`Field "${header}" is not allowed.`);
      }
    });

    requiredFields.forEach((requiredField) => {
      if (!headers.includes(requiredField)) {
        headerErrors.push(`Required field "${requiredField}" is missing.`);
      }
    });

    if (headerErrors.length > 0) {
      setErrorMessage((prev) => [...prev, ...headerErrors]);
      return;
    }

    const data = rows.slice(1).map((row) => parseRow(row));
    setHeaderList(headers);
    setDataList(data);
    setDataErrorArray([]); // Clear any previous errors
  };

  const handleFileDelete = () => {
    document.getElementById("inputId").value = "";
    setErrorMessage([]);
    setDataErrorArray([]);
    setFile(null);
    setHeaderList([]);
    setDataList([]);
  };

  const handleFileDataVerify = () => {
    setFileDataCheck(true);
    const emailIndex = headerList.indexOf("email");
    const extCodeIndex = headerList.indexOf("external_code");
    const emailSet = new Set();
    const extCodeSet = new Set();
    const mobileIndex = headerList.indexOf("mobile");
    const countryCodeIndex = headerList.indexOf("country_code");
    const validCountryCodes = ["91", "+91", "+971", "971"];
    const pinCodeIndex = headerList.indexOf("pin_code");
    const countryIndex = headerList.indexOf("country");

    const errors = [];
    const rowErrors = new Set();
    const columnErrors = new Set();

    dataList.forEach((row, rowIndex) => {
      if (type) {
        // Check for unique external codes
        if (extCodeIndex !== -1) {
          const extCode = row[extCodeIndex];
          if (extCode) {
            if (extCodeSet.has(extCode)) {
              errors.push(
                `Row ${
                  rowIndex + 1
                } External Code (${extCode}): The value is the same as another origin in this file. Make sure each External Code is only used once.`
              );
              rowErrors.add(rowIndex + 1);
              columnErrors.add(extCodeIndex + 1);
            } else {
              extCodeSet.add(extCode);
            }
          }
        }
        if (emailIndex !== -1) {
          const email = row[emailIndex];
          if (email) {
            if (!emailPattern.test(email)) {
              errors.push(
                `Row ${
                  rowIndex + 1
                } Email (${email}): The email format is invalid.`
              );
              rowErrors.add(rowIndex + 1);
              columnErrors.add(emailIndex + 1);
            }
          }
        }
      } else {
        // Check for email format
        if (emailIndex !== -1) {
          const email = row[emailIndex];
          if (email) {
            if (!emailPattern.test(email)) {
              errors.push(
                `Row ${
                  rowIndex + 1
                } Email (${email}): The email format is invalid.`
              );
              rowErrors.add(rowIndex + 1);
              columnErrors.add(emailIndex + 1);
            } else if (emailSet.has(email)) {
              errors.push(
                `Row ${
                  rowIndex + 1
                } Email (${email}): The value is the same as another user in this file. Make sure each Email is only used once.`
              );
              rowErrors.add(rowIndex + 1);
              columnErrors.add(emailIndex + 1);
            } else {
              emailSet.add(email);
            }
          }
        }
      }

      // Check for phone numbers
      if (mobileIndex !== -1 && countryCodeIndex !== -1) {
        const countryCode = row[countryCodeIndex];
        const mobile = row[mobileIndex];
        let isValidCountryCode = true;

        if (countryCode) {
          if (!validCountryCodes.includes(countryCode)) {
            errors.push(
              `Row ${
                rowIndex + 1
              } Country Code (${countryCode}): Only India (91, +91) and UAE (971, +971) country codes are allowed.`
            );
            rowErrors.add(rowIndex + 1);
            columnErrors.add(countryCodeIndex + 1);
            isValidCountryCode = false;
          }
        }
        if (isValidCountryCode && mobile) {
          let isValid = false;
          if (
            (countryCode === "+971" || countryCode === "971") &&
            uaePattern.test(mobile)
          ) {
            isValid = true;
          } else if (
            (countryCode === "+91" || countryCode === "91") &&
            indiaPattern.test(mobile)
          ) {
            isValid = true;
          }

          if (!isValid) {
            errors.push(
              `Row ${
                rowIndex + 1
              } Mobile (${mobile}): The mobile number format is invalid for the country code ${countryCode}.`
            );
            rowErrors.add(rowIndex + 1);
            columnErrors.add(mobileIndex + 1);
          }
        }
      }

      // validation for the pin code
      if (pinCodeIndex !== -1 && row[countryIndex] === "India") {
        const pinCodePattern = /^\d{6}$/;
        const pinCode = row[pinCodeIndex];
        if (!pinCodePattern.test(pinCode)) {
          errors.push(
            `Row ${
              rowIndex + 1
            } Pin Code (${pinCode}): The pin code must be a 6-digit number for India.`
          );
          rowErrors.add(rowIndex + 1);
          columnErrors.add(headerList.indexOf("pin_code") + 1);
        }
      }

      // Check for required fields data
      requiredFields.forEach((field) => {
        const fieldIndex = headerList.indexOf(field);
        if (
          field === "pin_code" &&
          row[countryIndex] === "United Arab Emirates"
        ) {
          return;
        }
        if (
          fieldIndex !== -1 &&
          (!row[fieldIndex] || row[fieldIndex].trim() === "")
        ) {
          errors.push(
            `Row ${rowIndex + 1} ${field.replace(
              "_",
              " "
            )}: This field is required.`
          );
          rowErrors.add(rowIndex + 1);
          columnErrors.add(fieldIndex + 1);
        }
      });
    });

    setDataErrorArray(errors);
    setErrorRows(rowErrors);
    setErrorColumns(columnErrors);
  };

  const confirmAdd = () => {
    setIsLoading(true);
    // dispatch for origin
    if (type === "origin") {
      const managerId = clientId || customerId;
      dispatch(
        actions.uploadBulkOrigin({
          file,
          managerId,
          onSuccess: () => {
            setIsLoading(false);
            onClose();
            openCompletedModal();

            if (clientId) {
              dispatch(clientActions.fetchWarehouseDetailsRequest(clientId));
            } else {
              dispatch(
                customerActions.fetchWarehouseDetailsRequest(customerId)
              );
            }
          },
          onFailure: (error) => {
            if (error?.success === false) {
              let error1;
              if (Array.isArray(error?.reason)) {
                error1 = error.reason;
              } else {
                error1 = error?.reason
                  ?.split("\n")
                  .filter((row) => row.trim() !== "");
              }
              setUploadFailed(error1);
            }
            setIsLoading(false);
          },
        })
      );
    } else {
      // dispatch for the users
      dispatch(
        actions.bulkUserUpload({
          customerId,
          clientId,
          file,
          onSuccess: () => {
            setIsLoading(false);
            onClose();
            openCompletedModal();
            if (clientId)
              dispatch(
                actions2.fetchAllUsersforClient({
                  customerId,
                  page: 0,
                })
              );
            else
              dispatch(
                actions2.fetchAllUsersforCustomer({ customerId, page: 0 })
              );
          },
          onFailure: (error) => {
            if (error?.success === false) {
              let error1;
              if (Array.isArray(error?.reason)) {
                error1 = error.reason;
              } else {
                error1 = error?.reason
                  ?.split("\n")
                  .filter((row) => row.trim() !== "");
              }
              setUploadFailed(error1);
            }
            setIsLoading(false);
          },
        })
      );
    }
  };

  const handleReUpload = () => {
    closeReUploadConfirmModal();
    setFile(null);
    setErrorMessage([]);
    setDataErrorArray([]);
    setFileDataCheck(false);
    setHeaderList([]);
    setDataList([]);
    setErrorRows(new Set());
    setErrorColumns(new Set());
    onOpen();
    setUploadFailed(null);
  };

  const handleBack = () => {
    if (!fileDataCheck) {
      onClose();
      openTemplateDownload();
    } else {
      setFileDataCheck(false);
    }
    setUploadFailed(null);
  };

  const handleCancelUpload = () => {
    closeCancelUploadModal();

    setFile(null);
    setErrorMessage([]);
    setDataErrorArray([]);
    setFileDataCheck(false);
    setHeaderList([]);
    setDataList([]);
    setErrorRows(new Set());
    setErrorColumns(new Set());
    setUserType && setUserType(null);
    setClientId && setClientId(null);

    onClose();
    setUploadFailed(null);
  };

  const handleCompleted = () => {
    closeCompletedModal();

    setFile(null);
    setErrorMessage([]);
    setDataErrorArray([]);
    setFileDataCheck(false);
    setHeaderList([]);
    setDataList([]);
    setErrorRows(new Set());
    setErrorColumns(new Set());
    setUserType && setUserType(null);
    setClientId && setClientId(null);
    setUploadFailed(null);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        // onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent minW={"900px"} borderRadius="15px" minH={"600px"}>
          {/* hide header if the loader is true */}
          {isLoading === false ? (
            <>
              {" "}
              <ModalHeader color={"#2D3748"} fontWeight={700} fontSize={"18px"}>
                <Button
                  onClick={handleBack}
                  width="30px"
                  height="32px"
                  background={"white"}
                  border={"1px solid #E2E8F0"}
                  padding={0}
                  borderRadius={"10px"}
                  marginRight={"5px"}
                >
                  {" "}
                  <img src={leftIcon} alt="" srcset="" />{" "}
                </Button>{" "}
                {title}
              </ModalHeader>
              <HStack
                borderBottom={"1px solid #E2E8F0"}
                paddingBottom={"16px"}
                fontSize="14px"
              >
                {userType && (
                  <HStack>
                    <Text pl={"25px"} fontWeight="400" color="#718096">
                      User Type:
                    </Text>
                    <Text fontWeight="500" color="#4A5568">
                      {" "}
                      {userType}
                    </Text>
                  </HStack>
                )}
                {userType !== "My Users" ? (
                  <HStack gap={"5px"}>
                    <Text pl={"25px"} fontWeight="400" color="#718096">
                      {manager}:
                    </Text>
                    <Avatar
                      width={"24px"}
                      height={"24px"}
                      name={details?.orgName || "NA"}
                      src={avatar}
                    />
                    <Text fontWeight="500" color="#4A5568">
                      {" "}
                      {details?.orgName || "NA"}{" "}
                    </Text>
                  </HStack>
                ) : null}
              </HStack>
              <ModalCloseButton
                me="5px"
                mt="6px"
                onClick={
                  fileDataCheck === true
                    ? openCancelUploadModal
                    : handleCancelUpload
                }
              />{" "}
            </>
          ) : null}
          {isLoading === true ? (
            <ModalBody>
              <Stack
                alignItems="center"
                justifyContent="center"
                minH="600px"
                p="24px"
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="teal.500"
                  w="74px"
                  h="74px"
                />
              </Stack>
            </ModalBody>
          ) : (
            <>
              <ModalBody p={"24px"}>
                <VStack gap={"36px"} align={"flex-start"}>
                  {/* file upload  */}
                  {fileDataCheck === true ? (
                    <VerifyFileData
                      dataErrorArray={dataErrorArray}
                      headerList={headerList}
                      dataList={dataList}
                      errorRows={errorRows}
                      errorColumns={errorColumns}
                      uploadFailed={uploadFailed}
                    />
                  ) : (
                    <Stack
                      border={"1.5px solid #CBD5E0"}
                      borderRadius={"8px"}
                      w={"100%"}
                    >
                      <Stack m="20px">
                        <Text color="#2D3748" fontSize="16px" fontWeight="500">
                          File upload
                        </Text>
                        <Input
                          id="inputId"
                          style={{
                            display: "none",
                          }}
                          type="file"
                          accept=".csv"
                          onChange={(e) => {
                            handleFileUpload(e);
                          }}
                        />
                        <HStack
                          style={{
                            width: "100%",
                            border: "2px dashed #E2E8F0",
                            borderRadius: "8px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            padding: "20px 40px 40px 40px",
                          }}
                          onClick={() => {
                            handleInputClick("inputId");
                          }}
                        >
                          <VStack align="center">
                            <Box
                              style={{
                                padding: "8px",
                                border: "1px solid #EDF2F7",
                                borderRadius: "8px",
                              }}
                            >
                              <FiUploadCloud size="24px" color="#718096" />
                            </Box>
                            <Text
                              fontSize="10px"
                              fontWeight="400"
                              color="#718096"
                              lineHeight="1.4"
                            >
                              Size limit: 10MB
                            </Text>
                            <Text
                              fontSize="10px"
                              fontWeight="400"
                              color="#718096"
                              lineHeight="1.4"
                              w={"442px"}
                              textAlign="center"
                            >
                              CSV file only
                            </Text>
                          </VStack>
                        </HStack>
                        {file?.name ? (
                          <HStack
                            w="100%"
                            justify="space-between"
                            align="center"
                            style={{
                              padding: "6px 12px",
                              background: "#F7FAFC",
                              borderRadius: "8px",
                              //   margin: "14px auto",
                            }}
                          >
                            <HStack>
                              <FiFile size="22px" color="#718096" />
                              <Text
                                color="#4A5568"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="1.4"
                              >
                                {file?.name}
                              </Text>
                            </HStack>
                            <span
                              style={{
                                padding: "6px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleFileDelete()}
                            >
                              <IoCloseCircleOutline
                                size="24px"
                                color="#718096"
                              />
                            </span>
                          </HStack>
                        ) : null}
                      </Stack>
                    </Stack>
                  )}
                  {/* file upload end  */}

                  {errorMessage.length > 0 ? (
                    <VStack gap={"18px"} align="flex-start" w={"100%"}>
                      <Text color="#4A5568">
                        You have errors that need to be fixed
                      </Text>
                      {errorMessage?.map((err) => (
                        <Alert
                          status="error"
                          variant="left-accent"
                          p="12px 16px"
                          borderRadius="8px"
                        >
                          <AlertIcon />
                          {err}
                        </Alert>
                      ))}
                    </VStack>
                  ) : null}
                </VStack>
                {/* Error when api failed  */}
                {uploadFailed && (
                  <UnorderedList
                    color="red"
                    fontSize="12px"
                    bg="#F7FAFC"
                    spacing="8px"
                    w="100%"
                    p="16px"
                    borderRadius="8px"
                    maxH="90px"
                    overflow="auto"
                    mt="20px"
                  >
                    {Array.isArray(uploadFailed) ? (
                      uploadFailed.map((item, index) => (
                        <ListItem key={index}>{item}</ListItem>
                      ))
                    ) : (
                      <ListItem>{uploadFailed}</ListItem>
                    )}
                  </UnorderedList>
                )}
              </ModalBody>
              <ModalFooter>
                {fileDataCheck === true ? (
                  <HStack gap="12px">
                    <Button
                      variant={dataErrorArray.length > 0 ? "solid" : "outline"}
                      colorScheme={dataErrorArray.length > 0 ? "login" : "teal"}
                      size="md"
                      borderRadius={"8px"}
                      onClick={openReUploadConfirmModal}
                    >
                      Re-upload
                    </Button>
                    {dataErrorArray.length > 0 ? null : (
                      <Button
                        colorScheme="login"
                        size="md"
                        borderRadius={"8px"}
                        onClick={confirmAdd}
                      >
                        {type == "origin"
                          ? "Confirm & Add Locations"
                          : "Confirm & Add Users"}
                      </Button>
                    )}
                  </HStack>
                ) : (
                  <Button
                    colorScheme="login"
                    size="md"
                    borderRadius={"8px"}
                    onClick={handleFileDataVerify}
                    isDisabled={
                      !file ? true : errorMessage.length > 0 ? true : false
                    }
                  >
                    Continue
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <UploadedTemplate
        isOpen={completedModalIsOpen}
        onClose={handleCompleted}
        openCompletedModal={openCompletedModal}
        fileName={file?.name}
        userType={userType}
        avatar={avatar}
        manager={manager}
        details={details}
      />

      {/* //Re-upload warning popup */}
      <ConfirmationModal
        isOpen={reUploadConfirmModalIsOpen}
        onClose={closeReUploadConfirmModal}
        header={"Are you sure you want to re-upload?"}
        content={
          " Re-uploading the file will discard the current data. Are you sure you want to continue?"
        }
        cancelButtonContent={"Cancel"}
        OkButtonContent={"Yes, Proceed"}
        okAction={handleReUpload}
      />
      {/* Cancel warning popup  */}
      <ConfirmationModal
        isOpen={cancelUploadModalIsOpen}
        onClose={closeCancelUploadModal}
        header={"Are you sure you want to cancel and exit?"}
        content={"Your users upload will be canceled."}
        cancelButtonContent={"Stay on Page"}
        OkButtonContent={"Cancel and Exit"}
        okAction={handleCancelUpload}
      />
    </>
  );
}

export default UploadFileModal;
