import React, { useEffect, useState } from "react";
import { Stack, Box, Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

export default function MultiSelect({ data, handleFormDataChange, formData }) {
  return (
    <Stack>
      {formData[data.element.id] ? (
        <CheckboxGroup
          name={data.element.id}
          value={formData[data.element.id].attachments}
          onChange={(val) => {
            handleFormDataChange(val, "", data.element.id, "NA");
          }}
        >
          {data &&
          data.element &&
          data.element.properties &&
          data.element.properties.options ? (
            data.element.properties.options.map((option) => {
              const capitalizedOption = capitalizeStringUpdated(option.label);
              return (
                <Box
                  style={{
                    border: "1px solid #EDF2F7",
                    borderRadius: "8px",
                    padding: "12px",
                  }}
                  key={option.value}
                >
                  <Checkbox size="md" colorScheme="login" value={option.value}>
                    {capitalizedOption}
                  </Checkbox>
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </CheckboxGroup>
      ) : (
        <></>
      )}
    </Stack>
  );
}
