import {
    Flex,
    Input,
    Text,
  } from "@chakra-ui/react";

  const CustomInput = ({
    label,
    placeholder,
    value,
    type,
    onChange,
    name,
    errorMessage,
    isValid,
  }) => {
    return (
      <Flex direction="column" gap="8px" minW={"300px"} flex={2}>
        <Text fontSize={"12px"} fontWeight={"700"}>
          {label}
        </Text>
        <Input
          type={type || "text"}
          placeholder={placeholder}
          value={value}
          width="100%"
          alignSelf="stretch"
          focusBorderColor="#3182ce"
          onChange={onChange}
          fontSize="14px"
          name={name}
          position="unset"
        />
        {!isValid && (
          <Text fontSize="12px" color="red.500">
            {errorMessage}
          </Text>
        )}
      </Flex>
    );
  };

  export default CustomInput;