import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const UserRoleTop = ({ props }) => {
  return (
    <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
      <Text fontSize={"18px"} fontWeight={700}>
        {props.totalRoles} User Roles
      </Text>
      <Link to="/admin/userRoles/create">
        <Button colorScheme="login" size="md" borderRadius={"8px"}>
          + Create Role
        </Button>
      </Link>
    </HStack>
  );
};

export default UserRoleTop;
