import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
const selectDomain = (state) => state?.clientPage || initialState;

export const isLoadingClientData = createSelector(
  [selectDomain],
  (state) => state.isLoadingClientData
);

export const selectClientsOfCustomers = createSelector(
  [selectDomain],
  (state) => state.clientsOfCustomer
);

export const selectClientDetails = createSelector(
  [selectDomain],
  (state) => state.clientDetails
);
export const selectClientAddress = createSelector(
  [selectDomain],
  (state) => state.clientAddress
);

export const selectClientDetail = createSelector(
  [selectDomain],
  (state) => state.clientDetail
);

export const selectAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.avatarUrl || null
);

export const selectClientLinks = createSelector(
  [selectDomain],
  (state) => state.clientLinks || null
);
export const selectProfileImgUrlsArr = createSelector(
  [selectDomain],
  (state) => state.profileImgUrlsArr
);
export const selectClientAddresses = createSelector(
  [selectDomain],
  (state) => state.clientAddresses
);
export const selectClientLink = createSelector(
  [selectDomain],
  (state) => state.clientLink || null
);
export const selectFiltersData = createSelector(
  [selectDomain],
  (state) => state.filtersData
);

export const selectLocation = createSelector(
  [selectDomain],
  (state) => state.locations
);

export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehouseDetails
);

export const selectTotalNumOfClients = createSelector(
  [selectDomain],
  (state) => state.totalNumOfClients
);

// export const selectAllUsersForClient = createSelector(
//   [selectDomain],
//   (state) => {
//     return state.allUsersForClient;
//   }
// );
// export const selectAllClientUserRoles = createSelector(
//   [selectDomain],
//   (state) => state.allClientUserRoles
// );
