import { Flex, Image, Text } from "@chakra-ui/react";
import ListIcon from "assets/svg/BsListTask.svg";
import React from "react";

const JobName = ({ jobName }) => {
  return (
    <Flex direction="column" gap="4px">
      <Text color="#319795" fontSize="14px" fontWeight="500">
        {jobName}
      </Text>
      {/* <Flex gap="10px" alignItems="center">
        <Image src={ListIcon} />
        <Text color="#319795" fontSize="14px" fontWeight="400">
          1/2 Completed
        </Text>
      </Flex> */}
    </Flex>
  );
};

export default JobName;
