import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "@chakra-ui/react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Collapse,
  Box,
  Text,
} from "@chakra-ui/react";
import { DropdownArrowIcon } from "components/Icons/Icons";
import { CloseIcon } from "@chakra-ui/icons";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const styles = {
  scrollableDropdown: {
    backgroundColor: "#FFF",
    position: "absolute",
    top: "47px",
    zIndex: "999",
    maxHeight: "300px",
    overflowY: "auto",
    width: "100%",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    padding: "8px",
  },
  optionsContainer: {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  dropdownLabel: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
};

export default function Select({
  customKey,
  options,
  placeholder,
  handleOptionChange,
  value,
  isDisabled,
  minWidth,
  maxWidth,
  check,
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  const handleSelect = (value) => {
    setInputValue(value);
    handleOptionChange(value, customKey);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  
  const handleInputVal = (value) => {
    setInputValue(value);
    // Filter options based on input value
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const ref = useRef();
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (isDropdownOpen) {
        setDropdownOpen(false);
      }
    },
  });

  const isInputEmpty = inputValue?.trim() === "";
  // console.log(inputValue, isInputEmpty)

  return (
    <>
      <Box
        ref={ref}
        style={{
          position: "relative",
          flex: "1",
          minWidth,
          maxWidth,
          // zIndex: 10000000000,
        }}
        onClick={() => {
          if (isDropdownOpen !== true) {
            setDropdownOpen(true);
          }
        }}
      >
        <InputGroup borderRadius="15px" height="40px">
          <Input
            bg="white"
            fontSize="14px"
            value={
              inputValue !== "UAE"
                ? capitalizeStringUpdated(inputValue)
                : inputValue
            }
            placeholder={placeholder}
            onChange={(e) => {
              handleInputVal(e.target.value);
            }}
            isDisabled={isDisabled}
          />
          {!isDisabled && (
            <InputRightElement>
              {isInputEmpty ? (
                <IconButton
                  bg="inherit"
                  _hover="none"
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                  icon={<DropdownArrowIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown();
                  }}
                />
              ) : (
                <IconButton
                  bg="inherit"
                  _hover="none"
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                  icon={<CloseIcon color="gray.400" height="10px" />}
                  onClick={() => {
                    toggleDropdown();
                    handleSelect("");
                    handleInputVal("");
                  }}
                />
              )}
            </InputRightElement>
          )}
        </InputGroup>

        <Collapse in={isDropdownOpen}>
          <Box sx={styles.scrollableDropdown}>
            {filteredOptions?.length === 0 ? (
              <Box className="name-location-container">
                {/* No Matching {placeholder} */}
                {options.length === 0 ? (
                  check ? (
                    <Text className="warehouse-name-text" color="red">
                      {check}
                    </Text>
                  ) : (
                    <Text className="warehouse-name-text">No data</Text>
                  )
                ) : (
                  <Text className="warehouse-name-text">{`No Matching ${placeholder}`}</Text>
                )}
              </Box>
            ) : (
              <Box sx={{ backgroundColor: "#FFF", zIndex: "10000" }}>
                {filteredOptions?.map((option) => (
                  <Box
                    sx={styles.optionsContainer}
                    _hover={{ cursor: "pointer", bg: "#F7FAFC" }}
                    key={option}
                    onClick={() => {
                      handleSelect(option);
                      toggleDropdown();
                    }}
                  >
                    <Text sx={styles.dropdownLabel}>{option}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
