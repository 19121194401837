export const styles = {
  notificationPageMainContainer: {
    display: "flex",
    padding: "20px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "15px",
    background: "#FFF",
    width: "100%",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
  },
  notificationPageInnerHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },
  notificationMainContainer: {
    width: "300px",
    padding: "20px",
    borderRadius: "15px",
    position: "absolute",
    right: "-20px",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  },
  notificationInnerContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    width: "100%",
  },
  notificationTopContainer: {
    justifyContent: "space-between",
  },
  notificationNewText: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "28px",
  },
  notificationMarkReadText: {
    color: "#718096",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  notificationMiddleContainer: {
    display: "flex",
    flexDirection: "column",
  },
  notificationWhNameText: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  notificationTimeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  notificationTime: {
    color: "#718096",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
};
