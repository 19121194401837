import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { styles } from "./FilterBar.style.module";
import WarehouseFilter from "views/shared/WarehouseFilter";
import DateRangePicker from "views/Dashboard/Inspection Table/components/DateRangePicker";
import AddFilterNew from "./AddFilterNew";
import Seperator from "assets/svg/seperator.svg";

export const FilterBar = ({
  selectedWarehouse,
  setSelectedWarehouse,
  warehouses,
  filters,
  handleFromDateChange,
  handleToDateChange,
  handleApplyFilter,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //start for warehouse filter state and method
  const [searchQueryOfWarehouse, setSearchQueryOfWarehouse] = useState("");
  const [inputValue, setInputValue] = useState(selectedWarehouse || "");

  const handleQuery = (val) => {
    setSearchQueryOfWarehouse(val);
  };

  const handleSelect = (value) => {
    setInputValue(value);
    handleQuery(value);
  };

  const handleWareHouseChange = (warehouseID) => {
    setSelectedWarehouse(warehouseID);
  };

  const handleInputVal = (value) => {
    setInputValue(value);
  };
  //end

  return (
    <Flex sx={styles.mainContainer}>
      <Flex
        w={{ base: "100%", md: "63%" }}
        gap={{ base: "10px", md: "16px" }}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex gap={{ base: "16px" }}>
          <Box flex={{ base: "1", md: "auto" }}>
            <WarehouseFilter
              warehouseOptions={warehouses}
              searchQuery={searchQueryOfWarehouse}
              inputValue={inputValue}
              handleQuery={handleQuery}
              handleSelect={handleSelect}
              handleWareHouseChange={handleWareHouseChange}
              handleInputVal={handleInputVal}
            />
          </Box>
          {isMobile && (
            <Box
              display={{ base: "block", md: "none", lg: "none" }}
              visibility={{ base: "visible", md: "hidden", lg: "hidden" }}
            >
              <AddFilterNew
                handleApplyFilter={handleApplyFilter}
                isMobile={isMobile}
              />
            </Box>
          )}
        </Flex>
        <Box>
          <DateRangePicker
            filters={filters}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
          />
        </Box>
      </Flex>

      <Box
        display={{ base: "none", md: "block" }}
        visibility={{ base: "hidden", md: "visible" }}
      >
        <Flex flexDirection="row" gap="16px">
          <Box>
            <Image src={Seperator} />
          </Box>
          {!isMobile && (
            <AddFilterNew
              handleApplyFilter={handleApplyFilter}
            />
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
