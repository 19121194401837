export const styles = {
  notificationMainContainer: {
    width: "300px",
    padding: "20px",
    borderRadius: "15px",
    position: "absolute",
    right: "-20px",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  },
  notificationInnerContainer: {
    flexDirection: "column",
    rowGap: "20px",
    width: "100%",
  },
  notificationTopContainer: {
    width: "100%",
    justifyContent: "space-between",
  },
  notificationNewText: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  notificationMarkReadText: {
    color: "#718096",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    cursor: "pointer",
  },
  notificationMiddleContainer: {
    rowGap: "12px",
  },
  notificationMiddleInnerContainer: {
    padding: "12px 16px 12px 16px",
    width: "100%",
  },
  notificationWhNameText: {
    width: "100%",
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  notificationTimeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  notificationTime: {
    color: "#718096",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  },
  cursorPointer: {},
};
