import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  warehouseInsightsData: null,
  warehouseInsightsLoading: false,
  error: false,
  inspectionData: null,
};

const collateralManagerSlice = createSlice({
  name: "collateralManager",
  initialState,
  reducers: {
    fetchWarehouseInsightsData(state, action) {
      state.warehouseInsightsLoading = true;
    },
    fetchWarehouseInsightsDataSuccess(state, action) {
      state.warehouseInsightsData = action.payload;
      state.warehouseInsightsLoading = false;
    },
    fetchWarehouseInsightsDataFailure(state, action) {
      state.warehouseInsightsLoading = false;
      state.error = true;
    },
    fetchInspectionData(state, action) {
      // state.loading = true;
    },
    fetchInspectionDataSuccess(state, action) {
      let requiredData = action?.payload || [];
      state.inspectionData = requiredData;
      // state.loading = false;
    },
    fetchInspectionDataFailure(state, action) {
      // state.loading = false;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.warehouseInsightsData = "";
      state.inspectionData = "";
    },
    clearStateFailure(state, action) {},
  },
});

export const { actions, reducer, name: sliceKey } = collateralManagerSlice;
