import React from "react";
import { useHistory } from "react-router-dom";
import Banner from "components/Layout/auth/Banner/Banner";


import { LoginForm } from "@agnext/reactlib";

const PasswordResetSuccessful = () => {
  const history = useHistory();

  const handleBackToSignin = () => {
    history.push("/auth/signin");
  };

  return (
    <LoginForm
      type="passwordResetSuccessful"
      leftComponent={<Banner />}
      logo={
        <Image
          w="100%"
          h="130%"
          mt="20px"
          src={ process.env.REACT_APP_LOGO_URL }
          alt="inspeqt-logo"
        />
      }
      handleGoBack={handleBackToSignin}
    />
  );
};
export default PasswordResetSuccessful;