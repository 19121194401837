import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Edit2 from "assets/svg/edit2.svg";
import location from "assets/svg/location.svg";
import dateRange from "assets/svg/dateRange.svg";
import buisnessLogo from "assets/svg/Busisness Type.svg";
import defaultAvatar from "assets/img/avatars/defaultAvatar.png";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import { Link } from "react-router-dom";

const ProfileWithName = ({ clientAvatar }) => {
  return (
    <Box
      background={"#EDF2F7"}
      height={"57px"}
      width={"57px"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ cursor: "pointer" }}
      borderRadius={"15px"}
    >
      <Image src={clientAvatar?.url || defaultAvatar} alt="" />
    </Box>
  );
};

const Dot = ({ status }) => {
  const bg = status === true ? "#4FD1C5" : "red";
  return (
    <Box
      style={{
        width: "7px",
        height: "7px",
        background: bg,
        borderRadius: "50%",
      }}
    />
  );
};

const statusText = {
  color: "#718096",
  fontSize: "14px",
  fontWeight: "400",
  wordWrap: "break-word",
  textTransform: "capitalize",
  alignItems: "center",
};

const outerBox = {
  display: "flex",
  justifyContent: "space-between",
  background: "linear-gradient(91deg, #FFF 39.14%, #B6FFF8 263.21%), #FFF",
  padding: "24px 20px",
  borderRadius: "15px",
};

const editButtonStyle = {
  background: "teal.400",
  height: "40px",
  width: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
};

const SvgAndText = ({ src, text }) => {
  return (
    <Flex align="center" gap={"1px"}>
      <Box>
        <Image src={src} alt="Dan Abramov" />
      </Box>
      <Text
        style={{
          color: "#718096",
          fontSize: "16px",
          fontWeight: "400",
          wordWrap: "break-word",
          padding: "4px",
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

const ViewClientTop = ({
  clientDetail,
  addressArray,
  clientAvatar,
  clientId,
  customerId,
  userRole
}) => {
  return (
    <Box style={outerBox}>
      <Flex width="100%" gap="16px">
        <ProfileWithName clientAvatar={clientAvatar} />
        <Flex flex="1" direction="column" gap="8px">
          <Flex lineHeight="25.2px" gap="8px" alignItems="center">
            {clientDetail && clientDetail?.name ? (
              <Text
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  color: "#2D3748",
                }}
              >
                {clientDetail?.name}
              </Text>
            ) : (
              "-"
            )}
            <Flex alignItems="center">
              <Flex alignItems="center" h="inherit" px="8.5px">
                <Dot status={clientDetail?.isActive} />
              </Flex>

              <Text sx={statusText}>
                {clientDetail?.isActive ? "Active" : "Inactive"}
              </Text>
            </Flex>
          </Flex>
          <Stack
            direction="row"
            align="center"
            height="100%"
            gap="24px"
            h="24px"
          >
            <SvgAndText
              src={location}
              text={clientDetail?.country ? clientDetail?.country : "-"}
            />
            {/* <SvgAndText
              src={buisnessLogo}
              text={clientDetail?.businessType || ""}
            /> */}
            <SvgAndText
              src={dateRange}
              text={
                clientDetail?.creationDt
                  ? extractDayMonthYearFromTimestamp(clientDetail?.creationDt)
                  : "-"
              }
            />
          </Stack>
        </Flex>
        {userRole === "admin" ? (
          <Link
            to={`/admin/clients/updateClient?clientId=${clientId}&customerId=${customerId}`}
          >
            <Box sx={editButtonStyle}>
              <Image src={Edit2} alt="" />
            </Box>
          </Link>
        ) : null}
      </Flex>
    </Box>
  );
};

export default ViewClientTop;
