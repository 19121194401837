import React from "react";
import Dropdown from "views/shared/Dropdown";

function Location({ locationNames, handleFilterQuery, filterQuery, property }) {
  return (
    <Dropdown
      key={property}
      options={locationNames}
      placeholder={"Location"}
      handleFilterQuery={handleFilterQuery}
      property={property}
      listKey={"city"}
      filterQuery={filterQuery}
    />
  );
}

export default Location;
