import { Button } from "@chakra-ui/react";
import React from "react";

export const getStatusColor = (status) => {
  switch (status) {
    case "draft":
      return "#D69E2E";
    case "completed":
      return "#3182CE";
    case "published" :
      return "#38A169";
  }
};
export const text = (status) => {
  switch (status) {
    case "published":
      return "PUBLISHED";
    case "completed":
    return "COMPLETED";
    case "draft":
      return "DRAFT";
  }
};

const Status = ({ status}) => {
  return (
    status ?
    <Button
      colorScheme="white"
      color="white"
      size="xs"
      bg={getStatusColor(status)}
      borderWidth={1}
      borderRadius={5}
      mx={"1px"}
      py={"0px"}
      px={"10px"}
      alignItems="center"
    >
      {text(status)}
    </Button>
    : <></>
  );
};

export default Status;