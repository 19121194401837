import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

export function generateUUId() {
  return uuidv4();
}

export function convertToCamelCase(str) {
  return _.camelCase(str);
}

export function filterArray(arr, filterString) {
  filterString = filterString.toLowerCase();
  return arr.filter((option) =>
    option.label.toLowerCase().includes(filterString)
  );
}

export function getDataForFormSubmission({ formData, components }) {}

export function isNanoId(
  id,
  alphabet = "1234567890abcdefghijklmnopqrstuvwxyz",
  length = 16
) {
  const nanoIdPattern = new RegExp(`^[${alphabet}]{${length}}$`);

  return nanoIdPattern.test(id);
}
