import { HStack, Box, ButtonGroup, Text, Input } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import Editable from "../../../Components/Editable";
import * as selectors from "../selectors";
import { actions } from "../slice";
import { generateId } from "../../../../../utils/index";
import { AddIcon } from "@chakra-ui/icons";
import Block from "../Block";
import LinkTo from "../../../Components/LinkTo";

export default function Section({ sectionId, sectionOrder, sectionOrderLast }) {
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [titleOption, setTitleOption] = useState({
    label: "",
    value: "",
  });

  const [sectionDescription, setsectionDesciption] = useState("Description");

  const [pageNumber, setPageNumber] = useState(1);

  const sections = useSelector(selectors.selectSectionsForBlocks);

  const dispatch = useDispatch();

  const getBlocks = useCallback(() => {
    let blocks = [];
    if (sections.length) {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          blocks = section.structure && section.structure.blocks;
        }
      });
    }
    return blocks;
  }, [sections, sectionId]);

  const getSectionName = useCallback(() => {
    let sectionName = "";
    if (sections.length) {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          sectionName = section.title;
        }
      });
    }
    return sectionName;
  }, [sections, sectionId]);

  const getLinks = useCallback(() => {
    let links;
    if (sections.length) {
      sections.forEach((section) => {
        if (section.id === sectionId) {
          links = section.structure &&
            section.structure.links && [...section.structure.links];
        }
      });
    }
    return links || [];
  }, [sectionId, sections]);

  const [sectionName, setSectionName] = useState(getSectionName());

  const handleSectionNameChange = (value) => {
    setSectionName(value);
  };

  const handleSectionDescriptionChange = (value) => {
    setsectionDesciption(value);
  };

  const handleSubmitLinkTo = () => {
    dispatch(
      actions.addLinkToSection({
        sectionId,
        linkTo: {
          type: selectedOption.value,
          id: titleOption.value,
          title: titleOption.label,
        },
      })
    );
    const newOption = { label: "", value: "" };
    setSelectedOption(newOption);
    setTitleOption(newOption);
  };

  const handleSectionNameSubmit = (sectionName) => {
    const payload = {
      sectionId,
      sectionName,
    };
    dispatch(actions.editSectionName(payload));
  };

  const handlePageNumberChange = (e) => {
    setPageNumber(e.target.value);
  };

  useEffect(() => {
    dispatch(
      actions.editSectionPageNumber({
        sectionId,
        pageNumber: parseInt(pageNumber),
      })
    );
  }, [dispatch, pageNumber, sectionId]);

  const handleSectionDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      sectionId,
    };
    dispatch(actions.duplicateSection(payload));
  };

  const handleSectionDelete = () => {
    const payload = {
      sectionId,
    };
    dispatch(actions.deletionSection(payload));
  };

  const handleSectionDescriptionSubmit = (sectionDescription) => {
    const payload = {
      sectionId,
      sectionDescription,
    };
    dispatch(actions.editSectionDescription(payload));
  };

  const moveUpHandler = () => {
    const payload = {
      sectionId,
    };
    dispatch(actions.moveUpSection(payload));
  };

  const moveDownHandler = () => {
    const payload = {
      sectionId,
    };
    dispatch(actions.moveDownSection(payload));
  };

  const handleAddBlock = async () => {
    const blockId = await generateId();
    dispatch(actions.initNewBlock({ sectionId, blockId }));
  };
  let blocks = getBlocks();
  return (
    <Box
      style={{
        padding: "16px",
        border: "1px solid #efefef",
        margin: "16px 0 16px 0",
        borderLeft: "2px solid rgba(84, 3, 117, 0.5)",
        borderRadius: "0 8px 8px 0",
      }}
    >
      <HStack
        justify="space-between"
        style={{
          background: "rgba(84,3, 117, 0.03)",
          padding: "7px 12px",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <Box w="40%">
          <Editable
            text={sectionName}
            handleTextChange={handleSectionNameChange}
            handleSubmit={handleSectionNameSubmit}
          />
        </Box>
        <HStack w="100px">
          <Text>Page</Text>
          <Input
            value={pageNumber}
            onChange={handlePageNumberChange}
            type="number"
            focusBorderColor="login.500"
          />
        </HStack>
        <ButtonGroup
          justifyContent="center"
          size="md"
          style={{ position: "relative" }}
        >
          <Moveup
            moveUpHandler={moveUpHandler}
            isDisabled={sectionOrder === 1 ? true : false}
          />
          <MoveDown
            moveDownHandler={moveDownHandler}
            isDisabled={sectionOrder === sectionOrderLast ? true : false}
          />
          <Delete deleteHandler={handleSectionDelete} />
          <Duplicate duplicateHandler={handleSectionDuplicate} />
          <LinkTo
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            title={titleOption}
            setTitle={setTitleOption}
            handleSubmit={handleSubmitLinkTo}
            links={getLinks()}
            parentType="sections"
            parentId={sectionId}
          />
        </ButtonGroup>
        <Box>
          <Button
            size="sm"
            onClick={handleAddBlock}
            colorScheme="login"
            leftIcon={<AddIcon />}
            label="Block"
          />
        </Box>
      </HStack>
      <HStack style={{ padding: "16px 0" }}>
        <Box w="30%" style={{ paddingLeft: "12px" }}>
          <Editable
            text={sectionDescription}
            handleTextChange={handleSectionDescriptionChange}
            handleSubmit={handleSectionDescriptionSubmit}
          />
        </Box>
      </HStack>

      {getBlocks() &&
        getBlocks().map((block) => (
          <Block
            key={block.id}
            blockId={block.id}
            sectionId={sectionId}
            blockOrder={block.order}
            blockOrderLast={
              blocks.length >= 1 ? blocks[blocks.length - 1].order : 0
            }
          />
        ))}
    </Box>
  );
}
