import { Avatar, AvatarGroup, Box } from "@chakra-ui/react";
import React, { useEffect } from "react";

const AvatarGroupWithName = ({ avatarUrlArr }) => {
  // useEffect(() => {}, [avatarNameArr]);
  return (
    <AvatarGroup size="sm" spacing={"-0.5rem"} max={7}>
      {avatarUrlArr?.map((avatarDetail, index) => (
        <Avatar
          key={index}
          // name={avatarNameArr[index].name}
          src={avatarDetail.url}
        />
      ))}
    </AvatarGroup>
  );
};

export default AvatarGroupWithName;
