import React, { useState } from "react";
import { HStack, Box, Text, VStack } from "@chakra-ui/react";
import Select from "./components/Select";

const filtersContainerStyle = {
  padding: "16px 20px",
  justifyContent: "flex-end",
  gap: "32px",
  width: "100%",
  backgroundColor: "#FFF",
  boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
  borderRadius: "15px",
  gap: "16px",
  border: "1px solid #E2E8F0",
  borderRadius: "15px",
};

export default function SummaryNCCF() {
  const [filterQuery, setFilterQuery] = useState({
    seasons: "",
    commodities: "",
    inspectionType: "Procurement",
  });

  const options = ["optionA", "optionB", "optionC", "optionD"];

  const inspectionTypes = ["Procurement", "Loading", "Unloading"];

  const handleFilterChange = (value, key) => {
    const newFilterQuery = {
      ...filterQuery,
      [key]: value,
    };
    setFilterQuery(newFilterQuery);
  };

  return (
    <Box mt="75px">
      <HStack sx={filtersContainerStyle}>
        <Box w="330px">
          <Select
            options={options}
            placeholder="Commodities"
            handleOptionChange={handleFilterChange}
            key="commdities"
          />
        </Box>
        <Box w="330px">
          <Select
            options={options}
            placeholder="Seasons"
            handleOptionChange={handleFilterChange}
            key="seasons"
          />
        </Box>
        <Box w="330px">
          <Select
            options={inspectionTypes}
            placeholder="Inspection Types"
            handleOptionChange={handleFilterChange}
            key="inspectionType"
          />
        </Box>
      </HStack>
      <HStack w="100%" justifyContent="space-between" mt="32px" gap="12px">
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Quantity Procured through BEAM
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            292392 MT
          </Text>
        </VStack>
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Quantity Inspected
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            171749 MT
          </Text>
        </VStack>
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Quantity Accepted
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            91940 MT
          </Text>
        </VStack>
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Quantity Unaccepted
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            79808 MT
          </Text>
        </VStack>
      </HStack>
      <HStack w="100%" mt="32px" gap="16px">
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Inspections Conducted
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            99
          </Text>
        </VStack>
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Warehouses Inspected
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            13
          </Text>
        </VStack>
        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "12px 20px",
            borderRadius: "15px",
            background: "#fff",
            width: "calc(25% - 12px)",
          }}
          gap="12px"
        >
          <Text style={{ color: "#7e8da2", fontWeight: "400" }}>
            Location Covered
          </Text>
          <Text
            color="login.label"
            style={{ fontWeight: "500", fontSize: "22px" }}
          >
            11
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
}
