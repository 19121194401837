import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const CustomModal = ({
  isOpen,
  onClose,
  headerText,
  bodyText,
  buttonText,
  onButtonClick,
  additionalContent,
  colorScheme,
  maxWidth,
  isDisabled,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={maxWidth} borderRadius="18px">
        <ModalHeader color={"#2D3748"} fontWeight={700} fontSize={"18px"}>
          {headerText}
        </ModalHeader>
        <ModalCloseButton me="5px" mt="6px"/>
        <ModalBody>
          {bodyText}
          {additionalContent && additionalContent}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={colorScheme}
            onClick={onButtonClick}
            isDisabled={isDisabled}
            mr={3}
          >
            {buttonText}
          </Button>
          <Button colorScheme="modalsLight" onClick={onClose} color={"black"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
