import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.dashboard || initialState;

export const selectData = createSelector([selectDomain], (state) => state.data);

export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehousesData
);

export const loading = createSelector([selectDomain], (state) => state.loading);
export const workflowLoading = createSelector(
  [selectDomain],
  (state) => state.workflowLoading
);
export const error = createSelector([selectDomain], (state) => state.error);
export const selectInspectionIdArr = createSelector(
  [selectDomain],
  (state) => state.inspectionIdArr
);
export const selectTableData = createSelector(
  [selectDomain],
  (state) => state.tableData
);
export const selectTableLoading = createSelector(
  [selectDomain],
  (state) => state.tableloading
);
export const selectInspectionCounts = createSelector(
  [selectDomain],
  (state) => state.inspectionCount
);
export const selectGeneratedReportMonths = createSelector(
  [selectDomain],
  (state) => state.generatedReportMonths
);
export const selectWorkflow = createSelector(
  [selectDomain],
  (state) => state.workflow
);

export const selectSectionsForBlocks = createSelector(
  [selectDomain],
  (state) => state.sections || []
);

export const selectBlocksForComposites = createSelector(
  [selectDomain],
  (state) => state.blocks || []
);

export const selectCompositesForComponents = createSelector(
  [selectDomain],
  (state) => state.composites || []
);

export const selectComponentsForElements = createSelector(
  [selectDomain],
  (state) => state.components || []
);

export const selectBlockchainData = createSelector(
  [selectDomain],
  (state) => state.blockchainData || []
);

export const selectBlockchainVerificationFailed = createSelector(
  [selectDomain],
  (state) => state.blockchainVerificationFailed || null
);

export const selectUserDetails = createSelector(
  [selectDomain],
  (state) => state.userData || null
);

export const selectAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.avatarUrl || null
);

export const selectJobDetails = createSelector(
  [selectDomain],
  (state) => state.jobDetails || null
);

export const selectTaskDetails = createSelector(
  [selectDomain],
  (state) => state.taskDetails || null
);

export const selectClientsOfCustomer = createSelector(
  [selectDomain],
  (state) => state.clientsOfCustomer
);

export const selectIsHederaEnabled = createSelector(
  [selectDomain],
  (state) => state.isHederaEnabled
);

export const selectFiltersData = createSelector(
  [selectDomain],
  (state) => state.filtersData
);