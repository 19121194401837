import React from "react";
import DateRangePicker from "../../../../../../views/Dashboard/Inspection Table/components/DateRangePicker";

function DateSelection({
  filters,
  handleFromDateChange,
  handleToDateChange,
  property,
}) {
  return (
    <DateRangePicker
      width="100%"
      filters={filters}
      handleFromDateChange={handleFromDateChange}
      handleToDateChange={handleToDateChange}
    />
  );
}

export default DateSelection;
