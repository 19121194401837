import React, { useEffect, useRef, useState } from "react";
import {
  HStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  VStack,
  Text,
  Button,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { ViewIcon, EditIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import WorkflowExport from "../WorkflowExport";
import Duplicate from "../duplicate";
import { CustomModal } from "../Modal/CustomModal";
import FallbackToast from "errorsFallback/FallbackToast";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "errorsFallback/ErrorFallback";

export default function WorkflowAction({
  id,
  handleWorkflowDuplicate,
  oldWorkflowTitle,
  status,
}) {
  const [workflowTitle, setWorkflowTitle] = useState("");
  const initialRef = useRef(null);
  const {
    isOpen: isOpenCopyWorkflowModal,
    onOpen: onOpenCopyWorkflowModal,
    onClose: onCloseCopyWorkflowModal,
  } = useDisclosure();

  useEffect(() => {
    if (oldWorkflowTitle) {
      setWorkflowTitle(oldWorkflowTitle);
    }
    if (isOpenCopyWorkflowModal && initialRef.current) {
      initialRef.current.focus();
    }
  }, [oldWorkflowTitle, isOpenCopyWorkflowModal]);
  return (
    <>
      <HStack>
        <Link to={`/admin/workflows/${id}`}>
          <Button
            borderRadius="8px"
            colorScheme="grey"
            sx={{
              _hover: {
                bg: "teal.400",
                ".icon": {
                  color: "white",
                },
              },
            }}
            I
          >
            {status === "draft" ? (
              <EditIcon color="#718096" size={18} className="icon" />
            ) : (
              <ViewIcon color="#718096" size={18} className="icon" />
            )}
          </Button>
        </Link>
        <Duplicate
          duplicateHandler={() => {
            onOpenCopyWorkflowModal();
          }}
        />

        <WorkflowExport workflowId={id} />
      </HStack>
      {/* Below modal represent the input taken from the user for the copied wokflow name.*/}
      <CustomModal
        isOpen={isOpenCopyWorkflowModal}
        onClose={onCloseCopyWorkflowModal}
        headerText="Copy Workflow"
        onButtonClick={() => {
          handleWorkflowDuplicate(id, workflowTitle);
        }}
        bodyText={
          <>
            <Text color="#718096" mb="10px">
              Name
            </Text>
            <Input
              ref={initialRef}
              focusBorderColor="#38B2AC"
              type="text"
              value={workflowTitle}
              onChange={(e) => setWorkflowTitle(e.target.value)}
            />
          </>
        }
        buttonText="Make a copy"
        maxWidth="436px"
        colorScheme="teal"
        initRef={initialRef}
        isDisabled={!workflowTitle}
      />
    </>
    // <Popover
    //   autoFocus={false}
    //   closeOnBlur={true}
    //   closeOnEsc={true}
    //   closeOnOverlayClick={true}
    //   style={{position: "relative"}}
    // >
    //   {({ isOpen, onClose }) => (
    //     <>
    //       <PopoverTrigger>
    //         <HStack style={{ cursor: "pointer" }} align="center">
    //           <IoEllipsisVerticalSharp />
    //         </HStack>
    //       </PopoverTrigger>
    //       <Portal>
    //         <PopoverContent
    //           w="120px"
    //           style={{
    //             outline: "none",
    //             border: "1px solid #E2E8F0",
    //             padding: "8px",
    //             boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    //             background: "red",
    //             position: "absolute",
    //             top: "0",
    //             right: "100px"
    //           }}
    //         >
    //           <VStack gap="8px">
    //             {/* <Link to={`/admin/workflows/update/${id}`}>
    //               <HStack justify="center">
    //                 <Text>Edit</Text>
    //               </HStack>
    //             </Link> */}
    // <Link to={`/admin/workflows/${id}`}>
    //   <HStack justify="center">
    //     <Text>View</Text>
    //   </HStack>
    // </Link>
    //           </VStack>
    //         </PopoverContent>
    //       </Portal>
    //     </>
    //   )}
    // </Popover>
  );
}
