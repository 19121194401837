import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

const tableHeader = {
  padding: "16px 24px",
  fontSize: "12px",
  fontWeight: "600",
  color: "#718096",
};

const tableRowLeft = {
  padding: "16px 24px",
  minWidth: "123px",
  fontSize: "14px",
  fontWeight: "600",
  color: "#4A5568",
};
const tableRowRight = {
  padding: "16px 24px",
  fontSize: "14px",
  fontWeight: "400",
};

const ViewRolePermission = ({ groupedData }) => {
  const permissionEntries = groupedData && Object.entries(groupedData);

  const finalObject =
    groupedData &&
    permissionEntries.map(([category, permissions]) => {
      const actions = permissions.map((permission) => {
        return permission.permsSetActionType;
      });

      return { [category]: actions };
    });

  const transformedData =
    groupedData &&
    finalObject.map((entry, index) => {
      const key = Object.keys(entry)[0];
      const values = entry[Object.keys(entry)[0]];
      const transformedValues = values?.map((value, valueIndex) => {
        if (value?.includes("_")) {
          const words = value.split("_");
          const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1)
          );
          return capitalizedWords.join(" ");
        } else {
          return value?.charAt(0).toUpperCase() + value?.slice(1);
        }
      });
      const sortedTransformedValues = transformedValues.sort();

      const transformedEntry = {
        [key]: sortedTransformedValues,
      };

      return transformedEntry;
    });

  return (
    <Stack spacing={"24px"}>
      <Box>
        <Text fontWeight={700}>
          {groupedData && Object.keys(groupedData).length} Pages Permitted
        </Text>
      </Box>
      <Box
        style={{
          background: "white",
          boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
          borderRadius: 15,
        }}
      >
        <Flex>
          <Text sx={tableHeader} style={{ minWidth: "123px" }}>
            Pages
          </Text>
          <Text sx={tableHeader}>Permissions</Text>
        </Flex>
        {groupedData &&
          transformedData.map((entry, index) => (
            <Flex style={{ borderTop: "1px #E2E8F0 solid" }} key={index}>
              <Text sx={tableRowLeft}>{Object.keys(entry)[0]}</Text>
              <Text sx={tableRowRight}>
                {entry[Object.keys(entry)[0]].join(", ")}
              </Text>
            </Flex>
          ))}
      </Box>
    </Stack>
  );
};

export default ViewRolePermission;
