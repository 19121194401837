import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ClientsTop = ({ totalClients, userRole }) => {
  return (
    <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
      <Text fontSize={"18px"} fontWeight={700}>
        {totalClients < 2
          ? totalClients + " Client"
          : totalClients + " Clients"}
      </Text>
      {userRole === "admin" ? (
        <Link to="/admin/clients/addClient">
          <Button
            colorScheme="login"
            bg={"#38B2AC"}
            size="md"
            borderRadius={"8px"}
          >
            + Add Client
          </Button>
        </Link>
      ) : null}
    </HStack>
  );
};

export default ClientsTop;
