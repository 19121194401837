import { Box, Flex, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CreateRoleInput from "./components/createRoleInput/CreateRoleInput";
import PermissonsTable from "./components/CreateRole/PermissionsTable/PermissonsTable";
import CreateRoleBottom from "./components/CreateRole/CreateRoleBottom/CreateRoleBottom";
import { watchRolesAndPermission } from "./saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import { userStorage } from "utils/helper";
import { useHistory, useParams } from "react-router-dom";
import FallbackUI from "errorsFallback/FallbackUI";
import { ErrorBoundary } from "react-error-boundary";

const containerStyles = {
  display: "flex",
  mt: "75px",
  height: "85vh",
  gap: "16px",
  flexDirection: "column",
  justifyContent: "space-between",
};

const CreateRole = ({ userData }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchRolesAndPermission });
  const [selectedValues, setSelectedValues] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const { roleId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userData) {
      dispatch(actions.fetchRolePermissionsDataRequest({ roleId }));
      setRoleName(userData?.payload?.roleName || "");
      setRoleDescription(userData?.payload?.description || "");
    }
  }, [userData]);

  useEffect(() => {
    dispatch(actions.fetchPermissionsDataRequest());
  }, []);

  const allPermissionsData = useSelector(selectors.selectPermissionData);
  const groupedDataByPages = allPermissionsData?.payload.reduce(
    (acc, currentItem) => {
      const { permsSetName, ...rest } = currentItem;
      if (!acc[permsSetName]) {
        acc[permsSetName] = [];
      }
      acc[permsSetName].push({ ...rest });
      return acc;
    },
    {}
  );
  const groupedDataByAction = allPermissionsData?.payload.reduce(
    (acc, currentItem) => {
      const { actionType, ...rest } = currentItem;
      if (!acc[actionType]) {
        acc[actionType] = [];
      }
      acc[actionType].push({ ...rest });
      return acc;
    },
    {}
  );

  const rolePermissionData = useSelector(selectors.selectRolePermissionData);
  const prevPermissions = rolePermissionData?.payload?.map((permission) => {
    return permission.permsSetId;
  });

  useEffect(() => {
    if (userData && rolePermissionData?.payload) {
      const newSelectedValues = rolePermissionData.payload.map(
        (permission) => permission.permsSetId
      );
      setSelectedValues(newSelectedValues);
    }
  }, [rolePermissionData]);

  const userId = userStorage.get()?.id;

  const handleButtonClick = (buttonName, handleError) => {
    // //console.log("Button clicked:", buttonName);
    const newRoleObject = {
      roleName: roleName,
      description: roleDescription,
      permsSetIds: selectedValues,
    };
    if (buttonName === "Go Back" || buttonName === "Save Changes") {
      if (buttonName === "Go Back") {
        setSelectedValues([]);
        history.goBack();
      } else if (buttonName === "Save Changes") {
        newRoleObject.updatedBy = userId;
        if (userData) {
          newRoleObject.status = "active";
        }
        dispatch(
          actions.fetchRoleUpdateDataRequest({
            newRoleObject,
            roleId,
            onSuccess: (status, reason) => {
              //console.log(status);
              if (status === true) {
                history.goBack();
              } else {
                //console.log(reason);
                handleError(reason);
              }
            },
          })
        );
      }
    }
    if (buttonName === "Save as Draft" || buttonName === "Create Role") {
      newRoleObject.createdBy = userId;
      newRoleObject.status =
        buttonName === "Save as Draft" ? "draft" : "active";
      dispatch(
        actions.fetchPostUserRoleRequest({
          newRoleObject,
          onSuccess: (status, reason) => {
            //console.log(status);
            if (status === true) {
              history.goBack();
            } else {
              handleError(reason);
            }
          },
        })
      );
    }
  };

  const handlePermissionsTableData = (data) => {};
  const isEditing = Boolean(userData);
  const buttons = [];
  if (isEditing) {
    buttons.push("Go Back");
    buttons.push("Save Changes");
  } else {
    buttons.push("Save as Draft");
    buttons.push("Create Role");
  }

  const isDisabled = () => {
    if (
      selectedValues.length == 0 ||
      roleName === "" ||
      roleDescription === ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box sx={containerStyles}>
        <Stack gap={"36px"}>
          <CreateRoleInput
            isEditing={isEditing}
            roleName={roleName}
            setRoleName={setRoleName}
            roleDescription={roleDescription}
            setRoleDescription={setRoleDescription}
          />
          <PermissonsTable
            groupedDataByPages={groupedDataByPages}
            onPermissionsTableData={handlePermissionsTableData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            groupedDataByAction={groupedDataByAction}
          />
        </Stack>
        <CreateRoleBottom
          isEditing={isEditing}
          handleButtonClick={handleButtonClick}
          buttons={buttons}
          isDisabled={isDisabled}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default CreateRole;
