import { Button, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useErrorBoundary } from "react-error-boundary";
import { FaExclamationTriangle } from "react-icons/fa";

function FallbackUI({
  error,
  resetErrorBoundary,
  pt = "10px",
  mtop = "0px",
  minH = "500px",
}) {
  const { resetBoundary } = useErrorBoundary();
  return (
    <Stack pt={pt} mt={mtop} justifyContent="center" alignContent="center">
      <VStack
        justifyContent="center"
        alignContent="center"
        borderRadius="15px"
        padding="10px"
        backgroundColor="white"
        border={"1px solid #efefef"}
        minH={minH}
      >
        <Stack>
          <FaExclamationTriangle size="60px" color="#DD6B20"/>
        </Stack>
        <Heading size="lg">Something Went Wrong</Heading>
        <Text color="#DD6B20">Please Contact Service Provider (contact@agnext.com)!!</Text>
        {/* <Button
          colorScheme="login"
          onClick={() => {
            resetBoundary();
            console.log("try again");
          }}
        >
          Try Again
        </Button> */}
      </VStack>
    </Stack>
  );
}

export default FallbackUI;
