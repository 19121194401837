import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.myApprovals || initialState;

export const selectTotalPendingApprovalsCount = createSelector(
  [selectDomain],
  (state) => state.totalPendingApprovalsCount
);

export const selectCommoditiesData = createSelector(
  [selectDomain],
  (state) => state.commoditiesData
);

export const selectCommoditiesLoading = createSelector(
  [selectDomain],
  (state) => state.commoditiesLoading
);

export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehousesData
);

export const error = createSelector([selectDomain], (state) => state.error);

export const selectReviewedInspectionData = createSelector(
  [selectDomain],
  (state) => state.reviewedInspectionData
);
export const selectSubmittedInspectionData = createSelector(
  [selectDomain],
  (state) => state.submittedInspectionData
);

export const selectReviewedDataWithoutPages = createSelector(
  [selectDomain],
  (state) => state.reviewedDataWithoutPages
);
export const selectPendingApprovalsCountForBadge = createSelector(
  [selectDomain],
  (state) => state.PendingApprovalsCountForBadge
);

export const selectFiltersData = createSelector(
  [selectDomain],
  (state) => state.filtersData
);
