import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Button,
  FormControl,
  FormLabel,
  Switch,
  Collapse,
  ButtonGroup,
  Box,
} from "@chakra-ui/react";
import ColumnsFilterSvg from "assets/svg/BsLayoutThreeColumns.svg";
import { useOutsideClick } from "@chakra-ui/react";
import { ResetIcon } from "components/Icons/Icons";
import { DragIcon } from "components/Icons/Icons";
import DragButton from "assets/svg/DragHandle.svg";
import { v4 as uuidv4 } from "uuid";
import { SearchBar } from "./SearchBar";

const ColumnItem = ({ option, index, moveColumn, handleCheckedOptions }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN",
    item: { index, option },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "COLUMN",
    drop: (draggedItem) => {
      if (!drag) return;

      const draggedIndex = draggedItem.index;
      const hoverIndex = index;

      if (draggedIndex === hoverIndex) return;
      moveColumn(draggedIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  return (
    <Box
      ref={(node) => drag(drop(node))}
      className="column-customize-label"
      style={{
        display: option?.display?.toLowerCase() === "action" ? "none" : "block",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <FormControl display="flex" justifyContent="space-between">
        <FormLabel htmlFor={option.display} display="flex" gap="8px" m="0px">
          <img className="drag-btn" src={DragButton} alt="Drag" />
          {option.display}
        </FormLabel>
        <Switch
          className="dropdownOption"
          float="right"
          isChecked={option.visible}
          id={option.display}
          colorScheme="login"
          onChange={(e) =>
            handleCheckedOptions(option.display, e.target.checked)
          }
        />
      </FormControl>
    </Box>
  );
};

const ColumnFilter = ({
  checkedOptions,
  handleCheckedOptions,
  handleReset,
  handleDragUpdate,
  isMobile,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [columnHeadList, setColumnHeadList] = useState([]);

  useEffect(() => {
    let filteredcheckedOptions = checkedOptions.filter((option) => {
      return option?.display?.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setColumnHeadList(filteredcheckedOptions);
  }, [checkedOptions, searchQuery]);

  const moveColumn = (fromIndex, toIndex) => {
    const updatedOptions = [...checkedOptions];
    const [movedItem] = updatedOptions.splice(fromIndex, 1);
    updatedOptions.splice(toIndex, 0, movedItem);
    handleDragUpdate(updatedOptions);
  };

  const ref = React.useRef();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      setDropdownOpen(false);
      setSearchQuery("");
    },
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <Box ref={ref} style={{ position: "relative" }}>
        <Box
          className="ColumnFilterContainer"
          bg="#F7FAFC"
          onClick={toggleDropdown}
        >
          <img src={ColumnsFilterSvg} alt="Columns Filter" />
        </Box>
        <Collapse
          in={isDropdownOpen}
          className={
            isDropdownOpen
              ? isMobile
                ? "customize-column-mobile"
                : "customize-column"
              : "customize-column hide"
          }
        >
          <ButtonGroup style={{ width: "100%" }}>
            <Button
              className="reset-btn"
              leftIcon={<ResetIcon />}
              variant="outline"
              onClick={handleReset}
            >
              Reset
            </Button>
          </ButtonGroup>
          <SearchBar
            searchQuery={searchQuery}
            handleSearchQuery={setSearchQuery}
            placeHolder={"Search Column Headers..."}
          />
          <Box className="scrollable-dropdown">
            {columnHeadList?.map((option, index) => (
              <ColumnItem
                key={uuidv4()}
                option={option}
                index={index}
                moveColumn={moveColumn}
                handleCheckedOptions={handleCheckedOptions}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
    </DndProvider>
  );
};

export default ColumnFilter;
