import React from "react";
import "./index.css";
import loginPageImage from "../../../../assets/img/logInImage2.png";
import { Box, Image, Text } from "@chakra-ui/react";

function Banner() {
  return (
    <Box className="banner-container">
      <Box className="image-container">
        <Image
          src={loginPageImage}
          borderRadius="inherit"
          w="100%"
          h="100%"
          objectFit="cover"
          objectPosition="center"
          className="banner-image"
        />
      </Box>
      <Box className="tags-container" justifyContent="space-between">
        <Box className="tags-inner-container">
          <Text className="tagline">
            Digital Inspections - Easier, Faster & Reliable
          </Text>
          <Text className="quote">
            Uncover the true value of collateral assets through meticulous
            inspections, enabling accurate risk assessment for improved lending
            strategies.
          </Text>
        </Box>
        <Text className="rights">
          © 2023 AgNext Technologies. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
}

export default Banner;
