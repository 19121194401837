// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_hello__rNzt8 {\n  background: red;\n}\n\n.styles_links__GiCw1 {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Workflow Builder/Components/moveup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".hello {\n  background: red;\n}\n\n.links {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hello": "styles_hello__rNzt8",
	"links": "styles_links__GiCw1"
};
export default ___CSS_LOADER_EXPORT___;
