import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../services/apis";
import * as API1 from "services/index";

import { downloadPdf, downloadSummarySheet } from "utils/commonFunctions";
//Generator Functions
export function* handleFetchData({ payload }) {
  try {
    const res = yield call(API.getInspection, payload.payload);
    const { data } = res;
    if (
      data.payload.length === 0 &&
      payload &&
      payload.hasOwnProperty("onSuccess")
    ) {
      payload.onSuccess(false);
    }
    yield put(actions.fetchDataSuccess(data));
  } catch (err) {
    yield put(actions.fetchDataFailure(err?.response?.data));
    if(payload.hasOwnProperty("onSuccess")){
      payload.onSuccess(false);
    }
  }
}


export function* handlefetchAllData({ payload }) {
  try {
    const res = yield call(API.getAllItems, payload.payload);
    const { data } = res;
    if (data.success) {
      payload.onSuccess(false);
    }
    yield put(actions.fetchAllDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchAllDataFailure(err?.response?.data));
    payload.onSuccess(false);
  }
}

export function* handleFetchWarehousesData({ payload }) {
  try {
    const res = yield call(API.getWarehouses, payload);
    const { data } = res;
    yield put(actions.fetchWarehousesSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWarehousesFailure(err?.response?.data));
  }
}

export function* handleViewReport({ payload }) {
  try {
    const { source, fileName } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: fileName,
    });
    const { url } = res.data;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    yield put(actions.viewReportSuccess());
  } catch (err) {
    yield put(actions.viewReportFailure());
  }
}

export function* handleDownloadReport({ payload }) {
  try {
    const { source, inspectionDetails } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: inspectionDetails?.fileName,
    });
    downloadPdf(res.data.url, inspectionDetails);
    yield put(actions.downloadReportSuccess());
  } catch (err) {
    yield put(actions.downloadReportFailure());
  }
}
export function* handleDownloadSummarySheet({ payload }) {
  try {
    const { month, year, clientId } = payload;

    const res = yield call(API.fetchSummarySheet, { month, year, clientId });
    downloadSummarySheet(res);
  } catch (error) {
    console.error("Error downloading summary sheet:", error);
    payload.onFailure();
  }
}
function* sendEmails({ payload }) {
  try {
    const response = yield call(API.sendReport, payload.data);
    yield put(actions.sendEmailSuccess());
    payload.onSuccess();
  } catch (error) {
    yield put(actions.sendEmailFailure(error?.response?.data));
    payload.onFailure(error?.message);
  }
}
// to get the month number inspection was done
export function* handleFetchGeneratedReportMonths(action) {
  try {
    const res = yield call(API.getBarChartData, action.payload);
    const { data } = res;
    yield put(actions.fetchGeneratedReportMonthsSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGeneratedReportMonthsFailure(err?.response?.data));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}

export function* handleFetchWorkflowData({ payload }) {
  try {
    const res = yield call(API1.fetchFormData, payload);
    const { data } = res;
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleFetchBlockchainData({ payload }) {
  try {
    const res = yield call(API.fetchBlockchainData, payload);
    const { data } = res;
    yield put(actions.fetchBlockchainDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchBlockChainDataFailure(err?.response?.data));
  }
}

// export function* handleFetchClearStates({ payload }) {
//   // console.log(payload);
//   yield put(actions.fetchClearStatesSuccess());
// }

export function* handleFetchUserData({ payload }) {
  try {
    const res = yield call(API.getUserProfile, payload);
    const { data } = res;
    yield put(actions.fetchUserDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchUserDataFailure(err?.response?.data));
  }
}

export function* handleFetchJobRequest({ payload }) {
  try {
    const res = yield call(API.getInspectionJobDetails, payload);
    const { data } = res;
    yield put(actions.fetchJobDetailsSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchJobDetailsFailure());
  }
}

function* handleGetMultipleTasksOfJob(action) {
  try {
    const response = yield call(API.getTasks, action.payload);
    const { data } = response;
    yield put(actions.fetchTaskDetailsSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchTaskDetailsFailure(e));
  }
}

export function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(API.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}
function* handleFetchClientsByCustomerId(action) {
  try {
    const response = yield call(API.getClientsByCustomerId, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsOfCustomerSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchClientsOfCustomerFailure(e));
  }
}

function* handleCheckIsHederaEnable(action) {
  try {
    const response = yield call(API.isHederaEnabled, action.payload);
    const { data } = response;
    yield put(actions.checkIsHederaEnableSuccess(data.payload));
  } catch (e) {
    yield put(actions.checkIsHederaEnableFailure(e));
  }
}

// for inspection filter data 
function* handleFetchInspectionFilterData(action) {
  try {
    const response = yield call(API.inspectionTableFiltersData, action.payload);
    const { data } = response;
    yield put(actions.fetchInspectionFilterDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchInspectionFilterDataFailure(e));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* dashboardSaga() {
  yield takeLatest(actions.fetchData.type, handleFetchData);
  yield takeLatest(actions.fetchWarehouses.type, handleFetchWarehousesData);
  yield takeLatest(actions.viewReport.type, handleViewReport);
  yield takeLatest(actions.sendEmail.type, sendEmails);
  yield takeLatest(actions.downloadReport.type, handleDownloadReport);
  yield takeLatest(actions.fetchAllData.type, handlefetchAllData);
  yield takeLatest(
    actions.downloadSummarySheet.type,
    handleDownloadSummarySheet
  );
  yield takeLatest(
    actions.fetchGeneratedReportMonths.type,
    handleFetchGeneratedReportMonths
  );
  yield takeLatest(actions.clearState.type, handleClearState);
  yield takeEvery(actions.fetchWorkflowData.type, handleFetchWorkflowData);
  yield takeEvery(actions.fetchBlockchainData.type, handleFetchBlockchainData);
  // yield takeEvery(actions.fetchClearStates, handleFetchClearStates);
  yield takeLatest(actions.fetchUserData.type, handleFetchUserData);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(actions.fetchJobDetailsRequest.type, handleFetchJobRequest);
  yield takeLatest(
    actions.fetchTaskDetailsRequest.type,
    handleGetMultipleTasksOfJob
  );
  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handleFetchClientsByCustomerId
  );
  yield takeLatest(actions.checkIsHederaEnable.type, handleCheckIsHederaEnable);
  // fetchInspectionFilterData
  yield takeLatest(actions.fetchInspectionFilterData.type, handleFetchInspectionFilterData);
}
