import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function Edit({ editHandler }) {
  return (
    <>
      <Button
        leftIcon={<EditIcon color="login.500" />}
        colorScheme="login"
        variant="ghost"
        onClick={editHandler}
      />
    </>
  );
}

export default Edit;
