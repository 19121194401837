import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import AllJobTop from "../components/allJobs/AllJobTop";
import AllJobsFilterBar from "../components/allJobs/AllJobsFilterBar";
import AllJobsTable from "../components/allJobs/AllJobsTable";
import { watchJobsAndManagement } from "../saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import { actions, reducer, sliceKey } from "../slice";
import { isConstructorDeclaration } from "typescript";
import { useUserContext } from "context/UserContext";
import { format, startOfDay } from "date-fns";
import { getCurrentProfile } from "utils/commonFunctions";
import { calculateTotalPages } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";

const AllJobs = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });

  const dispatch = useDispatch();

  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const customerId = entity?.ownerEntityId;

  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get("clientId");

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [tempLocations, setTempLocations] = useState([]);
  const [url, setUrl] = useState("");
  const [page, setPage] = useState(0);
  const [isLoadingPagination, setIsLoadingPagination] = useState(true);
  const handleIsLoadingPagination = (status) => {
    setIsLoadingPagination(status);
  };

  const allJobs = useSelector(selectors.selectGetAllJobs);
  const totalNumberOfJobs = useSelector(selectors.selectJobCount);
  const customerUser = useSelector(selectors.selectCustomerUsers);
  const clientsOfCustomer = useSelector(selectors.selectClientsOfCustomer);
  const warehouseDetails = useSelector(selectors.selectWarehouseData);
  const clientImageUrls = useSelector(selectors.selectuserProfileImgUrls);
  //const allTasksDetail = useSelector(selectors.selectGetAllTasks)
  // const allUserDetails = useSelector(selectors.selectUserDetailsArr);
  // const allUserAvatarUrls = useSelector(selectors.selectMultipleAvatarUrls);

  const clients = useCallback(() => {
    return clientsOfCustomer.map((obj) => {
      return {
        ["value"]: obj?.clientName ? obj.clientName : "XYZ",
        ["id"]: obj?.clientId,
      };
    });
  }, [clientsOfCustomer]);

  const locations = useMemo(() => {
    const uniqueWarehouses = {};

    return (
      warehouseDetails &&
      warehouseDetails
        .filter((warehouse) => {
          if (!uniqueWarehouses[warehouse.id]) {
            uniqueWarehouses[warehouse.id] = true;
            return true;
          }
          return false;
        })
        .map((warehouse) => ({
          id: warehouse.id,
          category: warehouse.city + ", " + warehouse.state,
        }))
    );
  }, [warehouseDetails]);

  const clientAvatarList = allJobs?.map((job) => {
    const { clientDetails } = job;
    return clientDetails.avatar || "";
  });

  useEffect(() => {
    if (clientAvatarList) {
      dispatch(actions.fetchUserProfileImgURL(clientAvatarList));
    }
  }, [allJobs]);

  const assignees = useCallback(() => {
    return customerUser.map((obj) => {
      return {
        ["value"]: obj?.firstName + " " + obj?.lastName,
        ["id"]: obj?.userId,
      };
    });
  }, [customerUser]);

  useEffect(() => {
    dispatch(actions.fetchWarehouseDetailsRequest());

    dispatch(
      actions.fetchCustomerUsers({
        customerId,
        roleName: "auditor",
      })
    );
    dispatch(
      actions.fetchClientsOfCustomer({
        customerId,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      actions.getAllJobs({
        customerId,
        query: url,
        page,
        onSuccess: handleIsLoadingPagination,
      })
    );
  }, [url, page]);

  // useEffect(() => {
  //   if (allTasksDetail && allTasksDetail.length) {
  //     const arrOfArrsOfUserIds = allTasksDetail.map((taskArr) => {
  //       return taskArr.map((task) => {
  //         return "c8f89535-a0b1-4a7d-b801-d5f4fb9d2db0"; //task.assignedTo
  //       });
  //     });
  //     dispatch(actions.fetchMultipleUserDetails(arrOfArrsOfUserIds));
  //   }
  // }, [allTasksDetail]);
  // useEffect(() => {
  //   if (allUserDetails && allUserDetails.length) {
  //     const arrOfArrsOfAvatarName = allUserDetails.map((userDetailArr) => {
  //       return userDetailArr.map((userDetail) => {
  //         return userDetail.avatar;
  //       });
  //     });
  //     dispatch(actions.fetchMultipleAvatarUrls(arrOfArrsOfAvatarName));
  //   }
  // }, [allUserDetails]);

  const [filters, setFilters] = useState({
    client: null,
    assignee: null,
    status: null,
    priority: null,
    date: null,
  });

  const [tempFilters, setTempFilters] = useState({
    priority: null,
    date: null,
  });

  useEffect(() => {
    if (clientId && !filters.client) {
      handleOptionChange(clientId, "client");
    }
  }, []);

  const handleOptionChange = useCallback((value, customKey) => {
    value = value ? value : null;
    if (customKey === "priority" || customKey === "date") {
      setTempFilters((prevFIlters) => {
        return {
          ...prevFIlters,
          [customKey]: value,
        };
      });
      if (value === null) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [customKey]: value,
        }));
      }
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [customKey]: value,
      }));
    }
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilters((prevFilters) => {
      return { ...prevFilters, priority: null, date: null };
    });
    setSelectedLocations([]);
    setTempFilters({
      priority: null,
      date: null,
    });
    setTempLocations([]);
  }, []);

  const filterJobs = useCallback((filters, selectedLocations) => {
    const queryParams = [];

    if (filters.status) {
      let statusArray = [];
      statusArray.push(filters.status);
      const stringifiedStatusArray = statusArray?.join(",");
      queryParams.push(`status=${stringifiedStatusArray}`);
    }

    if (filters.client) {
      queryParams.push(`client=${filters.client}`);
    }

    if (filters.assignee) {
      queryParams.push(`assignee=${filters.assignee}`);
    }

    if (filters.priority) {
      queryParams.push(`priority=${filters.priority}`);
    }

    if (filters.date) {
      queryParams.push(
        `start_due_dt=${startOfDay(filters.date).toISOString()}`
      );
      queryParams.push(`end_due_dt=${filters.date.toISOString()}`);
    }
    if (selectedLocations.length) {
      queryParams.push(`origins=${selectedLocations?.join(",")}`);
    }
    const newUrl = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    setUrl(newUrl);
    handleApplyButton();
  }, []);

  useEffect(() => {
    filterJobs(filters, selectedLocations);
  }, [filters, selectedLocations]);

  // useEffect(() => {
  //   if (!tempFilters.priority || !tempFilters.date) {
  //     setFilters((prevFilters) => {
  //       const updatedFilters = { ...prevFilters };
  //       if (!tempFilters.priority) {
  //         updatedFilters.priority = null;
  //       }
  //       if (!tempFilters.date) {
  //         updatedFilters.date = null;
  //       }
  //       return updatedFilters;
  //     });
  //   }
  //   if (!tempLocations.length) {
  //     setSelectedLocations([]);
  //   }
  // }, [tempLocations, tempFilters.priority, tempFilters.date]);

  const handleApplyButtonClick = () => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        ...tempFilters,
      };
    });
    setSelectedLocations(tempLocations);
  };

  const numPages = calculateTotalPages(totalNumberOfJobs?.noOfItems, 10);
  const handleNextPage = () => {
    if (page === numPages - 1) return;
    handleIsLoadingPagination(true);
    setPage(page + 1);
  };
  const handlePreviousPage = () => {
    if (page === 0) return;
    handleIsLoadingPagination(true);
    setPage(page - 1);
  };

  const handlePageSelect = (pageNumber) => {
    if (page === pageNumber) return;
    handleIsLoadingPagination(true);
    setPage(pageNumber);
  };
  const handleApplyButton = () => {
    setPage(0);
  };
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Stack spacing={"36px"} direction="column">
          <ErrorBoundary
            fallback={
              <FallbackToast
                message={`Failed to Load Create Job option and counts of Jobs`}
              />
            }
          >
            <AllJobTop
              totalJobCount={totalNumberOfJobs?.noOfItems}
              customerId={customerId}
            />
          </ErrorBoundary>
          <ErrorBoundary
            fallback={<FallbackToast message={`Failed to Load Filters`} />}
          >
            <AllJobsFilterBar
              filters={filters}
              setFilters={setFilters}
              handleOptionChange={handleOptionChange}
              assignees={assignees()}
              clients={clients()}
              handleClearFilters={handleClearFilters}
              locations={locations}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              tempFilters={tempFilters}
              handleApplyButtonClick={handleApplyButtonClick}
              tempLocations={tempLocations}
              setTempLocations={setTempLocations}
              handleApplyButton={handleApplyButton}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallbackUI minH="70vh" />}>
            <AllJobsTable
              allJobs={allJobs}
              customerId={customerId}
              clientImageUrls={clientImageUrls}
              page={page}
              numPages={numPages}
              handleNextPage={handleNextPage}
              isLoadingPagination={isLoadingPagination}
              handlePreviousPage={handlePreviousPage}
              handlePageSelect={handlePageSelect}
              // allUserAvatarUrls={allUserAvatarUrls}
              // allTasksDetail={allTasksDetail}
              // allUserAvatarUrls={allUserAvatarUrls}
            />
          </ErrorBoundary>
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default AllJobs;
