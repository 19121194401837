import { createSlice } from "@reduxjs/toolkit";
import { generateUUId } from "utils/utils";

export const initialState = {
  loading: false,
  error: null,
  workflow: {},
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
};

const homePage = createSlice({
  name: "homePage1",
  initialState,
  reducers: {
    // Fetch Data
    fetchWorkflowData: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowDataSuccess: (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload) && action.payload.length == 0) {
        state.workflow = {};
        state.sections = [];
        state.blocks = [];
        state.composites = [];
        state.components = [];
      } else {
        let newWorkflow = JSON.parse(JSON.stringify(action.payload.form));
        newWorkflow["inspectionId"] = generateUUId();
        state.workflow = [newWorkflow];
        state.sections = action.payload.sections;
        state.blocks = action.payload.blocks;
        state.composites = action.payload.composites;
        state.components = action.payload.components;
      }
    },
    fetchWorkflowDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postWorkflowData: (state, action) => {
      state.loading = true;
    },

    postWorkflowDataSuccess: (state, action) => {
      state.loading = false;
    },

    postWorkflowDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },

    storeFileToUpload: (state, action) => {
      //console.log({ payload: action.payload });
      const files = state.files || [];
      state.files = [...files, action.payload];
    },
    fetchReviewData: (state, action) => {
      state.loading = true;
    },
    fetchReviewDataSuccess: (state, action) => {
      state.loading = false;
      state.reviewData = action.payload;
    },
    fetchReviewDataFailure: (state, action) => {
      state.loading = false;
    },
    // Init

    // Clear State

    clearState: (state, action) => {
      state = initialState;
    },

    init: (state, action) => {
      const { loggedInUser, id } = action.payload;
      state.workflow = {
        ...state.workflow,
        id: id,
        createdBy: loggedInUser,
        title: "Workflow",
        application: "Rake Inspection",
        client: generateUUId(),
      };
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
    },

    // Init End
  },
});

export const { actions, reducer, name: sliceKey } = homePage;
