import React from "react";
import { HStack, Box, Input, VStack, Text } from "@chakra-ui/react";
import { FiUploadCloud, FiFile } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";

export default function MediaUpload() {
  return (
    <Box>
      <Input
        id="input_file"
        onChange={(e) => {
          //console.log(e.target.files[0]);
        }}
        style={{ display: "none" }}
        type="file"
      />
      <HStack
        style={{
          width: "100%",
          border: "2px dashed #E2E8F0",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "20px 40px 40px 40px",
        }}
        onClick={() => {
          // handleInputClick("input_profile_img");
        }}
      >
        <VStack align="center">
          <Box
            style={{
              padding: "8px",
              border: "1px solid #EDF2F7",
              borderRadius: "8px",
            }}
          >
            <FiUploadCloud size="24px" color="#718096" />
          </Box>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
          >
            Size limit: 10MB
          </Text>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
            w="442px"
            textAlign="center"
          >
            accepts image & video files
          </Text>
        </VStack>
      </HStack>
      <HStack
        w="100%"
        justify="space-between"
        align="center"
        style={{
          padding: "6px 12px",
          background: "#F7FAFC",
          borderRadius: "8px",
          margin: "14px auto",
        }}
      >
        <HStack>
          <FiFile size="22px" color="#718096" />
          <Text
            color="#4A5568"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
          >
            Filename.pdf
          </Text>
        </HStack>
        <IoCloseCircleOutline size="24px" color="#718096" />
      </HStack>
    </Box>
  );
}
