import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  Box,
  Button,
  Flex,
  Image,
  Select,
  Text,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import { format } from "date-fns";
import {
  transformArrayOfArrayOfObjects,
  transformArray,
  replaceNullWithNA,
} from "utils/commonFunctions";

import { sliceKey, reducer, actions } from "./slice";
import { dashboardSaga } from "./saga";
import * as selectors from "./selectors";

import "./index.css";

import InspectionDataTable from "./components/InspectionDataTable";
import SearchAndFilterOptions from "./components/SearchAndFilterOptions";

import { useWarehouseFilter } from "../context/WarehouseFilterContext";
import { useAddFilter } from "../context/AddFilterContext";
import { calculateTotalPages } from "utils/commonFunctions";
import Download from "assets/svg/download-icon-white.svg";
import { userStorage } from "utils/helper";
import { getCurrentProfile } from "utils/commonFunctions";
import { useUserContext } from "context/UserContext";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";

export default function Dashboard() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: dashboardSaga });
  const dispatch = useDispatch();

  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  //useContext
  //   const { selectedWarehouse } = useWarehouseFilter();
  const { query } = useAddFilter();
  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const entityId = entity?.ownerEntityId;
  const entityType = entity?.ownerEntityType;

  //useState
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [page, setPage] = useState(0);
  const { fromDate, toDate } = filters;

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const [selectedClient, setSelectedClient] = useState({
    id: null,
    value: null,
  });

  const showErrorToast = () => {
    toast({
      title: "Report is Generating",
      description: "Please wait few seconds",
      status: "loading",
      duration: 2000,
      isClosable: true,
    });
  };
  const showSummaryErrorToast = () => {
    toast({
      title: "Something Went wrong",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  //Use Selectors
  let data = useSelector(selectors.selectData) || [];
  let warehouses = useSelector(selectors.selectWarehouseData) || [];
  let inspectionIds = useSelector(selectors.selectInspectionIdArr) || [];
  let tableData = useSelector(selectors.selectTableData) || [];
  let totalInspectionCount =
    useSelector(selectors.selectInspectionCounts) || "";
  let reportMonthArray =
    useSelector(selectors.selectGeneratedReportMonths) || [];
  const clientsOfCustomer = useSelector(selectors.selectClientsOfCustomer);

  let yearMonthArray = reportMonthArray.reduce((acc, currentItem) => {
    const { year, month } = currentItem;
    if (!acc[year]) acc[year] = [];
    const date = new Date(year, month, 0);
    const formatDate = format(date, "MMMM");
    acc[year].push(formatDate);
    return acc;
  }, {});

  //Variables & functions
  const modifiedData = data.map(replaceNullWithNA);
  const numPages = calculateTotalPages(totalInspectionCount, 10);

  const userRoles = userStorage.get()?.roles || [];
  const isClient = userRoles.includes("client");

  const excludedValues = [
    "CM AGENCY",
    "Warehouse Code",
    "Audit Date",
    "Auditor Name",
    "Inspection Report",
    "submit",
  ];

  const isExcluded = (item) =>
    excludedValues.includes(item.display) ||
    item?.display?.toLowerCase().includes("image") ||
    item?.display?.toLowerCase().includes("photo") ||
    item?.display?.toLowerCase().includes("submit") ||
    item?.display === "Name of the Bank";
  let Theads = transformArray(tableData?.flat()).filter((item) => {
    return !isExcluded(item);
  });

  let data_transformed = transformArrayOfArrayOfObjects(
    tableData,
    modifiedData
  );
  let headingMap = [
    { display: "Audit Date", value: "auditDate", visible: true },
    { display: "Client Name", value: "clientOrgName", visible: true },
    { display: "Warehouse Name", value: "originName", visible: true },
    { display: "Warehouse Code", value: "originCode", visible: true },
    { display: "CM Name", value: "cmName", visible: true },
    { display: "Location", value: "originLocation", visible: true },
    { display: "Auditor Name", value: "auditorName", visible: true },
  ];

  headingMap = headingMap.filter((item) => {
    if (isClient && item?.display === "Client Name") {
      return false;
    }
    return true;
  });
  const options = useCallback(
    [
      ...headingMap,
      ...Theads,
      { display: "Action", value: "action", visible: true },
    ],
    [tableData]
  );
  const [checkedOptions, setCheckedOptions] = useState(
    Theads.length > 0 ? options : headingMap
  );

  const handleCheckedOptions = (optionValue, isChecked) => {
    setCheckedOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.display === optionValue
          ? { ...option, visible: isChecked }
          : option
      )
    );
  };

  const handleReset = () => {
    setCheckedOptions(headingMap);
  };

  const handleDragUpdate = (updatedState) => {
    setCheckedOptions(updatedState);
  };

  const handleNextPage = () => {
    if (page === numPages - 1) return;
    handleIsLoadingPagination(true);
    setPage(page + 1);
  };
  const [isLoadingPagination, setIsLoadingPagination] = useState(true);
  const handleIsLoadingPagination = (status) => {
    setIsLoadingPagination(status);
  };

  //Page change handlers
  const handlePreviousPage = () => {
    if (page === 0) return;
    handleIsLoadingPagination(true);
    setPage(page - 1);
  };

  const handlePageSelect = (pageNumber) => {
    if (page === pageNumber) return;
    handleIsLoadingPagination(true);
    setPage(pageNumber);
  };

  useEffect(() => {
    if (entityId && entityType === "customer") {
      dispatch(
        actions.fetchClientsOfCustomer({
          customerId: entityId,
        })
      );
    } else {
      setClientId(entityId);
    }
  }, [entityId]);

  useEffect(() => {
    setCheckedOptions(options);
  }, [options.length]);

  useEffect(() => {
    if (inspectionIds && inspectionIds.length) {
      dispatch(
        actions.fetchAllData({
          payload: inspectionIds,
          onSuccess: handleIsLoadingPagination,
        })
      );
    }
  }, [inspectionIds]);

  useEffect(() => {
    const requiredWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouse
    );
    const warehouseId = selectedWarehouse ? requiredWarehouse.id : null;
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      clientIds: selectedClient?.id,
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
      page,
      status: ["approved"],
    };
    dispatch(
      actions.fetchData({
        payload: forQueryParams,
        onSuccess: handleIsLoadingPagination,
      })
    );
    dispatch(actions.fetchWarehouses({ payload: forQueryParams }));
  }, [selectedWarehouse, fromDate, toDate, query, page, selectedClient]);

  //State Handler
  const handleSearchQuery = (value) => {
    setSearchQuery(value);
  };
  const handleFromDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
    handleApplyButton()
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
    handleApplyButton()
  };

  const initiateFetchData = () => {
    setTimeout(() => {
      const requiredWarehouse = warehouses.find(
        (warehouse) => warehouse.id === selectedWarehouse
      );
      const warehouseId = selectedWarehouse ? requiredWarehouse.id : null;
      // Prepare query paramenters to get filtered data from API
      const forQueryParams = {
        clientIds: selectedClient?.id,
        warehouseId: warehouseId,
        ...(fromDate &&
          toDate && {
            start_dt: format(fromDate, "yyyy-MM-dd"),
            end_dt: format(toDate, "yyyy-MM-dd"),
          }),
        query,
        page,
        status: ["approved"],
      };

      dispatch(
        actions.fetchData({
          payload: forQueryParams,
        })
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }, 60000);
  };

  const handleReportLoading = () => {
    showErrorToast();
    if (!isLoading) {
      setIsLoading(true);
      initiateFetchData({});
    }
  };
  //Dispatch Handlers
  const onClickViewReport = (fileName) => {
    if (fileName === "N.A.") {
      handleReportLoading();
      return;
    }
    dispatch(
      actions.viewReport({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        fileName,
      })
    );
  };
  const onClickDownloadReport = (inspection) => {
    if (inspection?.fileName === "N.A.") {
      handleReportLoading();
      return;
    }
    console.log({inspection})
    dispatch(
      actions.downloadReport({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        inspectionDetails: inspection,
      })
    );
  };
  // const modifiedCheckedOptions = useCallback(() => {
  //   return checkedOptions.filter((item) => item)
  // }, []);

  // console.log({modifiedCheckedOptions})
  const ref2 = useRef();

  useOutsideClick({
    ref: ref2,
    handler: () => setIsOpen2(false),
  });

  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [monthArray, setMonthArray] = useState("");
  const [clientId, setClientId] = useState("");
  let yearList = Object.keys(yearMonthArray).filter((year) => year >= 2023);

  useEffect(() => {
    if (clientId) {
      dispatch(
        actions.fetchGeneratedReportMonths({
          clients: [clientId],
          unit: "month",
        })
      );
    }
  }, [clientId]);

  const clients = useCallback(() => {
    return clientsOfCustomer.map((obj) => {
      return {
        ["value"]: obj?.clientName ? obj?.clientName : "XYZ",
        ["id"]: obj?.clientId,
      };
    });
  }, [clientsOfCustomer]);

  const handleDownloadSummarySheet = () => {
    dispatch(
      actions.downloadSummarySheet({
        month: selectedMonth.toLowerCase(),
        year: selectedYear,
        clientId,
        onFailure: () => {
          showSummaryErrorToast();
        },
      })
    );
  };

  useEffect(() => {
    if (selectedYear.length && reportMonthArray.length) {
      setMonthArray(yearMonthArray[selectedYear]);
    }
  }, [reportMonthArray, selectedYear]);

  const handleApplyButton = () => {
    setPage(0);
  };

  return (
    <>
      <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
        <Flex
          flexDirection="column"
          pt={{ base: "75px", md: "75px" }}
          gap={"10px"}
        >
          <Flex justifyContent={"space-between"}>
            <Text fontSize={"18px"} fontWeight={"700"}>
              {totalInspectionCount || 0} Inspections
            </Text>
            <Button colorScheme="login" onClick={() => setIsOpen2(!isOpen2)}>
              <Flex gap={"8px"}>
                <Text fontSize={"16px"} fontWeight={"600"}>
                  Download Report
                </Text>
                <Image src={Download} />
              </Flex>
            </Button>
          </Flex>
          <Box ref={ref2} className={isOpen2 ? "drop-Down2" : "hide"}>
            {entityType === "customer" ? (
              <Select
                value={clientId}
                onChange={(e) => {
                  const val = e.target.value;
                  setClientId(val);
                  setSelectedMonth("");
                  setSelectedYear("");
                  // setMonthArray("")
                }}
              >
                <option key={"Select Client"} value={""}>
                  Select Client
                </option>
                {clients()?.map((client) => (
                  <option key={client.id} value={client.id}>
                    {capitalizeStringUpdated(client.value)}
                  </option>
                ))}
              </Select>
            ) : (
              <></>
            )}
            {yearList?.length && clientId ? (
              <>
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option key={"Select Year"} value={""}>
                    Select Year
                  </option>
                  {yearList.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
                <Select
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.target.value);
                  }}
                >
                  <option key={"Select Month"} value={""}>
                    Select Month
                  </option>
                  {monthArray ? (
                    monthArray?.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
                <Box alignContent={"center"} sx={{ marginLeft: "6%" }}>
                  <Button
                    isDisabled={
                      selectedMonth && selectedYear && clientId ? false : true
                    }
                    colorScheme="login"
                    onClick={handleDownloadSummarySheet}
                  >
                    <Flex gap={"8px"}>
                      <Text fontSize={"16px"} fontWeight={"600"}>
                        Download
                      </Text>
                      <Image src={Download} />
                    </Flex>
                  </Button>
                </Box>
              </>
            ) : (
              <Text style={{ color: "#E53E3E", alignSelf: "center" }}>
                {clientId ? "No Report Found!" : "Select Client"}
              </Text>
            )}
          </Box>
          <ErrorBoundary
            fallback={<FallbackToast message={`Failed to Load filters `} />}
          >
            <SearchAndFilterOptions
              selectedWarehouse={selectedWarehouse}
              setSelectedWarehouse={setSelectedWarehouse}
              warehouseOptions={warehouses}
              handleSearchQuery={handleSearchQuery}
              searchQuery={searchQuery}
              // dropDownList={dropDownList}
              filters={filters}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              checkedOptions={checkedOptions}
              handleCheckedOptions={handleCheckedOptions}
              handleReset={handleReset}
              handleDragUpdate={handleDragUpdate}
              handleApplyButton={handleApplyButton}
              clients={clients()}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              entityType={entityType}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallbackUI minH="70vh" />}>
            <Box>
              <InspectionDataTable
                onClickViewReport={onClickViewReport}
                onClickDownloadReport={onClickDownloadReport}
                data={data_transformed}
                heading={checkedOptions}
                page={page}
                numPages={numPages}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                handlePageSelect={handlePageSelect}
                isLoadingPagination={isLoadingPagination}
              />
            </Box>
          </ErrorBoundary>
        </Flex>
      </ErrorBoundary>
    </>
  );
}
