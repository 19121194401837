import React from "react";
import TemplateDownload from "../components/TemplateDownload";
import UploadFileModal from "../components/UploadFileModal";
import {
  Button,
  Img,
  useDisclosure,
} from "@chakra-ui/react";
import uploadIcon from "../../../assets/svg/upload.svg";
import {
  useInjectReducer,
  useInjectSaga,
} from "redux-injectors";
import {
  actions,
  reducer,
  sliceKey,
} from "../slice";
import { bulkUploadSaga } from "../saga";
import { useDispatch } from "react-redux";

const allowedFields = [
  "name",
  "city",
  "state",
  "address",
  "postal_code",
  "external_code",
  "area",
  "contact_person",
  "region",
  "country",
  "address_line2",
  "additional_properties",
  "mobile",
  "email",
  "country_code",
  "longitude",
  "latitude",
  "origin_type",
];
const requiredFields = [
  "name",
  "city",
  "state",
  "address",
  "postal_code",
  "origin_type",
];

function BulkOriginCreation({
  isDisabled,
  manager,
  details,
  avatar,
  clientId,
  customerId,
}) {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: bulkUploadSaga,
  });
  const dispatch = useDispatch();
  const {
    isOpen: bulkIsOpen,
    onOpen: onBulkOpen,
    onClose: closeBulkOpen,
  } = useDisclosure();

  const {
    isOpen: bulkToUpload,
    onOpen: onBulkToUpload,
    onClose: closeBulkToUpload,
  } = useDisclosure();

  const handleOriginTemplate = ()=> {
    window.open(`https://docs.google.com/spreadsheets/d/1-H2fySsaEnOk9fnx54NB1RPs8HCuYuTwQNWvQBOFm4s/copy?gid=0#gid=0`)
  }

  let originPointers = ['Name, City, State, Address, Postal Code and Origin Type are required fields', 'External code must be unique'];

  return (
    <>
      <Button
        size="lg"
        height="48px"
        width="100%"
        borderRadius={"10px"}
        borderColor="white"
        bg="white"
        onClick={onBulkOpen}
        isDisabled={isDisabled}
      >
        <Img
          src={uploadIcon}
          style={{
            height: "16px",
            width: "16px",
            marginRight: "10px",
          }}
          alt=""
        />
        Bulk Upload
      </Button>
      <TemplateDownload
        isOpen={bulkIsOpen}
        onClose={closeBulkOpen}
        openUploadFileModal={onBulkToUpload}
        closeTemplateDownload={closeBulkOpen}
        title={"Bulk Location Upload"}
        manager={manager}
        details={details}
        avatar={avatar}
        handleClick={handleOriginTemplate}
        pointers={originPointers}
      />
      <UploadFileModal
        isOpen={bulkToUpload}
        onClose={closeBulkToUpload}
        openTemplateDownload={onBulkOpen}
        title={"Bulk Location Upload"}
        manager={manager}
        details={details}
        avatar={avatar}
        allowedFields={allowedFields}
        requiredFields={requiredFields}
        onOpen={onBulkToUpload}
        type={"origin"}
        clientId={clientId}
        customerId={customerId}
      />
    </>
  );
}

export default BulkOriginCreation;
