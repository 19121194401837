import React, { useCallback, useEffect, useState } from "react";
import {
  HStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  VStack,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { Select, Button } from "@agnext/reactlib";
import { useSelector, useDispatch } from "react-redux";
import * as selectors from "../../pages/Create/selectors";
import { actions } from "../../pages/Create/slice";
import { ExternalLinkIcon, CloseIcon } from "@chakra-ui/icons";
import { options } from "views/Variables/variables";


export default function NavigateTo({
  title,
  setTitle,
  handleSubmit,
  parentId,
}) {
  const sections = useSelector(selectors.selectSectionList);

  const sectionsData = useSelector(selectors.selectSectionsForBlocks);
  
  const [titleOptions, setTitleOptions] = useState([]);

  const dispatch = useDispatch();

  const handleTitleChange = (e) => {
    let label = "";
    titleOptions.forEach((option) => {
      if (option.value === e.target.value) {
        label = option.label;
      }
    });
    const newOption = { label, value: e.target.value };
    // console.log({ newOption });
    // console.log(titleOptions);
    handleSubmit(newOption.value);
    setTitle(newOption);
  };
  
  useEffect(() => {
    let newTitleOptions = [];
    if (sections.length) {
      newTitleOptions = sections.filter((option) => option.value !== parentId);
    }
    setTitleOptions(newTitleOptions);
  }, [parentId, sections]);

  return (
          <>
          {sectionsData &&  sections?.length < 3 ? (
            <Tooltip label="Requires at least three pages, to use this feature." hasArrow>
              <Box w="300px">
                <Select
                  value={title.value}
                  onChange={handleTitleChange}
                  options={titleOptions}
                  placeholder="Navigate to ( Page )"
                  focusBorderColor="login.500"
                  isDisabled={true}
                />
              </Box>
            </Tooltip>
          ) : (
            <Box w="300px">
              <Select
                value={title.value}
                onChange={handleTitleChange}
                options={titleOptions}
                placeholder="Navigate to ( Page )"
                focusBorderColor="login.500"
              />
            </Box>
          )}
        </>
       )
}
