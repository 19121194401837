import React from "react";
import { HStack, VStack, Text, useMediaQuery } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

export default function HeaderGray({
  form,
  numPages,
  pageNumber,
  hidePagesDetails,
  filledPages,
}) {
  const [isMobileScreen] = useMediaQuery("(max-width: 500px)");
  const percentageCompletion = parseInt((filledPages / numPages) * 100);
  return (
    <HStack
      w="100%"
      h="100px"
      style={{ background: "#4A5568", borderRadius: "16px 16px 0 0" }}
      align="center"
      justify="center"
    >
      <HStack
        w={isMobileScreen ? "100%" : "740px"}
        style={{ padding: "0 24px" }}
        align="center"
        justify="space-between"
      >
        <VStack alignItems="flex-start" gap="4px">
          <Text
            color="#EDF2F7"
            fontSize={isMobileScreen ? "20px" : "24px"}
            fontWeight="500"
            align="start"
          >
            {form && capitalizeStringUpdated(form.title)}
          </Text>
          <Text color="#EDF2F7" fontSize="12px" fontWeight="400" align="start">
            {form && form.description}
          </Text>
        </VStack>
        {!hidePagesDetails ? (
          <VStack alignItems="flex-end" gap={isMobileScreen ? "4px" : "8px"}>
            <Text
              color="#EDF2F7"
              fontSize={isMobileScreen ? "14px" : "16px"}
              fontWeight="400"
              align="end"
            >
              Page {pageNumber + 1} of {numPages}
            </Text>
            <Text
              color="#EDF2F7"
              fontSize={isMobileScreen ? "14px" : "16px"}
              fontWeight="400"
              align="end"
            >
              {filledPages} / {numPages} ({percentageCompletion}%)
            </Text>
          </VStack>
        ) : (
          <></>
        )}
      </HStack>
    </HStack>
  );
}
