import React, { useEffect } from "react";
import CreateRole from "./CreateRole";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions, sliceKey, reducer } from "./slice";
import { watchRolesAndPermission } from "./saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "./selectors";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const EditRole = () => {
  const dispatch = useDispatch();
  const { roleId } = useParams();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchRolesAndPermission });
  const editData = useSelector(selectors.selectEditData);
  useEffect(() => {
    if (roleId) {
      dispatch(actions.fetchEditRoleRequest({ roleId }));
    }
  }, [dispatch, roleId]);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <div>
        <CreateRole userData={editData} />
      </div>
    </ErrorBoundary>
  );
};

export default EditRole;
