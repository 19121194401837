import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../services/apis"

//Generator Functions
export function* handleFetchClientsOfCustomer(action) {
  try {
    const res = yield call(API.getClientsByCustomerId, action.payload);
    const { data } = res;
    yield put(actions.fetchClientsOfCustomerSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchClientsOfCustomerFailure(err));
  }
}

function* handleBulkUserUpload(action) {
  try {
    const res = yield call(API.bulkUserCreate, action.payload);
    const { data } = res;
    yield put(actions.bulkUserUploadSuccess(data.payload));
    action.payload?.onSuccess();
    console.log(data.payload)
  } catch (err) {
    yield put(actions.bulkUserUploadFailure(err));
    console.log("ddd",err?.response?.data);
    action.payload?.onFailure(err?.response?.data);
  }
}

function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(API.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}

function* handleBulkOrigin(action) {
  try {
      const res = yield call(API.bulkOriginCreate,action.payload );
      const { data } = res;
      yield put(actions.uploadBulkOriginSuccess(data.payload));
      action.payload?.onSuccess()
    
  } catch (err) {
    action.payload?.onFailure(err?.response?.data);
  }

}

export function* bulkUploadSaga() {
  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handleFetchClientsOfCustomer
  );
  yield takeLatest(actions.bulkUserUpload.type, handleBulkUserUpload);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(actions.uploadBulkOrigin.type, handleBulkOrigin)
}
