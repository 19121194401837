import { useEffect } from "react";
import useToastNotification from "utils/useToastNotification";

const FallbackToast = ({ message }) => {
  const show = useToastNotification();

  useEffect(() => {
    show({
      message: message.replace(/([a-z])([A-Z])/g, "$1 $2"),
      status: "error",
    });
  }, []);

  return null;
};

export default FallbackToast;
