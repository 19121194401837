import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import locationBlack from "assets/svg/locationBlack.svg";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const flexStyles = {
  gap: "18px",
  justifyContent: {
    base: "space-between",
    sm: "space-between",
    xxl: "space-between",
    xxxl: "space-between",
    xxxxl: "flex-start",
  },
  width: "100%",
  flexWrap: "wrap",
};

const BasicInfo = ({ label, displayText, location, forAddress }) => {
  return (
    <Flex
      gap={"11px"}
      w={forAddress ? "100%" : { base: "100%", md: "23%" }}
      minW={"300px"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};

export const LocationInfo = ({ label, displayText, location }) => {
  return (
    <Flex
      gap={"11px"}
      justifyContent={{ base: "space-between", md: "flex-start" }}
    >
      {label && (
        <Text
          sx={{
            color: "#4A5568",
            fontWeight: location ? 600 : 700,
            fontSize: "14px",
          }}
        >
          {label}
        </Text>
      )}
      {displayText && (
        <Text
          sx={{
            color: "#2D3748",
            fontWeight: location ? 400 : 500,
            fontSize: "14px",
          }}
        >
          {displayText}
        </Text>
      )}
    </Flex>
  );
};

const linkTypeCheck = (clientEmailPhone, check) => {
  return clientEmailPhone?.filter((linkObj) => linkObj.linkType === check);
};

const ViewClientInfo = ({ clientDetail, addressArray, clientEmailPhone }) => {
  const registeredAddress = addressArray?.filter(
    (address) => address.isRegistered
  );
  const email =
    (clientEmailPhone &&
      clientEmailPhone?.length &&
      linkTypeCheck(clientEmailPhone, "email")) ||
    [];
  const phone =
    (clientEmailPhone &&
      clientEmailPhone?.length &&
      linkTypeCheck(clientEmailPhone, "phone")) ||
    [];
  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600} color="#2D3748">
            Basic Info
          </Text>
        </Box>
        <Box
          style={{
            background: "white",
            boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
            borderRadius: 15,
          }}
        >
          <Box padding={"16px 24px"}>
            <Flex gap={"28px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Client Details
              </Text>
              <Box>
                <Flex gap={"12px"} direction={"column"}>
                  <Flex
                    {...flexStyles}
                    direction={{ base: "column", md: "row" }}
                  >
                    <BasicInfo
                      label={"Country:"}
                      displayText={
                        clientDetail?.country ? clientDetail?.country : "-"
                      }
                    />
                    <BasicInfo
                      label={"Organization Name:"}
                      displayText={
                        clientDetail?.name ? clientDetail?.name : "-"
                      }
                    />
                    {/* <BasicInfo
                      label={"Buisness Type:"}
                      displayText={clientDetail?.businessType || ""}
                    /> */}
                    <BasicInfo
                      label={"Email:"}
                      displayText={email.length ? email[0].value : "-"}
                    />
                    <BasicInfo
                      label={"Phone Number:"}
                      displayText={
                        phone.length
                          ? "+" + phone[0].countryCode + " " + phone[0].value
                          : "-"
                      }
                    />
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>

        {registeredAddress?.length ? (
          <>
            <Box>
              <Text fontWeight={600}>Registered Address</Text>
            </Box>
            <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
              <Box
                style={{
                  background: "white",
                  boxShadow:
                    "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
                  borderRadius: 15,
                  flex: "1",
                }}
              >
                <Flex gap="18px" padding={"16px 24px"}>
                  <Image src={locationBlack} alt="Dan Abramov" />{" "}
                  <BasicInfo
                    forAddress={true}
                    displayText={
                      (registeredAddress[0]?.addressInfo?.addressLine1
                        ? capitalizeStringUpdated(
                            registeredAddress[0].addressInfo.addressLine1
                          )
                        : "") +
                      (registeredAddress[0]?.addressInfo?.addressLine2
                        ? ", " +
                          capitalizeStringUpdated(
                            registeredAddress[0].addressInfo.addressLine2
                          )
                        : "") +
                      (registeredAddress[0]?.city
                        ? ", " +
                          capitalizeStringUpdated(registeredAddress[0].city)
                        : "") +
                      (registeredAddress[0]?.state
                        ? ", " +
                          capitalizeStringUpdated(registeredAddress[0].state)
                        : "") +
                      (registeredAddress[0]?.country
                        ? ", " + registeredAddress[0].country
                        : "") +
                      (registeredAddress[0]?.postalCode
                        ? " " + registeredAddress[0].postalCode
                        : "")
                    }
                  />
                </Flex>
              </Box>
            </Flex>
          </>
        ) : (
          <></>
        )}

        {/* <Box>
          <Text fontWeight={600}>Basic Info</Text>
        </Box>
        <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
          <Box
            style={{
              background: "white",
              boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
              borderRadius: 15,
              flex: "1",
            }}
          >
            <Box padding={"16px 24px"}>
              <Flex gap={"18px"}>
                <Box minWidth={"18px"}>
                  <Image src={locationBlack} alt="Dan Abramov" />
                </Box>
                <Flex direction={"column"}>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    flexWrap={"wrap"}
                    flex={"1"}
                    sx={{
                      "> div": {
                        marginBottom: { base: "12px", md: "12px" }, // Adjust as needed
                        marginRight: { base: "0px", md: "36px" },
                      },
                    }}
                  >
                    <LocationInfo
                      label={"Name:"}
                      displayText={"Rahul"}
                      location={true}
                    />

                    <LocationInfo
                      label={"Contact Person:"}
                      displayText={"Neeraj"}
                      location={true}
                    />
                    <LocationInfo
                      label={"Email:"}
                      displayText={"Neerajbawana@agnext.in"}
                      location={true}
                    />
                    <LocationInfo
                      label={"Location Type:"}
                      displayText={"Warehouse"}
                      location={true}
                    />
                  </Flex>
                  <BasicInfo
                    displayText={
                      "C-184, Industrial Area, Sector 75, Sahibzada Ajit Singh Nagar, Punjab 140308"
                    }
                  />
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Box
            style={{
              background: "white",
              boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
              borderRadius: 15,
              flex: "1",
            }}
          >
            <Box padding={"16px 24px"}></Box>
          </Box>
        </Flex> */}
      </Stack>
    </>
  );
};

export default ViewClientInfo;
