import { Box, Image } from "@chakra-ui/react";
import Eye from "assets/svg/eye-icon.svg";
import React from "react";
import { Link } from "react-router-dom";

const JobTaskTableEyeBtn = ({
  workflowId,
  formId,
  taskId,
  jobId,
  inspectionId,
  originId,
  status,
}) => {
  // console.log(taskId);
  // console.log(jobId);
  const url = `/admin/viewresponses?workflowId=${workflowId}&formId=${formId}&taskId=${taskId}&jobId=${jobId}&inspectionId=${inspectionId}&originId=${originId}`;
  const isDisabled = status === "todo";
  return (
    <Link to={isDisabled ? "#" : url}>
      <Box
        background={isDisabled ? "#E2E8F0" : "#EDF2F7"}
        height={"40px"}
        width={"48px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={"12px"}
        style={{
          cursor: isDisabled ? "not-allowed" : "pointer"
        }}
      >
        <Image src={Eye} alt="" />
      </Box>
    </Link>
  );
};

export default JobTaskTableEyeBtn;
