import { Table } from "@agnext/reactlib";
import {
  Box,
  Flex,
  Image,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Eye from "assets/svg/eye-icon.svg";
import { SearchBar } from "../SearchBar/SearchBar";

const Icons = () => {
  return (
    <Flex>
      <Box
        background={"#EDF2F7"}
        height={"40px"}
        width={"48px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={"12px"}
        style={{ cursor: "pointer" }}
        ml={"10px"}
      >
        <Image src={Eye} alt="" />
      </Box>
    </Flex>
  );
};

const ProfileWithName = () => {
  return (
    <Flex gap={"15px"} alignItems={"center"}>
      <Box
        background={"#EDF2F7"}
        height={"32px"}
        width={"32px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={"50%"}
        style={{ cursor: "pointer" }}
      >
        {/* <Image src={Eye} alt="" /> */}
      </Box>

      <Box>
        <Text>Full Name</Text>
      </Box>
    </Flex>
  );
};

const Header = [
  {
    displayText: "Full Name",
    keyText: "fullName",
    hasSort: true,
  },
  {
    displayText: "Department",
    keyText: "department",
    hasSort: true,
  },
  {
    displayText: "Designation",
    keyText: "designation",
    hasSort: true,
  },
  {
    displayText: "Role",
    keyText: "role",
    hasSort: true,
  },
  {
    displayText: "Email",
    keyText: "email",
    hasSort: true,
  },
  {
    displayText: "Actions",
    keyText: "actions",
    hasSort: true,
  },
];

const data = [
  {
    fullName: <ProfileWithName />,
    department: "Department",
    designation: "Full Designation",
    role: "Role",
    email: "full email",
    actions: <Icons />,
  },
];

const ViewRoleUserTable = () => {
  return (
    <Stack spacing={"24px"}>
      <Box>
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          alignItems={{ base: "flex-start", md: "center" }}
          gap={{ base: "20px" }}
        >
          <Box>
            <Text fontWeight={700}>22 Users Assigned</Text>
          </Box>
          <Box>
            <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
              <Box>
                <SearchBar />
              </Box>
              <Box>
                <Tabs size={"sm"}>
                  <TabList>
                    <Tab>10 My Users</Tab>
                    <Tab>13 Client Users</Tab>
                  </TabList>
                </Tabs>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box background="white" borderRadius={12}>
        <Table
          overflow="auto"
          overflowY="auto"
          // tableContainerHeight={{ base: "441px", sm: "378px" }}
          headerList={Header}
          dataList={data}
        />
      </Box>
    </Stack>
  );
};

export default ViewRoleUserTable;
