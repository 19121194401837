import {
  summedData,
  getPercentage,
  formatNumber,
  formattedDates,
  convertMonthNumberToName,
  uniqueIdsArray,
  getDataArray,
  arraySum,
  getMonthArray,
  weekStartingDates,
  weekWiseArray,
  getUniqueObjectsByProperty,
} from "utils/commonFunctions";
import bagImage from "assets/svg/sack.svg";
import wheat from "assets/svg/wheat.svg";
import locationImage from "assets/svg/pin1.svg";
import auditImage from "assets/svg/AiOutlineFileSearch.svg";
import ScaleIcon from "../../../assets/svg/IoScaleOutline.svg";
import scheduled from "../../../assets/svg/scheduled.svg";
import inProg from "../../../assets/svg/inProgress.svg";
import search from "../../../assets/svg/Search2.svg";
import check from "../../../assets/svg/check.svg";
import warning from "../../../assets/svg/warn.svg";
import client from "../../../assets/svg/client.svg";
import auditor1 from "../../../assets/svg/auditor1.svg";
import timeRate from "../../../assets/svg/timeRate.svg";
import inspectTime from "../../../assets/svg/inspectTime.svg";
import InspectionTypes from "./components/TableComponents/InspectionTypes";
import Action from "./components/TableComponents/Action";
import { addDays, format } from "date-fns";
import ClientDetails from "./components/TableComponents/ClientDetails";

const entityTableData = {
  auditorsForTable: {
    headerList: [],
    dataList: [],
  },
  clientsForTable: {
    headerList: [
      {
        displayText: "Client Name",
        keyText: "clientName",
      },
      {
        displayText: "Total Inspections",
        keyText: "totalInspections",
      },
      {
        displayText: "Scheduled Inspections",
        keyText: "scheduledInspections",
      },
      {
        displayText: "Sites Inspected",
        keyText: "sitesInspected",
      },
      { displayText: "No. of Auditors", keyText: "noOfAuditors" },
      {
        displayText: "Avg. On-Time Completion Rate",
        keyText: "avgOnTimeCompletionRate",
      },
      {
        displayText: "Avg. Time / Inspection",
        keyText: "avgTimeInspection",
      },
      {
        displayText: "Inspection Types",
        keyText: "inspectionTypes",
      },
      {
        keyText: "action",
        displayText: "Action",
      },
    ],
    dataList: [
      {
        clientName: <ClientDetails />,
        totalInspections: 100,
        scheduledInspections: 23,
        sitesInspected: 10,
        noOfAuditors: 20,
        avgTimeInspection: "2d 3.5h",
        avgOnTimeCompletionRate: "90%",
        action: <Action clientId="abcd" />,
        inspectionTypes: <InspectionTypes inspectionTypes={["type A"]} />,
      },
      {
        clientName: <ClientDetails />,
        totalInspections: 100,
        scheduledInspections: 23,
        sitesInspected: 10,
        noOfAuditors: 20,
        avgTimeInspection: "2d 3.5h",
        avgOnTimeCompletionRate: "90%",
        action: <Action clientId="abcd" />,
        inspectionTypes: <InspectionTypes inspectionTypes={["type A"]} />,
      },
      {
        clientName: <ClientDetails />,
        totalInspections: 100,
        scheduledInspections: 23,
        sitesInspected: 10,
        noOfAuditors: 20,
        avgTimeInspection: "2d 3.5h",
        avgOnTimeCompletionRate: "90%",
        action: <Action clientId="abcd" />,
        inspectionTypes: <InspectionTypes inspectionTypes={["type A"]} />,
      },
      {
        clientName: <ClientDetails />,
        totalInspections: 100,
        scheduledInspections: 23,
        sitesInspected: 10,
        noOfAuditors: 20,
        avgTimeInspection: "2d 3.5h",
        avgOnTimeCompletionRate: "90%",
        action: <Action clientId="abcd" />,
        inspectionTypes: <InspectionTypes inspectionTypes={["type A"]} />,
      },
    ],
  },
};

const nccfSummaryTableData = {
  summaryTableProcurement: {
    headerList: [
      {
        displayText: "Client Name",
        keyText: "To Convert",
      },
      {
        displayText: "Total Inspections",
        keyText: "Into",
      },
      {
        displayText: "Scheduled Inspections",
        keyText: "Multiply By",
        hasHeatmap: true,
        heatmapColor: { r: 32, g: 183, b: 32 },
      },
      {
        displayText: "Sites Inspected",
        keyText: "Date",
      },
      { keyText: "auditorName", displayText: "No. of Auditors" },
      {
        keyText: "Avg. On-Time Completion Rate",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
      {
        keyText: "Inspection Types",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
      {
        keyText: "Actions",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
    ],

    dataList: [
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 30.48,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 32,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 12,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 22,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 21,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 12,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 15,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 5,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 2,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 2,
      },
    ],
  },
  summaryTableLoading: {
    headerList: [
      {
        displayText: "To Convert To",
        keyText: "To Convert",
      },
      {
        displayText: "Into",
        keyText: "Into",
      },
      {
        displayText: "Multiply By",
        keyText: "Multiply By",
        hasHeatmap: true,
        heatmapColor: { r: 32, g: 183, b: 32 },
      },
      {
        displayText: "Date",
        keyText: "Date",
      },
      { keyText: "auditorName", displayText: "Auditor Name" },
      {
        keyText: "value",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
    ],

    dataList: [
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 30.48,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 40,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 12,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 22,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 21,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 12,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 15,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 5,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 2,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 2,
      },
    ],
  },
  summaryTableUnloading: {
    headerList: [
      {
        displayText: "To Convert To",
        keyText: "To Convert",
      },
      {
        displayText: "Into",
        keyText: "Into",
      },
      {
        displayText: "Multiply By",
        keyText: "Multiply By",
        hasHeatmap: true,
        heatmapColor: { r: 32, g: 183, b: 32 },
      },
      {
        displayText: "Date",
        keyText: "Date",
      },
      { keyText: "auditorName", displayText: "Auditor Name" },
      {
        keyText: "value",
        displayText: "Value",
        hasHeatmap: true,
        heatmapColor: { r: 166, g: 32, b: 32 },
      },
    ],

    dataList: [
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 30.48,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 29,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 12,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 22,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 21,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 12,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 15,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 5,
      },
      {
        "To Convert": "Feet asc saD",
        Into: "Centimetres (cm)",
        "Multiply By": 2,
        Date: format(addDays(new Date(), 2), "dd/MM/yyyy"),
        auditorName: "Indraneel",
        value: 2,
      },
    ],
  },
};

const chartData = [
  {
    name: "Jan",
    "Quantity Inspected (MT)": 3900,
    "Quantity Accepted (MT)": 300,
    "Quantity Unaccepted (MT)": 3600,
  },
  {
    name: "Feb",
    "Quantity Inspected (MT)": 3680,
    "Quantity Accepted (MT)": 380,
    "Quantity Unaccepted (MT)": 3300,
  },
  {
    name: "March",
    "Quantity Inspected (MT)": 4100,
    "Quantity Accepted (MT)": 345,
    "Quantity Unaccepted (MT)": 3755,
  },
  {
    name: "April",
    "Quantity Inspected (MT)": 3800,
    "Quantity Accepted (MT)": 450,
    "Quantity Unaccepted (MT)": 3350,
  },
  {
    name: "May",
    "Quantity Inspected (MT)": 4100,
    "Quantity Accepted (MT)": 370,
    "Quantity Unaccepted (MT)": 3730,
  },
  {
    name: "June",
    "Quantity Inspected (MT)": 3333,
    "Quantity Accepted (MT)": 333,
    "Quantity Unaccepted (MT)": 3000,
  },
  {
    name: "July",
    "Quantity Inspected (MT)": 3553,
    "Quantity Accepted (MT)": 353,
    "Quantity Unaccepted (MT)": 3200,
  },
  {
    name: "Aug",
    "Quantity Inspected (MT)": 3700,
    "Quantity Accepted (MT)": 380,
    "Quantity Unaccepted (MT)": 3320,
  },
  {
    name: "Sep",
    "Quantity Inspected (MT)": 3600,
    "Quantity Accepted (MT)": 300,
    "Quantity Unaccepted (MT)": 3300,
  },
  {
    name: "Oct",
    "Quantity Inspected (MT)": 2940,
    "Quantity Accepted (MT)": 120,
    "Quantity Unaccepted (MT)": 2820,
  },
  {
    name: "Nov",
    "Quantity Inspected (MT)": 3200,
    "Quantity Accepted (MT)": 200,
    "Quantity Unaccepted (MT)": 3000,
  },
  {
    name: "Dec",
    "Quantity Inspected (MT)": 3000,
    "Quantity Accepted (MT)": 100,
    "Quantity Unaccepted (MT)": 2900,
  },
];

function tempFormateChartData() {
  return chartData.map((dataItem) => ({
    ...dataItem,
    "Trucks Inspected": dataItem["Quantity Inspected (MT)"],
    "Trucks Accepted": dataItem["Quantity Accepted (MT)"],
    "Trucks Unaccepted": dataItem["Quantity Unaccepted (MT)"],
  }));
}

const dataAdapter = (
  state,
  widgetRef,
  selectedDataType,
  selectedDateType,
  dataSource,
  entityId
) => {
  // console.log(widgetRef, selectedDataType, selectedDateType, dataSource);
  switch (widgetRef) {
    case "barChart": {
      return {
        chartData:
          dataSource === "barChartProcurement"
            ? chartData
            : tempFormateChartData(chartData),
        title: "Summary Analysis based on Quantity (MT)",
        dataKeys:
          dataSource === "barChartProcurement"
            ? {
                key1: "Quantity Inspected (MT)",
                key2: "Quantity Accepted (MT)",
                key3: "Quantity Unaccepted (MT)",
              }
            : {
                key1: "Trucks Inspected",
                key2: "Trucks Accepted",
                key3: "Trucks Unaccepted",
              },
      };
    }
    case "tableSimple": {
      return nccfSummaryTableData[dataSource];
    }
    case "numberOfInspections1": {
      return {
        value: 1197,
      };
    }
    case "quantityInspected1": {
      return {
        value: 48745.71,
      };
    }
    case "quantityAccepted1": {
      return {
        value: 275.92,
      };
    }
    case "quantityUnaccepted1": {
      return {
        value: 48469.79,
      };
    }
    case "trucksInspected1": {
      return {
        value: 2140,
      };
    }
    case "trucksAccepted1": {
      return {
        value: 10,
      };
    }
    case "trucksUnaccepted1": {
      return {
        value: 2128,
      };
    }
    case "samplingNotDone1": {
      return {
        value: 4,
      };
    }
    case "quantityInspected2": {
      return {
        value: 65522.79,
      };
    }
    case "quantityAccepted2": {
      return {
        value: 290.03,
      };
    }
    case "quantityUnaccepted2": {
      return {
        value: 65173.55,
      };
    }
    case "bagsInspected1": {
      return {
        value: 1267258,
      };
    }

    case "trucksInspected2": {
      return {
        value: 1186,
      };
    }
    case "trucksAccepted2": {
      return {
        value: 8,
      };
    }
    case "trucksUnaccepted2": {
      return {
        value: 1178,
      };
    }

    case "samplingNotDone2": {
      return {
        value: 0,
      };
    }
    case "quantityInspected3": {
      return {
        value: 34992.46,
      };
    }
    case "quantityAccepted3": {
      return {
        value: 229.65,
      };
    }
    case "quantityUnaccepted3": {
      return {
        value: 34762.81,
      };
    }
    case "bagsInspected2": {
      return {
        value: 704132,
      };
    }

    // above code for NCCF

    //Below Filter list
    case "clientName": {
      const clientData = state.clientsOfCustomer || [];
      const clientNames = clientData?.map((obj) => {
        return { id: obj.clientId, value: obj.clientName };
      });
      return clientNames;
    }
    case "commodity": {
      const commoditiesData = state.commoditiesData || [];
      const commodityNames = commoditiesData?.filter((obj) => {
        if (obj.name !== null && obj.name !== undefined) {
          return obj.name;
        }
      });

      return commodityNames;
    }
    case "clientName": {
      const clientNameData = state.clientNames;
      const clientNames = clientNameData?.filter((obj) => {
        if (obj.name !== null && obj.name !== undefined) {
          return obj.name;
        }
      });

      return clientNames;
    }
    case "inspectionTypes": {
      const inspectionTypeData = state.inspectionTypes || [];
      const inspectionTypes = inspectionTypeData?.map((inspection) => ({
        name: inspection,
        value: inspection,
      }));

      return inspectionTypes;
    }
    case "auditor": {
      const auditorData = _.cloneDeep(state?.customerUsers);
      let auditors = auditorData
        ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
        ?.map((obj) => {
          return {
            ["value"]: obj?.firstName + " " + obj?.lastName,
            ["id"]: obj?.userId,
          };
        });

      return auditors;
    }
    case "location": {
      const warehouseData = state.warehousesData;
      const uniqueObjects =
        getUniqueObjectsByProperty(warehouseData, "city") || [];

      return uniqueObjects;
    }
    case "dateRangePicker": {
    }

    case "totalInspections": {
      //Kotak
      const inspectionsLineChartData = state?.inspectionsLineChartData || [];
      const tooltipData = state?.barChartData || [];
      const loading = state?.portfolioLineChartLoading;

      const inspectionsData = getDataArray(
        inspectionsLineChartData,
        selectedDateType,
        "inspectionCount"
      );
      const inspectionsSum = arraySum(inspectionsData);

      const inspectionsLabel =
        selectedDateType === "day" || selectedDateType === "month"
          ? getMonthArray(inspectionsLineChartData, selectedDateType)
          : weekStartingDates(
              weekWiseArray(inspectionsLineChartData, "inspectionCount")
            );

      const insight = {
        title: "Total Inspections",
        amount: { val1: inspectionsSum || 0 },
        icon: auditImage,
        labels: inspectionsLabel,
        chartData: inspectionsData,
        percentage: getPercentage(
          inspectionsLineChartData,
          inspectionsSum,
          selectedDateType,
          "inspectionCount",
          "Inspections"
        ),
      };

      return { insight, loading, tooltipData };
    }

    case "quantityInspected": {
      const inspectionsLineChartData = state?.inspectionsLineChartData || [];
      const quantityLineChartData = state?.quantityLineChartData || [];
      const tooltipData = state?.barChartData || [];
      const loading = state?.portfolioLineChartLoading;

      const quantityLabel =
        selectedDateType === "day" || selectedDateType === "month"
          ? getMonthArray(quantityLineChartData, selectedDateType)
          : weekStartingDates(
              weekWiseArray(inspectionsLineChartData, "totalWeight")
            );

      const quantityData = getDataArray(
        quantityLineChartData,
        selectedDateType,
        "totalWeight"
      );

      const quantitySum = arraySum(quantityData);

      const insight = {
        title: "Quantity Inspected (MT)",
        amount: {
          val1: isNaN(quantitySum)
            ? formatNumber(0, "Quantity Inspected (MT)")
            : formatNumber(quantitySum, "Quantity Inspected (MT)") || 0,
        },
        icon: ScaleIcon,
        labels: quantityLabel,
        chartData: quantityData,
        percentage: getPercentage(
          quantityLineChartData,
          quantitySum,
          selectedDateType,
          "totalWeight",
          "Quantity"
        ),
        iconBg: "#B799DD",
      };
      // console.log({ insight, loading, tooltipData });
      return { insight, loading, tooltipData };
    }

    case "numberOfBags": {
      const analyticsData = state?.analyticsData || [];
      const loading = state?.analyticsLoading;

      const bagSum =
        (analyticsData.length > 0 && summedData(analyticsData)) || [];
      const insight = bagSum?.hasOwnProperty("totalNoOfBags")
        ? {
            title: "No of Bags",
            amount: { val1: formatNumber(bagSum?.totalNoOfBags, "No of Bags") },
            icon: bagImage,
            percentage: getPercentage(
              analyticsData,
              bagSum?.totalNoOfBags,
              selectedDateType,
              "totalNoOfBags",
              "Bags"
            ),
            iconBg: "#63B3ED",
          }
        : {
            title: "No of Bags",
            amount: { val1: formatNumber(0, "No of Bags") },
            icon: bagImage,
            percentage: getPercentage(
              analyticsData,
              0,
              selectedDateType,
              "totalNoOfBags",
              "Bags"
            ),
          };

      return { insight, loading };
    }

    case "commodities": {
      const analyticsData = state?.analyticsData || [];
      const loading = state?.analyticsLoading;
      const insight = {
        title: "Commodities",
        amount: { val1: uniqueIdsArray(analyticsData, "commodities") },
        icon: wheat,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "commodities"),
          selectedDateType,
          "commodities",
          "Commodity"
        ),
        iconBg: "#F6AD55",
      };
      return { insight, loading };
    }

    case "sitesInspected": {
      //kotak
      const analyticsData = state?.analyticsData || [];
      const loading = state?.analyticsLoading;

      const insight = {
        title: "Sites Inspected",
        amount: {
          val1: uniqueIdsArray(analyticsData, "originInspected"),
        },
        icon: locationImage,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "originInspected"),
          selectedDateType,
          "originInspected",
          "Sites"
        ),
        iconBg: "#FFAFCC",
      };

      return { insight, loading };
    }

    case "inspectionCount": {
      //Kotak Barchart
      let barChartData = state?.barChartData || [];
      const loading = state?.barChartLoading;
      let labels = [];
      let data = [];

      if (barChartData?.length > 0) {
        if ("week" in barChartData[0]) {
          labels = formattedDates(barChartData, "weekStartTimestamp");
        } else if ("day" in barChartData[0]) {
          labels = barChartData.map((item) => {
            if (item.month && item.year) {
              return `${item.day} ${convertMonthNumberToName(
                item.month,
                item.year
              )}`;
            }
          });
        } else {
          labels = barChartData.map((item) => {
            if (item.month && item.year) {
              return `${convertMonthNumberToName(item.month, item.year)}`;
            }
          });
        }
        data = barChartData || [];
      }
      barChartData =
        state?.barChartData?.map((dataItem) => {
          return [
            {
              label: "Quantity (MT)",
              value: dataItem?.quantity.toFixed(2),
              year: dataItem?.year,
            },
            {
              label: "No of Bags",
              value: dataItem?.noOfBags || 0,
              year: dataItem?.year,
            },
            {
              label: "Inspection Count",
              value: dataItem?.inspectionsCount || 0,
              year: dataItem?.year,
            },
          ];
        }) || [];

      // console.log({ barChartData, data, labels });
      return { barChartData, data, labels, loading };
    }

    case "commodityHealth": {
      const doughnutChartData = state?.doughnutChartData || {};
      const colorMap = { average: "#FFB783", good: "#39AEA3", bad: "#FF6D6D" };
      const selectedKeys = Object.keys(doughnutChartData).filter(
        (key) => doughnutChartData[key] !== 0
      );

      const chartArr = [];
      for (let key in doughnutChartData) {
        if (selectedKeys.includes(key)) {
          chartArr.push(doughnutChartData[key]);
        }
      }

      const selectedColors = selectedKeys.map((key) => colorMap[key]);
      return { chartArr, selectedColors, colorMap };
    }

    case "measuredCommodities": {
      let quantityInsightsData = state?.quantityInsightsData || [];
      quantityInsightsData = quantityInsightsData?.map((obj) => {
        const resKeys = ["quantity", "commodity", "noOfBags"];

        const transformData = (val) => {
          if (_.isNumber(val) && !_.isInteger(val)) return val.toFixed(2);
          if (_.isString(val)) return _.capitalize(val);
          return val;
        };
        return resKeys.reduce((acc, cv) => {
          acc[cv] =
            (obj && obj[cv] && obj[cv] === "") ||
            obj[cv] === null ||
            obj[cv] === undefined ||
            obj[cv] === 0
              ? "-"
              : transformData(obj[cv]);
          return acc;
        }, {});
      });

      return { quantityInsightsData };
    }

    case "warehouseScore": {
      const warehouseScore = state?.warehouseScore || [];
      return warehouseScore;
    }

    case "geographicalReach": {
      const mapChartData = state?.mapChartData || [];
      const loading = state?.mapChartLoading;
      const tooltipData = [
        { label: "Total Inspected Quantity", keyText: "quantity", unit: "MT" },
        { label: "No. of Bags", keyText: "noOfBags" },
      ];
      return { mapChartData, tooltipData, loading };
    }
    // Agency Dashboard Below

    case "totalInspections_overview": {
      const inspectionsCompletedData = state?.inspectionsCompletedData || {};
      const tooltipData = state?.inspectionsCompletedData?.details || [];
      const loading = state?.inspectionsCompletedDataLoading;

      let labels = [];
      let data = [];
      let inspectionsSum = null;

      if (inspectionsCompletedData && inspectionsCompletedData.totalItems) {
        if ("week" in inspectionsCompletedData?.details[0]) {
          labels = formattedDates(
            inspectionsCompletedData?.details,
            "weekStartTimestamp"
          );
        } else if ("day" in inspectionsCompletedData?.details[0]) {
          labels = inspectionsCompletedData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${item.day} ${convertMonthNumberToName(
                item.month,
                item.year
              )}`;
            }
          });
        } else {
          labels = inspectionsCompletedData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${convertMonthNumberToName(item.month, item.year)}`;
            }
          });
        }
        data = inspectionsCompletedData?.details?.map((item) => {
          if (item["inspectionsCount"]) {
            return parseInt(item["inspectionsCount"]);
          }
        });
        inspectionsSum = inspectionsCompletedData?.totalItems;
      }
      const insight = {
        title: "Inspections Completed",
        amount: { val1: inspectionsSum || 0 },
        icon: auditImage,
        labels: labels,
        chartData: data,
        // percentage: getPercentage(
        //   inspectionsCompletedData?.details,
        //   inspectionsSum,
        //   selectedDateType,
        //   "inspectionsCount",
        //   "Inspections"
        // ),
      };

      return { insight, loading, tooltipData };
    }
    case "clientsInspected": {
      // console.log({ state });
      const clientsInspectedData = state?.clientsInspectedData || {};
      const tooltipData = state?.clientsInspectedData?.details || [];
      const loading = state?.clientsInspectedDataLoading;
      const clientsArr = state?.clientsOfCustomer || [];

      let labels = [];
      let data = [];
      let inspectionsSum = null;

      if (clientsInspectedData && clientsInspectedData.totalItems) {
        if ("week" in clientsInspectedData?.details[0]) {
          labels = formattedDates(
            clientsInspectedData?.details,
            "weekStartTimestamp"
          );
        } else if ("day" in clientsInspectedData?.details[0]) {
          labels = clientsInspectedData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${item.day} ${convertMonthNumberToName(
                item.month,
                item.year
              )}`;
            }
          });
        } else {
          labels = clientsInspectedData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${convertMonthNumberToName(item.month, item.year)}`;
            }
          });
        }
        data = clientsInspectedData?.details?.map((item) => {
          if (item["clientsCount"]) {
            return parseInt(item["clientsCount"]);
          }
        });
        inspectionsSum = clientsInspectedData?.totalItems;
      }

      const insight = {
        title: "Clients Inspected",
        amount: { val1: inspectionsSum || 0, val2: clientsArr?.length || 0 },
        icon: client,
        labels: labels,
        chartData: data,
        // percentage: getPercentage(
        //   clientsInspectedData?.details,
        //   inspectionsSum,
        //   selectedDateType,
        //   "clientsCount",
        //   "Inspections"
        // ),
        iconBg: "#30B1D0",
      };

      return { insight, loading, tooltipData };
    }

    case "sitesInspected_overview": {
      const sitesInspectedData = state?.sitesInspectedData || {};
      const tooltipData = state?.sitesInspectedData?.details || [];
      const loading = state?.sitesInspectedDataLoading;

      let labels = [];
      let data = [];
      let inspectionsSum = null;

      if (sitesInspectedData && sitesInspectedData.totalItems) {
        if ("week" in sitesInspectedData?.details[0]) {
          labels = formattedDates(
            sitesInspectedData?.details,
            "weekStartTimestamp"
          );
        } else if ("day" in sitesInspectedData?.details[0]) {
          labels = sitesInspectedData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${item.day} ${convertMonthNumberToName(
                item.month,
                item.year
              )}`;
            }
          });
        } else {
          labels = sitesInspectedData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${convertMonthNumberToName(item.month, item.year)}`;
            }
          });
        }
        data = sitesInspectedData?.details?.map((item) => {
          if (item["originsCount"]) {
            return parseInt(item["originsCount"]);
          }
        });
        inspectionsSum = sitesInspectedData?.totalItems;
      }

      const insight = {
        title: "Sites Inspected",
        amount: { val1: inspectionsSum || 0 },
        icon: locationImage,
        iconBg: "#FFAFCC",
        labels: labels,
        chartData: data,
        // percentage: getPercentage(
        //   sitesInspectedData.details,
        //   inspectionsSum,
        //   selectedDateType,
        //   "originsCount",
        //   "Inspections"
        // ),
      };

      return { insight, loading, tooltipData };
    }

    case "scheduled": {
      const analyticsData = state?.agencyAnalyticsData || [];
      const loading = state?.agencyAnalyticsDataLoading;
      const insight = {
        title: "Scheduled",
        amount: {
          val1: analyticsData ? analyticsData["scheduledInspections"] : 0,
        },
        icon: scheduled,
        // percentage: getPercentage(
        //   analyticsData,
        //   uniqueIdsArray(analyticsData, "commodities"),
        //   selectedDateType,
        //   "commodities",
        //   "Commodity"
        // ),
        iconBg: "#A0AEC0",
      };
      return { insight, loading, isInfoBtnActive: true };
    }
    case "inProgress": {
      const analyticsData = state?.agencyAnalyticsData || [];
      const loading = state?.agencyAnalyticsDataLoading;
      const insight = {
        title: "In-Progress",
        amount: {
          val1: analyticsData ? analyticsData["inprogressInspections"] : 0,
        },
        icon: inProg,
        // percentage: getPercentage(
        //   analyticsData,
        //   uniqueIdsArray(analyticsData, "commodities"),
        //   selectedDateType,
        //   "commodities",
        //   "Commodity"
        // ),
        iconBg: "#63B3ED",
      };
      return { insight, loading, isInfoBtnActive: true };
    }
    case "inReview": {
      const analyticsData = state?.agencyAnalyticsData || [];
      const loading = state?.agencyAnalyticsDataLoading;
      const insight = {
        title: "In-Review",
        amount: {
          val1: analyticsData ? analyticsData["inreviewInspections"] : 0,
        },
        icon: search,
        // percentage: getPercentage(
        //   analyticsData,
        //   uniqueIdsArray(analyticsData, "commodities"),
        //   selectedDateType,
        //   "commodities",
        //   "Commodity"
        // ),
        iconBg: "#F6AD55",
      };
      return { insight, loading, isInfoBtnActive: true };
    }
    case "approved": {
      const approvedInspectionCount = state?.approvedInspectionCount || 0;
      const loading = state?.approvedInspectionCountLoading;
      const insight = {
        title: "Approved",
        amount: { val1: approvedInspectionCount },
        icon: check,
        // percentage: getPercentage(
        //   analyticsData,
        //   uniqueIdsArray(analyticsData, "commodities"),
        //   selectedDateType,
        //   "commodities",
        //   "Commodity"
        // ),
        iconBg: "#68D391",
      };
      return { insight, loading };
    }
    case "overdue": {
      const analyticsData = state?.agencyAnalyticsData || [];
      const loading = state?.agencyAnalyticsDataLoading;
      const insight = {
        title: "Overdue",
        amount: {
          val1: analyticsData ? analyticsData["overdueInspections"] : 0,
        },
        icon: warning,
        // percentage: getPercentage(
        //   analyticsData,
        //   uniqueIdsArray(analyticsData, "commodities"),
        //   selectedDateType,
        //   "commodities",
        //   "Commodity"
        // ),
        iconBg: "#FC8181",
      };
      return { insight, loading, isInfoBtnActive: true };
    }
    case "inspectionCount_overview": {
      //BARCHART AGENCY
      let barChartData = state?.agencyBarChartData || {};
      const loading = state?.agencyBarChartDataLoading;
      let labels = [];
      let data = [];
      if (barChartData && barChartData.totalItems) {
        if ("week" in barChartData?.details[0]) {
          labels = formattedDates(barChartData?.details, "weekStartTimestamp");
        } else if ("day" in barChartData?.details[0]) {
          labels = barChartData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${item.day} ${convertMonthNumberToName(
                item.month,
                item.year
              )}`;
            }
          });
        } else {
          labels = barChartData?.details?.map((item) => {
            if (item.month && item.year) {
              return `${convertMonthNumberToName(item.month, item.year)}`;
            }
          });
        }
        data = barChartData?.details || [];
      }
      barChartData = barChartData?.details || [];

      return { barChartData, data, labels, loading };
    }
    case "geographicalReachWithFilter": {
      let mapChartData = _.cloneDeep(state?.agencyGeographicalReachData || []);
      let loading = state?.agencyGeographicalReachDataLoading;
      const tooltipData = [
        { label: "Inspections Completed", keyText: "inspectionDoneCount" },
        // {
        //   label: "Avg. Inspection Completion Rate",
        //   keyText: "avgInspCompRate",
        //   unit: "%",
        // },
        { label: "Sites Inspected", keyText: "originsCount" },
        {
          label: "Clients Inspected",
          keyText: "clientsAssignedCount",
          keyText1: "clientsCount",
        },
      ];
      mapChartData = mapChartData.map((mapData) => {
        return {
          ...mapData,
          avgInspCompRate: Math.round(
            (mapData["inspectionDoneCount"] /
              mapData["inspectionAssignedCount"]) *
              100
          ),
        };
      });

      return { mapChartData, tooltipData, loading };
    }
    case "totalInspections_client_statistics": {
      const analyticsData = state?.clientStatisticsData || [];
      const loading = state?.clientStatisticsLoading;

      const inspectionCountMap = {};
      let res = 0;
      analyticsData?.forEach((client) => {
        client?.inspectionCompleted?.forEach((inspection) => {
          if (!inspectionCountMap[inspection]) {
            inspectionCountMap[inspection] = true;
            res += 1;
          }
        });
      });

      const insight = {
        title: "Inspections Completed",
        amount: { val1: res },
        icon: auditImage,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "commodities"),
          selectedDateType,
          "commodities",
          "Commodity"
        ),
      };
      return { insight, loading };
    }
    case "numAuditors": {
      const analyticsData = state?.clientStatisticsData || [];
      const loading = state?.clientStatisticsLoading;

      const auditorsCountMap = {};
      let res = 0;
      analyticsData?.forEach((client) => {
        client?.auditorAudited?.forEach((auditor) => {
          if (!auditorsCountMap[auditor]) {
            auditorsCountMap[auditor] = true;
            res += 1;
          }
        });
      });

      const insight = {
        title: "Auditors Audited",
        amount: { val1: res },
        icon: auditor1,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "commodities"),
          selectedDateType,
          "commodities",
          "Commodity"
        ),
        iconBg: "#30B1D0",
      };
      return { insight, loading };
    }

    case "averageOnTimeCompletionRate": {
      const analyticsData = state?.clientStatisticsData || [];
      const loading = state?.clientStatisticsLoading;

      const { onTime, total } = analyticsData?.reduce(
        (acc, cv) => {
          let onTime = 0,
            total = 0;
          if (cv.inspectionsCompletedOnTime) {
            onTime = cv.inspectionsCompletedOnTime.length;
          }
          if (cv.inspectionCompleted) {
            total = cv.inspectionCompleted.length;
          }
          total = cv.inspectionCompleted.length;
          acc.onTime += onTime;
          acc.total += total;
          return acc;
        },
        { onTime: 0, total: 0 }
      );

      const res = isNaN(Math.round((onTime * 100) / total))
        ? 0
        : `${Math.round((onTime * 100) / total)}%`;

      const insight = {
        title: "Avg. On-Time Completion Rate",
        amount: { val1: res },
        icon: timeRate,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "commodities"),
          selectedDateType,
          "commodities",
          "Commodity"
        ),
        iconBg: "#FFAFCC",
      };
      return { insight, loading };
    }

    case "averageTimePerInspection": {
      const analyticsData = state?.clientStatisticsData || [];
      const loading = state?.clientStatisticsLoading;

      const { totalTime, totalInspections } = analyticsData.reduce(
        (acc, cv) => {
          const totalInspections = cv.inspectionCompleted?.length;
          const totalTime = cv.avgCompletionTime * totalInspections;

          acc.totalInspections += totalInspections;
          acc.totalTime += totalTime;
          return acc;
        },
        { totalTime: 0, totalInspections: 0 }
      );

      const formatAvgCompletionTime = (ms) => {
        if (isNaN(ms)) {
          return 0;
        }
        const millisecondsInADay = 24 * 60 * 60 * 1000;
        const millisecondsInAnHour = 60 * 60 * 1000;

        const days = Math.floor(ms / millisecondsInADay);
        let hours = ((ms % millisecondsInADay) / millisecondsInAnHour).toFixed(
          1
        );

        const floatingHour = parseInt(hours.toString().split(".")[1]);

        if (!floatingHour) {
          hours = hours.toString().split(".")[0];
        }

        if (days && hours) {
          return `${days}d ${hours}h`;
        } else if (days) {
          return `${days}d`;
        } else {
          return `${hours}h`;
        }
      };

      const res = formatAvgCompletionTime(totalTime / totalInspections);

      const insight = {
        title: "Avg. Time / Inspection",
        amount: { val1: res },
        icon: inspectTime,
        percentage: getPercentage(
          analyticsData,
          uniqueIdsArray(analyticsData, "commodities"),
          selectedDateType,
          "commodities",
          "Commodity"
        ),
        iconBg: "#63B3ED",
      };
      return { insight, loading };
    }
    case "clientsForTable": {
      const loading = state?.clientStatisticsLoading;
      const formatAvgCompletionTime = (ms) => {
        if (isNaN(ms)) return 0;
        const millisecondsInADay = 24 * 60 * 60 * 1000;
        const millisecondsInAnHour = 60 * 60 * 1000;

        const days = Math.floor(ms / millisecondsInADay);
        let hours = ((ms % millisecondsInADay) / millisecondsInAnHour).toFixed(
          1
        );

        const floatingHour = parseInt(hours.toString().split(".")[1]);

        if (!floatingHour) {
          hours = hours.toString().split(".")[0];
        }

        if (days && hours) {
          return `${days}d ${hours}h`;
        } else if (days) {
          return `${days}d`;
        } else {
          return `${hours}h`;
        }
      };
      const calculateAvOnTimeCompletionRate = (onTime, total) => {
        if (onTime && total) {
          return `${Math.round((onTime * 100) / total)}%`;
        } else if (total) {
          return `0%`;
        } else {
          return `-`;
        }
      };
      const getInspectionTypes = (categories) =>
        categories.map((category) => category.type);
      const headerList = [
        {
          displayText: "Client Name",
          keyText: "clientName",
        },
        {
          displayText: "Inspections Completed",
          keyText: "totalInspections",
        },
        {
          displayText: "Scheduled Inspections",
          keyText: "scheduledInspections",
        },
        {
          displayText: "Sites Inspected",
          keyText: "sitesInspected",
        },
        { displayText: "No. of Auditors", keyText: "noOfAuditors" },
        {
          displayText: "Avg. On-Time Completion Rate",
          keyText: "avgOnTimeCompletionRate",
        },
        {
          displayText: "Avg. Time / Inspection",
          keyText: "avgTimeInspection",
        },
        {
          displayText: "Inspection Types",
          keyText: "inspectionTypes",
        },
        {
          keyText: "action",
          displayText: "Action",
        },
      ];
      const dataList = state.clientStatisticsData?.map((client, index) => {
        const logo = state.entityProfileImgUrls[index];
        return {
          clientName: (
            <ClientDetails
              customerId={entityId}
              clientId={client.clientId}
              clientName={client.clientName}
              clientLogo={logo ? logo.url : ""}
              clientEmail={client.clientEmail}
            />
          ),
          totalInspections: client.inspectionCompleted?.length,
          scheduledInspections: client.inspectionScheduled?.length,
          sitesInspected: client.originInspected.length,
          noOfAuditors: client.auditorAudited.length,
          avgTimeInspection: formatAvgCompletionTime(client.avgCompletionTime),
          avgOnTimeCompletionRate: calculateAvOnTimeCompletionRate(
            client.inspectionsCompletedOnTime?.length,
            client.inspectionCompleted?.length
          ),
          action: <Action clientId={client.clientId} />,
          inspectionTypes: client?.inspectionCategories ? (
            <InspectionTypes
              inspectionTypes={getInspectionTypes(client.inspectionCategories)}
            />
          ) : (
            "-"
          ),
        };
      });
      return { headerList, dataList, unit: "Clients", loading };
    }
    default:
      return null;
  }
};

export default dataAdapter;
