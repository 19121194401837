import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  warehouseInsightsData: null,
  warehouseInsightsLoading: false,
  error: false,
};

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    fetchWarehouseInsightsData(state, action) {
      state.warehouseInsightsLoading = true;
    },
    fetchWarehouseInsightsDataSuccess(state, action) {
      state.warehouseInsightsData = action.payload;
      state.warehouseInsightsLoading = false;
    },
    fetchWarehouseInsightsDataFailure(state, action) {
      state.warehouseInsightsLoading = false;
      state.error = true;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.warehouseInsightsData = "";
    },
    clearStateFailure(state, action) {},
  },
});

export const { actions, reducer, name: sliceKey } = warehouseSlice;
