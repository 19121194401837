import React from "react";
import { Box, CircularProgress, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import { Pagination, Table } from "@agnext/reactlib";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import AvatarWithName from "views/jobs/components/viewJob/Table Components/AvatarWithName";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { calculateTotalPages } from "utils/commonFunctions";

const Icons = ({ userId, customerId, userRole, clientId = "" }) => {
  // console.log(clientId);
  return (
    <Flex gap={2}>
      <Link
        to={
          clientId
            ? `/admin/users/viewUser?userId=${userId}&customerId=${customerId}&clientId=${clientId}`
            : `/admin/users/viewUser?userId=${userId}&customerId=${customerId}`
        }
      >
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      {userRole !== "manager" ? (
        <Link
          to={
            clientId
              ? `/admin/users/updateUser?userId=${userId}&customerId=${customerId}&clientId=${clientId}`
              : `/admin/users/updateUser?userId=${userId}&customerId=${customerId}`
          }
        >
          <Box
            background={"#EDF2F7"}
            height={"40px"}
            width={"48px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"12px"}
            style={{ cursor: "pointer" }}
          >
            <Image src={Edit} alt="" />
          </Box>
        </Link>
      ) : (
        <></>
      )}
    </Flex>
  );
};

const UserTable = ({
  ownerEntityId,
  allUsers,
  usersProfileImgUrls,
  clientProfileImgUrls,
  userRole,
  isClientUser,
  page,
  setPage,
  isLoadingPagination,
  handleIsLoadingPagination,
  totalNumOfUsers
}) => {
  // UserTable
  // for my users
  const HeaderForMyUsers = [
    {
      displayText: "Full Name",
      keyText: "fullName",
      hasSort: false,
    },
    {
      displayText: "Department",
      keyText: "department",
      hasSort: true,
    },
    {
      displayText: "Designation",
      keyText: "designation",
      hasSort: true,
    },
    {
      displayText: "Role",
      keyText: "role",
      hasSort: true,
    },
    {
      displayText: "Location",
      keyText: "location",
      hasSort: true,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: true,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
      width: "150px",
    },
  ];
  // console.log(allUsers);
  const tableListForMyUsers = allUsers?.map((user, index) => ({
    fullName: (
      <AvatarWithName
        avatarUrl={usersProfileImgUrls ? usersProfileImgUrls[index]?.url : ""}
        name={
          capitalizeStringUpdated(user.firstName + " " + user.lastName) || "-"
        }
      />
    ),
    department: capitalizeStringUpdated(user.department) || "-",
    designation: capitalizeStringUpdated(user.designation) || "-",
    actions: (
      <Icons
        userId={user.userId}
        customerId={ownerEntityId}
        userRole={userRole}
      />
    ),
    role: user.roles?.length
      ? capitalizeStringUpdated(
          user?.roles
            ?.map((role) => role?.roleName)
            .join(", ")
            .split("-")
            .join(" ")
        )
      : // capitalizeStringUpdated(user.roles[0]?.roleName)
        "-",
    location:
      user.addresses && user.addresses.length > 0
        ? user.addresses[0]?.city || user.addresses[0]?.state
          ? capitalizeStringUpdated(
              `${user.addresses[0]?.city ?? ""}${
                user.addresses[0]?.city && user.addresses[0]?.state ? ", " : ""
              }${user.addresses[0]?.state ?? ""}`
            )
          : "-"
        : "-",
    email: user.email,
    createdDate: extractDayMonthYearFromTimestamp(user.creationDt) || "-",
  }));

  // for Client Users
  const HeaderForClientUsers = [
    {
      displayText: "Full Name",
      keyText: "fullName",
      hasSort: false,
    },
    {
      displayText: "Client Name",
      keyText: "clientName",
      hasSort: false,
    },
    {
      displayText: "Role",
      keyText: "role",
      hasSort: true,
    },
    {
      displayText: "Location",
      keyText: "location",
      hasSort: true,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: true,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
      width: "150px",
    },
  ];
  // console.log(allUsers);
  const tableListForClientUser = allUsers?.map((user, index) => ({
    fullName: (
      <AvatarWithName
        bg="red"
        avatarUrl={usersProfileImgUrls ? usersProfileImgUrls[index]?.url : ""}
        name={capitalizeStringUpdated(user.firstName + " " + user.lastName)}
      />
    ),
    clientName: (
      <AvatarWithName
        avatarUrl={clientProfileImgUrls ? clientProfileImgUrls[index]?.url : ""}
        name={capitalizeStringUpdated(user.clientName)}
      />
    ),
    actions: (
      <Icons
        userId={user.userId}
        customerId={ownerEntityId}
        userRole={userRole}
        clientId={user.clientId}
      />
    ),
    role: user.roles?.length
      ? capitalizeStringUpdated(
          user?.roles
            ?.map((role) => role?.roleName)
            .join(", ")
            .split("-")
            .join(" ")
        )
      : "-",
    location:
      user.addresses && user.addresses.length > 0
        ? user.addresses[0]?.city || user.addresses[0]?.state
          ? capitalizeStringUpdated(
              `${user.addresses[0]?.city ?? ""}${
                user.addresses[0]?.city && user.addresses[0]?.state ? ", " : ""
              }${user.addresses[0]?.state ?? ""}`
            )
          : "-"
        : "-",
    email: user.email,
    createdDate: extractDayMonthYearFromTimestamp(user.creationDt) || "-",
  }));

  // Pagination 
  const numPages = calculateTotalPages(totalNumOfUsers, 10);
  const handleNextPage = () => {
    if (page === numPages - 1) return;
    handleIsLoadingPagination(true);
    setPage(page + 1);
  };
  const handlePreviousPage = () => {
    if (page === 0) return;
    handleIsLoadingPagination(true);
    setPage(page - 1);
  };

  const handlePageSelect = (pageNumber) => {
    if (page === pageNumber) return;
    handleIsLoadingPagination(true);
    setPage(pageNumber);
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"flex-end"} w={"100%"} mb="10px">
        <Pagination
          page={page}
          numPages={numPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Box>
      <Box>
        <HStack
          display={isLoadingPagination ? "flex" : "none"}
          justifyContent={"center"}
        >
          <CircularProgress isIndeterminate color="#4FD1C5" size="36px" />
        </HStack>
        <Box
          display={isLoadingPagination ? "none" : "block"}
          background={"white"}
          borderRadius={"12px"}
        >
          <Table
            overflow="auto"
            overflowY="auto"
            // tableContainerHeight={{ base: "441px", sm: "378px" }}
            headerList={isClientUser ? HeaderForClientUsers : HeaderForMyUsers}
            dataList={
              isClientUser ? tableListForClientUser : tableListForMyUsers
            }
            stickyColumn={["Actions"]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserTable;
