import React from "react";
import { Button } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

export default function JSONExport({ fetchData, name }) {
  const handleExport = async () => {
    const res = await fetchData();
    const data = res.data.payload;
    // Convert the data object to a JSON string
    const jsonData = JSON.stringify(data, null, 2);

    // Create a blob with the JSON data
    const blob = new Blob([jsonData], { type: "application/json" });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.json`;
    document.body.appendChild(a);

    // Click the <a> element to trigger the download
    a.click();

    // Cleanup: remove the <a> element and revoke the URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button
      variant="ghost"
      borderRadius="8px"
      onClick={handleExport}
      sx={{
        _hover: {
          bg: "teal.400",
          ".icon": {
            color: "white",
          },
        },
      }}
    >
      <DownloadIcon color="#718096"  className="icon"/>
    </Button>
  );
}
