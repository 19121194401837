import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const AllJobTop = ({ totalJobCount, customerId }) => {
  return (
    <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
      <Text fontSize={"18px"} fontWeight={700}>
        {totalJobCount ? `${totalJobCount} Jobs` : "Jobs"}
      </Text>
      <Link to={`/admin/add-job?customerId=${customerId}`}>
        <Button colorScheme="login" size="md" borderRadius={"8px"}>
          + Create New Job
        </Button>
      </Link>
    </HStack>
  );
};

export default AllJobTop;
