import { Table } from "@agnext/reactlib";
import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import { Link } from "react-router-dom";

const Icons = ({ roleId }) => {
  return (
    <Flex gap={2}>
      <Link to={`/admin/userRoles/view/${roleId}`}>
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      <Link to={`/admin/userRoles/edit/${roleId}`}>
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Edit} alt="" />
        </Box>
      </Link>
    </Flex>
  );
};

const StatusComponent = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Draft":
        return "#718096";
      case "Active":
        return "#718096";
      case "inactive":
        return "#DD6B20";
      default:
        return "#718096";
    }
  };

  return (
    <Box
      style={{
        width: "auto",
        height: "100%",
        paddingLeft: 4,
        paddingRight: 4,
        background: getStatusColor(status),
        borderRadius: 4,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "inline-flex",
      }}
    >
      <Box
        style={{
          color: "white",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: "700",
          textTransform: "uppercase",
          wordWrap: "break-word",
        }}
      >
        {status == "inactive" ? "deactivated" : status}
      </Box>
    </Box>
  );
};

const UserRoleTable = ({ props }) => {
  const tableData = props?.map((data) => {
    const { roleId, description, numberOfUsers, roleName, status } = data;
    let tempRoleName = roleName;
    if (tempRoleName?.includes("_")) {
      const words = tempRoleName.split("_");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      tempRoleName = capitalizedWords.join(" ");
    } else {
      const word =
        tempRoleName?.charAt(0).toUpperCase() + tempRoleName?.slice(1);
      tempRoleName = word;
    }
    return {
      roleName: tempRoleName,
      description: description,
      status: <StatusComponent status={status} />,
      noOfUser: numberOfUsers,
      actions: <Icons roleId={roleId} />,
    };
  });
  tableData?.sort((a, b) => a.roleName.localeCompare(b.roleName));
  const Header = [
    {
      displayText: "Role Name",
      keyText: "roleName",
      hasSort: true,
    },
    {
      displayText: "Description",
      keyText: "description",
    },
    {
      displayText: "No of Users",
      keyText: "noOfUser",
      hasSort: true,
    },
    {
      displayText: "Status",
      keyText: "status",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];

  return (
    <Box background={"white"} borderRadius={"12px"}>
      <Table
        overflow="auto"
        overflowY="auto"
        // tableContainerHeight={{ base: "441px", sm: "378px" }}
        headerList={Header}
        dataList={tableData}
      />
    </Box>
  );
};

export default UserRoleTable;
