import { useState } from "react";
import styles from "./styles.module.css";
import { HStack, Box } from "@chakra-ui/react";
import Profile from "./components/Profile";
import ChangePassword from "./components/ChangePassword";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { userStorage } from "utils/helper";
import { actions, reducer, sliceKey } from "./slice";
import { userAccountSaga } from "./saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import { useEffect } from "react";

export default function Account() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userAccountSaga });

  let dispatch = useDispatch();

  const userStorageData = userStorage?.get();

  let userData = useSelector(selectors.selectUserData) || [];
  let uploadURL = useSelector(selectors.selectPreDefinedURL) || "";
  let uploadLoading = useSelector(selectors.selectUploadLoading) || "";
  let {
    id,
    email,
    firstName,
    lastName,
    mobile,
    countryCode,
    avatar,
  } = userData;
  useEffect(() => {
    dispatch(actions.fetchUserData({ id: userStorageData.id }));
  }, []);

  return (
    <HStack
      sx={{ width: "100%", padding: "75px 0" }}
      gap="25px"
      align="stretch"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Profile
        id={id}
        emailId={email}
        firstName={firstName}
        lastName={lastName}
        mobile={mobile}
        countryCode={countryCode}
        uploadURL={uploadURL}
        uploadLoading={uploadLoading}
        avatar={avatar}
      />
      <ChangePassword emailId={email} />
    </HStack>
  );
}
