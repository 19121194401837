import {
  Avatar,
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import React from "react";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
const styles = {
  popoverMainContainer: {
    display: "flex",
    minWidth: "255px",
    padding: "12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    border: "1px solid #EDF2F7",
    background: "#FFF",
    boxShadow:
      "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
    height: "112px",
  },
  name: {
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px" /* 142.857% */,
  },
  viewProfileBtn: {},
};

const PopupBox = ({ id, name, email, logo,customerId }) => {
  return (
    <VStack sx={{ ...styles.popoverMainContainer }}>
      <HStack gap="12px" height="48px">
        <Avatar name={name} src={logo}/>
        <VStack alignItems="center" gap="16px">
          <Box>
            <Text sx={{ ...styles.name }}>{name}</Text>
            <HStack alignItems="center" gap="8px">
              <Icon as={CiMail} boxSize={5} color="#4A5568" />
              <Text sx={{ color: "#4A5568", fontSize: "12px" }}>{email}</Text>
            </HStack>
          </Box>
        </VStack>
      </HStack>
      <HStack w="100%" justifyContent={"flex-end"}>
        <Link to={`/admin/clients/viewClient?clientId=${id}&customerId=${customerId}`} target="_blank">
          <Button
            size="xs"
            sx={{
              width: "171px",
              height: "24px",
              colorScheme: "gray",
              fontWeight: 600,
              fontSize: "12px",
              borderRadius: "4px",
            }}
            rightIcon=""
          >
            View Profile
          </Button>
        </Link>
      </HStack>
    </VStack>
  );
};

export default PopupBox;
