import React, { useState } from "react";
import Dropdown from "views/shared/Dropdown";

function Commodity({
  commodityNames,
  handleFilterQuery,
  filterQuery,
  property,
}) {
  return (
    <Dropdown
      key={property}
      options={commodityNames}
      placeholder={"Commodity"}
      handleFilterQuery={handleFilterQuery}
      property={property}
      listKey={"name"}
      filterQuery={filterQuery}
    />
  );
}

export default Commodity;
