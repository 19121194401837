import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import Low from "assets/svg/Low.svg";
import Medium from "assets/svg/Medium.svg";
import High from "assets/svg/High.svg";
import Critical from "assets/svg/Critical.svg";

const getPriorityImg = (priority) => {
   switch (priority) {
      case "low":
         return Low;
      case "medium":
         return Medium;
      case "high":
         return High;
      case "critical":
         return Critical;
   }
};

const PriorityWIthIcon = ({ priority }) => {
   return (
      <Flex gap={"8px"} alignItems={"center"}>
         <Box w="20px" h="20px" alignItems={"center"}>
            <Image w="100%" h="100%" src={getPriorityImg(priority?.toLowerCase())} alt="Dan Abramov" />
         </Box>
         <Text fontSize={"12px"} fontWeight={"500"}>
            {capitalizeStringUpdated(priority)}
         </Text>
      </Flex>
   );
};

export default PriorityWIthIcon;
