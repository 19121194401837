import React, { useEffect, useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { MobileRangePicker } from "@agnext/reactlib";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  startOfYear,
  endOfMonth,
  endOfYear,
  subMonths,
  subWeeks,
  getYear,
} from "date-fns";

const MobileDateRangePicker = ({
  width,
  filters,
  handleFromDateChange,
  handleToDateChange,
}) => {
  const [selectedPresetValue, setSelectedPresetValue] = useState("All Time");

  const { fromDate, toDate } = filters;

  const presetDateRanges = [
    "All Time",
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "This Month",
    "Last Month",
  ];

  const currentYear = getYear(new Date());
  const handlePresetDateRangeClick = (range) => {
    setSelectedPresetValue(range);

    const dateRangeMap = {
      Today: { fromDate: startOfDay(new Date()), toDate: endOfDay(new Date()) },
      Yesterday: {
        fromDate: startOfDay(subDays(new Date(), 1)),
        toDate: endOfDay(subDays(new Date(), 1)),
      },
      "This Week": {
        fromDate: startOfWeek(new Date()),
        toDate: endOfDay(new Date()),
      },
      "Last Week": {
        fromDate: startOfWeek(subWeeks(new Date(), 1)),
        toDate: endOfWeek(subWeeks(new Date(), 1)),
      },
      "This Month": {
        fromDate: startOfMonth(new Date()),
        toDate: endOfDay(new Date()),
      },
      "Last Month": {
        fromDate: startOfMonth(subMonths(new Date(), 1)),
        toDate: endOfMonth(subMonths(new Date(), 1)),
      },
      "Kharif Season": {
        fromDate: startOfMonth(new Date(currentYear, 6, 1)),
        toDate: endOfMonth(new Date(currentYear, 10, 30)),
      },
      "Rabi Season": {
        fromDate: startOfMonth(new Date(currentYear, 10, 1)),
        toDate: endOfMonth(new Date(currentYear + 1, 2, 31)),
      },
      "All Time": { fromDate: null, toDate: null },
    };

    const { fromDate, toDate } = dateRangeMap[range] || {
      fromDate: null,
      toDate: null,
    };

    handleFromDateChange(fromDate);
    handleToDateChange(toDate);
  };
  useEffect(() => {
    if (!fromDate && !toDate) {
      setSelectedPresetValue("All Time");
    } else if (fromDate && toDate && selectedPresetValue === "All Time") {
      setSelectedPresetValue("");
    }
  }, [fromDate, toDate]);
  return (
    <>
      <Box width={width}>
        <MobileRangePicker
          width={"100%"}
          fromDate={filters.fromDate}
          toDate={filters.toDate}
          setFromDate={handleFromDateChange}
          setToDate={handleToDateChange}
          backgroundSelected="#4FD1C5"
          colorSelected="#fff"
          startYear={2023}
          endYear={getYear(new Date())}
          leftComponent={
            <>
              {presetDateRanges.map((item, index) => (
                <Button
                  variant="outline"
                  style={{
                    padding: "0px 8px",
                    borderRadius: "8px",
                    height: "24px",
                    background:
                      selectedPresetValue === item ? "#718096" : "none",
                    color: selectedPresetValue === item ? "#fff" : "#4A5568",
                    alignItems: "baseline",
                    minWidth: "fit-content",
                  }}
                  textAlign="center"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  key={index}
                  onClick={() => handlePresetDateRangeClick(item)}
                >
                  {item}
                </Button>
              ))}
            </>
          }
        />
      </Box>
    </>
  );
};

export default MobileDateRangePicker;
