import { useEffect } from "react";
import useToastNotification from "utils/useToastNotification";

const ErrorFallback = ({ message, errorToast, setErrorToast }) => {
  const show = useToastNotification();

  useEffect(() => {
    if (errorToast === true) {
      show({
        message: message.replace(/([a-z])([A-Z])/g, "$1 $2"),
        status: "error",
      });
    }
    setErrorToast(false);
  }, []);

  return null;
};

export default ErrorFallback;
