import JSONExport from "../JSONExport";
import * as APIs from "../../../../services";

export default function WorkflowExport({ workflowId }) {
  const handleFetchWorkflowData = async () => {
    // fetch workflow data
    const workflowData = await APIs.fetchWorkflow(workflowId);
    const workflow = workflowData.data.payload.workflow;
    // extract all form ids related to workflow
    const wForms = workflowData.data.payload.forms;
    const formIds = wForms.reduce((acc, cv) => {
      acc.push(cv.id);
      return acc;
    }, []);
    // create promise array for all api calls for all form ids
    const formsDataPromises = formIds.map((id) => APIs.fetchFormData({ id }));
    const formsDataArray = await Promise.all(formsDataPromises);

    console.log({formsDataArray})
    // extract data in the format needed while import
    const forms = formsDataArray.reduce((acc, cv) => {
      let newForm = {
        form: cv.data.payload.form,
        sections: cv.data.payload.sections,
        blocks: cv.data.payload.blocks,
        composites: cv.data.payload.composites,
        components: cv.data.payload.components,
      };
      acc.push(newForm);
      return acc;
    }, []);

    return {
      data: {
        payload: {
          workflow,
          forms,
        },
      },
    };
  };

  return <JSONExport fetchData={handleFetchWorkflowData} name="workflow" />;
}
