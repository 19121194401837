import { Table } from "@agnext/reactlib";
import { Box } from "@chakra-ui/react";

export default function TableSimple({ data }) {
  return (
    <Box
      w="100%"
      // w={"calc(50% - 12px)"}
      sx={{
        border: "1px solid #E2E8F0",
        boxShadow: "0px 3.499999761581421px 5.5px rgba(0, 0, 0, 0.02)",
        background: "#fff",
        borderRadius: "15px",
        padding: "20px",
        overflowX: "scroll",
      }}
    >
      <Table
        headerList={data.headerList}
        dataList={data.dataList}
        tableContainerHeight={{ base: "441px", sm: "378px" }}
        fixedHeader={true}
      />
      {/* <Box w="800px" h="400px" bg="#ccc"></Box> */}
    </Box>
  );
}
