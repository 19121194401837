import React, { useEffect } from "react";
import AddClient from "./AddClient";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../slice";
import { watchClientSaga } from "../saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import Client from "./Client";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const EditClient = ({ location }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchClientSaga });
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get("clientId");
  const customerId = searchParams.get("customerId");

  const clientDetails = useSelector(selectors.selectClientDetails);
  const clientAddress = useSelector(selectors.selectClientAddress);
  const links = useSelector(selectors.selectClientLinks);
  const originLocations = useSelector(selectors.selectWarehouseData);

  const address = clientAddress && clientAddress[0];
  useEffect(() => {
    dispatch(actions.fetchClientDataRequest({ clientId, customerId }));
    dispatch(actions.fetchClientAddressRequest({ clientId, customerId }));
    dispatch(actions.fetchClientLinksRequest({ clientId, customerId }));
    dispatch(actions.fetchWarehouseDetailsRequest(clientId));
  }, [customerId, dispatch]);

  const refreshLocations = () => {
    dispatch(actions.fetchWarehouseDetailsRequest(clientId));
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <AddClient
        oldLinks={links}
        isEditing={true}
        oldClientDetails={clientDetails}
        oldClientAddress={address}
        clientId={clientId}
        // customerId={customerId}
        clientAddress={clientAddress}
        oldLocations={originLocations}
        refreshLocations={refreshLocations}
      />
    </ErrorBoundary>
  );
};

export default EditClient;
