export const TABLE_HEADERS = {
  fullHeaders: [
    {
      displayText: "Name",
      keyText: "name",
      hasSort: false,
    },
    {
      displayText: "Warehouse Code",
      keyText: "externalCode",
      hasSort: false,
    },
    {
      displayText: "Contact Person",
      keyText: "contactPerson",
      hasSort: true,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: true,
    },
    {
      displayText: "Phone Number",
      keyText: "phoneNumber",
      hasSort: false,
    },
    {
      displayText: "Location Type",
      keyText: "locationType",
      hasSort: true,
    },
    {
      displayText: "Address",
      keyText: "address",
      hasSort: true,
    },
    {
      displayText: "Status",
      keyText: "status",
      hasSort: false,
    },
    {
      displayText: "Actions",
      keyText: "actions",
      hasSort: false,
    },
  ],
  partialHeaders: [
    {
      displayText: "Name",
      keyText: "name",
      hasSort: false,
    },
    {
      displayText: "Warehouse Code",
      keyText: "externalCode",
      hasSort: false,
    },
    {
      displayText: "Contact Person",
      keyText: "contactPerson",
      hasSort: true,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: true,
    },
    {
      displayText: "Phone Number",
      keyText: "phoneNumber",
      hasSort: false,
    },
    {
      displayText: "Location Type",
      keyText: "locationType",
      hasSort: true,
    },
    {
      displayText: "Address",
      keyText: "address",
      hasSort: true,
    },
  ],
};
