import React, { useState } from "react";
import {
  HStack,
  VStack,
  Box,
  Text,
  Select,
  Flex,
  CircularProgress,
} from "@chakra-ui/react";
import MapChart from "../../../../../components/Charts/MapChart";
import noRecordImage from "../../../../../assets/img/Empty Case.png";

const geoDropdownDetails = [
  { label: "Inspection Count", value: "inspectionDoneCount" },
  // { label: "Inspection Completion Rate", value: "avgInspCompRate" }, hidden until logic is clear for this parameter
  { label: "Sites Inspected", value: "originsCount" },
];

const styles = {
  geoInsightsContainer: {
    borderRadius: "15px",
    background: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    gap: "0px",
    border: "1px solid #E2E8F0",
  },
  geoheaderContainer: {
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    padding: "20px",
  },
  header: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
  },
  mapDropdowntitle: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    width: "50%",
    alignContent: "center",
  },
  select: {
    width: "100%",
    height: "32px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#F7FAFC",
  },
  selectOpt: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  message: {
    display: "flex",
    width: "100%",
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "190px",
    margin: "auto",
  },
};

export default function GeographicalReach({
  data,
  selectedDataKey,
  showDropdown = false,
  isMobile,
}) {
  const [selectedGeoOption, setSelectedGeoOption] = useState(selectedDataKey);
  const { mapChartData, tooltipData, loading } = data;
  const handleDataTypeChange = (event) => {
    const newValue = event.target.value;
    setSelectedGeoOption(newValue);
  };

  return (
    <VStack sx={styles.geoInsightsContainer} bg={"#FFF"} width={"100%"}>
      <HStack sx={styles.geoheaderContainer}>
        <Box>
          <Text sx={styles.header}>Geographical Reach</Text>
        </Box>

        {showDropdown && mapChartData && mapChartData?.length ? (
          <Flex gap="16px">
            <Select
              sx={styles.select}
              onChange={handleDataTypeChange}
              value={selectedGeoOption}
            >
              <option disabled>View Map By:</option>
              {geoDropdownDetails.map((option) => {
                return (
                  <option
                    sx={styles.selectOpt}
                    key={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </Flex>
        ) : (
          <></>
        )}
      </HStack>
      <Box width={"100%"}>
        {loading ? (
          <Flex
            sx={{
              justifyContent: "center",
              height: "inherit",
              alignItems: "center",
            }}
          >
            <CircularProgress
              isIndeterminate
              color="#4FD1C5"
              size="45px"
              alignSelf={"center"}
            />
          </Flex>
        ) : mapChartData &&
          tooltipData &&
          mapChartData?.length !== 0 &&
          tooltipData?.length ? (
          <MapChart
            regionData={mapChartData}
            tooltipData={tooltipData}
            handleDataTypeChange={handleDataTypeChange}
            selectedGeoOption={selectedGeoOption}
            isMobile={isMobile}
          />
        ) : (
          <VStack style={styles.message}>
            <img
              style={styles.image}
              src={noRecordImage}
              alt="No Records Image"
            />
            <p>No Data Available</p>
          </VStack>
        )}
      </Box>
    </VStack>
  );
}
