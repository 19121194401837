import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Select from "views/shared/Select";

const flexStyles = {
  gap: "18px",
  flexDirection: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};

const departments = ["Operations", "Management"];

const CustomInput = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  isValid,
  errorMessage,
  isDisabled,
}) => {
  return (
    <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <Input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        width="100%"
        alignSelf="stretch"
        focusBorderColor="#3182ce"
        onChange={(event) => onChange(event.target.value)}
        fontSize="14px"
        isDisabled={isDisabled}
      />
      {!isValid && (
        <Text fontSize="12px" color="red.500">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

const countryCodes = ["India +91", "UAE +971"];

const AddUser = ({
  handleInputChange,
  userDetails,
  emailIsValid,
  setEmailIsValid,
  isPhoneValid,
  setIsPhoneValid,
  countryCode,
  setCountryCode,
  setUserDetails,
}) => {
  const handleOptionChange = (value, customKey) => {
    if (customKey === "countryCode") {
      let code = !value ? null : value === "India +91" ? 91 : 971;
      setUserDetails((prevValues) => {
        return { ...prevValues, countryCode: code };
      });
      if (value) {
        setIsPhoneValid(validatePhone(userDetails?.mobile, value));
      } else {
        if (!userDetails?.mobile) setIsPhoneValid(true);
        else setIsPhoneValid(false);
      }
      setCountryCode(value);
    } else {
      setUserDetails((prevValues) => {
        return { ...prevValues, [customKey]: value };
      });
    }
  };
  const validateEmail = (email) => {
    if (!email) return true;
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };
  const validatePhone = (phone, code) => {
    let tempCode = code ? code : countryCode;
    if (!phone) return true;
    if (!tempCode) return false;
    if (tempCode === "India +91") {
      // Indian mobile number validation
      const pattern = /^[6-9]\d{9}$/;
      return pattern.test(phone);
    } else if (tempCode === "UAE +971") {
      // UAE mobile number validation
      const pattern = /^[0-9]{9}$/;
      return pattern.test(phone);
    }
    return false;
  };
  return (
    <Box padding={"16px 24px"}>
      <Flex direction={"column"} gap={"18px"}>
        <Flex sx={flexStyles}>
          <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              Department*
            </Text>
            <Select
              customKey="department"
              options={departments}
              placeholder="Select"
              value={userDetails?.department}
              handleOptionChange={handleOptionChange}
            />
          </Flex>
          <CustomInput
            label="Designation*"
            placeholder="eg. Manager"
            value={userDetails?.designation}
            onChange={(value) => handleInputChange("designation", value)}
          />
        </Flex>
        <Flex sx={flexStyles}>
          <CustomInput
            label="First Name*"
            placeholder="eg Amit"
            value={userDetails?.firstName}
            onChange={(value) => handleInputChange("firstName", value)}
          />

          <CustomInput
            label="Last Name*"
            placeholder="eg. Singh"
            value={userDetails?.lastName}
            onChange={(value) => handleInputChange("lastName", value)}
          />
        </Flex>
        <Flex sx={flexStyles}>
          <CustomInput
            label="Email*"
            placeholder="eg. abc@gmail.com"
            value={userDetails?.email}
            onChange={(value) => {
              handleInputChange("email", value);
              handleInputChange("userName", value);
              setEmailIsValid(validateEmail(value));
            }}
            isValid={emailIsValid}
            errorMessage="Invalid email format"
          />
          <Flex w="49%">
            <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
              <Text fontSize={"12px"} fontWeight={"700"}>
                Phone Number*
              </Text>

              <InputGroup>
                <InputLeftAddon p={"0"} br={"2px"} border={"none"}>
                  <Select
                    placeholder="(+XX)"
                    customKey="countryCode"
                    value={countryCode}
                    handleOptionChange={handleOptionChange}
                    options={countryCodes}
                    minWidth="120px"
                    maxWidth="120px"
                  />
                </InputLeftAddon>

                <Flex w="100%">
                  <Input
                    type={"number"}
                    placeholder={
                      countryCode === "UAE +971"
                        ? "eg. +971 000 000 ####"
                        : "eg. +91 000 000 ####"
                    }
                    value={userDetails?.mobile}
                    width="100%"
                    alignSelf="stretch"
                    focusBorderColor="#3182ce"
                    onChange={(event) => {
                      setIsPhoneValid(validatePhone(event.target.value));
                      handleInputChange("mobile", event.target.value);
                    }}
                    fontSize="14px"
                  />
                </Flex>
              </InputGroup>
              {!isPhoneValid && (
                <Text fontSize="12px" color="red.500">
                  {!countryCode
                    ? "Please select Country code"
                    : "Please enter valid mobile number"}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AddUser;
