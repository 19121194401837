import React, { useEffect, useState } from "react";
import { Box, Text, VStack, Stack } from "@chakra-ui/react";
import { Droppable, Draggable } from "react-beautiful-dnd";
export default function SectionsList({ sections, selected }) {
  return (
    <Box
      w="400px"
      p="16px"
      h="calc(100vh - 200px)"
      border="1px solid #efefef"
      bg={`rgba(226, 232, 240, .8)`}
      borderRadius="16px"
    >
      <Text color="#2D3748" fontSize="16px" fontWeight="500" mb="16px">
        Pages
      </Text>
      <Box h="calc(100% - 40px)" overflow="scroll">
        <Droppable droppableId="droppable-pages-list">
          {(provided, snapshot) => (
            <VStack
              w="100%"
              gap="12px"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sections?.length ? (
                sections.map((section, index) => (
                  <Draggable
                    key={section.id}
                    draggableId={`${section.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Stack
                        w="100%"
                        h="48px"
                        justify="center"
                        alignItems="flex-start"
                        pl="16px"
                        bg={`#fff`}
                        borderRadius="16px"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Text
                          color={section.id === selected ? "teal" : "#2D3748"}
                          fontSize="14px"
                          fontWeight="500"
                        >
                          {section.title}
                        </Text>
                      </Stack>
                    )}
                  </Draggable>
                ))
              ) : (
                <></>
              )}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </Box>
    </Box>
  );
}
