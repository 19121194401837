import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../services/apis";

//Generator Functions
export function* handleFetchNotifications({ payload }) {
  try {
    const res = yield call(API.getNotifications, payload.payload);
    const { data } = res;
    yield put(actions.fetchNotificationsSuccess());
  } catch (err) {
    yield put(actions.fetchNotificationsFailure(err));
  }
}
export function* handleMarkReadAllNotifications({ payload }) {
  try {
    const res = yield call(API.markReadAllNotifications, payload.payload);
    const { data } = res;
    yield put(actions.markReadAllNotificationsSuccess(data?.payload));
  } catch (err) {
    yield put(actions.markReadAllNotificationsFailure(err));
  }
}
export function* handleReadNotification({ payload }) {
  try {
    const res = yield call(API.readNotification, payload.payload);
    const { data } = res;
    yield put(actions.readNotificationSuccess(data?.payload));
  } catch (err) {
    yield put(actions.readNotificationFailure(err));
  }
}
export function* handleFetchUserData({ payload }) {
  try {
    const res = yield call(API.getUserProfile, payload);
    const { data } = res;
    yield put(actions.fetchUserDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchUserDataFailure(err?.response?.data));
  }
}
export function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(API.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}
export function* notificationSaga() {
  yield takeLatest(actions.fetchNotifications.type, handleFetchNotifications);
  yield takeLatest(
    actions.markReadAllNotifications.type,
    handleMarkReadAllNotifications
  );
  yield takeLatest(actions.readNotification.type, handleReadNotification);
  yield takeLatest(actions.fetchUserData.type, handleFetchUserData);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(actions.clearState.type, handleClearState);
}
