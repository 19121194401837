import React from "react";
import {
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import UserBulkCreation from "../../../BulkUpload/BulkUserCreation/index";
import uploadIcon from "../../../../assets/svg/upload.svg";
import plusIcon from "../../../../assets/svg/BiPlus.svg";

const UserTop = ({
  totalUsers,
  isClientUser,
  setIsClientUser,
  ownerEntityId,
  userRole,
  handleApplyButton
}) => {
  const {
    isOpen: userBulkUploadModalIsOpen,
    onOpen: userBulkUploadModalOnOpen,
    onClose: userBulkUploadModalOnClose,
  } = useDisclosure();

  return (
    <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
      {/* <Text fontSize={"18px"} fontWeight={700}>
        Users
      </Text> */}
      <Text
        fontSize={"18px"}
        fontWeight={700}
        lineHeight={"25.2px"}
        color={"var(#2D3748)"}
      >
        {isClientUser
          ? totalUsers < 2
            ? totalUsers + " Client User"
            : totalUsers + " Client Users"
          : totalUsers < 2
          ? totalUsers + " My User"
          : totalUsers + " My Users"}
      </Text>

      <Flex gap={"24px"}>
        <Tabs colorScheme="teal">
          <TabList>
            <Tab
              onClick={() => {
                setIsClientUser(false);
                handleApplyButton();
              }}
            >
              My Users
            </Tab>
            <Tab
              onClick={() => {
                setIsClientUser(true);
                handleApplyButton();
              }}
            >
              Client Users
            </Tab>
          </TabList>
        </Tabs>
        {userRole !== "manager" ? (
          // <Link to={`/admin/users/addUser?customerId=${ownerEntityId}`}>
          //   <Button
          //     colorScheme="login"
          //     bg={"#38B2AC"}
          //     size="md"
          //     borderRadius={"8px"}
          //   >
          //     + Add User
          //   </Button>
          // </Link>
          <Menu>
            <MenuButton
              colorScheme="login"
              borderRadius="8px"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              Add User
            </MenuButton>
            <MenuList placement="left" color="#4A5568" zIndex={10}>
              <MenuItem gap="10px">
                <Image src={plusIcon} alt="icon" w="24px" />
                <Link to={`/admin/users/addUser?customerId=${ownerEntityId}`}>
                  Create New User
                </Link>
              </MenuItem>

              <MenuItem onClick={userBulkUploadModalOnOpen} gap="10px">
                <Image src={uploadIcon} alt="icon" w="24px" />
                Bulk upload
              </MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <></>
        )}
      </Flex>
      <UserBulkCreation
        onOpen={userBulkUploadModalOnOpen}
        isOpen={userBulkUploadModalIsOpen}
        onClose={userBulkUploadModalOnClose}
        ownerEntityId={ownerEntityId}
      />
    </HStack>
  );
};

export default UserTop;
