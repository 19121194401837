import React, { useEffect } from "react";
import AddCustomer from "./AddCustomer";
import { useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../slice";
import { CustomerSaga } from "../saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors";
import FallbackUI from "errorsFallback/FallbackUI";
import { ErrorBoundary } from "react-error-boundary";

const EditCustomer = ({ location }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: CustomerSaga });

  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("agencyId");

  const customerDetails = useSelector(selectors.selectCustomerDetails);
  const links = useSelector(selectors.selectAllCUstomerLinks);
  const customerAddresses = useSelector(selectors.selectCustomerAddresses);
  const originLocations = useSelector(selectors.selectWarehouseData);

  useEffect(() => {
    dispatch(actions.getCustomerDetails({ customerId }));
    dispatch(actions.getCustomerLinks({ customerId }));
    dispatch(actions.getCustomerAddresses({ customerId }));
    dispatch(actions.fetchWarehouseDetailsRequest(customerId));
  }, [customerId]);

  const refreshLocations = () => {
    dispatch(actions.fetchWarehouseDetailsRequest(customerId));
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <AddCustomer
        isEditing={true}
        oldCustomerDetails={customerDetails}
        oldLinks={links}
        oldCustomerAddresses={customerAddresses}
        customerId={customerId}
        oldLocations={originLocations}
        refreshLocations={refreshLocations}
      />
    </ErrorBoundary>
  );
};

export default EditCustomer;
