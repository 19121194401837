import { Avatar, Box, HStack, Text, useOutsideClick } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import PopupBox from "./PopupBox";

const ClientDetails = ({
  clientId,
  clientName,
  clientLogo,
  clientEmail,
  customerId,
}) => {
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const ref = useRef();

  const handleClick = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  useOutsideClick({
    ref: ref,
    handler: () => setIsBoxVisible(false),
  });

  return (
    <>
      <HStack sx={{ position: "relative" }}>
        <Avatar name={clientName} src={clientLogo} />
        <Text
          position="relative"
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#319795",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {clientName}
        </Text>
        {isBoxVisible && (
          <Box ref={ref} position="absolute" zIndex={10} top="54px">
            <PopupBox
              id={clientId}
              name={clientName}
              logo={clientLogo}
              email={clientEmail}
              customerId={customerId}
            />
          </Box>
        )}
      </HStack>
    </>
  );
};

export default ClientDetails;
