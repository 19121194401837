import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FiFile } from "react-icons/fi";
import IoCheckmarkDoneCircle from "../../../assets/svg/IoCheckmarkDoneCircle.svg";
import { Table } from "@agnext/reactlib";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import * as selectors from "../selectors";

function UploadedTemplate({
  fileName,
  isOpen,
  onClose,
  userType,
  avatar,
  manager,
  details,
}) {
  const dataErrorResponse = useSelector(selectors.selectDataErrorResponse);

  const header = Object.keys(
    dataErrorResponse?.duplicateData?.[0] || {}
  ).map((key) => ({ displayText: key, keyText: key }));
  const tableList = dataErrorResponse?.duplicateData;

  return (
    //
    <Modal
      // onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={"900px"}
        borderRadius="15px"
      >
        <ModalHeader color={"#2D3748"} fontWeight={700} fontSize={"18px"}>
          Bulk Upload
        </ModalHeader>
        <HStack
          borderBottom={"1px solid #E2E8F0"}
          paddingBottom={"16px"}
          fontSize="14px"
        >
          {userType && (
            <HStack>
              <Text pl={"25px"} fontWeight="400" color="#718096">
                User Type:
              </Text>
              <Text fontWeight="500" color="#4A5568">
                {" "}
                {userType}
              </Text>
            </HStack>
          )}
          {userType !== "My Users" ? (
            <HStack gap={"5px"}>
              <Text pl={"25px"} fontWeight="400" color="#718096">
                {manager}:
              </Text>
              <Avatar
                width={"24px"}
                height={"24px"}
                name={details?.orgName || "NA"}
                src={avatar}
              />
              <Text fontWeight="500" color="#4A5568">
                {" "}
                {details?.orgName || "NA"}{" "}
              </Text>
            </HStack>
          ) : null}
        </HStack>
        <ModalCloseButton me="5px" mt="6px" onClick={onClose} />
        <ModalBody>
          <VStack alignItems="center" w="100%" gap="16px">
            <Box maxW="64px">
              <Image src={IoCheckmarkDoneCircle} alt="" />
            </Box>
            <Text
              color="#4A5568"
              fontWeight="500"
              fontSize="18px"
              lineHeight="25.2px"
            >
              Upload Completed!
            </Text>
            <HStack
              w="60%"
              justify="space-between"
              align="center"
              style={{
                padding: "6px 12px",
                background: "#F7FAFC",
                borderRadius: "8px",
              }}
            >
              <HStack>
                <FiFile size="22px" color="#718096" />
                <Text
                  color="#4A5568"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="1.4"
                >
                  {fileName}
                </Text>
              </HStack>
            </HStack>
            <Text
              color="#718096"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
            >
              {`${dataErrorResponse?.uploaded} out of ${dataErrorResponse?.total} assets successfully added.`}
            </Text>
          </VStack>

          {dataErrorResponse?.not_uploaded !== 0 ? (
            <Stack gap={"18px"} w="100%">
              <Text color={"#4A5568"} fontSize={"16px"} fontWeight={"500"}>
                {dataErrorResponse?.duplicateData?.length} assets could not be
                imported:
              </Text>
              <UnorderedList
                color="#4A5568"
                fontSize="12px"
                fontWeight="400"
                bg="#F7FAFC"
                spacing="8px"
                w="100%"
                p="10px"
                borderRadius="8px"
                maxH="90px"
                overflow="auto"
              >
                {dataErrorResponse?.duplicateDataMessage?.map((item, index) => (
                  <ListItem key={index}>{item}</ListItem>
                ))}
              </UnorderedList>
              <Stack
                maxH="180px"
                maxW="852px"
                border="1px solid #E2E8F0"
                borderRadius="8px"
              >
                <Table
                  overflow="auto"
                  overflowY="auto"
                  headerList={header}
                  dataList={tableList}
                />
              </Stack>
            </Stack>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="login"
            size="md"
            borderRadius={"8px"}
            onClick={onClose}
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    //
  );
}

export default UploadedTemplate;
