import React from "react";
import { Breadcrumb, BreadcrumbItem, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { isNanoId } from "utils/utils";
import dashRoutes from "routes";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const _ = require("lodash");

export default function Breadcrumbs() {
  const { pathname } = useLocation();
  let pathItems = pathname.split("/");
  // pathItems = pathItems.slice(2);
  pathItems = pathItems.slice(pathItems.length - 1);

  let url = "/admin";
  const breadcrumbLinks = pathItems.map((pathItem, i) => {
    url += `/${pathItem}`;
    let newPathItem = "";
    if (isNanoId(pathItem)) {
      newPathItem = dashRoutes.reduce((acc, cv) => {
        // console.log(`/admin${cv.path}`)
        if (url === `/admin${cv.path}`) {
        }
      }, "");
    }
    return (
      <BreadcrumbItem key={i} color="mainText">
        {/* <Link to={url}> */}
        {/* {capitalizeStringUpdated(pathItem)} */}
        <Text fontSize="16px" color="#1f2733" fontWeight="700">
          {_.startCase(pathItem)}
        </Text>
        {/* </Link> */}
      </BreadcrumbItem>
    );
  });
  return <Breadcrumb>{breadcrumbLinks}</Breadcrumb>;
}
