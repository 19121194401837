import { Table } from "@agnext/reactlib";
import {
  Box,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import checkIcon from "../../../assets/svg/IoCheckmarkCircleSharp.svg";
import crossIcon from "../../../assets/svg/closeCircle.svg";

function VerifyFileData({
  dataErrorArray,
  headerList,
  dataList,
  errorRows,
  errorColumns,
  uploadFailed
}) {
  const headerListT = ["SR No.", ...headerList]?.map((data, index) => ({
    displayText: data,
    keyText: data,
    rightComponent:
      index === 0 ? null : (
        <Box w="25px" h="25px">
          {errorColumns.has(index) ? (
            <Image src={crossIcon} />
          ) : (
            <Image src={checkIcon} />
          )}
        </Box>
      ),
  }));

  const dataListT = dataList?.map((row, rowIndex) => {
    const rowData = {
      "SR No.": rowIndex + 1,
      bg: errorRows.has(rowIndex + 1)
        ? { r: 255, g: 245, b: 245 }
        : "transparent",
    };
    headerListT.forEach((header, index) => {
      if (header.keyText !== "SR No.") {
        rowData[header.keyText] = row[index - 1]; // Adjust index for "SR No."
      }
    });
    return rowData;
  });

  return (
    <Stack gap="18px" maxW="852px">
      {dataErrorArray?.length > 0 ? (
        <VStack gap={"18px"} align="flex-start" w={"100%"}>
          <Text color="#4A5568" fontWeight="500">
            {dataErrorArray?.length} errors found in your file
          </Text>
          <UnorderedList
            styleType="-"
            color="#4A5568"
            fontSize="12px"
            fontWeight="400"
            bg="#F7FAFC"
            spacing="8px"
            w="100%"
            p="16px"
            borderRadius="8px"
            maxH="90px" overflow="auto"
          >
            {dataErrorArray?.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </VStack>
      ) : (
        !uploadFailed && <Text color="#4A5568" fontWeight="500">
          No errors detected in your file.
        </Text>
      )}
      <Stack overflow="auto" maxW="852px" maxH="250px" border="1px solid #E2E8F0" borderRadius="8px">
        <Table
          overflow="auto"
          overflowY="auto"
          headerList={headerListT}
          dataList={dataListT}
        />
      </Stack>
    </Stack>
  );
}

export default VerifyFileData;
