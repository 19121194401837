import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actions } from "../Create/slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";
import { replaceIds } from "utils";
import { updateFormStructureIds } from "utils";

export function* handleFetchWorkflows() {
  try {
    //console.log("saga was hit");
    const res = yield call(API.fetchWorkflows);
    //console.log("res", res);
    const data = res.data.payload;
    yield put(actions.fetchWorkflowsSuccess(data));
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchWorkflowsFailure(err?.response?.data));
  }
}
export function* handleCloneWorkflow({ payload }) {
  try {
    const res = yield call(API.fetchWorkflow, payload.workflowId);
    const data = res.data.payload;
    payload.onSuccess({
      workflow: data.workflow,
      forms: data.forms
      })
    yield put(actions.cloneWorkflowSuccess())
  } catch (err) {
    console.log(err);
    yield put(actions.cloneWorkflowFailure());
  }
}

export function* handleInitCopyWorkflow({ payload }) {
  try {
    const resWorkflow = yield call(API.postWorkflow, payload.payload.workflowData);
    const resForms = yield call(API.postFormsData, payload.payload.formData);
    const resWorkflowForms = yield call(
      API.postWorkflowFormData,
      payload.payload.workflowFormData
    );
    if(resWorkflow.data.success ) {
      payload.onSuccess()
    }
    yield put(actions.initCopyWorkflowSuccess())
  } catch (err) {
    console.log(err)
    yield put(actions.initCopyWorkflowFailure());
  }
}

export function* handleCopyForms({ payload }) {
  try {
    const res = yield call(API.fetchFormData, payload);
    const { data } = res;
    const replacedIdData =updateFormStructureIds(data.payload , payload.newId )
    const {form,sections,blocks, composites,components}= replacedIdData
    const postData ={
      forms: [{...form , status : "draft"}],
      sections,
      blocks,
      composites,
      components,
    }
    const postForm = yield call(API.postFormData, postData);
    yield put(actions.copyFormsSuccess())
  } catch (err) {
    console.log(err)
    yield put(actions.copyFormsFailure());
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* WorkflowsSaga() {
  yield takeLatest(actions.fetchWorkflows.type, handleFetchWorkflows);
  yield takeLatest(actions.cloneWorkflow.type, handleCloneWorkflow);
  yield takeLatest(actions.initCloneWorkflow.type, handleInitCopyWorkflow);
  yield takeEvery(actions.copyForms.type, handleCopyForms);
}
