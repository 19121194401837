import React from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
export function SearchBar(props) {
  const { searchQuery, handleSearchQuery } = props;
  const mainTeal = useColorModeValue("gray", "gray");
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "gray.800");
  return (
    <InputGroup
      bg={inputBg}
      borderRadius="8px"
      w="auto"
      _focus={{
        borderColor: { mainTeal },
      }}
      _active={{
        borderColor: { mainTeal },
      }}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            _hover="none"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="14px" h="14px" />}
            marginTop={"2px"}
          ></IconButton>
        }
      />
      <Input
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "#B5BECA",
        }}
        fontSize="xs"
        py="11px"
        backgroundColor="#F7FAFC"
        value={searchQuery}
        placeholder="Search any query..."
        onChange={(e) => {
          handleSearchQuery(e.target.value);
        }}
        height={"40px"}
      />
    </InputGroup>
  );
}
