import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "../../services/apis";
import { actions } from "./slice";
import * as API1 from "services/index";
import { downloadPdf, downloadSummarySheet } from "utils/commonFunctions";

function* fetchWarehouseDetails(action) {
  try {
    const response = yield call(api.getWarehouseData);
    const { data } = response;
    yield put(actions.fetchWorkflowDetailsSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchWorkflowDetailsFaliure(e));
  }
}
function* fetchWarehouseDetailsByClientId(action) {
  try {
    const response = yield call(api.getWarehouseDetails,action.payload);
    const { data } = response;
    yield put(
      actions.fetchWarehouseDetailsRequestByClientIdSuccess(data.payload)
    );
  } catch (e) {
    yield put(actions.fetchWarehouseDetailsRequestByClientIdFaliure(e));
  }
}
function* handleFetchJob(action) {
  try {
    const response = yield call(api.getOneJob, action.payload);
    const { data } = response;
    yield put(actions.fetchJobSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchJobFaliure(e));
  }
}
function* fetchCreateInspection(action) {
  try {
    const response = yield call(
      api.createInspection,
      action.payload.inspectionPayload
    );
    action.payload.onSuccess(response.data.payload.id);
    yield put(actions.fetchCreateInspectionSuccess());
  } catch (e) {
    action.payload.onFailure();
    yield put(actions.fetchCreateInspectionFailure(e));
  }
}

function* fetchCreateJob(action) {
  try {
    const response = yield call(api.createJob, action.payload);
    action.payload.onSuccess(
      response.data.payload.id,
      action.payload.inspectionId
    );
    yield put(actions.fetchCreateJobSuccess());
  } catch (e) {
    action.payload.onFailure();
    yield put(actions.fetchCreateJobFailure(e));
  }
}

function* fetchPostTasks(action) {
  try {
    const response = yield call(api.createTasks, action.payload);
    action.payload.onSuccess(response.data.success);
    yield put(actions.fetchPostTasksSuccess());
  } catch (e) {
    // action.payload.onFailure();
    yield put(actions.fetchPostTasksFailure(e));
  }
}

function* fetchJobList(action) {
  // try {
  //   const response = yield call(api.createJob);
  //   console.log(response);
  //   yield put(actions.fetchJobsListSuccess());
  // } catch (e) {
  //   yield put(actions.fetchCreateJobFailure(e));
  // }
}
function* fetchUserDetails(action) {
  try {
    const response = yield call(api.userDetails, action.payload);
    yield put(actions.fetchUserDetailsSuccess(response));
  } catch (error) {
    yield put(actions.fetchUserDetailsFaliure(error));
  }
}

function* handleFetchWarehouseDetailsById(action) {
  try {
    const response = yield call(api.getWarehouses, action.payload);
    const { data } = response;
    console.log("warehouseDetails--", data)
    const warehouseObj = {};
    let modifiedData = data.payload.map((obj) => {
      warehouseObj[obj.id] = { ...obj };
    });
    yield put(actions.fetchWarehouseDetailsByIdSuccess(warehouseObj));
  } catch (error) {
    yield put(actions.fetchWarehouseDetailsByIdFaliure(error));
  }
}

function* fetchWorkflowList(action) {
  try {
    const response = yield call(api.getWorkflowList);
    const { data } = response;
    yield put(actions.fetchWorkfkowListSuccess(data.payload)); //fetchWorkfkowListSuccess
  } catch (e) {
    yield put(actions.fetchWorkfkowListFailure(e));
  }
}

function* fetchWorkflowData(action) {
  try {
    const response = yield call(api.fetchWorkflowData, action.payload);
    const { data } = response;
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchWorkflowDataFailure(e));
  }
}

function* fetchGetTasks(action) {
  try {
    const response = yield call(api.getTasks, action.payload);
    const { data } = response;
    yield put(actions.fetchGetTasksSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchGetTasksFailure(e));
  }
}

function* fetchInspection(action) {
  try {
    const response = yield call(api.getOneInspection, action.payload);
    const { data } = response;
    yield put(actions.fetchGetInspectionSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchGetInspectionFailure(e));
  }
}

function* fetchUpdateTask(action) {
  try {
    const response = yield call(api.updateTask, action.payload);
    // action.payload.onSuccess(response.data.success);
    yield put(actions.fetchUpdateTaskSuccess());
  } catch (e) {
    yield put(actions.fetchUpdateTaskFailure(e));
  }
}

function* fetchDeleteTasks(action) {
  try {
    const response = yield call(api.deleteTasks, action.payload);
    action.payload.onSuccess();
    yield put(actions.fetchDeleteTasksSuccess());
  } catch (e) {
    yield put(actions.fetchDeleteTasksFailure(e));
  }
}

function* fetchUpdateJob(action) {
  try {
    const response = yield call(api.updateJob, action.payload);
    action.payload.onSuccess(response.data.success);
    yield put(actions.fetchUpdateJobSuccess());
  } catch (e) {
    action.payload.onFailure();
    yield put(actions.fetchUpdateJobFailure(e));
  }
}

function* fetchUpdateInspection(action) {
  try {
    const response = yield call(api.updateInspection, action.payload);
    yield put(actions.fetchUpdateInspectionSuccess());
  } catch (e) {
    action.payload.onFailure();
    yield put(actions.fetchCreateInspectionFailure(e));
  }
}
function* handleFetchUserDetail(action) {
  try {
    const response = yield call(api.getUserDetails, action.payload);
    yield put(actions.fetchUserDetailSuccess(response));
  } catch (e) {
    yield put(actions.fetchUserDetailFailure(e));
  }
}

function* handleFetchUserProfileImgURL(action) {
  try {
    const response = yield call(api.getUserProfileImg, action.payload);
    yield put(actions.fetchUserProfileImgSuccess(response));
  } catch (e) {
    yield put(actions.fetchUserProfileImgFailure(e));
  }
}
function* handleUpdateJobStatus(action) {
  try {
    const response = yield call(api.updateJobStatus, action.payload);
    const { status } = response;
    if (status === 200) {
      action.payload.onSuccess();
    }
    yield put(actions.updateJobSuccess(response));
  } catch (e) {
    yield put(actions.updateJobFailure(e));
  }
}
function* handleDeleteJob(action) {
  try {
    const response = yield call(api.deleteJob, action.payload);
    const { status } = response;
    if (status === 200) {
      action.payload.onSuccess();
    }
    yield put(actions.deleteJobSuccess(response));
  } catch (e) {
    yield put(actions.deleteJobFailure(e));
  }
}
function* handleGetAllJobs(action) {
  try {
    const response = yield call(api.getAllJobs, action.payload);
    const { data } = response;
    if (data.success) {
      action.payload.onSuccess(false);
    }
    yield put(actions.getAllJobsSuccess(data));
  } catch (e) {
    yield put(actions.getAllJobsFailure(e));
  }
}
function* handleGetMultipleTasksOfJob(action) {
  try {
    const response = yield call(api.getMultipleTasks, action.payload);
    yield put(actions.getMultipleTasksOfJobSuccess(response));
  } catch (e) {
    yield put(actions.getMultipleTasksOfJobFailure(e));
  }
}
function* handleFetchMultipleUserDetails(action) {
  try {
    const response = yield call(api.getMultipleUserDetails, action.payload);
    yield put(actions.fetchMultipleUserDetailsSuccess(response));
  } catch (e) {
    yield put(actions.fetchMultipleUserDetailsFailure(e));
  }
}
function* handleFetchMultipleAvatarUrls(action) {
  try {
    const response = yield call(api.getMultipleAvatarUrls, action.payload);
    yield put(actions.fetchMultipleAvatarUrlsSuccess(response));
  } catch (e) {
    yield put(actions.fetchMultipleAvatarUrlsFailure(e));
  }
}
function* handleFetchCustomerUsers(action) {
  try {
    const response = yield call(api.getCustomerUsers, action.payload);
    const { data } = response;
    yield put(actions.fetchCustomerUsersSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchCustomerUsersFailure(e));
  }
}
function* handleFetchClientsByCustomerId(action) {
  try {
    const response = yield call(api.getClientsByCustomerId, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsOfCustomerSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchClientsOfCustomerFailure(e));
  }
}

export function* handleFetchFormData({ payload }) {
  try {
    const res = yield call(API1.fetchFormData, payload);
    const { data } = res;
    // console.log(data);
    yield put(actions.fetchFormDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchFormDataFailure(err?.response?.data));
  }
}

export function* handleFetchReviewData({ payload }) {
  try {
    const res = yield call(API1.getData, payload);
    const { data } = res;
    yield put(actions.fetchReviewDataSuccess(data.payload));
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchReviewDataFailure(err?.response?.data));
  }
}
export function* handleFetchInspectionDetails({ payload }) {
  try {
    const res = yield call(api.getInspectionDetails, payload);
    const { data } = res;
    yield put(actions.fetchInspectionDetailsSuccess(data.payload));
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchInspectionDetailsFailure(err?.response?.data));
  }
}

export function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(api.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}

function* handleFetchInspectionReport({ payload }) {
  try {
    const res = yield call(api.getInspectionReport, payload);
    const { data } = res;
    yield put(actions.fetchInspectionReportSuccess(data?.payload));
  } catch (err) {
    yield put(actions.fetchInspectionReportFailure(err));
  }
}

export function* handleDownloadReport({ payload }) {
  try {
    const { source } = payload;
    const res = yield call(api.fetchSASUrl, {
      source,
      blobName: payload?.data?.fileName,
    });
    downloadPdf(res.data.url, payload.data);
    yield put(actions.downloadReportSuccess());
  } catch (err) {
    yield put(actions.downloadReportFailure());
  }
}

// handleFetchTrackLocation;
export function* handleFetchTrackLocation({ payload }) {
  try {
    const res = yield call(api.getAuditorTrackLocation, payload);
    const { data } = res;
    yield put(actions.fetchTrackLocationSuccess(data?.payload));
  } catch (err) {
    yield put(actions.fetchTrackLocationsFailure(err));
  }
}
// export function* handleClearReviewDetails() {
//   try {
//     const { source } = payload;
//     const res = yield call(api.fetchSASUrl, {
//       source,
//       blobName: payload?.data?.fileName,
//     });
//     downloadPdf(res.data.url, payload.data);
//     yield put(actions.downloadReportSuccess());
//   } catch (err) {
//     yield put(actions.downloadReportFailure());
//   }
// }
export function* watchJobsAndManagement(action) {
  yield takeLatest(
    actions.fetchWarehouseDetailsRequest.type,
    fetchWarehouseDetails
  );
  yield takeLatest(
    actions.fetchWarehouseDetailsRequestByClientId.type,
    fetchWarehouseDetailsByClientId
  );
  yield takeLatest(actions.fetchJob.type, handleFetchJob);
  yield takeLatest(
    actions.fetchCreateInspectionRequest.type,
    fetchCreateInspection
  );
  yield takeLatest(actions.fetchCreateJobRequest.type, fetchCreateJob);
  yield takeLatest(actions.fetchJobsListRequest.type, fetchJobList);
  yield takeLatest(actions.fetchUserDetailsRequest.type, fetchUserDetails);
  yield takeLatest(
    actions.fetchWarehouseDetailsById.type,
    handleFetchWarehouseDetailsById
  );
  yield takeLatest(actions.fetchWorkfkowListRequest.type, fetchWorkflowList);
  yield takeLatest(actions.fetchWorkflowDataRequest.type, fetchWorkflowData);
  yield takeLatest(actions.fetchPostTasksRequest.type, fetchPostTasks);
  yield takeLatest(actions.fetchGetTasksRequest.type, fetchGetTasks);
  yield takeEvery(actions.fetchUpdateTaskRequest.type, fetchUpdateTask);
  yield takeEvery(actions.fetchGetInspectionRequest.type, fetchInspection);
  yield takeEvery(actions.fetchDeleteTasksRequest.type, fetchDeleteTasks);
  yield takeEvery(actions.fetchUpdateJobRequest.type, fetchUpdateJob);
  yield takeEvery(
    actions.fetchUpdateInspectionRequest.type,
    fetchUpdateInspection
  );
  yield takeLatest(actions.fetchUserDetail.type, handleFetchUserDetail);

  yield takeLatest(
    actions.fetchUserProfileImgURL.type,
    handleFetchUserProfileImgURL
  );
  yield takeLatest(actions.updateJob.type, handleUpdateJobStatus);
  yield takeLatest(actions.deleteJob.type, handleDeleteJob);
  yield takeLatest(actions.getAllJobs.type, handleGetAllJobs);
  yield takeLatest(
    actions.getMultipleTasksOfJob.type,
    handleGetMultipleTasksOfJob
  );
  yield takeLatest(
    actions.fetchMultipleUserDetails.type,
    handleFetchMultipleUserDetails
  );
  yield takeLatest(
    actions.fetchMultipleAvatarUrls.type,
    handleFetchMultipleAvatarUrls
  );
  yield takeLatest(actions.fetchCustomerUsers.type, handleFetchCustomerUsers);
  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handleFetchClientsByCustomerId
  );
  yield takeEvery(actions.fetchFormData.type, handleFetchFormData);
  yield takeLatest(actions.fetchReviewData.type, handleFetchReviewData);
  yield takeLatest(
    actions.fetchInspectionDetails.type,
    handleFetchInspectionDetails
  );
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(
    actions.fetchInspectionReport.type,
    handleFetchInspectionReport
  );
  yield takeLatest(actions.downloadReport.type, handleDownloadReport);
  // yield takeLatest(actions.clearReviewDetails.type, handleClearReviewDetails);
  yield takeLatest(actions.fetchTrackLocation.type, handleFetchTrackLocation);
}
