import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

function ConfirmationModal({
  isOpen,
  onClose,
  header,
  content,
  cancelButtonContent,
  OkButtonContent,
  okAction
}) {
  return (
    <>
      <Modal
        // onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={"486px"} borderRadius="15px">
          <ModalHeader color={"#2D3748"} fontWeight={700} fontSize={"18px"}>
            {header}
          </ModalHeader>
          <ModalCloseButton me="5px" mt="6px" onClick={onClose} />
          <ModalBody>
            <VStack alignItems="left" w="100%" gap="16px">
              <Text color="#718096" fontWeight="400">
                {content}
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                // bg="#EDF2F7"
                size="md"
                borderRadius={"8px"}
                onClick={onClose}
              >
                {cancelButtonContent}
              </Button>
              <Button
                colorScheme={OkButtonContent === "Cancel and Exit"? "red" : "login"}
                size="md"
                borderRadius={"8px"}
                onClick={okAction}
              >
                {OkButtonContent}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
