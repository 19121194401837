import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import Close from "assets/svg/ModalCloseButton.svg";
import Group from "assets/svg/Group.svg";
import Hash from "assets/svg/FiHash.svg";
import Seperator from "assets/svg/seperator.svg";
import FiUser from "assets/svg/FiUser.svg";
import MdDate from "assets/svg/MdDateRange.svg";
import House from "assets/svg/BsHouseDoor.svg";
import Remove from "assets/img/remove.png";
import InfoCircle from "assets/svg/InfoCircle.svg";

import CloseCircle from "assets/svg/closeCircle.svg";
import ConfirmationNumber from "assets/svg/MdOutlineConfirmationNumber.svg";
import * as selectors from "../selectors";
import { sliceKey, reducer, actions } from "../slice";
import { dashboardSaga } from "../saga";
import DataComparision from "./DataComparision";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import Hazard from "assets/svg/Warning.svg";
import Verified from "assets/svg/verifiedIcon.svg";
import VerifiedSmall from "assets/svg/VerifiedIconSm.svg";
import Locaiton from "assets/svg/GoLocation.svg";
import { fetchAvatarUrl } from "utils/commonFunctions";

const leftTextStyle = {
  color: "var(--Gray-Gray-500, #718096)",
  fontSize: "16px",
  fontWeight: "400",
  alignItems: "center",
};
const rightTextStyle = {
  color: "var(--Gray-Gray-500, #718096)",
  fontSize: "16px",
  fontWeight: "600",
};

const CustomDrawer = ({
  isOpen,
  onClose,
  inspectionId,
  selectedInspection,
  clearStates,
}) => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [retryKey, setRetryKey] = useState(0);
  const [discrepancyFound, setDiscrepancyFound] = useState(false);
  const [noDiscrepancy, setNoDiscrepancy] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [lastVerifiedDate, setLastVerifyDate] = useState(null);

  const handleTabClick = (index) => {
    setTabIndex(index);
  };
  const dispatch = useDispatch();

  const getBlockChainData = (inspectionId) => {
    dispatch(actions.fetchBlockchainData({ inspectionId }));
  };

  const handleRetryButton = () => {
    setIsVerifying(true);
    getBlockChainData(inspectionId);
    setRetryKey(retryKey + 1);
    if (retryKey > 100) {
      setRetryKey(0);
    }
  };

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: dashboardSaga });
  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);
  const workflowLoading = useSelector(selectors.workflowLoading);


  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const blockChainData = useSelector(selectors.selectBlockchainData);

  const blockChainVerificationFailed = useSelector(
    selectors.selectBlockchainVerificationFailed
  );

  const userData = useSelector(selectors.selectUserDetails);
  const avatarUrl = useSelector(selectors.selectAvatarUrl);
  const [clientUrl, setClientUrl] = useState(null);

  useEffect(() => {
    if (userData && userData.avatar) {
      let avatar = userData.avatar;
      dispatch(actions.fetchAvatarURL({ payload: avatar }));
    }
  }, [userData?.avatar]);

  useEffect(() => {
    if (selectedInspection?.clientAvatar) {
      fetchAvatarUrl(selectedInspection.clientAvatar).then((url) => {
        if (url) setClientUrl(url);
      });
    }
  }, [selectedInspection?.clientAvatar]);

  useEffect(() => {
    if (blockChainVerificationFailed) {
      setIsFailed(true);
      setIsVerifying(false);
    }
  }, [retryKey, blockChainVerificationFailed, inspectionId]);

  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  useEffect(() => {
    if (blockChainData && blockChainData?.dataObj?.data?.length) {
      if (blockChainData?.diffElements?.length > 0) {
        setDiscrepancyFound(true);
        setIsVerifying(false);
      } else if (blockChainData?.diffElements?.length === 0) {
        setNoDiscrepancy(true);
        setIsVerifying(false);
      }
      const modifiedData = blockChainData.dataObj.data.map((data) => {
        let id = data.templateItemId;
        return { [id]: data };
      });
      const modifiedData1 = blockChainData.dataObj.data.map((data) => {
        let id = data.id;
        return { [id]: data };
      });
      setData(modifiedData);
      setData1(modifiedData1);
      if (blockChainData?.latestVerificationInfo?.verificationDate) {
        setLastVerifyDate(
          blockChainData?.latestVerificationInfo?.verificationDate
        );
      }
    }
  }, [blockChainData]);
  useEffect(() => {}, []);
  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );

  const getElements = useCallback(
    (sectionId) => {
      let elementDisplayData = [];
      if (
        sectionsForBlocks &&
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        sectionsForBlocks.forEach((section) => {
          section.structure.blocks.forEach((block) => {
            blocksForComposites.forEach((blockForComposites) => {
              if (block.id === blockForComposites.id) {
                blockForComposites.structure.composites.forEach((composite) => {
                  compositesForComponents.forEach((compositeForComponents) => {
                    if (composite.id === compositeForComponents.id) {
                      compositeForComponents.structure.components.forEach(
                        (component) => {
                          componentsForElements.forEach(
                            (componentForElements) => {
                              if (componentForElements.id === component.id) {
                                componentForElements.structure.elements.forEach(
                                  (element) => {
                                    elementDisplayData.push({
                                      sectionId: section.id,
                                      element,
                                    });
                                  }
                                );
                              }
                            }
                          );
                        }
                      );
                    }
                  });
                });
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.sectionId === sectionId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [
      sectionsForBlocks,
      blocksForComposites,
      compositesForComponents,
      componentsForElements,
    ]
  );

  useEffect(() => {
    if (!isOpen) {
      setIsFailed(false);
      setIsVerifying(false);
      setDiscrepancyFound(false);
      setNoDiscrepancy(false);
      clearStates();
      setLastVerifyDate(null);
      setTabIndex(0);
      setClientUrl(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedInspection) {
      dispatch(actions.fetchUserData({ id: selectedInspection?.auditorId }));
    }
  }, [selectedInspection]);

  const formatDate = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return "N.A.";
      }
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    },
    [inspectionId]
  );

  const formatTime = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return "N.A.";
      }
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      return date
        .toLocaleTimeString("en-IN", options)
        .replace(/(am|pm)/i, (match) => match.toUpperCase());
    },
    [inspectionId]
  );

  const getReferenceNo = useCallback(
    (originCode, auditDate) => {
      if (!originCode || !auditDate) return "Not Available";
      const date = new Date(auditDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return originCode.toUpperCase() + `${day}${month}${year}`;
    },
    [inspectionId]
  );

  return (
    <Box
      id="verification-modal"
      position="fixed"
      top="0"
      right={isOpen ? "0" : "-100%"}
      width="90%"
      height="100vh"
      bg="white"
      boxShadow="2xl"
      borderLeftRadius="lg"
      p="24px"
      transition="right 0.5s ease"
      zIndex="10"
      overflow="scroll"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <VStack gap="4px" alignItems="flex-start">
          <Flex alignItems="center" gap="16px">
            {isVerifying ? (
              <CircularProgress isIndeterminate color="teal.400" size="24px" />
            ) : (
              <Box>
                <Image
                  src={
                    isFailed
                      ? CloseCircle
                      : discrepancyFound
                      ? Hazard
                      : noDiscrepancy
                      ? VerifiedSmall
                      : Group
                  }
                />
              </Box>
            )}
            <Text size="18px" fontWeight="700">
              {isFailed
                ? "Verification Failed"
                : discrepancyFound
                ? `${blockChainData?.diffElements?.length} Discrepancies found`
                : noDiscrepancy
                ? "No Discrepancy Found"
                : "Verify Inspection Data"}
            </Text>
          </Flex>
          <Flex gap="4px" alignItems="center" justifyContent="flex-start">
            <Text fontSize="14px" fontWeight="400" color="#718096">
              Data verification powered by{" "}
            </Text>
            <Box>
              <Image w="24px" h="24px" src={Group} />
            </Box>
            <Text fontSize="14px" fontWeight="500" color="#4A5568">
              Hedera
            </Text>
          </Flex>
        </VStack>

        <Flex justifyContent={"space-between"} gap="64px" align-items="center">
          {discrepancyFound ? (
            <Box>
              <Flex>
                <Text
                  color="#2D3748"
                  fontSize="16px"
                  fontWeight="500"
                  onClick={() => handleTabClick(0)}
                  px={4}
                  py={2}
                  borderBottom={
                    tabIndex === 0
                      ? "2px solid var(--Teal-Teal-400, #38B2AC)"
                      : "2px solid var(--Grey-Gray-100, #CBD5E0)"
                  }
                  cursor="pointer"
                >
                  All Data
                </Text>
                <Text
                  color="#2D3748"
                  fontSize="16px"
                  fontWeight="500"
                  onClick={() => handleTabClick(1)}
                  px={4}
                  py={2}
                  borderBottom={
                    tabIndex === 1
                      ? "2px solid var(--Teal-Teal-400, #38B2AC)"
                      : "2px solid var(--Grey-Gray-100, #CBD5E0)"
                  }
                  cursor="pointer"
                >
                  Discrepancies
                </Text>
                <Text
                  color="#2D3748"
                  fontSize="16px"
                  fontWeight="500"
                  onClick={() => handleTabClick(2)}
                  px={4}
                  py={2}
                  borderBottom={
                    tabIndex === 2
                      ? "2px solid var(--Teal-Teal-400, #38B2AC)"
                      : "2px solid var(--Grey-Gray-100, #CBD5E0)"
                  }
                  cursor="pointer"
                >
                  Verified
                </Text>
              </Flex>
            </Box>
          ) : (
            <></>
          )}

          {(discrepancyFound || noDiscrepancy) && (
            <Flex gap="16px" alignItems="center">
              <Flex>
                <Text fontSize="14px" color="#4A5568" fontWeight="600">
                  Last Verified:
                </Text>
                <Text fontSize="14px" color="#4A5568" fontWeight="400" ml={2}>
                  {`${formatDate(lastVerifiedDate)} | ${formatTime(
                    lastVerifiedDate
                  )}`}
                </Text>
              </Flex>
              <Tooltip
                hasArrow
                label={"Data can be refreshed once every 24 hours"}
                borderRadius="4px"
                w="191px"
                placement="bottom-start"
              >
                <Image src={InfoCircle} h="24px" w="24px"></Image>
              </Tooltip>
            </Flex>
          )}
          <Button
            borderRadius="8px"
            // position="absolute"
            // top="4"
            // right="4"
            onClick={onClose}
            aria-label="Close"
            padding="0px"
            w="32px"
            h="32px"
          >
            <Image src={Close} />
          </Button>
        </Flex>
      </Flex>
      <Box
        mt="36px"
        p="24px"
        border="2px solid var(--Grey-Gray-100, #EDF2F7)"
        borderRadius="8px"
      >
        <VStack alignItems="left" gap="16px">
          <HStack>
            <Text
              sx={{
                color: "var(--Gray-Gray-700, #2D3748)",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Client :
            </Text>
            <Box display={"contents"} mr={2}>
              <Avatar
                size="sm"
                src={clientUrl}
                name={selectedInspection?.clientName}
              />
            </Box>
            <Text
              sx={{
                color: "var(--Gray-Gray-700, #2D3748)",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              {selectedInspection?.clientName}
            </Text>
          </HStack>

          <Stack
            gap={{ base: "10px", md: "36px" }}
            direction={{ base: "column", md: "row" }}
            alignItems="center"
          >
            <Flex gap="6px" alignItems="center">
              <Image src={Hash} />
              <Text sx={leftTextStyle}>Reference No: </Text>
              <Text sx={rightTextStyle}>
                {getReferenceNo(
                  selectedInspection?.warehouseCode,
                  selectedInspection?.auditDate
                )}
              </Text>
            </Flex>
            <Box>
              <Image src={Seperator} />
            </Box>
            <Stack direction="row" alignItems="center" height="100%">
              <Box alignItems="center">
                <Image src={FiUser} alt="Dan Abramov" />
              </Box>
              <Text style={leftTextStyle}>Auditor:</Text>
              <Box alignItems="center">
                <Avatar w="24px" h="24px" src={avatarUrl && avatarUrl.url} />
              </Box>
              <Text
                sx={{
                  fontSize: "14px",
                  color: "var(--Grey-Gray-600, #4A5568)",
                  fontStyle: " normal",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {selectedInspection?.auditorName}
              </Text>
            </Stack>
            <Box>
              <Image src={Seperator} />
            </Box>
            <Stack direction="row" align="center" height="100%">
              <Box>
                <Image src={MdDate} alt="Dan Abramov" />
              </Box>
              <Text style={leftTextStyle}>Audit Date:</Text>

              <Text style={rightTextStyle}>
                {formatDate(selectedInspection?.auditDate)}
              </Text>
            </Stack>
          </Stack>
          <HStack flexWrap="wrap">
            <Flex gap="6px" alignItems="center" mr="32px">
              <Image src={House} />
              <Text sx={leftTextStyle}>Warehouse Name: </Text>
              <Text sx={rightTextStyle}>{selectedInspection?.originName}</Text>
            </Flex>
            <Box mr="32px">
              <Image src={Seperator} />
            </Box>
            <Flex gap="6px" alignItems="center" mr="32px">
              <Image src={ConfirmationNumber} />
              <Text sx={leftTextStyle}>Warehouse Code: </Text>
              <Text sx={rightTextStyle}>
                {selectedInspection?.warehouseCode}
              </Text>
            </Flex>
            <Box mr="32px">
              <Image src={Seperator} />
            </Box>
            <Flex gap="6px" alignItems="center" mr="32px">
              <Image src={Locaiton} />
              <Text sx={leftTextStyle}>Warehouse Address: </Text>
              <Text sx={rightTextStyle}>
                {" "}
                {selectedInspection?.originLocation}
              </Text>
            </Flex>
          </HStack>
        </VStack>
      </Box>

      {discrepancyFound ? (
        <DataComparision
          tabIndex={tabIndex}
          getElements={getElements}
          sectionsForBlocks={sectionsForBlocks}
          data={data}
          workflowLoading={workflowLoading}
          data1={data1}
          diffElements={blockChainData?.diffElements}
          inspectionId={inspectionId}
        />
      ) : (
        <Box mt="74px" p="24px" pt="0">
          <VStack gap="24px">
            <Flex alignItems="center" justifyContent="center">
              <Box>
                {isVerifying ? (
                  <CircularProgress
                    isIndeterminate
                    color="teal.400"
                    w="53.33px"
                    h="53.33"
                  />
                ) : isFailed ? (
                  <Image src={Remove} w="53.33px" h="53.33" />
                ) : noDiscrepancy ? (
                  <Image src={Verified} w="53.33px" h="53.33" />
                ) : (
                  // <Image src={Group} w="53.33px" h="53.33" />
                  <CircularProgress
                    isIndeterminate
                    color="teal.400"
                    w="53.33px"
                    h="53.33"
                  />
                )}
              </Box>
            </Flex>
            <VStack alignItems="center" justifyContent="center" gap="16px">
              <Text
                sx={{
                  color: "var(--gray-900, #171923)",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "32px",
                }}
              >
                {isVerifying
                  ? "Verifying Inspection Data"
                  : isFailed
                  ? "Oops! Something Went Wrong "
                  : noDiscrepancy
                  ? "Inspection Verified, No Discrepancies Found!"
                  : "Verifying  Inspection Data"}
              </Text>
              <Text
                sx={{
                  color: "var(--gray-600, #4A5568)",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >
                {isVerifying
                  ? "Please Wait While Your Inspection Data is Being Verified Against Hedera."
                  : isFailed
                  ? "Verification Process Failed. Please Try Again."
                  : noDiscrepancy
                  ? "Inspection data on the platform matches every detail recorded on the Hedera blockchain."
                  : "Confirm data integrity with a quick Hedera verification."}
              </Text>
            </VStack>
            <HStack>
              {isFailed ? (
                <Button
                  borderRadius="8px"
                  colorScheme="login"
                  size="md"
                  onClick={handleRetryButton}
                >
                  Retry
                </Button>
              ) : (
                <></>
              )}
            </HStack>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default CustomDrawer;
