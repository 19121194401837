import { HStack, Image, Tooltip } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import Eye from "assets/svg/eye-icon.svg";

export default function Action({ clientId }) {
  return (
    <>
      <Tooltip label="View Inspections">
        <Link
          to={`/admin/jobs?clientId=${clientId}`}
          rel="noreferrer"
          target={"_blank"}
        >
          <HStack
            w="48px"
            h="40px"
            justify="center"
            alignItems="center"
            borderRadius="12px"
            background="#EDF2F7"
            cursor="pointer"
          >
            <Image src={Eye} alt="Action" />
          </HStack>
        </Link>
      </Tooltip>
    </>
  );
}
