import React, { useRef, useContext, useState } from "react";
import { Button, Box } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { FilterIcon } from "components/Icons/Icons";
import FilterCategory from "components/FilterCategory/FilterCategory";
import { styles } from "./../../../Users/pages/myApprovals/components/filterBar/AddFilterNew.module";
import { AddFilterContext } from "views/Dashboard/context/AddFilterContext";

const AddFilter = ({
  stateDropDownForCustomer,
  cityDropDownForCustomer,
  handleApplyFilter,
  isMobile,
}) => {
  const {
    selectedItems,
    toggleCollapse,
    handleRemoveItem,
    handleSelectChange,
    handleItemClick,
    handleClear,
    isOpen,
    setIsOpen,
    handleQuery,
    query,
  } = useContext(AddFilterContext);
  const allFilterCategories = [
    {
      title: "State",
      property: "state",
      dropDownList: stateDropDownForCustomer,
    },
    {
      title: "City",
      property: "city",
      dropDownList: cityDropDownForCustomer,
    },
    {
      title: "Status",
      property: "status",
      dropDownList: [{ status: "Active" }, { status: "InActive" }],
    },
  ];
  // const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useOutsideClick({
     ref: ref,
     handler: () => {
        setIsOpen(false);
     },
  });

  // const [query, setQuery] = useState({
  //   cmName: [],
  //   auditorName: [],
  //   originLocation: [],
  // });
  // const toggleCollapse = () => {
  //   setIsOpen(!isOpen);
  // };
  return (
    <Box ref={ref} style={{ position: "relative" }}>
      <Button
        sx={styles.button}
        rightIcon={<FilterIcon width="30px" height="18px" color="#A0AEC0" />}
        variant="outline"
        onClick={toggleCollapse}
      >
        Add Filter
        {/* {(query?.cmName.length > 0 ||
          query?.auditorName.length > 0 ||
          query?.originLocation.length > 0) && (
          <Box sx={styles.greenCircle}></Box>
        )} */}
      </Button>
      <Box
        className={
          isOpen ? (isMobile ? "drop-Down-mobile" : "drop-Down") : "hide"
        }
      >
        {allFilterCategories?.map((category) => (
          <FilterCategory
            key={category.property}
            title={category.title}
            property={category.property}
            dropDownList={category.dropDownList}
            selectedItems={selectedItems}
            handleRemoveItem={handleRemoveItem}
            handleSelectChange={handleSelectChange}
            handleItemClick={handleItemClick}
            isMobile={isMobile}
          />
        ))}

        <Box sx={styles.clearApplyBtnContainer}>
          <Button sx={styles.clearApplyBtn} onClick={handleClear}>
            Clear
          </Button>
          <Button
            sx={styles.clearApplyBtn}
            onClick={handleQuery}
            // onClick={() => {
            //   handleQuery();
            //   handleApplyFilter();
            // }}
            colorScheme="login"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddFilter;
