import { createSlice } from "@reduxjs/toolkit";
import { generateUUId } from "utils/utils";

export const initialState = {
  loading: false,
  reviewDataLoading: false,
  fileUploadLoading: false,
  error: null,
  form: [],
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
  downloading: false,
  reportLoading: false,
  filesUploadLoader: {},
  filesUploadError: {},
  trackLocation: [],
  locationLoading: null,
  originLocation:{},
  auditorAvatarUrl: null,
  clientAvatarUrl : null,
  imageUrls: {},
};

const reviewInspection = createSlice({
  name: "reviewInspection",
  initialState,
  reducers: {
    // Fetch Data
    fetchFormData: (state, action) => {
      state.loading = true;
    },
    fetchFormDataSuccess: (state, action) => {
      state.loading = false;
      state.form = [JSON.parse(JSON.stringify(action.payload.form))];
      state.sections = action.payload.sections;
      state.blocks = action.payload.blocks;
      state.composites = action.payload.composites;
      state.components = action.payload.components;
    },
    fetchFormDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postWorkflowData: (state, action) => {
      state.loading = true;
    },

    postWorkflowDataSuccess: (state, action) => {
      state.loading = false;
    },

    postWorkflowDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },

    storeFileToUpload: (state, action) => {
      const files = state.files || [];
      state.files = [...files, action.payload];
    },
    fetchReviewData: (state, action) => {
      state.reviewDataLoading = true;
    },
    fetchReviewDataSuccess: (state, action) => {
      state.reviewDataLoading = false;
      state.reviewData = action.payload;
    },
    fetchReviewDataFailure: (state, action) => {
      state.reviewDataLoading = false;
    },

    fetchPreview: (state, action) => {
      state.reportLoading = true;
    },
    fetchPreviewSuccess: (state, action) => {
      state.reportLoading = false;
    },
    fetchPreviewFailure: (state, action) => {
      state.reportLoading = false;
    },
    // Init

    init: (state, action) => {
      const { loggedInUser, id } = action.payload;
      state.workflow = {
        ...state.workflow,
        id: id,
        createdBy: loggedInUser,
        title: "Workflow",
        application: "Rake Inspection",
        client: generateUUId(),
      };
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
    },
    downloadImage(state, action) {
      state.downloading = true;
    },
    downloadImageSuccess(state, action) {
      state.downloading = false;
    },
    downloadImageFailure(state, action) {
      state.downloading = false;
    },

    // Init End

    postFileData: (state, action) => {
      const { fileName } = action.payload;
      if (!state?.filesUploadLoader) {
        state.filesUploadLoader = {};
      }
      state.filesUploadLoader[fileName] = true;
    },
    postFileDataSuccess: (state, action) => {
      const { fileName, url, id } = action.payload;
      state.filesUploadLoader[fileName] = false;
      state.imageUrls[fileName] = url;
    },
    postFileDataFailure: (state, action) => {
      const { fileName } = action.payload.payload;
      state.filesUploadLoader[fileName] = false;
    },
    //auditor Location tracking
    fetchTrackLocation: (state, action) => {
      state.locationLoading = true;
    },
    fetchTrackLocationSuccess: (state, action) => {
      state.trackLocation = action.payload;
      state.locationLoading = false;
    },
    fetchTrackLocationsFailure: (state, action) => {
      state.locationLoading = false;
    },
// get origin location 
    fetchOriginLocation: (state, action) => {
      state.loading = true;
    },
    fetchOriginLocationSuccess: (state, action) => {
      state.originLocation = action.payload;
      state.loading = false;
    },
    fetchOriginLocationFailure : (state, action)=>{
      state.loading = false;
    },
  // get Auditor Avatar
    fetchAuditorAvatarURL: (state, action) => {
      state.loading = true;
    },
    fetchAuditorAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.auditorAvatarUrl = action.payload?.url;
    },
    fetchAuditorAvatarURLFailure: (state, action) => {
      state.loading = false;
    },
  // get client Avatar
  fetchClientAvatarURL: (state, action) => {
    state.loading = true;
  },
  fetchClientAvatarURLSuccess: (state, action) => {
    state.loading = false;
    state.clientAvatarUrl = action.payload?.url;
  },
  fetchClientAvatarURLFailure: (state, action) => {
    state.loading = false;
  },    
    

    clearReviewDetails: (state, action) => {
      state.reviewData = [];
      state.form = [];
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
      state.reportLoading = false;
      state.auditorAvatarUrl = null
      state.clientAvatarUrl = null;
      state.imageUrls= {};
    },
    fetchDonePreview: (state, action) => {
      state.reportLoading = true;
    },
    fetchDonePreviewSuccess: (state, action) => {
      state.reportLoading = false;
    },
    fetchDonePreviewFailure: (state, action) => {
      state.reportLoading = false;
    },
    genReport: (state, action) => {
      state.reportLoading = true;
    },
    genReportSuccess: (state, action) => {
      state.reportLoading = false;
    },
    genReportFailure: (state, action) => {
      state.reportLoading = false;
    },
    // get image Urls
    fetchImageUrls: (state, action) => { 
      state.loading = true;
    },
    fetchImageUrlsSuccess: (state, action) => {
      state.imageUrls = action.payload;
      state.loading = false;
    },
    fetchImageUrlsFailure: (state, action) => {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = reviewInspection;
