export const WarehouseHeader = [
  {
    displayText: "Warehouse Name",
    keyText: "originName",
    hasSort: true,
    hasTooltip: false,
  },
  { displayText: "Warehouse Code", keyText: "originCode", hasSort: true },
  { displayText: "Warehouse Score", keyText: "warehouseScore", hasSort: true },
];
