import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "../Create/slice";

const selectDomain = (state) => state.homePage || initialState;

export const selectWorkflows = createSelector(
  [selectDomain],
  (state) => state.workflows
);
export const selectWorkflow = createSelector(
  [selectDomain],
  (state) => state.workflow
);