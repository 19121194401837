// import * as API from "../utils/apiHelper";
import axiosBuilder from "../axios";

const headerHandler = (token) => {
  const storedLoginData = localStorage.getItem("inspectionDashboard");
  const loginData = JSON.parse(storedLoginData);
  return {
    authorization: token || loginData?.token,
    "Content-Type": "application/json",
  };
};

export const postFormData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    axiosBuilder.post("/forms/", payload, { headers });
  } else {
    const headers = headerHandler();
    axiosBuilder.post("/forms/", payload, { headers });
  }
};

export const postFormsData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    axiosBuilder.post("/forms/many", payload, { headers });
  } else {
    const headers = headerHandler();
    axiosBuilder.post("/forms/many", payload, { headers });
  }
};

export const postWorkflowFormData = (payload) => {
  const headers = headerHandler();
  axiosBuilder.post("/workflowForms/", payload, { headers });
};

export const fetchForms = () => {
  const headers = headerHandler();
  return axiosBuilder.get("/forms/", { headers });
};

export const fetchManyForms = (payload) => {
  // if (payload.token) {
  //   const { token } = payload;
  //   const headers = headerHandler(token);
  //   return axios.get("/forms/");
  // } else {
  const headers = headerHandler();
  return axiosBuilder.get(`/forms/${payload?.join(",")}/many`, {
    headers,
  });
  // }
};

export const deleteForm = (payload) => {
  const headers = headerHandler();
  return axiosBuilder.delete(
    `forms/${payload.id}?workflowId=${payload.workflowId}`,
    { headers }
  );
};

export const fetchWorkflows = () => {
  const headers = headerHandler();
  return axiosBuilder.get("/workflows", { headers });
};

export const fetchWorkflow = (payload) => {
  const headers = headerHandler();
  return axiosBuilder.get(`/workflows/${payload}`, { headers });
};

export const postWorkflow = (payload) => {
  const headers = headerHandler();
  return axiosBuilder.post("/workflows/", payload, { headers });
};

export const fetchFormData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/forms/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/forms/${payload.id}`, { headers });
  }
};

export const fetchSectionData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/section/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/section/${payload.id}`, { headers });
  }
};

export const fetchBlockData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/block/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/block/${payload.id}`, { headers });
  }
};

export const fetchCompositeData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/composite/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/composite/${payload.id}`, { headers });
  }
};

export const fetchComponentData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/component/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/component/${payload.id}`, { headers });
  }
};

export const fetchSasurl = (payload) => {
  if (payload.token) {
    const { token, fileName } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.post("/extras/pre-signed-url", {fileName}, { headers });
  } else {
    const headers = headerHandler();
    const { fileName } = payload;
    return axiosBuilder.post("/extras/pre-signed-url", {fileName}, { headers });
  }
};

export const uploadFile = (payload) => {
  let url = payload.sasURL;
  var headers = {
    "x-ms-blob-type": "BlockBlob",
    "x-ms-date": new Date().toUTCString(),
  };
  var response = fetch(url, {
    method: "PUT",
    headers,
    body: payload.file,
    muteHttpExceptions: true,
  });
  return response;
};

export const postData = (payload) => {
  const { inspectionId, jobId, taskId } = payload;
  let headers = payload.token ? headerHandler(payload.token) : headerHandler();

  return axiosBuilder.post(
    `/inspections/${inspectionId}/jobs/${jobId}/tasks/${taskId}/items/`,
    payload,
    { headers }
  );
};

export const getData = (payload) => {
  const { inspectionId, jobId, taskId } = payload;
  let headers = payload.token ? headerHandler(payload.token) : headerHandler();

  return axiosBuilder.get(
    `/inspections/${inspectionId}/jobs/${jobId}/tasks/${taskId}/items/`,
    { headers }
  );
};

export const getSingleTask = (payload) => {
  const { inspectionId, jobId, taskId } = payload;
  let headers = payload.token ? headerHandler(payload.token) : headerHandler();
  return axiosBuilder.get(
    `/inspections/${inspectionId}/jobs/${jobId}/tasks/${taskId}`,
    { headers }
  );
};
