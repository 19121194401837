import {
  Box,
  HStack,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { analyticsDashboardSaga } from "./saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import {
  masterConfig as masterConfigClient,
  formatConfig,
} from "../../../masterConfig";
import _ from "lodash";
import { format } from "date-fns";
import { getDateDifferenceInfo, getFromDate } from "utils/commonFunctions";
import { v4 as uuidv4 } from "uuid";
import Filters from "./components/Filters";
import MeasuredCommodities from "./components/MeasuredCommodities";
import CommodityHealth from "./components/CommodityHealth/CommodityHealth";
import WarehouseScoreComp from "./components/WarehouseScoreComp/WarehouseScoreComp";
import InspectionCount from "./components/InspectionCount";
import GeographicalReach from "./components/GeographicalReach";
import DataWidget from "./components/DataWidget";
import SectionHeader from "./components/SectionHeader";
import dataAdapter from "./dataAdaptor";
import TableSimple from "./components/TableSimple";
import BarChart from "./components/BarChart";
import { ErrorBoundary } from "react-error-boundary";
import { useUserContext } from "../../../context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import ErrorFallback from "errorsFallback/ErrorFallback";
import DataCard from "./components/DataCard";
import EntityTable from "./components/EntityTable";

function AnalyticsDashboard({ isForClient }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: analyticsDashboardSaga });

  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get("clientId");

  //useStates
  const [filterQuery, setFilterQuery] = useState(null);
  const [isMultipleFilters, setIsMultipleFilters] = useState(false);

  const [selectedDateType, setSelectedDateType] = useState("week");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState(selectedWarehouse || "");
  const [disabledDateTypes, setDisabledDateTypes] = useState([]);
  const [errorToast, setErrorToast] = useState(true);
  const [barchartDateType, setBarchartDateType] = useState("week");

  //Below 2 states are for bar chart dropdown
  const [selectedDataType, setSelectedDataType] = useState("inspectionsCount");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(
    "Inspection Count"
  );

  const masterConfig = useSelector(selectors.masterConfig);

  const [tab, setTab] = useState(null);

  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const entityId = entity?.ownerEntityId;
  const entityType = entity?.ownerEntityType;

  const handleTabChange = (val) => {
    setTab(val);
  };

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isTablet] = useMediaQuery(
    "(min-width: 769px) and (max-width: 1080px)"
  );

  const layout = isMobile
    ? "mobileLayout"
    : isTablet
    ? "tabletLayout"
    : "webLayout";

  const { config, tabs } =
    isForClient || entityType === "client"
      ? formatConfig(_.cloneDeep(masterConfigClient), layout)
      : masterConfig
      ? formatConfig(_.cloneDeep(masterConfig), layout)
      : { config: null, tabs: null };

  function containsExactWord(str, word) {
    const regex = new RegExp(`\\b${word}(?:_|\\b)`, "i");
    return regex.test(str);
  }
  const generateDateType = () => {
    const keyPrefix = isMobile ? "mobileDateRangePicker" : "dateRangePicker";
    const { fromDate, toDate } = isMultipleFilters
      ? filterQuery[`${keyPrefix}_${_.camelCase(tab)}`]
      : filterQuery[`${keyPrefix}`];
    return getDateDifferenceInfo(fromDate, toDate);
  };

  const handleFilterQuery = (value, filterType, key) => {
    setFilterQuery((prevStates) => {
      if (containsExactWord(filterType, "client")) {
        const client = dataAdapter(state, "clientName")?.find(
          (client) => client.id === value
        );
        return {
          ...prevStates,
          [filterType]: { id: value, value: client ? client.value : "" },
        };
      } else if (containsExactWord(filterType, "auditor")) {
        const auditor = dataAdapter(state, "auditor")?.find(
          (auditor) => auditor.id === value
        );
        return {
          ...prevStates,
          [filterType]: { id: value, value: auditor ? auditor.value : "" },
        };
      } else if (filterType === "inititalState") {
        setFilterQuery(value);
      } else if (key) {
        //Checks Key  for nested filters
        if (key === "toDate") {
          //based on fromDate and todate  generating disabled date types for bar chart
          const { fromDate, toDate } = {
            ...prevStates[filterType],
            [key]: value,
          };
          const dateUnit = getDateDifferenceInfo(fromDate, toDate);
          setDisabledDateTypes(dateUnit?.disabled);
          setSelectedDateType(dateUnit?.dateType);
          setBarchartDateType(dateUnit?.dateType);
        }
        return {
          ...prevStates,
          [filterType]: {
            ...prevStates[filterType],
            [key]: value,
          },
        };
      } else {
        return {
          ...prevStates,
          [filterType]: value,
        };
      }
    });
  };

  const handleDataTypeChange = (event) => {
    const newValue = event.target.value;
    const selectedIndex = event.target.selectedIndex;
    const selectedOptionLabel = event.target.options[selectedIndex].label;

    setSelectedOptionLabel(selectedOptionLabel);
    setSelectedDataType(newValue);
  };

  const handleDateType = (type) => {
    let forQueryParams = {};
    if (filterQuery !== null) {
      forQueryParams = generateQueryParams();
    }
    if (entityType === "customer" && !isForClient) {
      dispatch(
        actions.fetchAgencyBarChartData({
          ...forQueryParams,
          unit: type,
        })
      );
    } else {
      dispatch(
        actions.fetchBarChartData({
          payload: {
            ...forQueryParams,
            unit: type,
          },
        })
      );
    }
    setBarchartDateType(type);
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleQuery = (val) => {
    setSearchQuery(val);
  };

  const handleSelect = (value) => {
    setInputValue(value);
    handleQuery(value);
  };

  const handleWareHouseChange = (warehouseID) => {
    setSelectedWarehouse(warehouseID);
  };

  const handleInputVal = (value) => {
    setInputValue(value);
  };
  const dispatch = useDispatch();

  //useSelectors
  const state = useSelector(selectors.state);

  // let warehouseInsightsData =
  //   useSelector(selectors.selectWarehouseInsightsData) || [];
  let warehouses = useSelector(selectors.selectWarehouseData) || [];

  //This function generates query params for api call.
  const generateQueryParams = () => {
    let query;
    const key = isMobile ? "mobileDateRangePicker" : "dateRangePicker";
    const { fromDate, toDate } = isMultipleFilters
      ? filterQuery[`${key}_${_.camelCase(tab)}`]
      : filterQuery[`${key}`];
    query = {
      // warehouseId: selectedWarehouse,
      ...(fromDate &&
        toDate && {
          start_dt:
            entityType === "client" || isForClient
              ? format(fromDate, "yyyy-MM-dd")
              : fromDate.toISOString(),
          end_dt:
            entityType === "client" || isForClient
              ? format(toDate, "yyyy-MM-dd")
              : toDate.toISOString(),
        }),
      location: isMultipleFilters
        ? filterQuery[`location_${_.camelCase(tab)}`]
        : filterQuery["location"],
      commodities: isMultipleFilters
        ? filterQuery[`commodity_${_.camelCase(tab)}`]
        : filterQuery["commodity"],
      clientIds: isMultipleFilters
        ? filterQuery[`client_${_.camelCase(tab)}`]?.id
        : filterQuery["client"]?.id,
      auditors: isMultipleFilters
        ? filterQuery[`auditor_${_.camelCase(tab)}`]?.id
        : filterQuery["auditor"]?.id,
      inspection_types: isMultipleFilters
        ? filterQuery[`inspectionType_${_.camelCase(tab)}`]
        : filterQuery["inspectionType"],
    };
    return query;
  };

  useEffect(() => {
    dispatch(actions.fetchUserContext("web")); //To fetch configuration
    if (entityId && entityType === "customer" && !isForClient) {
      dispatch(
        actions.fetchClientsOfCustomer({
          customerId: entityId,
        })
      );
      dispatch(
        actions.fetchCustomerUsersByRole({
          customerId: entityId,
          roleName: "auditor",
        })
      );
    }
    return () => {
      setFilterQuery(null);
    };
  }, [entityId]);

  const getWidget = useCallback((widgetId, dataSource) => {
    const widgetType = widgetId.split("_")[0];
    const widgetMap = {
      filters: (
        <Filters
          toggleDrawer={toggleDrawer}
          clientNames={dataAdapter(state, "clientName")}
          inspectionTypes={dataAdapter(state, "inspectionTypes")}
          auditorNames={dataAdapter(state, "auditor")}
          filterQuery={filterQuery}
          commodityNames={dataAdapter(state, "commodity")}
          locationNames={dataAdapter(state, "location")}
          handleFilterQuery={handleFilterQuery}
          isDrawerOpen={isDrawerOpen}
          warehouses={warehouses}
          inputValue={inputValue}
          handleQuery={handleQuery}
          handleSelect={handleSelect}
          handleWareHouseChange={handleWareHouseChange}
          handleInputVal={handleInputVal}
          searchQuery={searchQuery}
          key={widgetId}
          config={config}
          tab={tab}
          setFilterQuery={setFilterQuery}
          isMultipleFilters={isMultipleFilters}
          isMobile={isMobile}
        />
      ),
      barChart: (
        <BarChart data={dataAdapter(state, widgetType, _, _, dataSource)} />
      ),
      tableSimple: (
        <TableSimple data={dataAdapter(state, widgetType, _, _, dataSource)} />
      ),
      numberOfInspectionsNCCF1: (
        <DataWidget
          title="Number Of Inspections"
          data={dataAdapter(state, "numberOfInspections1")}
          key={widgetId}
        />
      ),
      quantityInspectedNCCF1: (
        <DataWidget
          title="Quantity Inspected (MT)"
          data={dataAdapter(state, "quantityInspected1")}
          key={widgetId}
        />
      ),
      quantityAcceptedNCCF1: (
        <DataWidget
          title="Quantity Accepted (MT)"
          data={dataAdapter(state, "quantityAccepted1")}
          key={widgetId}
        />
      ),
      quantityUnacceptedNCCF1: (
        <DataWidget
          title="Quantity Unaccepted (MT)"
          data={dataAdapter(state, "quantityUnaccepted1")}
          key={widgetId}
        />
      ),
      trucksInspectedNCCF1: (
        <DataWidget
          title="Trucks Inspected"
          data={dataAdapter(state, "trucksInspected1")}
          key={widgetId}
        />
      ),
      trucksAcceptedNCCF1: (
        <DataWidget
          title="Trucks Accepted"
          data={dataAdapter(state, "trucksAccepted1")}
          key={widgetId}
        />
      ),
      trucksUnacceptedNCCF1: (
        <DataWidget
          title="Trucks Unaccepted"
          data={dataAdapter(state, "trucksUnaccepted1")}
          key={widgetId}
        />
      ),
      quantityInspectedNCCF2: (
        <DataWidget
          title="Quantity Inspected (MT)"
          data={dataAdapter(state, "quantityInspected2")}
          key={widgetId}
        />
      ),
      quantityAcceptedNCCF2: (
        <DataWidget
          title="Quantity Accepted (MT)"
          data={dataAdapter(state, "quantityAccepted2")}
          key={widgetId}
        />
      ),
      quantityUnacceptedNCCF2: (
        <DataWidget
          title="Quantity Unaccepted (MT)"
          data={dataAdapter(state, "quantityUnaccepted2")}
          key={widgetId}
        />
      ),
      samplingNotDoneNCCF1: (
        <DataWidget
          title="Sampling Not Done"
          data={dataAdapter(state, "samplingNotDone1")}
          key={widgetId}
        />
      ),
      bagsInspectedNCCF1: (
        <DataWidget
          title="Bags Inspected"
          data={dataAdapter(state, "bagsInspected1")}
          key={widgetId}
        />
      ),
      trucksInspectedNCCF2: (
        <DataWidget
          title="Trucks Inspected"
          data={dataAdapter(state, "trucksInspected2")}
          key={widgetId}
        />
      ),
      trucksAcceptedNCCF2: (
        <DataWidget
          title="Trucks Accepted"
          data={dataAdapter(state, "trucksAccepted2")}
          key={widgetId}
        />
      ),
      trucksUnacceptedNCCF2: (
        <DataWidget
          title="Trucks Unaccepted"
          data={dataAdapter(state, "trucksUnaccepted2")}
          key={widgetId}
        />
      ),
      quantityInspectedNCCF3: (
        <DataWidget
          title="Quantity Inspected (MT)"
          data={dataAdapter(state, "quantityInspected3")}
          key={widgetId}
        />
      ),
      quantityAcceptedNCCF3: (
        <DataWidget
          title="Quantity Accepted (MT)"
          data={dataAdapter(state, "quantityAccepted3")}
          key={widgetId}
        />
      ),
      quantityUnacceptedNCCF3: (
        <DataWidget
          title="Quantity Unaccepted (MT)"
          data={dataAdapter(state, "quantityUnaccepted3")}
          key={widgetId}
        />
      ),
      samplingNotDoneNCCF2: (
        <DataWidget
          title="Sampling Not Done"
          data={dataAdapter(state, "samplingNotDone2")}
          key={widgetId}
        />
      ),
      bagsInspectedNCCF2: (
        <DataWidget
          title="Bags Inspected"
          data={dataAdapter(state, "bagsInspected2")}
          key={widgetId}
        />
      ),
      header: <SectionHeader data={dataSource} />,
      totalInspections: (
        <DataCard
          data={dataAdapter(state, "totalInspections", _, selectedDateType)}
          key={widgetId}
        />
      ),
      quantityInspected: (
        <DataCard
          data={dataAdapter(state, "quantityInspected", _, selectedDateType)}
          key={widgetId}
        />
      ),
      numberOfBags: (
        <DataCard
          data={dataAdapter(state, "numberOfBags", _, selectedDateType)}
          key={widgetId}
        />
      ),
      commodities: (
        <DataCard
          data={dataAdapter(state, "commodities", _, selectedDateType)}
          key={widgetId}
        />
      ),
      sitesInspected: (
        <DataCard
          data={dataAdapter(state, "sitesInspected", _, selectedDateType)}
          key={widgetId}
        />
      ),
      inspectionCount: (
        <InspectionCount
          selectedOption={selectedDataType}
          selectedOptionLabel={selectedOptionLabel}
          selectedDateType={barchartDateType}
          handleDateType={handleDateType}
          disabledDateTypes={disabledDateTypes}
          handleDataTypeChange={handleDataTypeChange}
          inspectionCountData={dataAdapter(
            state,
            "inspectionCount",
            selectedDataType
          )}
          key={widgetId}
          isDropdownVisible={true}
          isFor="client"
          selectedDataType={selectedDataType}
        />
      ),
      commodityHealth: (
        <CommodityHealth
          data={dataAdapter(state, "commodityHealth")}
          key={widgetId}
        />
      ),
      measuredCommodities: (
        <MeasuredCommodities
          data={dataAdapter(state, "measuredCommodities")}
          key={widgetId}
        />
      ),
      warehouseScore: (
        <WarehouseScoreComp
          score={dataAdapter(state, "warehouseScore")}
          key={widgetId}
          handleDateType={handleDateType}
        />
      ),
      geographicalReach: (
        <GeographicalReach
          key={widgetId}
          data={dataAdapter(state, dataSource)}
          selectedDataKey={"quantity"}
          isMobile={isMobile}
        />
      ),
      dataCard: (
        <DataCard
          data={dataAdapter(state, dataSource, _, selectedDateType)}
          key={widgetId}
        />
      ),
      barchartCount: (
        <InspectionCount
          selectedDateType={barchartDateType}
          handleDateType={handleDateType}
          disabledDateTypes={disabledDateTypes}
          inspectionCountData={dataAdapter(state, dataSource)}
          key={widgetId}
          isFor="agency"
        />
      ),
      geographicalReachWithFilter: (
        <GeographicalReach
          data={dataAdapter(state, dataSource)}
          key={widgetId}
          showDropdown={true}
          selectedDataKey={"inspectionDoneCount"}
          isMobile={isMobile}
        />
      ),
      entityTable: (
        <EntityTable
          data={dataAdapter(state, dataSource, _, _, dataSource, entityId)}
          key={widgetId}
        />
      ),
    };
    return (
      <ErrorBoundary
        fallback={
          <ErrorFallback
            errorToast={errorToast}
            setErrorToast={setErrorToast}
            message={`Failed to Load ${widgetType}`}
          />
        }
      >
        {" "}
        {widgetMap[widgetType]}
      </ErrorBoundary>
    );
  });

  const apiDependencyArr = () => {
    let arr = [];
    if (filterQuery) {
      arr = Object.keys(filterQuery).reduce((acc, key) => {
        if (
          key.includes("dateRangePicker") ||
          key.includes("mobileDateRangePicker")
        ) {
          acc.push(`${filterQuery[key].fromDate}`);
          acc.push(`${filterQuery[key].toDate}`);
        } else if (containsExactWord(key, "client")) {
          acc.push(`${filterQuery[key].id}`);
        } else if (containsExactWord(key, "auditor")) {
          acc.push(`${filterQuery[key].id}`);
        } else {
          acc.push(`${filterQuery[key]}`);
        }
        return acc;
      }, []);
    }
    return arr;
  };

  useEffect(
    () => {
      // Prepare query parameters to get filtered data from API
      let forQueryParams = {};
      if (filterQuery !== null) {
        forQueryParams = generateQueryParams();
      }
      const dateType = filterQuery ? generateDateType() : null;
      if (entityType === "client" || isForClient) {
        dispatch(actions.fetchAnalyticsData({ payload: forQueryParams }));
        dispatch(
          actions.fetchDoughnutChartData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchQuantityInsightsData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchWarehouseInsightsData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchBarChartData({
            payload: {
              ...forQueryParams,
              unit: dateType?.dateType,
            },
          })
        );
        dispatch(
          actions.fetchPortfolioLineChartData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchInspectionsLineChartData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchQuantityLineChartData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchCommoditiesData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchMapChartData({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchWarehouses({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
        dispatch(
          actions.fetchWarehouseScore({
            payload: isForClient
              ? { ...forQueryParams, clientIds: clientId }
              : forQueryParams,
          })
        );
      }
      //v2 endpoints below
      else if (entityType === "customer" && !isForClient) {
        if (tab === "Overview" || !tab) {
          dispatch(
            actions.fetchInspectionsCompletedData({
              ...forQueryParams,
              unit: dateType?.dateType,
            })
          );
          dispatch(
            actions.fetchSitesInspectedData({
              ...forQueryParams,
              unit: dateType?.dateType,
            })
          );
          dispatch(
            actions.fetchClientsInspectedData({
              ...forQueryParams,
              unit: dateType?.dateType,
            })
          );
          dispatch(
            actions.fetchApprovedInspectionCount({
              ...forQueryParams,
              status: "approved",
            })
          );
          dispatch(
            actions.fetchAgencyGeographicalReachData({
              ...forQueryParams,
            })
          );
          dispatch(
            actions.fetchAgencyAnalyticsData({
              ...forQueryParams,
            })
          );

          dispatch(
            actions.fetchAgencyBarChartData({
              ...forQueryParams,
              unit: dateType?.dateType,
            })
          );
        }
        if (tab === "Client Statistics") {
          dispatch(
            actions.fetchClientStatisticsData({
              ...forQueryParams,
              onSuccess: (clientStatisticsData) => {
                if (clientStatisticsData?.length) {
                  const logoNames = clientStatisticsData.map(
                    (client) => client.clientLogo
                  );
                  // dispatch(actions.fetchEntityProfileImgURL(logoNames));
                }
              },
            })
          );
        }
      }
    },
    filterQuery
      ? isMultipleFilters
        ? [...apiDependencyArr(), selectedWarehouse]
        : [selectedWarehouse, ...apiDependencyArr()]
      : []
  );

  useEffect(() => {
    //handling for client Statistics tab on initial load.
    if (
      entityType === "customer" &&
      (tab === "Overview" || !tab) &&
      !isForClient
    ) {
      dispatch(
        actions.fetchClientStatisticsData({
          onSuccess: (clientStatisticsData) => {
            if (clientStatisticsData?.length) {
              const logoNames = clientStatisticsData.map(
                (client) => client.clientLogo
              );
              dispatch(actions.fetchEntityProfileImgURL(logoNames));
            }
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (tabs && tabs.length && !tab) {
      setTab(tabs[0].value);
    }
  }, [tabs]);

  // This function generates filter object to save filter selections dynamically using the configuration

  const createCustomFiltersObject = (filtersArray) => {
    const filterStateHelper = (filter) =>
      filter["dataSource"] === "dateRangePicker" ||
      filter["dataSource"] === "mobileDateRangePicker"
        ? {
            fromDate: null,
            toDate: null,
          }
        : filter["dataSource"] === "client" ||
          filter["dataSource"] === "auditor"
        ? {
            id: null,
            value: null,
          }
        : null;

    const result = {};
    if (filtersArray.length > 1) {
      setIsMultipleFilters(true);
      filtersArray.forEach((tab) => {
        if (tab.tabName) {
          tab.filters.forEach((filter) => {
            const key = `${filter["dataSource"]}_${_.camelCase(tab.tabName)}`;
            result[key] = filterStateHelper(filter);
          });
          if (
            tab.hasOwnProperty("secondaryFilters") &&
            tab.secondaryFilters.length
          ) {
            tab.secondaryFilters.forEach((filter) => {
              const key = `${filter["dataSource"]}_${_.camelCase(tab.tabName)}`;
              result[key] = filterStateHelper(filter);
            });
          }
        }
      });
    } else {
      setIsMultipleFilters(false);
      filtersArray[0].filters.forEach((filter) => {
        const key = `${filter["dataSource"]}`;
        result[key] = filterStateHelper(filter);
      });
      if (
        filtersArray[0].hasOwnProperty("secondaryFilters") &&
        filtersArray[0].secondaryFilters.length
      ) {
        filtersArray[0].secondaryFilters.forEach((filter) => {
          const key = `${filter["dataSource"]}`;
          result[key] = filterStateHelper(filter);
        });
      }
    }
    return result;
  };

  useEffect(() => {
    let initialStates = {};
    if (
      config &&
      config?.centralArea &&
      config?.centralArea?.filtersObj?.length &&
      !filterQuery
    ) {
      initialStates = createCustomFiltersObject(
        config?.centralArea?.filtersObj
      );
      setFilterQuery((prevStates) => {
        return { ...initialStates, ...prevStates };
      });
    }
  }, [config]);

  useEffect(() => {
    if (filterQuery) {
      setFilterQuery(null);
    }
  }, [isMobile, isTablet]);

  return (
    <Box
      sx={{
        marginTop: isForClient ? "0px" : "75px",
      }}
    >
      <Tabs w="100%" position="relative" colorScheme="teal">
        {isForClient || entityType === "client" ? (
          <></>
        ) : (
          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            align="flex-start"
            flexDirection={{ base: "column", md: "row" }}
            sx={{
              marginBottom:
                isForClient || entityType === "client"
                  ? "50px"
                  : isMobile
                  ? "32px"
                  : "36px",
            }}
            gap={{ base: "24px", md: "inherit" }}
          >
            <Text
              sx={{
                color: "#2D3748",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "140%",
              }}
            >
              {tab}
            </Text>

            <TabList w={{ base: "100%", md: "auto" }}>
              <HStack w={"100%"}>
                {tabs &&
                  tabs &&
                  tabs.map((tab) => (
                    <Tab
                      onClick={() => handleTabChange(tab.value)}
                      key={tab.id}
                      w={{ base: "100%", md: "auto" }}
                    >
                      {tab.value}
                    </Tab>
                  ))}
              </HStack>
            </TabList>
          </Box>
        )}
        {filterQuery ? getWidget("filters") : <></>}
        <TabPanels>
          {config &&
            config.centralArea.dashboard.map((tab) => {
              return (
                <TabPanel padding="0px" key={uuidv4()}>
                  <VStack gap={{ base: "32px", md: "36px" }}>
                    {tab.rows.map((row) => {
                      if (row.rowOrder === 1) {
                        return null;
                      }
                      const widgets = row.widgets;
                      return (
                        <HStack
                          justify="space-between"
                          w="100%"
                          gap={{ base: "16px", md: "24px" }}
                          key={row.rowOrder}
                        >
                          {widgets.map((widget) =>
                            getWidget(
                              widget.id.split("_")[0],
                              widget.dataSource
                            )
                          )}
                        </HStack>
                      );
                    })}
                  </VStack>
                </TabPanel>
              );
            })}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default AnalyticsDashboard;
