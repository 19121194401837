import * as api from "services/apis";
import { actions } from "./slice";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { isArray } from "lodash";

export function* handleFetchAllUsersForCustomer({ payload }) {

  try {
    const res = yield call(api.getAllUsers, payload);
    yield put(
      actions.fetchAllUsersforCustomerSuccess({ response: res.data })
    );
    payload.onSuccess();
  } catch (err) {
    yield put(actions.fetchAllUsersforCustomerFailure(err));
    payload.onFailure();
  }
}

function* handleFetchProfileImgURLArr(action) {
  try {
    const response = yield call(api.getUserProfileImg, action.payload);
    yield put(actions.fetchProfileImgURLArrSuccess(response));
  } catch (e) {
    yield put(actions.fetchProfileImgURLArrFailure(e));
  }
}

function* handleFetchUserRoles(action) {
  try {
    const response = yield call(api.getCustomerUserRoles, action.payload);
    const { data } = response;
    yield put(actions.fetchAllCustomerUserRolesSuccess(data?.payload));
  } catch (error) {
    yield put(actions.fetchAllCustomerUserRolesFailure(error));
  }
}

function* handleCreateUser(action) {
  try {
    const response = yield call(api.createUser, action.payload);
    action.payload.onSuccess(response.data.payload.userId);

    yield put(actions.fetchCreateUserSuccess());
  } catch (error) {
    console.log(error);
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.fetchCreateUserFailure(error));
  }
}

function* handleUpdateUser(action) {
  try {
    const response = yield call(api.updateUser, action.payload);
    action.payload.onSuccess();
    yield put(actions.fetchUpdateUserSuccess());
  } catch (error) {
    console.log(error);
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.fetchUpdateUserFailure(error));
  }
}

function* handleCreateAddress(action) {
  try {
    const response = yield call(api.createAddress, action.payload);
    action.payload.onSuccess();
    yield put(actions.fetchCreateAddressSuccess());
  } catch (error) {
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.fetchCreateAddressFailure(error));
  }
}

function* handleUpdateAddress(action) {
  try {
    const response = yield call(api.updateAddress, action.payload);
    yield put(actions.fetchUpdateAddressSuccess());
    action.payload.onSuccess();
  } catch (error) {
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.fetchUpdateAddressFailure(error));
  }
}

function* handleGetUserData(action) {
  try {
    const response = yield call(api.getUserData, action.payload);
    const { data } = response;
    yield put(actions.fetchUserDataSuccess(data.payload));
  } catch (error) {
    yield put(actions.fetchUserDataFailure(error));
  }
}

function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(api.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}

function* handleFetchAllUsersForClient({ payload }) {
    try {
    const res = yield call(api.getAllClientUsers, payload);
    yield put(
      actions.fetchAllUsersforClientSuccess({ response: res.data })
    );
    payload.onSuccess();
  } catch (err) {
    yield put(actions.fetchAllUsersforClientFailure(err));
  }
  payload.onFailure();
}
function* handleFetchProfileImgURLArrForClientUser(action) {
  try {
    const response = yield call(api.getUserProfileImg, action.payload);
    yield put(actions.fetchProfileImgURLArrForClientUserSuccess(response));
  } catch (e) {
    yield put(actions.fetchProfileImgURLArrForClientUserFailure(e));
  }
}

export function* handlefetchClientsOfCustomer(action) {
  try {
    const response = yield call(api.getCustomerClientDetails, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsOfCustomerSuccess(data?.payload));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchClientsOfCustomerFaliure(e));
  }
}

export function* handleFetchClientUserRoles(action) {
  try {
    const response = yield call(api.getClientUserRoles, action.payload);
    const { data } = response;
    // console.log("sagafroRole", data);
    yield put(actions.fetchClientUserRolesSuccess(data?.payload));
  } catch (error) {
    yield put(actions.fetchClientUserRolesFailure(error));
  }
}

function* handleCreateUserForClient(action) {
  try {
    const response = yield call(api.createClientUser, action.payload);
    console.log("afterCreateUserForClientSaga", response);
    action.payload.onSuccess(response.data.payload.userId);
    yield put(actions.createUserForClientSuccess());
  } catch (error) {
    // console.log(error);
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.createUserForClientFailure(error));
  }
}

function* handleCreateAddressForClientUser(action) {
  try {
    const response = yield call(api.createAddressForClientUser, action.payload);
    action.payload.onSuccess();
    yield put(actions.createAddressForClientUserSuccess());
  } catch (error) {
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.createAddressForClientUserFailure(error));
  }
}
function* handleUpdateClientUserData(action) {
  try {
    const response = yield call(api.updateClientUserData, action.payload);
    action.payload.onSuccess();
    yield put(actions.updateClientUserDataSuccess());
  } catch (error) {
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.updateClientUserDataFailure(error));
  }
}
function* handleUpdateAddressForClientUser(action) {
  try {
    const response = yield call(api.updateClientUserAddress, action.payload);
    yield put(actions.updateAddressForClientUserSuccess());
    action.payload.onSuccess();
  } catch (error) {
    action.payload.onFailure(error.response.data.reason);
    yield put(actions.updateAddressForClientUserFailure(error));
  }
}
function* handlefetchClientUserData(action) {
  try {
    const response = yield call(api.getClientUserData, action.payload);
    const { data } = response;
    yield put(actions.fetchClientUserDataSuccess(data.payload));
  } catch (error) {
    yield put(actions.fetchClientUserDataFailure(error));
  }
}

function* handlefetchProfileImgURLArrOfClient(action) {
  try {
    const response = yield call(api.getUserProfileImg, action.payload);
    yield put(actions.fetchProfileImgURLArrOfClientSuccess(response));
  } catch (e) {
    yield put(actions.fetchProfileImgURLArrOfClientFailure(e));
  }
}

function* handleFetchClearStates({ payload }) {
  // console.log(payload);
  yield put(actions.clearStateSuccess());
}
function* handleFetchClearAllStates({ payload }) {
  yield put(actions.clearAllStateSuccess());
}

function* handleFetchLocationData(action) {
  try {
    const response = yield call(api.getLocationData, action.payload);
    if(isArray(response.data.payload)){
      console.log("sagaaa")
      action.payload.onSuccess() 
      // return
    }
    else{
        yield put(actions.fetchLocationDataSuccess({response : response.data.payload, id : action.payload.id}));
    }
  
  } catch (error) {
    console.log(error);
    yield put(actions.fetchLocationDataFailure(error));
  }
}

function* handleChangeUserStatus(action) {
  try {
    const response = yield call(api.setUserStatus, action.payload);
    yield put(actions.ChangeUserStatusSuccess());
    action.payload.onSuccess();
  } catch (error) {
    yield put(actions.ChangeUserStatusFailure());
  }
}

export function* handleDeleteUser(action) {
  try {
    const response = yield call(api.deleteUser, action.payload);
    const { data } = response;
    yield put(actions.deleteUserSuccess(data?.payload));
    action.payload.onSuccess(data.success);
  } catch (e) {
    console.log(e);
    yield put(actions.deleteUserFailure(e));
    action.payload.onSuccess(e.response.data.success);
  }
}

export function* handleDeleteUserAddress({ payload }) {
  try {
    const res = yield call(api.deleteUserAddress, payload);
    payload.onSuccess(res.data.success);
    // const { data } = res;
    yield put(actions.deleteUserAddressSuccess());
  } catch (err) {
    payload.onFailure();
    yield put(actions.deleteUserAddressFailure(err));
  }
}
function* handleGetUserByRole(action) {
  try {
    const response = yield call(api.getCustomerUsers, action.payload);
    const { data } = response;
    yield put(actions.getUserByRoleSuccess(data?.payload));
  } catch (error) {
    console.log(error);
    yield put(actions.getUserByRoleFailure(error));
  }
}
// getUserProfileImg promise for all
// getPreDefinedURL single
export function* UserSaga() {
  yield takeLatest(
    actions.fetchAllUsersforCustomer.type,
    handleFetchAllUsersForCustomer
  );
  yield takeLatest(
    actions.fetchProfileImgURLArr.type,
    handleFetchProfileImgURLArr
  );
  yield takeLatest(
    actions.fetchAllCustomerUserRoles.type,
    handleFetchUserRoles
  );

  yield takeLatest(actions.fetchCreateUserRequest.type, handleCreateUser);
  yield takeLatest(actions.fetchCreateAddressRequest.type, handleCreateAddress);
  yield takeLatest(actions.fetchUserDataRequest.type, handleGetUserData);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeEvery(actions.fetchUpdateUserRequest.type, handleUpdateUser);
  yield takeEvery(actions.fetchUpdateAddressRequest.type, handleUpdateAddress);
  yield takeEvery(
    actions.fetchAllUsersforClient.type,
    handleFetchAllUsersForClient
  );

  yield takeLatest(
    actions.fetchProfileImgURLArrForClientUser.type,
    handleFetchProfileImgURLArrForClientUser
  );

  yield takeLatest(
    actions.fetchProfileImgURLArrOfClient.type,
    handlefetchProfileImgURLArrOfClient
  );

  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handlefetchClientsOfCustomer
  );

  yield takeLatest(
    actions.fetchClientUserRoles.type,
    handleFetchClientUserRoles
  );
  yield takeLatest(actions.createUserForClient.type, handleCreateUserForClient);
  yield takeLatest(
    actions.createAddressForClientUser.type,
    handleCreateAddressForClientUser
  );
  yield takeLatest(
    actions.updateClientUserData.type,
    handleUpdateClientUserData
  );
  yield takeLatest(
    actions.updateAddressForClientUser.type,
    handleUpdateAddressForClientUser
  );
  yield takeLatest(actions.fetchClientUserData.type, handlefetchClientUserData);
  yield takeLatest(actions.clearStates.type, handleFetchClearStates);
  yield takeLatest(actions.clearAllStates.type, handleFetchClearAllStates);

  yield takeEvery(actions.fetchLocationData.type, handleFetchLocationData);

  yield takeLatest(actions.ChangeUserStatus.type, handleChangeUserStatus);
  yield takeLatest(actions.deleteUser, handleDeleteUser);
  yield takeLatest(actions.deleteUserAddress, handleDeleteUserAddress);
  yield takeLatest(actions.getUserByRole, handleGetUserByRole);
}

