import {
  Badge,
  Box,
  CircularProgress,
  Flex,
  Image,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchSasurl } from "services/index";
import ComponentRepeatHedera from "./HederaComponents/ComponentRepeatHedera";
import { flattenStringifiedArray } from "utils/commonFunctions";
import { convertArrayToObject, transformObjectKeys } from "./HederaComponents/HederaHelperFunctions";

const GHOST_IMAGE = "ghost-imagepng_perfeqt_cwmiwjlnt5tezvsv.png";

const DataComparision = ({ getElements, getComponents, sectionsForBlocks, data, diffElements, tabIndex, inspectionId, data1, workflowLoading, getElements1 }) => {
 const [blobNames, setBlobNames] = useState({});
 const [diffElementMap, setDiffElementMap] = useState([]);
 const [diffElementMap1, setdiffElementMap1] = useState([]);

 const fetchBlobName = async (fileName, image) => {
  try {
   const response = await fetchSasurl({ fileName });
   const sasURL = response && response.data.payload.url;
   setBlobNames((prevBlobNames) => ({
    ...prevBlobNames,
    [image]: sasURL,
   }));
  } catch (error) {
   console.error("Error fetching blob name:", error);
  }
 };

 useEffect(() => {
  if (data && data.length && sectionsForBlocks && sectionsForBlocks.length) {
   JSON.parse(JSON.stringify(sectionsForBlocks))
    .sort((a, b) => a.pageNumber - b.pageNumber)
    .forEach((section) => {
     const elements = getElements(section.id);
     if (elements && elements.length) {
      elements.forEach((element) => {
       const elementId = element.element.id;
       const elementData = data.find((obj) => obj[elementId]);
       let value = null;
       if (elementData) {
        if (elementId in elementData) {
         value = elementData[elementId];
        }
       }
       if (elementData && elementData[elementId] && elementData[elementId].attachments && (value.itemType === "multiFileUpload" || value.itemType === "singleFileUpload" || value.itemType === "fileUpload")) {
        const imgNameArr = flattenStringifiedArray(elementData[elementId].attachments);
        imgNameArr.forEach((image) => {
         if (Array.isArray(image)) {
          image.forEach((image) => {
           fetchBlobName(image, image);
          });
          return;
         }
         fetchBlobName(image, image);
        });
       }
      });
     }
    });
  }
 }, [data, sectionsForBlocks]);
 useEffect(() => {
  if (data1 && data1.length) {
   data1.forEach((obj) => {
    const dataObj = Object.values(obj)[0];
    if (dataObj.itemType === "multiFileUpload" || dataObj.itemType === "singleFileUpload" || dataObj.itemType === "fileUpload") {
     let attachments = dataObj.attachments;
     if (attachments && attachments.length) {
      attachments = flattenStringifiedArray(attachments);
      attachments.forEach((image) => {
       if (Array.isArray(image)) {
        image.forEach((image) => {
         fetchBlobName(image, image);
        });
        return;
       }
       fetchBlobName(image, image);
      });
     }
    }
   });
  }
 }, [data1]);
 useEffect(() => {
  if (diffElements?.length > 0) {
   const tempElementMap = diffElements.map((obj) => {
    let templateId = obj.diff[0].data.templateItemId;
    return { [templateId]: obj.diff };
   });
   setDiffElementMap(tempElementMap);
  }
 }, [diffElements?.length, tabIndex]);

 useEffect(() => {
  if (diffElements?.length > 0) {
   const tempElementMap = diffElements.map((obj) => {
    let templateId = obj.diff[0].data.id;
    return { [templateId]: obj.diff };
   });
   setdiffElementMap1(tempElementMap);
  }
 }, [diffElements?.length, tabIndex]);
 useEffect(() => {
  if (diffElements?.length > 0) {
   diffElements.forEach((diffElement) => {
    const localData = diffElement.diff[0].data;

    if (localData && localData.itemInfo && localData.itemInfo.hashArray?.length > 0) {
     localData.itemInfo.hashArray.forEach((arr) => {
      arr &&
       arr.length &&
       arr?.forEach((info) => {
        let imageName = info.name;

        imageName && fetchBlobName(imageName, imageName);
       });
     });
    }

    const blockChainData = diffElement.diff[1].data;
    if (blockChainData && blockChainData.itemInfo && blockChainData.itemInfo.hashArray?.length > 0) {
     blockChainData.itemInfo.hashArray.forEach((arr) => {
      arr &&
       arr.length &&
       arr?.forEach((info) => {
        let imageName = info.name;
        imageName && fetchBlobName(imageName, imageName);
       });
     });
    }
   });
  }
 }, [diffElements?.length]);

 let count = 0;
 let count2 = 0;

 const elementHeightsLeft = useRef({});
 const elementHeightsRight = useRef({});
 const elHeightsLeft = useRef({});
 const elHeightsRight = useRef({});
 const formatDate = useCallback(
  (dateString) => {
   const date = new Date(dateString);
   if (isNaN(date.getTime())) {
    return "N.A.";
   }
   const options = { day: "numeric", month: "short", year: "numeric" };
   return date.toLocaleDateString("en-GB", options);
  },
  [inspectionId]
 );

 useEffect(() => {
  // Update heights of left and right elements
  const updateElementHeights = () => {
   Object.keys(elementHeightsLeft.current).forEach((elementId) => {
    const leftHeight = elementHeightsLeft.current[elementId];
    const rightHeight = elementHeightsRight.current[elementId];
    const maxHeight = Math.max(leftHeight, rightHeight);

    if (leftHeight < maxHeight) {
     elementHeightsLeft.current[elementId] = maxHeight;
    }
    if (rightHeight < maxHeight) {
     elementHeightsRight.current[elementId] = maxHeight;
    }
   });
  };

  updateElementHeights();
 }, [elementHeightsLeft.current, elementHeightsRight.current]);
 function getRepeatableComponent(blockData) {
  if (blockData.component.repeatable && blockData.component.repeatable.isRepeatable === true) {
   return blockData.component;
  }
  return null;
 }
 return (
  // Outter most container
  <Box mt="30px" pt="0" h="auto" width="100%">
   {workflowLoading ? (
    <Flex justifyContent="center">
     <CircularProgress isIndeterminate color="teal.400" />
    </Flex>
   ) : (
    <Flex gap="64px" width="100%">
     <Flex width="50%" direction="column">
      <Flex direction="column">
       <Text color="gray/500" fontWeight="600" fontSize="18px">
        Platform Data
       </Text>
       <Flex alignItems="center" gap="12px">
        <Box bg="#b98200" w="12px" h="12px" borderRadius="50%"></Box>
        <Text fontSize="14px" color="gray/500">
         Discripencies
        </Text>
       </Flex>
      </Flex>
      {data && data.length && sectionsForBlocks && sectionsForBlocks.length ? (
       JSON.parse(JSON.stringify(sectionsForBlocks))
        .sort((a, b) => a.pageNumber - b.pageNumber)
        .map((section) => {
         const sections = sectionsForBlocks?.find((currSection) => currSection.id === section.id)?.structure?.blocks;

         let elFromBlock = sections?.map((block) => {
          return getElements1(block.id).components.map((compEl, index) => {
           return compEl;
          });
         });

         if (elFromBlock.length) {
          return (
           <>
            {elFromBlock.map((blocks, index) => {
             return blocks.map((block, innerIndex) => {
              const repeatableComponent = getRepeatableComponent(block);

              if (repeatableComponent) {
               if (tabIndex === 1 || tabIndex === 2) {
                const modifyStatus = [];
                const elements = repeatableComponent.structure?.elements;
                const convertArrayToObject = (arr, index) => {
                 if (index !== 0 && index !== 1) {
                  throw new Error("Invalid index. Please provide 0 or 1.");
                 }

                 const result = {};

                 // Iterate through the array to extract the object based on the given index
                 arr.forEach((item) => {
                  const templateKey = Object.keys(item)[0]; // Get the dynamic key ('uq1zyo76l7ujbsge')
                  const entries = item[templateKey]; // Get the array of entries

                  if (entries && entries[index]) {
                   const entry = entries[index]; // Access the object at the specified index (0 or 1)
                   const data = entry.data;
                   result[data.id] = data; // Use 'id' as the key and the entire data object as the value
                  }
                 });

                 return result;
                };
                const hederaDataLocal = convertArrayToObject(diffElementMap, 0);
                const hederaData = convertArrayToObject(diffElementMap, 1);
                elements.forEach((el) => {
                 const id = el.id;
                 const elementData = data.find((obj) => obj[id]);
                 Object.values(elementData).forEach((obj) => {
                  const isHederaDiffLocal = hederaData.hasOwnProperty(obj.id);
                  isHederaDiffLocal ? modifyStatus.push(true) : modifyStatus.push(false);
                  if (obj.itemType === "multiFileUpload" || obj.itemType === "singleFileUpload" || obj.itemType === "fileUpload") {
                   hederaDataLocal ? modifyStatus.push(true) : modifyStatus.push(false);
                  }
                 });
                });
                if (tabIndex === 1 && modifyStatus.every((status) => !status)) {
                 return <></>;
                }
                if (tabIndex === 2 && modifyStatus.every((status) => status)) {
                 return <></>;
                }
               }

               count += 1;
               return (
                <React.Fragment key={innerIndex}>
                 <ComponentRepeatHedera component={repeatableComponent} data={data} diffElementMap={diffElementMap} count={count} bgColor={"#FFFFF0"} isHederaData={false} blobNames={blobNames} tabIndex={tabIndex} elHeightsLeft={elHeightsLeft} elHeightsRight={elHeightsRight} />
                </React.Fragment>
               );
              } else {
               let elements = block?.component?.structure?.elements;
               if (elements && elements.length) {
                 return elements.map((element) => {
                 const elementId = element.id;
                 
                 const foundObject = diffElementMap.find((obj) => obj.hasOwnProperty(elementId));
                 
                 let isDiff = false;
                 let localData = {};

                 if (foundObject) {
                  const diffArr = foundObject[elementId];
                  localData = diffArr[0].data;
                  isDiff = true;
                 }

                 if (tabIndex === 1 && isDiff === false) {
                  return <></>;
                 }
                 if (tabIndex === 2 && isDiff === true) {
                  return <></>;
                 }

                 const elementData = data && data.length && data.find((obj) => obj[elementId]);
                 let value = null;
                 if (elementData) {
                  if (elementId in elementData) {
                   value = elementData[elementId];
                  }
                 }

                 if (elementData !== undefined && value) count += 1;

                 return elementData !== undefined && value ? (
                  <React.Fragment key={elementId}>
                   <Flex ref={(el) => (elementHeightsLeft.current[elementId] = el && el.clientHeight)} mt={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} mb={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} gap="10px" w="100%" p="16px" id={elementId} border={isDiff ? "1px solid var(--yellow-500, #D69E2E)" : "none"} borderRadius={isDiff ? "8px" : "0px"} background={isDiff ? "var(--yellow-50, #FFFFF0)" : "none"} borderBottom={!isDiff ? "1px solid var(--Grey-Gray-200, #E2E8F0)" : "1px solid var(--yellow-500, #D69E2E)"} pt="24px" pb="24px" height={isDiff ? (elementHeightsRight.current[elementId] > elementHeightsLeft.current[elementId] ? elementHeightsRight.current[elementId] + "px" : "auto") : "auto"}>
                    <Flex border="1px solid #545454" borderRadius="50%" h="16px" w="16px" justifyContent="center" alignItems="center" padding="10px">
                     <Text fontSize="8px" color="#545454">
                      {count}
                     </Text>
                    </Flex>
                    <VStack gap="12px" alignItems="left" w="100%">
                     <Text fontSize="16px" fontWeight="500" color="#2D3748">
                      {isDiff ? localData.title : value.title}
                     </Text>
                     {value.value && (
                      <Text fontSize="16px" fontWeight="400" color="#4A5568">
                       {value.itemType === "datepicker" ? (isDiff ? formatDate(localData.value) : formatDate(value.value)) : isDiff ? localData.value : value.value}
                      </Text>
                     )}

                     {/* Handle file upload components here */}
                     {value.itemType === "multiFileUpload" || value.itemType === "singleFileUpload" || value.itemType === "fileUpload" ? (
                      isDiff ? (
                       <Flex flexWrap="wrap" gap="10px" w="100%">
                        {localData.itemInfo &&
                         localData.itemInfo.hashArray &&
                         localData.itemInfo.hashArray.map((info) => {
                          let imageName = info.name;
                          let modified = info.modified;

                          return (
                           <Tooltip hasArrow isDisabled={modified === false} label={modified !== false ? `Image is ${modified?.toLowerCase()} from the platform` : ""}>
                            <VStack w="30%">
                             <Image key={imageName} h="94px" w="100%" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                             {modified !== false && <Badge colorScheme={modified === "Deleted" ? "red" : "blue"}>{modified?.toUpperCase()}</Badge>}
                            </VStack>
                           </Tooltip>
                          );
                         })}
                       </Flex>
                      ) : (
                       <Flex flexWrap="wrap" gap="10px" w="100%">
                        {value.attachments && value.attachments.length ? (
                         value.attachments.map((imageName) => (
                          <Box w="30%">
                           <Image key={imageName} w="100%" h="94px" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                          </Box>
                         ))
                        ) : (
                         <></>
                        )}
                       </Flex>
                      )
                     ) : (
                      <Text fontSize="16px" fontWeight="400" color="#4A5568">
                       {isDiff ? localData.attachments.join(", ") : value.attachments.join(", ")}
                      </Text>
                     )}
                    </VStack>
                   </Flex>
                  </React.Fragment>
                 ) : (
                  <></>
                 );
                });
               }
              }
             });
            })}
           </>
          );
         }
        })
      ) : data1 && data1.length ? (
       data1.map((element) => {
        const elementId = Object.keys(element)[0];

        const foundObject = diffElementMap1.find((obj) => {
         return obj.hasOwnProperty(elementId);
        });

        let isDiff = false;
        let localData = {};
        if (foundObject) {
         const diffArr = foundObject[elementId];
         localData = diffArr[0].data;
         isDiff = true;
        }

        if (tabIndex === 1 && isDiff === false) {
         return <></>;
        }
        if (tabIndex === 2 && isDiff === true) {
         return <></>;
        }

        const elementData = data1 && data1.length && data1.find((obj) => obj[elementId]);

        let value = null;
        if (elementData) {
         if (elementId in elementData) {
          value = elementData[elementId];
         }
        }

        if (elementData !== undefined && value) {
         if (value.attachments && value.attachments.length) {
          const check = value.attachments.some((attachment) => {
           return attachment.includes(".document") || attachment.includes(".pdf");
          });
          if (check) return;
         }
         count += 1;
        }
        return elementData !== undefined && value ? (
         <>
          <Flex ref={(el) => (elementHeightsLeft.current[elementId] = el && el.clientHeight)} mt={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} mb={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} gap="10px" w="100%" p="16px" id={elementId} border={isDiff ? "1px solid var(--yellow-500, #D69E2E)" : "none"} borderRadius={isDiff ? "8px" : "0px"} background={isDiff ? "var(--yellow-50, #FFFFF0)" : "none"} borderBottom={!isDiff ? "1px solid var(--Grey-Gray-200, #E2E8F0)" : "1px solid var(--yellow-500, #D69E2E)"} pt="24px" pb="24px" height={isDiff ? (elementHeightsRight.current[elementId] > elementHeightsLeft.current[elementId] ? elementHeightsRight.current[elementId] + "px" : "auto") : "auto"}>
           <Flex border="1px solid #545454" borderRadius="50%" h="16px" w="16px" justifyContent="center" alignItems="center" padding="10px">
            <Text fontSize="8px" color="#545454">
             {count}
            </Text>
           </Flex>
           <VStack gap="12px" alignItems="left" w="100%">
            <Text fontSize="16px" fontWeight="500" color="#2D3748">
             {isDiff ? localData.title : value.title}
            </Text>
            {value.value && (
             <Text fontSize="16px" fontWeight="400" color="#4A5568">
              {value.itemType === "datepicker" ? (isDiff ? formatDate(localData.value) : formatDate(value.value)) : isDiff ? localData.value : value.value}
             </Text>
            )}

            {value.attachments && value.attachments.length ? (
             isDiff ? (
              <Flex flexWrap="wrap" gap="10px" w="100%">
               {localData.itemInfo &&
                localData.itemInfo.hashArray &&
                localData.itemInfo.hashArray.map((info) => {
                 let imageName = info.name;
                 let modified = info.modified;

                 return (
                  <Tooltip hasArrow isDisabled={modified === false} label={modified !== false ? `Image is ${modified?.toLowerCase()} from the platform` : ""}>
                   <VStack w="30%">
                    <Image key={imageName} h="94px" w="100%" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                    {modified !== false && <Badge colorScheme={modified === "Deleted" ? "red" : "blue"}>{modified?.toUpperCase()}</Badge>}
                   </VStack>
                  </Tooltip>
                 );
                })}
              </Flex>
             ) : (
              <Flex flexWrap="wrap" gap="10px" w="100%">
               {value.attachments && value.attachments.length ? (
                value.attachments.map((imageName) => (
                 <Box w="30%">
                  <Image key={imageName} w="100%" h="94px" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                 </Box>
                ))
               ) : (
                <></>
               )}
              </Flex>
             )
            ) : (
             <Text fontSize="16px" fontWeight="400" color="#4A5568">
              {isDiff ? localData?.attachments?.join(", ") : value?.attachments?.join(", ")}
             </Text>
            )}
           </VStack>
          </Flex>
         </>
        ) : (
         <></>
        );
       })
      ) : (
       <></>
      )}
     </Flex>

     <Flex width="50%" direction="column">
      <Flex direction="column">
       <Text color="gray/500" fontWeight="600" fontSize="18px">
        Hedera Verified Data
       </Text>
       <Flex alignItems="center" gap="12px">
        <Box bg="#38A169" w="12px" h="12px" borderRadius="50%"></Box>
        <Text fontSize="14px" color="gray/500">
         Verified
        </Text>
       </Flex>
      </Flex>

      {data && data.length && sectionsForBlocks && sectionsForBlocks.length ? (
       JSON.parse(JSON.stringify(sectionsForBlocks))
        .sort((a, b) => a.pageNumber - b.pageNumber)
        .map((section) => {
         const sections = sectionsForBlocks?.find((currSection) => currSection.id === section.id)?.structure?.blocks;


         let elFromBlock = sections?.map((block) => {
          return getElements1(block.id).components.map((compEl, index) => {
           return compEl;
          });
         });

         if (elFromBlock.length) {
          return (
           <>
            {elFromBlock.map((blocks) => {
             return blocks.map((block, innerIndex) => {
              const repeatableComponent = getRepeatableComponent(block);

              if (repeatableComponent) {
               if (tabIndex === 1 || tabIndex === 2) {
                const modifyStatus = [];
                const elements = repeatableComponent.structure?.elements;

                const hederaData = convertArrayToObject(diffElementMap, 1);
                elements.forEach((el) => {
                 const id = el.id;
                 const dataArr = transformObjectKeys(data);
                 const elementData = dataArr.find((obj) => obj[id]);
                 Object.values(elementData).forEach((obj) => {
                  const isHederaLocal = hederaData.hasOwnProperty(obj.id);

                  isHederaLocal ? modifyStatus.push(true) : modifyStatus.push(false);
                 });
                });
                if (tabIndex === 1 && modifyStatus.every((status) => !status)) {
                 return <></>;
                }
                if (tabIndex === 2 && modifyStatus.every((status) => status)) {
                 return <></>;
                }
               }
               count2 += 1;
               return (
                <React.Fragment key={innerIndex}>
                 <ComponentRepeatHedera component={repeatableComponent} data={data} diffElementMap={diffElementMap} count={count2} bgColor={"#F0FFF4"} isHederaData={true} blobNames={blobNames} tabIndex={tabIndex} elHeightsLeft={elHeightsLeft} elHeightsRight={elHeightsRight} />
                </React.Fragment>
               );
              } else {
               let elements = block?.component?.structure?.elements;

               if (elements && elements.length) {
                return elements.map((element) => {
                 const elementId = element.id;

                 const foundObject = diffElementMap.find((obj) => obj.hasOwnProperty(elementId));

                 let isDiff = false;
                 let blockchainData = {};

                 if (foundObject) {
                  const diffArr = foundObject[elementId];
                  blockchainData = diffArr[1].data;
                  isDiff = true;
                 }

                 if (tabIndex === 1 && isDiff === false) {
                  return <></>;
                 }
                 if (tabIndex === 2 && isDiff === true) {
                  return <></>;
                 }

                 const elementData = data && data.length && data.find((obj) => obj[elementId]);

                 let value = null;
                 if (elementData) {
                  if (elementId in elementData) {
                   value = elementData[elementId];
                  }
                 }

                 if (elementData !== undefined && value) count2++;

                 return elementData !== undefined && value ? (
                  <React.Fragment key={elementId}>
                   <Flex ref={(el) => (elementHeightsLeft.current[elementId] = el && el.clientHeight)} mt={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} mb={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} gap="10px" w="100%" p="16px" id={elementId} border={isDiff ? "1px solid var(--Green-Green-500, #38A169)" : "none"} borderRadius={isDiff ? "8px" : "0px"} background={isDiff ? "var(--green-50, #F0FFF4)" : "none"} borderBottom={!isDiff ? "1px solid var(--Grey-Gray-200, #E2E8F0)" : "1px solid var(--Green-Green-500, #38A169)"} pt="24px" pb="24px" height={isDiff ? (elementHeightsRight.current[elementId] > elementHeightsLeft.current[elementId] ? elementHeightsRight.current[elementId] + "px" : "auto") : "auto"}>
                    <Flex border="1px solid #545454" borderRadius="50%" h="16px" w="16px" justifyContent="center" alignItems="center" padding="10px">
                     <Text fontSize="8px" color="#545454">
                      {count2}
                     </Text>
                    </Flex>
                    <VStack gap="12px" alignItems="left" w="100%">
                     <Text fontSize="16px" fontWeight="500" color="#2D3748">
                      {isDiff ? blockchainData.title : value.title}
                     </Text>
                     {value.value && (
                      <Text fontSize="16px" fontWeight="400" color="#4A5568">
                       {value.itemType === "datepicker" ? (isDiff ? formatDate(blockchainData.value) : formatDate(value.value)) : isDiff ? blockchainData.value : value.value}
                      </Text>
                     )}

                     {/* Handle file upload components here */}
                     {value.itemType === "multiFileUpload" || value.itemType === "singleFileUpload" || value.itemType === "fileUpload" ? (
                      isDiff ? (
                       <Flex flexWrap="wrap" gap="10px" w="100%">
                        {blockchainData.itemInfo &&
                         blockchainData.itemInfo.hashArray &&
                         blockchainData.itemInfo.hashArray.map((info) => {
                          let imageName = info.name;
                          let modified = info.modified;

                          return (
                           <Tooltip hasArrow isDisabled={modified === false} label={modified !== false ? `Image is ${modified?.toLowerCase()} from the platform` : ""}>
                            <VStack w="30%">
                             <Image key={imageName} h="94px" w="100%" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                             {modified !== false && <Badge colorScheme={modified === "Deleted" ? "red" : "blue"}>{modified?.toUpperCase()}</Badge>}
                            </VStack>
                           </Tooltip>
                          );
                         })}
                       </Flex>
                      ) : (
                       <Flex flexWrap="wrap" gap="10px" w="100%">
                        {value.attachments && value.attachments.length ? (
                         value.attachments.map((imageName) => (
                          <Box w="30%">
                           <Image key={imageName} w="100%" h="94px" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                          </Box>
                         ))
                        ) : (
                         <></>
                        )}
                       </Flex>
                      )
                     ) : (
                      <Text fontSize="16px" fontWeight="400" color="#4A5568">
                       {isDiff ? blockchainData.attachments.join(", ") : value.attachments.join(", ")}
                      </Text>
                     )}
                    </VStack>
                   </Flex>
                  </React.Fragment>
                 ) : (
                  <></>
                 );
                });
               }
              }
             });
            })}
           </>
          );
         }
        })
      ) : data1 && data1.length ? (
       data1.map((element) => {
        const elementId = Object.keys(element)[0];

        const foundObject = diffElementMap1.find((obj) => obj.hasOwnProperty(elementId));

        let isDiff = false;
        let blockchainData = {};
        if (foundObject) {
         const diffArr = foundObject[elementId];
         blockchainData = diffArr[1].data;
         isDiff = true;
        }

        if (tabIndex === 1 && isDiff === false) {
         return <></>;
        }
        if (tabIndex === 2 && isDiff === true) {
         return <></>;
        }

        const elementData = data1 && data1.length && data1.find((obj) => obj[elementId]);
        let value = null;
        if (elementData) {
         if (elementId in elementData) {
          value = elementData[elementId];
         }
        }

        if (elementData !== undefined && value) {
         if (value.attachments && value.attachments.length) {
          const check = value.attachments.some((attachment) => {
           return attachment.includes(".document") || attachment.includes(".pdf");
          });
          if (check) return;
         }
         count2++;
        }
        return elementData !== undefined && value ? (
         <>
          <Flex ref={(el) => (elementHeightsRight.current[elementId] = el && el.clientHeight)} mt={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} mb={(tabIndex === 1 || tabIndex === 0) && isDiff === true ? "8px" : "0px"} p="16px" gap="10px" w="100%" id={elementId} border={isDiff ? "1px solid var(--Green-Green-500, #38A169)" : "none"} borderRadius={isDiff ? "8px" : "0px"} background={isDiff ? "var(--green-50, #F0FFF4)" : "none"} borderBottom={!isDiff ? "1px solid var(--Grey-Gray-200, #E2E8F0)" : "1px solid var(--Green-Green-500, #38A169)"} pt="24px" pb="24px" height={isDiff ? (elementHeightsLeft.current[elementId] > elementHeightsRight.current[elementId] ? elementHeightsLeft.current[elementId] + "px" : "auto") : "auto"}>
           <Flex border="1px solid #545454" borderRadius="50%" h="16px" w="16px" justifyContent="center" alignItems="center" padding="10px">
            <Text fontSize="8px" color="#545454">
             {count2}
            </Text>
           </Flex>
           <VStack gap="12px" alignItems="left" w="100%">
            <Text fontSize="16px" fontWeight="500" color="#2D3748">
             {isDiff ? blockchainData.title : value.title}
            </Text>
            {value.value && (
             <Text fontSize="16px" fontWeight="400" color="#4A5568">
              {value.itemType === "datepicker" ? (isDiff ? formatDate(blockchainData.value) : formatDate(value.value)) : isDiff ? blockchainData.value : value.value}
             </Text>
            )}

            {value.attachments && value.attachments.length ? (
             isDiff ? (
              <Flex flexWrap="wrap" gap="10px" w="100%">
               {blockchainData.itemInfo &&
                blockchainData.itemInfo.hashArray &&
                blockchainData.itemInfo.hashArray.map((info) => {
                 let imageName = info.name;
                 let modified = info.modified;
                 let hash = info.hash;

                 return (
                  <VStack w="30%">
                   {modified !== false ? (
                    <Tooltip hasArrow isDisabled={modified === false} label={modified !== false ? hash || "" : ""}>
                     <Box h="94px" w="100%" borderRadius="8px" border="1px solid #cbd5e0" p="10px" background="var(--Grey-Gray-200, #E2E8F0)">
                      <Flex justifyContent="center" w="100%" h="100%" alignItems="center">
                       <Text color="var(--gray-600, #4A5568)" fontSize="12px" overflow="hidden" textOverflow="ellipsis" whiteSpace="normal">
                        {hash || ""}
                       </Text>
                      </Flex>
                     </Box>
                    </Tooltip>
                   ) : (
                    <Image key={imageName} h="94px" w="100%" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                   )}
                  </VStack>
                 );
                })}
              </Flex>
             ) : (
              <Flex flexWrap="wrap" gap="10px" w="100%">
               {value.attachments && value.attachments.length ? (
                value.attachments.map((imageName) => (
                 <Box w="30%">
                  <Image key={imageName} w="100%" h="94px" src={blobNames[imageName]} borderRadius="8px" border="1px solid #cbd5e0" />
                 </Box>
                ))
               ) : (
                <></>
               )}
              </Flex>
             )
            ) : (
             <Text fontSize="16px" fontWeight="400" color="#4A5568">
              {isDiff ? blockchainData?.attachments?.join(", ") : value?.attachments?.join(", ")}
             </Text>
            )}
           </VStack>
          </Flex>
         </>
        ) : (
         <></>
        );
       })
      ) : (
       <></>
      )}
     </Flex>
    </Flex>
   )}
  </Box>
 );
};

export default DataComparision;
