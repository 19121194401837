import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  ArcElement,
} from "chart.js"; // Import required elements
import { Doughnut } from "react-chartjs-2";
// import "chartjs-plugin-datalabels"; // Import the plugin

// Register required elements
Chart.register(CategoryScale, LinearScale, Title, Tooltip, ArcElement);

const DoughnutChart = ({ doughnutData, selectedColors }) => {
  const data = {
    labels: ["Red", "Orange", "Yellow", "Green", "Blue"],
    datasets: [
      {
        data: doughnutData,
        backgroundColor: selectedColors,
      },
    ],
    offset: 10,
  };
  const customDatalabels = {
    id: "customDatalabels",
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;

      ctx.save();
      const total = data.datasets[0].data.reduce(
        (acc, curr) => Number(acc) + Number(curr),
        0
      );
      data.datasets[0].data.map((datapoint, index) => {
        const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();
        const percentage = ((datapoint / total) * 100).toFixed(2);
        ctx.font = "12px sans-serif";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(percentage + "%", x, y);
      });
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Doughnut data={data} options={options} plugins={[customDatalabels]} />
  );
};

export default DoughnutChart;
