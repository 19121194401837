/* eslint-disable */
import React, { useEffect } from "react";
import { Badge, Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Image, Stack, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import IconBox from "components/Icons/IconBox";

import "./index.css";
import { Separator } from "components/Separator/Separator";
import SideBarRoutes from "SidebarRoutes";
import { userStorage } from "utils/helper";
import "./index.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as myApprovalSelectors from "views/Users/pages/myApprovals/selectors";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
function SidebarResponsive(props) {
   const location = useLocation();

   const PendingApprovalsCountForBadge = useSelector(
    myApprovalSelectors.selectPendingApprovalsCountForBadge
 );
   const { userProfileData } = useUserContext();

   const isButtonActive = (buttonRoute) => {
      return location.pathname === buttonRoute;
   };
   const imageStyle = {
      height: "65%",
   };
   const mainPanel = React.useRef();

   // Color Mode
   let hamburgerColor = useColorModeValue("gray.500", "gray.200");
   if (props.secondary === true) {
      hamburgerColor = "white";
   }

   // Logo Component
   const logo = (
      <Box pt="0px" mb="8px">
         <Box display="flex" lineHeight="50%" justifyContent="flex-start">
            <Link to="/admin/dashboard">
               <img src={ process.env.REACT_APP_LOGO_URL } alt="logo" style={{ width: "51%", margin:"20px 10px 30px 10px" }} />
            </Link>
         </Box>

         <Separator />
      </Box>
   );

   // Responsive Sidebar Content

   // SIDEBAR
   const { isOpen, onOpen, onClose } = useDisclosure();
   const btnRef = React.useRef();

   let displayedRoutes = JSON.parse(JSON.stringify(SideBarRoutes));

   const entity = getCurrentProfile(userProfileData?.profiles);
   const entityType = entity?.ownerEntityType;

   const userRole = entity?.role.name;
   const isManager = userRole?.includes("manager");
   const isClient = userRole?.includes("client");
   const isSuperAdmin = userRole?.includes("super-admin");
   const isAuditor = userRole?.includes("auditor");
   const isAdmin = userRole?.includes("admin");
   const isViewer = userRole?.includes("viewer");

   if (entityType === "customer") {
      //checks for customer
      if (isAuditor) {
         displayedRoutes = displayedRoutes.filter((route) => route.name === "Bank Audit");
      } else if (isAdmin || isManager) {
         displayedRoutes = displayedRoutes.filter((route) => !["Agency", "Bank Audit","Roles & Permissions"].includes(route.name));
      }
   } else if (entityType === "client") {
      //checks for client
      if (isViewer) {
         displayedRoutes = displayedRoutes.filter((route) => route.name === "Dashboard" || route.name === "Inspections");
      }
   } else {
      // If platform provider
      displayedRoutes = displayedRoutes.filter((route) => route.name !== "Bank Audit");
   }

   const local = localStorage.getItem("inspectionDashboard");
   const loginObject = JSON.parse(local);

   return (
     <Flex
       display={{ sm: "flex", xl: "none" }}
       ref={mainPanel}
       alignItems="center"
     >
       <HamburgerIcon
         _hover={{ cursor: "pointer" }}
         color={hamburgerColor}
         w="18px"
         h="18px"
         ref={btnRef}
         onClick={onOpen}
       />
       <Drawer
         isOpen={isOpen}
         onClose={onClose}
         placement="left"
         finalFocusRef={btnRef}
       >
         <DrawerOverlay />
         <DrawerContent w="250px" maxW="250px">
           <DrawerCloseButton
             mt={"5px"}
             _focus={{ boxShadow: "none" }}
             _hover={{ boxShadow: "none" }}
           />
           <DrawerBody maxW="250px" px="1rem" pt="0px">
             <Box maxW="100%">
               <Box>{logo}</Box>
               <Stack
                 direction="column"
                 mb="40px"
                 // mt={true ? "20px" : "82px"}
               >
                 {displayedRoutes.map((route) => {
                   return `${loginObject?.user?.orgName}` === "HDFC" &&
                     route.name === "Inspections" ? (
                     ""
                   ) : (
                     <Link to={`${route.layout + route.path}`} key={route.path}>
                       <Box
                         key={route.path}
                         className={true ? "sidebar-content-container" : ""}
                         style={{
                           background:
                             isButtonActive(`${route.layout + route.path}`) &&
                             true
                               ? "white"
                               : "",
                           boxShadow:
                             isButtonActive(`${route.layout + route.path}`) &&
                             true
                               ? "0px 7px 11px rgba(0, 0, 0, 0.04)"
                               : "",
                         }}
                         onClick={onClose}
                       >
                         <Box
                           className={true ? "icon-box-sidebar" : "icon-box"}
                           style={{
                             background:
                               isButtonActive(`${route.layout + route.path}`) &&
                               !true
                                 ? "white"
                                 : "",
                           }}
                         >
                           <IconBox
                             h={"30px"}
                             w={"30px"}
                             bg={
                               isButtonActive(`${route.layout + route.path}`)
                                 ? "#4FD1C5"
                                 : "white"
                             }
                             my="auto"
                             boxShadow="0px 7px 11px rgba(0, 0, 0, 0.04)"
                           >
                             <Image
                               src={
                                 isButtonActive(`${route.layout + route.path}`)
                                   ? route.activeIcon
                                   : route.inActiveIcon
                               }
                               style={imageStyle}
                               alt={route.name + " Logo"}
                             />
                           </IconBox>
                         </Box>
                         <Text
                           className={"sidebar-content"}
                           position={"relative"}
                         >
                           {route.name === "Pending Approvals" ? (
                             <>
                               {route.name}
                               {PendingApprovalsCountForBadge ? (
                                 <Badge
                                   px="2px"
                                   bg={"#718096"}
                                   position={"absolute"}
                                   left={"-22px"}
                                   textAlign={"center"}
                                   bottom={"12px"}
                                   color={"#FFFFFF"}
                                   lineHeight={"16px"}
                                   borderRadius={"10px"}
                                   fontSize={"9px"}
                                   w={"20px"}
                                 >
                                   {PendingApprovalsCountForBadge}
                                 </Badge>
                               ) : (
                                 0
                               )}
                             </>
                           ) : (
                             route.name
                           )}
                         </Text>
                       </Box>
                     </Link>
                   );
                 })}
               </Stack>
             </Box>
           </DrawerBody>
         </DrawerContent>
       </Drawer>
     </Flex>
   );
}

export default SidebarResponsive;