import { useState } from "react";
import {
  HStack,
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  VStack,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { actions } from "../slice";

export default function ChangePassword({ emailId }) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasMinLength, setHasMinLength] = useState(false);
  const [hasSpecialChar, setSpecialChar] = useState(false);
  const [message, setMessage] = useState("");
  const [mediaQuerry] = useMediaQuery("(max-width: 889px)");
  const passwordHandler = (pass) => {
    setMessage("");
    setPassword(pass);
  };

  const confirmPasswordHandler = (confirmPass) => {
    setMessage("");
    setConfirmPassword(confirmPass);
  };

  const newPasswordHandler = (newPass) => {
    setMessage("");

    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\/\\-]/;

    setNewPassword(newPass);
    newPass.length >= 8 ? setHasMinLength(true) : setHasMinLength(false);
    specialCharRegex.test(newPass)
      ? setSpecialChar(true)
      : setSpecialChar(false);
  };

  const dispatch = useDispatch();

  const handlePasswordReset = () => {
    setMessage("");

    if (!hasMinLength || !hasSpecialChar) return;
    try {
      if (newPassword !== confirmPassword) {
        setMessage("Passwords do not match");
        return;
      } else if (password === newPassword) {
        setMessage("Old and new passwords must differ");
        return;
      }
    } catch (message) {
      if (message.response && message.response.data) {
        setMessage(message.response.data.reason);
      } else {
        setMessage("Internal server message");
      }
    }
    const passwordData = {
      email: emailId,
      password,
      newPassword,
    };
    dispatch(
      actions.passwordReset({
        passwordData,
        onSuccess: (msg) => {
          setMessage(msg);
        },
      })
    );
    setMessage("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        width: "100%",
        padding: "32px",
        borderRadius: "15px",
      }}
      rowGap={{ base: "1rem", md: mediaQuerry ? "4rem" : "5.5rem" }}
      style={{ position: "relative" }}
    >
      <Box>
        <Text
          style={{
            color: "#2D3748",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "32px",
          }}
        >
          Change Password
        </Text>
        <FormControl sx={{ margin: "20px auto 20px 0" }}>
          <FormLabel>Password</FormLabel>
          <Input
            value={password}
            onChange={(e) => passwordHandler(e.target.value)}
            type="password"
            placeholder="Enter current password"
          />
        </FormControl>
        <FormControl sx={{ margin: "20px auto 20px 0" }}>
          <FormLabel>New Password</FormLabel>
          <Input
            value={newPassword}
            onChange={(e) => newPasswordHandler(e.target.value)}
            type="password"
            placeholder="Enter new password"
          />
        </FormControl>
        <FormControl sx={{ margin: "20px auto 20px 0" }}>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            value={confirmPassword}
            onChange={(e) => confirmPasswordHandler(e.target.value)}
            type="password"
            placeholder="Confirm password"
          />
        </FormControl>
        <VStack
          spacing={2}
          style={{ marginTop: "16px", marginBottom: "24px" }}
          align="left"
        >
          <HStack spacing={2}>
            <CheckCircleIcon
              color={hasMinLength ? "login.500" : "login.gray"}
            />
            <Text>Must have at least 8 characters</Text>
          </HStack>
          <HStack spacing={2}>
            <CheckCircleIcon
              color={hasSpecialChar ? "login.500" : "login.gray"}
            />
            <Text>Must have at least one special characters</Text>
          </HStack>
        </VStack>
      </Box>

      <HStack justifyContent="flex-end">
        {message && (
          <Flex
            align="center"
            color={message.includes("Successful") ? "green" : "red"}
            mt={2}
            gap={2}
          >
            <WarningIcon boxSize={6} mr={1} />
            <Text>{message}</Text>
          </Flex>
        )}
        <Button
          w="212px"
          h="48px"
          colorScheme="login"
          style={{
            marginRight: "6px 0 0 auto",
          }}
          isDisabled={
            hasMinLength &&
            hasSpecialChar &&
            newPassword === confirmPassword &&
            password
              ? false
              : true
          }
          onClick={handlePasswordReset}
        >
          Change Password
        </Button>
      </HStack>
    </Box>
  );
}
