import { Box, Button, Text, VStack } from "@chakra-ui/react";
import { ViewReportIcon } from "components/Icons/Icons";
import React from "react";
import { Link } from "react-router-dom";

export const ActionBtnDropdown = ({ handleCloseModal, inspectionId }) => {
  return (
    <VStack w={"100%"} p={3} borderRadius={"15px"} gap={4}>
      <Box display="flex" flexDirection="column" w="100%">
        <Link to={`/admin/my-approval/inspections/reviewing/${inspectionId}`}>
          <Button
            variant="outline"
            w="100%"
            rightIcon={<ViewReportIcon />}
            onClick={() => {
              handleCloseModal();
            }}
          >
            Review
          </Button>
        </Link>
      </Box>
    </VStack>
  );
};
