import Dashboard from "views/Dashboard/Inspection Table";
import Workflows from "views/Workflow Builder/pages/Workflows";
import AnalyticsDashboard from "views/Dashboard/Analytics Dashboard";
import Warehouses from "views/Dashboard/Warehouses/pages/Warehouses/Warehouses";
import CmTable from "views/Dashboard/Collateral Manager/Pages/Collateral Manager Table/CmTable";
import UserRole from "views/RoleAndPermission/UserRole";

//SVGs
import activeDashboardSVG from "assets/svg/MdDashboard.svg";
import dashboardSVG from "assets/svg/MdDashboard-teal.svg";
import activeInspectionSVG from "assets/svg/HiDocumentSearch.svg";
import inspectionSVG from "assets/svg/HiDocumentSearch-teal.svg";
import activeUserSVG from "assets/svg/IoSettingsSharp.svg";
import InActiveUserSVG from "assets/svg/IoSettingsSharp-teal.svg";
import activeWorkflowSVG from "assets/svg/IoShareSocialSharp-teal.svg";
import inActiveWorkflowSVG from "assets/svg/IoShareSocialSharp.svg";
import inActiveJobSVG from "assets/svg/job-teal.svg";
import activeJobSVG from "assets/svg/job.svg";
import inActiveCustomerSVG from "assets/svg/HiOfficeBuilding-teal.svg";
import activeCustomerSVG from "assets/svg/HiOfficeBuilding.svg";
import inActiveClientSVG from "assets/svg/AiTwotoneBank-teal.svg";
import activeClientSVG from "assets/svg/AiTwotoneBank.svg";
import UserSVG from "assets/svg/HiUsers.svg";
import InactiveUserSVG from "assets/svg/HiUsers-teal.svg";

import { Inspections } from "views/Users/pages/myApprovals";
import activeApprovalSVG from "assets/svg/MdOutlineApproval.svg";
import approvalSVG from "assets/svg/MdOutlineApproval-teal.svg";
import GenerateWorkflow from "views/Workflow/pages/generateWorkflow";

import InActiveWarehouse from "assets/svg/Warehouses-teal.svg";
import ActiveWarehouse from "assets/svg/Warehouses.svg";
import ActiveCMIcon from "assets/svg/Collateral Managers-teal.svg";
import InActiveCMIcon from "assets/svg/Collateral Managers.svg";
import AllJobs from "views/jobs/pages/AllJobs";
import Customers from "views/Customer/Pages/Customers";
import Client from "views/Client/Pages/Client";
import User from "views/User/Pages/User";

var SideBarRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AnalyticsDashboard,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  // {
  //   path: "/dashboard/summary",
  //   name: "Summary",
  //   component: SummaryNCCF,
  //   layout: "/admin",
  //   activeIcon: activeDashboardSVG,
  //   inActiveIcon: dashboardSVG,
  // },
  {
    path: "/inspections",
    name: "Inspections",
    component: Dashboard,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
  },
  {
    path: "/agency",
    name: "Agency",
    component: Customers,
    layout: "/admin",
    activeIcon: activeCustomerSVG,
    inActiveIcon: inActiveCustomerSVG,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Client,
    layout: "/admin",
    activeIcon: activeClientSVG,
    inActiveIcon: inActiveClientSVG,
  },
  {
    path: "/users",
    name: "Users",
    component: User,
    layout: "/admin",
    activeIcon: UserSVG,
    inActiveIcon: InactiveUserSVG,
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    layout: "/admin",
    activeIcon: ActiveWarehouse,
    inActiveIcon: InActiveWarehouse,
  },
  {
    path: "/collateralManagers",
    name: "Collateral Managers",
    component: CmTable,
    layout: "/admin",
    activeIcon: InActiveCMIcon,
    inActiveIcon: ActiveCMIcon,
  },
  {
    path: "/pendingApprovals",
    name: "Pending Approvals",
    component: Inspections,
    layout: "/admin",
    activeIcon: activeApprovalSVG,
    inActiveIcon: approvalSVG,
  },
  {
    path: "/userRoles",
    name: "Roles & Permissions",
    component: UserRole,
    layout: "/admin",
    activeIcon: activeUserSVG,
    inActiveIcon: InActiveUserSVG,
  },
  {
    path: "/workflows",
    name: "Workflow",
    component: Workflows,
    layout: "/admin",
    activeIcon: activeWorkflowSVG,
    inActiveIcon: inActiveWorkflowSVG,
  },
  {
    path: "/viewForm",
    name: "Bank Audit",
    component: GenerateWorkflow,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: AllJobs,
    layout: "/admin",
    activeIcon: activeJobSVG,
    inActiveIcon: inActiveJobSVG,
  },
];
export default SideBarRoutes;
