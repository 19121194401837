import React from "react";
import styles from "./styles.module.css";
import {IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
function Delete({ deleteHandler }) {
  return (
    <>
      <IconButton
        variant="ghost"
        borderRadius="8px"
        sx={{
          _hover: {
            bg: "teal.400",
            color: "white",
            ".icon": {
              color: "white",
            },
          },
        }}
        icon={<DeleteIcon color="main.icon" className="icon" />}
        onClick={deleteHandler}
      />
    </>
  );
}

export default Delete;
