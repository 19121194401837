import { Avatar, Text, Wrap, WrapItem } from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const AvatarWithName = ({ avatarUrl, name }) => {
   const [avatar, setAvatar] = useState("");
   // Generate random color
   const getRandomColor = () => {
     const colors = [
       "red", "blue", "green", "yellow", "purple",
       "orange", "pink", "teal", "cyan", "indigo",
       "maroon", "navy", "lime", "brown", "gray",
       "olive", "silver", "gold", "violet"
     ]; // Add more colors as needed
     const randomIndex = Math.floor(Math.random() * colors.length);
     return colors[randomIndex];
   };
   const randomColor = useMemo(() => getRandomColor());
   // bg={getRandomColor()}

   useEffect(() => {
      setAvatar(avatarUrl);
   }, [avatarUrl]);
   return (
      <Wrap display={"flex"} flexDirection={"row"}>
         <WrapItem>
            <Avatar name={name} size="sm" src={avatarUrl} bg={avatarUrl? "transparent" : randomColor} />
         </WrapItem>
         <Text fontWeight={"500"} alignSelf={"center"}>
            {capitalizeStringUpdated(name)}
         </Text>
      </Wrap>
   );
};

export default AvatarWithName;
