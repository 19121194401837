import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";
import BackIcon from "../../../../../../assets/svg/backBtn.svg";
import { IconButton } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { SideIcon } from "components/Icons/Icons";
import DrawerDropdown from "views/shared/DrawerDropdown";
import _ from "lodash";
export const styles = {
  responsiveDropdown: {
    display: "flex",
    height: "48px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#F7FAFC",
  },
  text: {
    padding: "0px 48px 0px 16px",
  },
  icon: {
    paddingRight: "15px",
  },
  filtersContainer: {
    display: "flex",
    padding: "20px 16px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "15px 15px 0px 0px",
    background: "#FFF",
  },
  filterHead: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "140%",
  },
  filterBtn: {
    padding: "0px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
};
const FilterDrawer = ({
  filterQuery,
  property,
  options,
  handleFilterQuery,
  labelKey,
  valueKey,
  placeholder,
}) => {
  const [openedDropdownId, setOpenedDropdownId] = useState(null);
  return (
    <>
      <Box
        sx={styles.responsiveDropdown}
        onClick={() => {
          setOpenedDropdownId(property);
        }}
      >
        <Text sx={styles.text}>
          {filterQuery[property] && typeof filterQuery[property] === "object"
            ? _.capitalize(filterQuery[property][labelKey] || placeholder)
            : filterQuery[property]
            ? _.capitalize(filterQuery[property])
            : placeholder}
        </Text>
        <Box sx={styles.icon}>
          <SideIcon />
        </Box>
      </Box>
      <Drawer
        isOpen={openedDropdownId === property}
        placement="bottom"
        onClose={() => {
          setOpenedDropdownId(null);
        }}
        size={"full"}
      >
        <DrawerOverlay />
        <DrawerContent sx={styles.filtersContainer} style={{ height: "90vh" }}>
          <DrawerHeader p="0">
            <Flex justify="space-between" alignItems="center">
              <IconButton
                onClick={() => {
                  setOpenedDropdownId(null);
                }}
                background="white"
                icon={<Image src={BackIcon} />}
                position={"absolute"}
                left="16px"
                _focus={{
                  background: "white",
                  transform: "none",
                  borderColor: "transparent",
                }}
              />
              <Heading sx={styles.filterHead}>{placeholder}</Heading>
            </Flex>
          </DrawerHeader>
          <DrawerBody mt={"36px"} w={"100%"} p={"0px"}>
            {/* This part shows the list of options in a different drawer */}
            <DrawerDropdown
              placeholder={placeholder}
              options={options}
              handleFilterQuery={handleFilterQuery}
              property={property}
              labelKey={labelKey}
              valueKey={valueKey}
              filterQuery={filterQuery}
              setOpenedDropdownId={setOpenedDropdownId}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
