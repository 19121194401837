import { useEffect, useState } from "react";
import { HStack, Box } from "@chakra-ui/react";
import Notification from "./component/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { sliceKey, reducer, actions } from "./slice";
import { notificationSaga } from "./saga";
import * as selectors from "./selectors";
import { formatTimeDifference } from "utils/commonFunctions";
export default function Notifications() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: notificationSaga });

  const dispatch = useDispatch();

  const storedLoginData = localStorage.getItem("inspectionDashboard");
  const loginData = JSON.parse(storedLoginData);

  let data = useSelector(selectors.selectNotificationData) || [];

  useEffect(() => {
    dispatch(
      actions.fetchNotifications({
        payload: loginData,
      })
    );
  }, []);

  const handleMarkAllRead = () => {
    dispatch(actions.markReadAllNotifications({ payload: loginData }));
  };

  const handleNotificationRead = (notificationId) => {
    dispatch(actions.notificationRead({ payload: loginData }));
  };

  const modifiedResponse = data?.map((resObj) => {
    return { ...resObj, time: formatTimeDifference(resObj.creationDt) };
  });
  return (
    <Box pt="75px">
      <Notification
        modifiedResponse={modifiedResponse}
        handleMarkAllRead={handleMarkAllRead}
        handleNotificationRead={handleNotificationRead}
      />
    </Box>
  );
}
