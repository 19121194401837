import React from "react";
import "../index.css";
import { Box, Text } from "@chakra-ui/react";
function WarehouseFilterDropdown({
  id,
  warehouse,
  handleSelect,
  toggleDropdown,
  handleWareHouseChange,
}) {
  return (
    <>
      <Box
        style={{ width: "100%", textTransform: "capitalize" }}
        borderBottom="1px solid #E2E8F0"
        className="name-location-container"
        key={id}
        onClick={() => {
          toggleDropdown();
          handleSelect(warehouse.name);
          handleWareHouseChange(warehouse.id);
        }}
      >
        <Text className="warehouse-name-text">
          {warehouse?.name.toLowerCase()}
        </Text>
        <Text className="warehouse-location-text">
          {warehouse?.city.toLowerCase() + " , " + warehouse?.state}
        </Text>
      </Box>
    </>
  );
}

export default WarehouseFilterDropdown;
