import { Table } from "@agnext/reactlib";
import { Box, Checkbox, Switch, Text, Stack } from "@chakra-ui/react";
import React, { useState } from "react";

const CustomHeader = () => {
  return (
    <Box>
      <Switch size="sm" />
      <Text>View Details</Text>
    </Box>
  );
};

const CheckBox = ({ isDisabled, isChecked, onChange }) => {
  return (
    <Box>
      <Checkbox
        colorScheme="teal"
        isDisabled={isDisabled}
        isChecked={isChecked}
        onChange={onChange}
      ></Checkbox>
    </Box>
  );
};

const PermissonsTable = ({
  groupedDataByPages,
  selectedValues,
  setSelectedValues,
  groupedDataByAction,
}) => {
  const handleCheckboxChange = (id) => {
    // Check if the value is already in the array, and toggle accordingly
    setSelectedValues((prevValues) =>
      prevValues?.includes(id)
        ? prevValues?.filter((value) => value !== id)
        : [...prevValues, id]
    );
  };

  const handleCheck = (actionName) => {
    const array = groupedDataByAction && groupedDataByAction[actionName];
    const containsAllElements = array?.every((item) =>
      selectedValues.includes(item.id)
    );
    return containsAllElements;
  };
  const Header = [
    {
      displayText: "Pages",
      keyText: "pages",
    },
    {
      displayText: "Create",
      keyText: "create",
      hasSort: false,
      leftComponent: (
        <Box>
          <Switch
            size={"sm"}
            onChange={() => {
              handleSwitch("create");
            }}
            isChecked={handleCheck("create")}
          />
        </Box>
      ),
    },
    {
      displayText: "Edit",
      keyText: "edit",
      leftComponent: (
        <Box>
          <Switch
            size={"sm"}
            onChange={() => {
              handleSwitch("edit");
            }}
            isChecked={handleCheck("edit")}
          />
        </Box>
      ),
    },
    {
      displayText: "View List",
      keyText: "view_list",
      leftComponent: (
        <Box>
          <Switch
            size={"sm"}
            onChange={() => {
              handleSwitch("view_list");
            }}
            isChecked={handleCheck("view_list")}
          />
        </Box>
      ),
    },
    {
      displayText: "View Details",
      keyText: "view_details",
      leftComponent: (
        <Box>
          <Switch
            size={"sm"}
            onChange={() => {
              handleSwitch("view_details");
            }}
            isChecked={handleCheck("view_details")}
          />
        </Box>
      ),
    },
    {
      displayText: "Delete",
      keyText: "delete",
      leftComponent: (
        <Box>
          <Switch
            size={"sm"}
            onChange={() => {
              handleSwitch("delete");
            }}
            isChecked={handleCheck("delete")}
          />
        </Box>
      ),
    },
  ];

  const reconstructedArray =
    groupedDataByPages &&
    Object.entries(groupedDataByPages).map(([pages, inspections]) => {
      const inspectionData = {
        pages,
        ...inspections.reduce(
          (acc, { actionType, id }) => ({
            ...acc,
            [actionType]: (
              <CheckBox
                isChecked={selectedValues?.includes(id)}
                onChange={() => handleCheckboxChange(id)}
              />
            ),
          }),
          {}
        ),
      };
      return inspectionData;
    });

  groupedDataByPages &&
    reconstructedArray.forEach((data) => {
      if (!data.hasOwnProperty("create")) {
        Object.assign(data, { create: <CheckBox isDisabled={"true"} /> });
      }
      if (!data.hasOwnProperty("delete")) {
        Object.assign(data, { delete: <CheckBox isDisabled={"true"} /> });
      }
      if (!data.hasOwnProperty("edit")) {
        Object.assign(data, { edit: <CheckBox isDisabled={"true"} /> });
      }
      if (!data.hasOwnProperty("view_details")) {
        Object.assign(data, { view_details: <CheckBox isDisabled={"true"} /> });
      }
      if (!data.hasOwnProperty("view_list")) {
        Object.assign(data, { view_list: <CheckBox isDisabled={"true"} /> });
      }
    });

  const handleSwitch = (name) => {
    const isAllSelected = handleCheck(name);
    const array = groupedDataByAction[name];

    setSelectedValues((previousValues) => {
      if (isAllSelected) {
        const newSelectedValues = previousValues.filter(
          (value) => !array.some((item) => item.id === value)
        );
        return newSelectedValues;
      } else {
        const uniqueValuesSet = new Set(previousValues);

        array.forEach((item) => uniqueValuesSet.add(item.id));

        const newSelectedValues = [...uniqueValuesSet];

        return newSelectedValues;
      }
    });
  };

  return (
    <Stack spacing={"24px"}>
      <Box>
        <Text fontWeight={700}>Permissions</Text>
      </Box>
      <Box background="white" borderRadius={12}>
        <Table
          overflow="auto"
          overflowY="auto"
          // tableContainerHeight={{ base: "441px", sm: "378px" }}
          headerList={Header}
          dataList={reconstructedArray}
        />
      </Box>
      <Box></Box>
    </Stack>
  );
};

export default PermissonsTable;
