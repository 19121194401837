import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import View from "views/Workflow Builder/pages/View";
import { watchJobsAndManagement } from "../../saga";
import * as selectors from "../../selectors";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "../../slice";
import ViewForm from "./ViewForm";
const ViewResponses = () => {
   useInjectReducer({ key: sliceKey, reducer: reducer });
   useInjectSaga({ key: sliceKey, saga: watchJobsAndManagement });
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);

   const [selectedFormId, setSelectedFormId] = useState(null);
   const workflowid = searchParams.get("workflowId");
   const formid = searchParams.get("formId");
   const taskId = searchParams.get("taskId");
   const jobId = searchParams.get("jobId");
   const inspectionId = searchParams.get("inspectionId");
   const originId = searchParams.get("originId");
   const dispatch = useDispatch();
   const workflowData = useSelector(selectors.selectWorkflowData);
   const taskList = useSelector(selectors.selectTasksData);

   // console.log({ formid, taskId, jobId, inspectionId, originId });

   const [selectedTaskId, setSelectedTaskId] = useState(null);
   useEffect(() => {
      if (taskId) {
         setSelectedTaskId(taskId);
      }
   }, [taskId]);

   useEffect(() => {
      if (selectedFormId && inspectionId && jobId && selectedTaskId) {
         dispatch(
            actions.fetchReviewData({
               inspectionId,
               jobId,
               taskId: selectedTaskId,
            })
         );

         originId && dispatch(actions.fetchWarehouseDetailsById({ warehouseId: originId }));
         dispatch(
            actions.fetchGetTasksRequest({
               inspectionId: inspectionId,
               jobId: jobId,
            })
         );
      }
   }, [selectedFormId, taskId]);

   useEffect(() => {
      dispatch(actions.fetchWorkflowDataRequest({ workflowId: workflowid }));
   }, [workflowid]);
   useEffect(() => {
      if (formid && selectedFormId === null) {
         let id = formid;
         setSelectedFormId(formid);
         dispatch(actions.fetchFormData({ id }));
      }
      return () => {
         dispatch(actions.clearStates());
      };
   }, [formid]);

   const handleFormClick = useCallback(
      (formId) => {
         setSelectedFormId(formId);
         let currentTask = taskList.find((task) => {
            return task.formId === formId;
         });
         setSelectedTaskId(currentTask?.id);
         let id = formId;
         dispatch(actions.fetchFormData({ id }));
      },
      [selectedFormId]
   );

   const isFormSelected = useCallback(
      (formId) => {
         return formId === selectedFormId;
      },
      [selectedFormId]
   );
   const selectedFormStyleMemo = useMemo(
      () => ({
         borderRadius: "8px",
         border: "1.5px solid var(--Grey-Gray-300, #CBD5E0)",
         background: "var(--White, #FFF)",
         boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
      }),
      []
   );

  const getFormNameWithStatus = useCallback(
    (formId) => {
      const task = taskList?.find((task) => task.formId === formId);
      if (task && Object.keys(task).length) {
        return { formName: task?.name || "N.A.", status: task?.status || "" };
      }
      return { formName: "", status: "" };
    },
    [taskList]
  );

   return (
      <Box mt={"75px"} w={"100%"}>
         <Flex gap="24px" boxSizing="border-box" w={"100%"}>
            <Flex w="260px" direction="column" bg="#F9F9FB" borderRadius="15px" padding={"20px"}>
               {workflowData &&
                  workflowData?.forms?.map((form) => {
                     return (
                        <Box
                           key={form.id}
                           padding="16px 12px"
                           onClick={() => handleFormClick(form.id)}
                           style={isFormSelected(form.id) ? selectedFormStyleMemo : {}}
                        >
                           <Text sx={{ cursor: "pointer" }}>{form.title}</Text>
                        </Box>
                     );
                  })}
            </Flex>
            <Box overflow="auto" borderRadius="15px" w="100%">
               {selectedFormId && (
                  // <View key={selectedFormId} formId={selectedFormId} width="100%" />
                  <ViewForm
                     taskId={selectedTaskId}
                     originId={originId}
                     jobId={jobId}
                     inspectionId={inspectionId}
                     formNameWithStatus={getFormNameWithStatus(selectedFormId)}
                  />
               )}
            </Box>
         </Flex>
      </Box>
   );
};
export default ViewResponses;
