import { HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { dateWithTime } from "utils/commonFunctions";
import LocationInMap from "./LocationInMap";
import { Loader } from "components/Loader/Loader";

function AuditorTackedInfo({
  trackLocation,
  locationLoading,
  inspectionCompletedTime,
}) {
  const [distanceMoved, setDistanceMoved] = useState(0);

  let updatedTrackLocation = [...trackLocation];
  updatedTrackLocation?.sort(
    (a, b) => new Date(a.creationDt) - new Date(b.creationDt)
  );

  const startInspectionLocation =
    updatedTrackLocation.length > 0 ? updatedTrackLocation[0] : undefined;
  const lastInspectionLocation =
    updatedTrackLocation.length > 0
      ? updatedTrackLocation[updatedTrackLocation.length - 1]
      : undefined;

  const startInspectionDate = dateWithTime(startInspectionLocation?.creationDt);
  const completedInspectionDate = inspectionCompletedTime
    ? dateWithTime(inspectionCompletedTime)
    : "-";

  const lastInspectionDate = dateWithTime(lastInspectionLocation?.creationDt);

  // // // get duration
  // // // Parse the ISO strings into Date objects
  const startTime = new Date(startInspectionDate);
  const lastTime = new Date(lastInspectionDate);

  // // // Calculate the difference in milliseconds
  const differenceInMs = lastTime - startTime;

  // // // Convert milliseconds to hours, minutes
  const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
  const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
  const duration = `${hours}h ${minutes}min`;

  const coordinates = updatedTrackLocation?.map((coordinate) => [
    coordinate?.location?.x,
    coordinate?.location?.y,
  ]);

  //find out distance moved using Haversine formula
  function haversineDistance(coord1, coord2) {
    const R = 6371; // Radius of the Earth in kilometers
    const lat1 = coord1[0];
    const lon1 = coord1[1];
    const lat2 = coord2[0];
    const lon2 = coord2[1];

    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance;
  }

  const totalDistanceTraveled = (coordinates) => {
    let totalDistance = 0;
    for (let i = 0; i < coordinates.length - 1; i++) {
      totalDistance += haversineDistance(coordinates[i], coordinates[i + 1]);
    }
    return totalDistance;
  };
  useEffect(() => {
    if (coordinates.length > 0) {
      setDistanceMoved(totalDistanceTraveled(coordinates).toFixed(2));
    } else {
      setDistanceMoved(0);
    }
  }, [coordinates]);

  if(!coordinates.length > 0) return;
  return (
    <VStack
      alignItems="left"
      w="100%"
      gap="24px"
      justifyContent="center"
      borderRadius="8px"
      border="2px solid var(--Grey-Gray-100, #EDF2F7)"
      p="24px 20px"
      color="#4A5568"
      fontSize="16px"
      fontWeight="400"
      lineHeight="22.4px"
    >
      {locationLoading ? (
        <Loader position="center" />
      ) : (
        <>
          <Stack color="#4A5568" fontWeight="600">
            <Text>Auditor Location Tracking</Text>
          </Stack>
          <VStack>
            <LocationInMap coordinates={coordinates} />
          </VStack>
          {/* moment info  */}
          <VStack alignItems="flex-start" alignContent="flex-start" gap="24px">
            <HStack gap="64px">
              <VStack gap="10px" alignItems="flex-start">
                <Text color="#2D3748" fontWeight="500">
                  Inspection Duration
                </Text>
                <Text>{duration}</Text>
              </VStack>
              <VStack gap="10px" alignItems="flex-start">
                <Text color="#2D3748" fontWeight="500">
                  Distance Moved
                </Text>
                <Text> {distanceMoved}km</Text>
              </VStack>
            </HStack>
            <VStack alignItems="flex-start">
              <Text color="#2D3748" fontWeight="500">
                Inspection Started
              </Text>
              <Text>{startInspectionDate}</Text>
              <Text>
                {startInspectionLocation?.address?.fullAddress || "N.A"}(
                {`${startInspectionLocation?.location?.x || "N.A"}, ${
                  startInspectionLocation?.location?.y || "N.A"
                }`}
                )
              </Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Text color="#2D3748" fontWeight="500">
                Inspection Completed
              </Text>
              {inspectionCompletedTime ? (
                <>
                  <Text>{lastInspectionDate}</Text>
                  <Text>
                    {lastInspectionLocation?.address?.fullAddress || "N.A"} (
                    {`${lastInspectionLocation?.location?.x || "N.A"}, ${
                      lastInspectionLocation?.location?.y || "N.A"
                    }`}
                    )
                  </Text>
                </>
              ) : (
                <Text>-</Text>
              )}
            </VStack>
          </VStack>
        </>
      )}
    </VStack>
  );
}

export default AuditorTackedInfo;
