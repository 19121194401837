import React from "react";
import { CircularProgress, Box, Text } from "@chakra-ui/react";

export default function DataWidget({ title, data }) {
  //   const { title, value, loading } = data;

  //   if (analyticsLoading) {
  //     return (
  //       <CircularProgress
  //         isIndeterminate
  //         color="#4FD1C5"
  //         size="40px"
  //         alignSelf={"center"}
  //       />
  //     );
  //   }

  return (
    <Box
      w="100%"
      sx={{
        border: "1px solid #E2E8F0",
        boxShadow: "0px 3.499999761581421px 5.5px rgba(0, 0, 0, 0.02)",
        background: "#fff",
        borderRadius: "15px",
        padding: "20px",
      }}
    >
      <Text
        sx={{
          marginBottom: "10px",
          fontSize: "14px",
          color: "#7e8da2",
          fontWeight: "500",
          lineHeight: "150%",
        }}
      >
        {title}
      </Text>
      {data ? (
        <Text
          sx={{
            fontSize: "28px",
            fontWeight: "700",
            color: "#2d3748",
            lineHeight: "140%",
          }}
        >
          {data.value}
        </Text>
      ) : (
        <></>
      )}
    </Box>
  );
}
