import React, { useState, useEffect } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { postFormDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import Editable from "../../Components/Editable";
import { Box, HStack } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import Section from "./Section";
import { AddIcon } from "@chakra-ui/icons";
import { generateId } from "../../../../utils/index";
import { userStorage } from "utils/helper";
import { useParams } from "react-router-dom";
import InspectionTypes from "views/Workflow Builder/Components/InspectionTypes";

export default function Create() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: postFormDataSaga });
  const dispatch = useDispatch();
  const { formId } = useParams();
  let formData = {};
  const forms = useSelector(selectors.selectForms) || [];
  const sections = useSelector(selectors.selectSectionsForBlocks) || [];
  const blocks = useSelector(selectors.selectBlocksForComposites) || [];
  const composites = useSelector(selectors.selectCompositesForComponents) || [];
  const components = useSelector(selectors.selectComponentsForElements) || [];

  const [form, setForm] = useState("");

  const [description, setDescription] = useState("Description");

  // const [client, setClient] = useState("Client");

  // const [application, setApplication] = useState("Application");

  forms.forEach((form) => {
    if (form.id === formId) {
      formData = { ...form };
    }
  });

  useEffect(() => {
    setForm(formData && formData.title);
    setDescription(formData && formData.description);
  }, [formData]);

  const handlePostData = () => {
    const onSuccess = () => {
      alert("Saved Successfully");
    };
    const onError = () => {
      alert("Something Went Wrong");
    };
    dispatch(
      actions.postFormData({
        data: {
          form: formData,
          sections,
          blocks,
          composites,
          components,
        },
        onSuccess,
        onError,
      })
    );
  };

  const handleFormChange = (value) => {
    setForm(value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleFormSubmit = (form) => {
    dispatch(actions.editFormName({ form, formId }));
  };

  const handleDescriptionSubmit = (description) => {
    dispatch(actions.editFormDescription({ description, formId }));
  };

  const handleAddSection = async () => {
    const sectionId = await generateId();
    const blockId = await generateId();
    const compositeId = await generateId();
    const componentId = await generateId();
    dispatch(actions.initNewSection({ sectionId, formId }));
    dispatch(actions.initNewBlock({ blockId, sectionId }));
    dispatch(actions.initNewComposite({ compositeId, blockId }));
    dispatch(actions.initNewComponent({ componentId, compositeId }));
  };

  return (
    <Box mt={"67px"}>
      <HStack style={{ padding: "8px 0 40px 0" }} justify="space-between">
        <Box w={"60%"} style={{ margin: "32px auto 0" }}>
          <Editable
            text={form}
            handleTextChange={handleFormChange}
            handleSubmit={handleFormSubmit}
          />
          <HStack
            style={{
              padding: "16px 0 0 0",
              borderTop: "1px solid #efefef",
            }}
            justify="space-between"
          >
            <Box style={{ width: "33%" }}>
              <Editable
                text={description}
                handleSubmit={handleDescriptionSubmit}
                handleTextChange={handleDescriptionChange}
              />
            </Box>
            <Box
              style={{
                width: "33%",
                height: "32px",
              }}
            >
              <InspectionTypes />
            </Box>
          </HStack>
        </Box>
        <Box w={"40%"} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="sm"
            onClick={handleAddSection}
            colorScheme="login"
            leftIcon={<AddIcon />}
            label="Section"
          />
        </Box>
      </HStack>
      {sections.map((section) => (
        <Section
          key={section.id}
          sectionId={section.id}
          sectionOrder={section.order}
          sectionOrderLast={
            sections.length >= 1 ? sections[sections.length - 1].order : 0
          }
        />
      ))}
      {sections.length ? (
        <HStack
          justifyContent="flex-end"
          style={{ position: "fixed", right: "32px", bottom: "32px" }}
        >
          <Button
            size="sm"
            onClick={handlePostData}
            colorScheme="login"
            label="Save"
          />
        </HStack>
      ) : (
        <></>
      )}
    </Box>
  );
}
