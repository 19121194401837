import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";

function BulkUserUpload({
  isOpenBulkUserModal,
  onCloseBulkUserModal,
  openDownloadTemplateModal,
  userType,
  handleSetUserType,
  clientsOfCustomer,
  clientId,
  setClientId,
}) {

  const [showDropdown, setShowDropdown] = useState(false);

  const clients = useCallback(() => {
    return clientsOfCustomer?.map((obj) => {
      return {
        ["value"]: obj?.clientName ? obj?.clientName : "XYZ",
        ["id"]: obj?.clientId,
      };
    });
  }, [clientsOfCustomer]);


  const closeUserSelectionModal = ()=>{
    handleSetUserType(null)
    setClientId(null)
    onCloseBulkUserModal()
  }

  return (
    <Modal
      isOpen={isOpenBulkUserModal}
      // onClose={onCloseBulkUserModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={"711px"} borderRadius="15px">
        <ModalHeader
          color={"#2D3748"}
          fontWeight={700}
          fontSize={"18px"}
          borderBottom={"1px solid var(--Grey-Gray-200, #E2E8F0)"}
        >
          Bulk User Upload
        </ModalHeader>
        <ModalCloseButton me="5px" mt="6px" onClick={closeUserSelectionModal} />
        <ModalBody p={"24px"}>
          <VStack gap={"36px"} align={"flex-start"}>
            <VStack gap="8px" width="100%" alignItems="flex-start">
              <Text
                sx={{
                  color: "var(--Grey-Gray-700, #2D3748)",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "140%",
                }}
              >
                Select User Type
              </Text>
              <RadioGroup w="100%" onChange={handleSetUserType} value={userType}>
                <Stack direction="row" flexGrow={"inherit"}>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "14px 18px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                      flex: "1 0 0",
                      borderRadius: "8px",
                      border:
                        "1px solid #CBD5E0",
                    }}
                  >
                    <Radio value="My Users" colorScheme="login">
                      My User
                    </Radio>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "14px 18px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                      flex: "1 0 0",
                      borderRadius: "8px",
                      border:
                        "1px solid #CBD5E0",
                    }}
                  >
                    <Radio value="Client Users" colorScheme="login">
                      Client Users
                    </Radio>
                  </Box>
                </Stack>
              </RadioGroup>
            </VStack>
            {userType === "Client Users" ? (
            <VStack gap="8px" width="100%" alignItems="flex-start">
              <Text
                sx={{
                  color: "var(--Grey-Gray-700, #2D3748)",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "140%",
                }}
              >
                Client
              </Text>

              <Select
                value={clientId}
                onChange={(e) => {
                  const val = e.target.value;
                  setClientId(val);
                }}
                placeholder="Select Client"
                sx={{
                  display: "flex",
                  padding: "14px 18px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "10px",
                  flex: "1 0 0",
                  borderRadius: "8px",
                  border:
                    "var(--border-radius-0, 1px) solid var(--Grey-Gray-300, #CBD5E0)",
                }}
                height="fit-content"
              >
                {clients()?.map((client) => (
                  <option key={client.id} value={client.id}>
                    {capitalizeStringUpdated(client.value)}
                  </option>
                ))}
              </Select>
            </VStack>
             ) : (
              <></>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="login"
            size="md"
            borderRadius={"8px"}
            onClick={() => {
              onCloseBulkUserModal();
              openDownloadTemplateModal();
            }}
            isDisabled={!userType ? true : userType === "Client Users" ? !clientId : false}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default BulkUserUpload;
