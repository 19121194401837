import React, { useCallback, useEffect, useState } from "react";
import { Box, Text, VStack, Stack } from "@chakra-ui/react";
import Component from "../Component";

export default function Components({
  components,
  componentsForMap,
  setSelected,
  selected,
  hoveredComponent,
  setHoveredComponent,
}) {
  const getComponent = useCallback(
    (id) => {
      return components?.find((comp) => comp.id === id);
    },
    [components]
  );
  return (
    <Box
      w="600px"
      p="16px"
      h="calc(100vh - 200px)"
      bg={`rgba(226, 232, 240, .8)`}
      border="1px solid #efefef"
      borderRadius="16px"
    >
      <Text color="#2D3748" fontSize="16px" fontWeight="500" mb="16px">
        Components / Elements
      </Text>
      <Box h="calc(100% - 40px)" overflow="scroll">
        <VStack w="100%" gap="12px">
          {components?.length && componentsForMap?.length ? (
            componentsForMap.map((component, index) => (
              <VStack
                w="100%"
                minH="48px"
                justify="center"
                alignItems="flex-start"
                p="16px"
                bg={`#fff`}
                borderRadius="16px"
              >
                <Component
                  selected={selected}
                  setSelected={setSelected}
                  component={getComponent(component.id)}
                  hoveredComp={hoveredComponent}
                  setHoveredComp={setHoveredComponent}
                />
              </VStack>
            ))
          ) : (
            <></>
          )}
        </VStack>
      </Box>
    </Box>
  );
}
