import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../services/apis";
//Generator Functions

export function* handleFetchAnalyticsData({ payload }) {
  try {
    const res = yield call(API.getAnalyticsData, payload.payload);
    const { data } = res;
    yield put(actions.fetchAnalyticsDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchAnalyticsDataFailure(err?.response?.data));
  }
}

export function* handleFetchBarChartData({ payload }) {
  try {
    const res = yield call(API.getBarChartData, payload.payload);
    const { data } = res;
    yield put(actions.fetchBarChartDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchBarChartDataFailure(err?.response?.data));
  }
}
export function* handleFetchDoughnutChartData({ payload }) {
  try {
    const res = yield call(API.getDoughnutChartData, payload.payload);
    const { data } = res;
    yield put(actions.fetchDoughnutChartDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchDoughnutChartDataFailure(err?.response?.data));
  }
}
export function* handleFetchQuantityInsightsData({ payload }) {
  try {
    const res = yield call(API.getQuantityInsightsData, payload.payload);
    const { data } = res;
    yield put(actions.fetchQuantityInsightsDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchQuantityInsightsDataFailure(err?.response?.data));
  }
}
export function* handleFetchWarehouseInsightsData({ payload }) {
  try {
    const res = yield call(API.getWarehouseInsightsData, payload.payload);
    const { data } = res;
    yield put(actions.fetchWarehouseInsightsDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchWarehouseInsightsDataFailure(err?.response?.data));
  }
}
export function* handleFetchPortfolioLineChartData({ payload }) {
  try {
    const res = yield call(API.getPortfolioLineChartData, payload.payload);
    const { data } = res;
    yield put(actions.fetchPortfolioLineChartDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchPortfolioLineChartDataFailure(err?.response?.data));
  }
}
export function* handleFetchInspectionsLineChartData({ payload }) {
  try {
    const res = yield call(API.getInspectionLineChartData, payload.payload);
    const { data } = res;
    yield put(actions.fetchInspectionsLineChartDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchInspectionLineChartDataFailure(err?.response?.data));
  }
}
export function* handleFetchQuantityLineChartData({ payload }) {
  try {
    const res = yield call(API.getQuantityLineChartData, payload.payload);
    const { data } = res;
    yield put(actions.fetchQuantityLineChartDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchQuantityLineChartDataFailure(err?.response?.data));
  }
}
export function* handleFetchMapChartData({ payload }) {
  try {
    const res = yield call(API.getMapChartData, payload.payload);
    const { data } = res;
    yield put(actions.fetchMapChartDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchMapChartDataFailure(err?.response?.data));
  }
}
export function* handleFetchCommoditiesData({ payload }) {
  try {
    const res = yield call(API.getCommoditiesData, payload.payload);
    const { data } = res;
    yield put(actions.fetchCommoditiesDataSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchCommoditiesDataFailure(err?.response?.data));
  }
}
export function* handleFetchWarehousesData({ payload }) {
  try {
    const res = yield call(API.getWarehouses, payload.payload);
    const { data } = res;
    yield put(actions.fetchWarehousesSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchWarehousesFailure(err?.response?.data));
  }
}

export function* handleFetchWarehousesScore({ payload }) {
  try {
    const res = yield call(API.getWarehouseScore, payload.payload);
    const { data } = res;
    yield put(actions.fetchWarehouseScoreSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchWarehouseScoreFailure(err?.response?.data));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    console.log(err);
    yield put(actions.clearStateFailure(err));
  }
}

export function* handleFetchUserContext({ payload }) {
  try {
    const res = yield call(API.getUserContext, payload);
    const { data } = res;
    yield put(actions.fetchUserContextSuccess(data.payload));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchUserContextFailure(err));
  }
}

function* handleFetchClientsByCustomerId(action) {
  try {
    const response = yield call(API.getClientsByCustomerId, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsOfCustomerSuccess(data.payload));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchClientsOfCustomerFailure(e));
  }
}
function* handleFetchCustomerUsersByRole(action) {
  try {
    const response = yield call(API.getCustomerUsers, action.payload);
    const { data } = response;
    yield put(actions.fetchCustomerUsersSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchCustomerUsersFailure(e));
  }
}

function* handleFetchClientStatisticsData(action) {
  try {
    const response = yield call(
      API.fetchClientsStatisticsData,
      action.payload
    );
    const { data } = response;
    action.payload.onSuccess(data.payload);
    yield put(actions.fetchClientStatisticsDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchClientStatisticsDataFailure(e));
  }
}

function* handleFetchEntityProfileImgURL(action) {
  try {
    const response = yield call(API.getUserProfileImg, action.payload);
    yield put(actions.fetchEntityProfileImgURLSuccess(response));
  } catch (e) {
    yield put(actions.fetchEntityProfileImgURLFailure(e));
  }
}
function* handleFetchInspectionsCompletedData(action) {
  try {
    const response = yield call(
      API.getInspectionsCompletedData,
      action.payload
    );
    const { data } = response;
    yield put(actions.fetchInspectionsCompletedDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchInspectionsCompletedDataFailure(e));
  }
}
function* handleFetchSitesInspectedData(action) {
  try {
    const response = yield call(API.getSitesInspectedData, action.payload);
    const { data } = response;
    yield put(actions.fetchSitesInspectedDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchSitesInspectedDataFailure(e));
  }
}
function* handleFetchClientsInspectedData(action) {
  try {
    const response = yield call(API.getClientsInspectedData, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsInspectedDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchClientsInspectedDataFailure(e));
  }
}
function* handleFetchAgencyAnalyticsData(action) {
  try {
    const response = yield call(API.getAgencyAnalyticsData, action.payload);
    const { data } = response;
    yield put(actions.fetchAgencyAnalyticsDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchAgencyAnalyticsDataFailure(e));
  }
}
function* handleFetchAgencyBarChartData(action) {
  try {
    const response = yield call(
      API.getInspectionsCompletedData,
      action.payload
    );
    const { data } = response;
    yield put(actions.fetchAgencyBarChartDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchAgencyBarChartDataFailure(e));
  }
}
function* handleFetchApprovedInspectionCount(action) {
  try {
    const response = yield call(
      API.getInspectionsCompletedData,
      action.payload
    );
    const { data } = response;
    yield put(actions.fetchApprovedInspectionCountSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchApprovedInspectionCountFailure(e));
  }
}
function* handleAgencyGeographicalReachData(action) {
  try {
    const response = yield call(API.getAgencyGeoReachData, action.payload);
    const { data } = response;
    yield put(actions.fetchAgencyGeoReachDataDataSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchAgencyGeoReachDataDataFailure(e));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* analyticsDashboardSaga() {
  yield takeLatest(
    actions.fetchClientStatisticsData.type,
    handleFetchClientStatisticsData
  );
  yield takeLatest(actions.fetchAnalyticsData.type, handleFetchAnalyticsData);
  yield takeLatest(actions.fetchBarChartData.type, handleFetchBarChartData);
  yield takeLatest(
    actions.fetchDoughnutChartData.type,
    handleFetchDoughnutChartData
  );
  yield takeLatest(
    actions.fetchQuantityInsightsData.type,
    handleFetchQuantityInsightsData
  );
  yield takeLatest(
    actions.fetchWarehouseInsightsData.type,
    handleFetchWarehouseInsightsData
  );
  yield takeLatest(
    actions.fetchPortfolioLineChartData.type,
    handleFetchPortfolioLineChartData
  );
  yield takeLatest(
    actions.fetchInspectionsLineChartData.type,
    handleFetchInspectionsLineChartData
  );
  yield takeLatest(
    actions.fetchQuantityLineChartData.type,
    handleFetchQuantityLineChartData
  );
  yield takeLatest(actions.fetchMapChartData.type, handleFetchMapChartData);
  yield takeLatest(
    actions.fetchCommoditiesData.type,
    handleFetchCommoditiesData
  );
  yield takeLatest(actions.fetchWarehouses.type, handleFetchWarehousesData);
  yield takeLatest(
    actions.fetchWarehouseScore.type,
    handleFetchWarehousesScore
  );
  yield takeLatest(actions.clearState.type, handleClearState);
  yield takeLatest(actions.fetchUserContext.type, handleFetchUserContext);
  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handleFetchClientsByCustomerId
  );
  yield takeLatest(
    actions.fetchCustomerUsersByRole.type,
    handleFetchCustomerUsersByRole
  );
  yield takeLatest(
    actions.fetchEntityProfileImgURL.type,
    handleFetchEntityProfileImgURL
  );
  yield takeLatest(
    actions.fetchInspectionsCompletedData.type,
    handleFetchInspectionsCompletedData
  );
  yield takeLatest(
    actions.fetchSitesInspectedData.type,
    handleFetchSitesInspectedData
  );
  yield takeLatest(
    actions.fetchClientsInspectedData.type,
    handleFetchClientsInspectedData
  );
  yield takeLatest(
    actions.fetchClientsInspectedData.type,
    handleFetchClientsInspectedData
  );
  yield takeLatest(
    actions.fetchAgencyAnalyticsData.type,
    handleFetchAgencyAnalyticsData
  );
  yield takeLatest(
    actions.fetchAgencyBarChartData.type,
    handleFetchAgencyBarChartData
  );
  yield takeLatest(
    actions.fetchApprovedInspectionCount.type,
    handleFetchApprovedInspectionCount
  );
  yield takeLatest(
    actions.fetchAgencyGeographicalReachData.type,
    handleAgencyGeographicalReachData
  );
}
