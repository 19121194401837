import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.warehouse || initialState;

export const selectWarehouseInsightsData = createSelector(
  [selectDomain],
  (state) => state.warehouseInsightsData
);

export const selectWarehouseInsightsLoading = createSelector(
  [selectDomain],
  (state) => state.warehouseInsightsLoading
);

export const error = createSelector([selectDomain], (state) => state.error);
