import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.bulkUpload || initialState;

export const selectClientsOfCustomer = createSelector(
  [selectDomain],
  (state) => state.clientsOfCustomer
);

export const selectDataErrorResponse = createSelector(
  [selectDomain],
  (state) => state.dataErrorResponse
);

export const selectClientAvatar = createSelector(
  [selectDomain],
  (state) => state.clientAvatarUrl
);
