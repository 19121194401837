import React, { useState, useEffect } from "react";
import {
  Stack,
  HStack,
  VStack,
  Box,
  Checkbox,
  CheckboxGroup,
  Input,
  Text,
  Badge,
} from "@chakra-ui/react";
// import { Input } from "@agnext/reactlib";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { Search2Icon, SmallCloseIcon } from "@chakra-ui/icons";
import { filterArray } from "utils/utils";

function filterSelectedOptions(options, selectedArr) {
  return options.filter((option) => selectedArr.includes(option.value));
}

export default function CheckboxWithSearch({
  data,
  handleFormDataChange,
  formData,
  repeatable,
  index,
}) {
  if (repeatable?.isRepeatable) {
    const repeatIndex = index;

    const values = formData[data.element.id].attachments[repeatIndex]
      ? JSON.parse(formData[data.element.id].attachments[repeatIndex])
      : [];

    const handleChange = (val) => {
      handleFormDataChange(
        JSON.stringify(val),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    };

    const [filterText, setFilterText] = useState("");

    const handleFilterTextChange = (e) => {
      setFilterText(e.target.value);
    };

    const handleRemoveSelection = (val) => {
      const newValues = [...values].filter((currVal) => currVal !== val);
      handleChange(newValues);
    };

    return (
      <Stack>
        {formData[data.element.id] ? (
          <>
            <HStack
              w="100%"
              h="48px"
              style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
            >
              <HStack w="48px" h="48px" justify="center" align="center">
                <Search2Icon size={18} color="#718096" />
              </HStack>
              <Input
                variant="unstyled"
                color="#2D3748"
                placeholder="Search Options"
                _placeholder={{ color: "#A0AEC0" }}
                value={filterText}
                onChange={handleFilterTextChange}
              />
            </HStack>

            {values && values.length ? (
              <VStack
                w="100%"
                padding="16px"
                style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
                align="flex-start"
              >
                <Text color="#2D3748">Selected Options</Text>
                <HStack flexWrap="wrap">
                  {data &&
                  data.element &&
                  data.element.properties &&
                  data.element.properties.options ? (
                    filterSelectedOptions(
                      data.element.properties.options,
                      values
                    ).map((option) => {
                      const capitalizedOption = capitalizeStringUpdated(
                        option.label
                      );
                      return (
                        <HStack
                          size="md"
                          key={option.value}
                          align="center"
                          justify="center"
                          flexWrap="wrap"
                        >
                          <span
                            style={{
                              textTransform: "none",
                              background: "#4A5568",
                              borderRadius: "12px",
                              padding: "5px 12px",
                              maxWidth: "100%",
                            }}
                          >
                            <Text
                              maxWidth="100%"
                              fontSize="12px"
                              fontWeight="500"
                              color="#fff"
                              letterSpacing=".5px"
                            >
                              {capitalizedOption}
                            </Text>
                          </span>
                          <Badge
                            padding="5px"
                            style={{
                              textTransform: "none",
                              background: "#4A5568",
                              borderRadius: "40%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleRemoveSelection(option.value);
                            }}
                          >
                            <SmallCloseIcon w="4" h="4" color="#fff" />
                          </Badge>
                        </HStack>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </HStack>
              </VStack>
            ) : (
              <></>
            )}

            <CheckboxGroup
              name={`${data.element.id}${repeatIndex}`}
              value={values}
              onChange={(val) => {
                handleChange(val);
              }}
            >
              {data &&
              data.element &&
              data.element.properties &&
              data.element.properties.options ? (
                filterArray(data.element.properties.options, filterText).map(
                  (option) => {
                    const capitalizedOption = capitalizeStringUpdated(
                      option.label
                    );
                    return (
                      <Box
                        style={{
                          border: "1px solid #EDF2F7",
                          borderRadius: "8px",
                          padding: "12px",
                        }}
                        key={option.value}
                      >
                        <Checkbox
                          size="md"
                          colorScheme="login"
                          value={option.value}
                        >
                          {capitalizedOption}
                        </Checkbox>
                      </Box>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </CheckboxGroup>
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  }
  const [filterText, setFilterText] = useState("");

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleRemoveSelection = (val) => {
    const newValues = [...formData[data.element.id].attachments].filter(
      (currVal) => currVal !== val
    );
    handleFormDataChange(newValues, "", data.element.id, "NA");
  };

  return (
    <Stack>
      {formData[data.element.id] ? (
        <>
          <HStack
            w="100%"
            h="48px"
            style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
          >
            <HStack w="48px" h="48px" justify="center" align="center">
              <Search2Icon size={18} color="#718096" />
            </HStack>
            <Input
              variant="unstyled"
              color="#2D3748"
              placeholder="Search Options"
              _placeholder={{ color: "#A0AEC0" }}
              value={filterText}
              onChange={handleFilterTextChange}
            />
          </HStack>

          {formData &&
          formData[data.element.id] &&
          formData[data.element.id].attachments ? (
            <VStack
              w="100%"
              padding="16px"
              style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
              align="flex-start"
            >
              <Text color="#2D3748">Selected Options</Text>
              <HStack flexWrap="wrap">
                {data &&
                data.element &&
                data.element.properties &&
                data.element.properties.options ? (
                  filterSelectedOptions(
                    data.element.properties.options,
                    formData[data.element.id].attachments
                  ).map((option) => {
                    const capitalizedOption = capitalizeStringUpdated(
                      option.label
                    );
                    return (
                      <HStack
                        size="md"
                        key={option.value}
                        align="center"
                        justify="center"
                        flexWrap="wrap"
                      >
                        <span
                          style={{
                            textTransform: "none",
                            background: "#4A5568",
                            borderRadius: "12px",
                            padding: "5px 12px",
                            maxWidth: "100%",
                          }}
                        >
                          <Text
                            maxWidth="100%"
                            fontSize="12px"
                            fontWeight="500"
                            color="#fff"
                            letterSpacing=".5px"
                          >
                            {capitalizedOption}
                          </Text>
                        </span>
                        <Badge
                          padding="5px"
                          style={{
                            textTransform: "none",
                            background: "#4A5568",
                            borderRadius: "40%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleRemoveSelection(option.value);
                          }}
                        >
                          <SmallCloseIcon w="4" h="4" color="#fff" />
                        </Badge>
                      </HStack>
                    );
                  })
                ) : (
                  <></>
                )}
              </HStack>
            </VStack>
          ) : (
            <></>
          )}

          <CheckboxGroup
            name={data.element.id}
            value={formData[data.element.id].attachments}
            onChange={(val) => {
              handleFormDataChange(val, "", data.element.id, "NA");
            }}
          >
            {data &&
            data.element &&
            data.element.properties &&
            data.element.properties.options ? (
              filterArray(data.element.properties.options, filterText).map(
                (option) => {
                  const capitalizedOption = capitalizeStringUpdated(
                    option.label
                  );
                  return (
                    <Box
                      style={{
                        border: "1px solid #EDF2F7",
                        borderRadius: "8px",
                        padding: "12px",
                      }}
                      key={option.value}
                    >
                      <Checkbox
                        size="md"
                        colorScheme="login"
                        value={option.value}
                      >
                        {capitalizedOption}
                      </Checkbox>
                    </Box>
                  );
                }
              )
            ) : (
              <></>
            )}
          </CheckboxGroup>
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
}
