import React, { useEffect, useState } from "react";

const Email = ({ links }) => {
  const [clientLink, setClientLink] = useState(null);

  useEffect(() => {
    if (links && links.length) {
      links?.forEach((link) => {
        if (link.linkType === "email") {
          setClientLink(link.value);
        }
      });
    }
  }, [links]);

  return <div>{clientLink || "-"}</div>;
};

export default Email;
