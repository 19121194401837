import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ViewRoleTopCard from "./components/ViewRoleTopCard/ViewRoleTopCard";
import ViewRoleBottom from "./ViewRoleBottom/ViewRoleBottom";
import ViewRoleUserTable from "./components/ViewRoleUserTable/ViewRoleUserTable";
import { actions, sliceKey, reducer } from "./slice";
import { watchRolesAndPermission } from "./saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "./selectors";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewRolePermission from "./components/ViewRolePermission/ViewRolePermission";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

const ViewRole = () => {
  const dispatch = useDispatch();
  const { roleId } = useParams();
  const history = useHistory();

  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchRolesAndPermission });

  const [rerenderKey, setRerenderKey] = useState(0);

  const rolePermissionData = useSelector(selectors.selectRolePermissionData);
  const roleInformation = useSelector(selectors.selectEditData);
  const groupedData = rolePermissionData?.payload.reduce((acc, currentItem) => {
    const { permsSetName, ...rest } = currentItem;
    if (!acc[permsSetName]) {
      acc[permsSetName] = [];
    }
    acc[permsSetName].push({ ...rest });
    return acc;
  }, {});

  const createById = roleInformation?.payload?.createdBy;
  const updatedById = roleInformation?.payload?.updatedBy;

  const userIds = [createById, updatedById].filter(
    (id) => id !== null || undefined || ""
  );

  useEffect(() => {
    if (roleId) {
      dispatch(actions.fetchRolePermissionsDataRequest({ roleId }));
      dispatch(actions.fetchEditRoleRequest({ roleId }));
      dispatch(actions.fetchUserAssignedDataRequest({ roleId }));
      dispatch(actions.fetchUserRoleRequest());
      dispatch(actions.fetchSingleUsersRequest(roleId));
    }
  }, [roleId, rerenderKey]);

  useEffect(() => {
    if (roleInformation && userIds.length > 0) {
      dispatch(actions.fetchUserDetailsRequest(userIds));
    }
  }, [roleInformation]);

  const userData = useSelector(selectors.selectUserAssignedData);
  const userDataError = useSelector(selectors.selectUserAssignedDataError);
  const allRolesData = useSelector(selectors.selectUserData);
  const singleUsersData = useSelector(selectors.selectSingeUsers);
  const usersAssigned = singleUsersData?.data?.payload?.length;
  const singleUserAssigned = singleUsersData?.data?.payload
    ?.map((data) => {
      if (data.roleIds.length === 1) {
        return data.roleIds[0];
      }
    })
    .filter((value) => value !== undefined);
  const previousUsersData = userData?.data?.payload?.length;
  const handleDeleteButton = (nextStep, finalStep) => {
    if (userData && userDataError === null) {
      if (usersAssigned > 0) {
        nextStep();
      } else {
        finalStep();
      }
    }
  };

  const deleteRole = (newRoleId, finalAction) => {
    dispatch(actions.fetchDeleteRoleRequest({ roleId, newRoleId }));
    finalAction();
    history.goBack();
  };

  const userDetails = useSelector(selectors.selectUserDetails);
  const createdBy = userDetails && userDetails[0];
  const updatedBy = userDetails && userDetails[1];

  const allRoles = allRolesData?.payload
    ?.map((role) => {
      return (
        role.roleName !== roleInformation?.payload?.roleName && {
          roleName: role.roleName,
          id: role.roleId,
        }
      );
    })
    .filter(Boolean);
  const totalUserRoles = allRolesData?.payload.length;
  const handleDeactivateSwitch = (openFirstAction, handleFinalAction) => {
    if (singleUserAssigned.length > 0) {
      openFirstAction();
    } else {
      handleFinalAction();
    }
  };

  const handleDeactivateButton = (newRoleInfo) => {
    dispatch(actions.fetchDeactivateRoleRequest({ roleId, newRoleInfo }));
    setRerenderKey((prevKey) => prevKey + 1);
    if (rerenderKey > 10000) {
      setRerenderKey(0);
    }
  };

  const handleActivateRole = (isRevokeAssignments) => {
    dispatch(actions.fetchActivateRoleRequest({ roleId, isRevokeAssignments }));
    setRerenderKey((prevKey) => prevKey + 1);
    if (rerenderKey > 10000) {
      setRerenderKey(0);
    }
  };

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Flex direction={"column"} gap={"36px"}>
          <ViewRoleTopCard
            roleDetails={roleInformation?.payload}
            createdBy={createdBy}
            updatedBy={updatedBy}
          />
          <ViewRolePermission groupedData={groupedData} />
          {/* <ViewRoleUserTable userData={userData} /> */}
          <ViewRoleBottom
            deleteRole={deleteRole}
            handleDeleteButton={handleDeleteButton}
            roleDetails={roleInformation?.payload}
            allRoleNames={allRoles}
            totalUserRoles={totalUserRoles}
            userAssigned={usersAssigned}
            handleDeactivateSwitch={handleDeactivateSwitch}
            singleUserAssigned={singleUserAssigned}
            handleDeactivateButton={handleDeactivateButton}
            previousUsersData={previousUsersData}
            handleActivateRole={handleActivateRole}
          />
        </Flex>
      </Box>
    </ErrorBoundary>
  );
};

export default ViewRole;
